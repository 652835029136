import LEND_POOL_ABI from './abis/ILendPool.json'
import ERC721_ABI from './abis/IERC721Detailed.json'
import LEND_POOL_LOAN_ABI from './abis/LendPoolLoan.json'
import WETH_GATEWAY_ABI from './abis/IWETHGateway.json'
import PUNK_GATEWAY_ABI from './abis/IPunkGateway.json'
import WRAPPED_PUNKS_ABI from './abis/IWrappedPunks.json'
import CRYPTO_PUNKS_MARKET_ABI from './abis/CryptoPunksMarket.json'
import UI_POOL_DATA_PROVIDER_ABI from './abis/UiPoolDataProvider.json'
import BEND_COMPETITION_ABI from './abis/BendCompetition.json'
import BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI from './abis/BendProtocolIncentivesController.json'
import WETH_ABI from './abis/Weth.json'
import LEND_POOL_ADDRESS_PROVIDER_ABI from './abis/LendPoolAddressesProvider.json'
import MERKLE_DISTRIBUTOR_ABI from './abis/MerkleDistributor.json'
import VE_BEND_ABI from './abis/VeBend.json'
import FEE_DISTRIBUTOR_ABI from './abis/FeeDistributor.json'
import UNISWAP_PAIR_ABI from './abis/UniswapV2Pair.json'
import DEBT_TOKEN_ABI from './abis/DebtToken.json'
import BNFT_ABI from './abis/BNFT.json'
import BOUND_PUNK_GATEWAY_ABI from './abis/BoundPunkGateway.json'
import AIRDROP_DISTRIBUTION_CONTRACT_ABI from './abis/AirdropDistribution.json'
import BEND_STAKE_ABI from './abis/StakedBUNI.json'
import ENS_PUBLIC_RESOLVER_ABI from './abis/ens-public-resolver.json'
import ENS_ABI from './abis/ens-registrar.json'
import EIP2981_ABI from './abis/EIP2981.json'
import { ChainId } from 'wallet-module'

const DEFAULT_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID || '5'

const ADDRESSES: any = {
  [ChainId.MAINNET]: {
    FEE_DISTRIBUTOR_ADDRESS: '0x2338D34337dd0811b684640de74717B73F7B8059',
    VE_BEND_ADDRESS: '0xd7e97172C2419566839Bf80DeeA46D22B1B2E06E',
    MERKLE_DISTRIBUTOR_ADDRESS: '0x1b5d2904bE3E4711a848Be09b17dEE89E6A5BC27',
    LEND_POOL_ADDRESSES_PROVIDER: '0x24451F47CaF13B24f4b5034e1dF6c0E401ec0e46',
    LEND_POOL: '0x70b97A0da65C15dfb0FFA02aEE6FA36e507C2762',
    LEND_POOL_LOAN: '0x5f6ac80CdB9E87f3Cfa6a90E5140B9a16A361d5C',
    UI_POOL_DATA_PROVIDER: '0x5250cCE48E43AB930e45Cc8E71C87Ca4B51244cf',
    WETH_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000001',
    PUNK_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000002',
    WETH_ADDRESS: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    BEND_ADDRESS: '0x0d02755a5700414B26FF040e1dE35D337DF56218',
    CRYPTOPUNKS_ADDRESS: '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB',
    WPUNKS_ADDRESS: '0xb7F7F6C52F2e2fdb1963Eab30438024864c313F6',
    BWETH: '0xeD1840223484483C0cb050E6fC344d1eBF0778a9',
    BDebtWETH: '0x87ddE3A3f4b629E389ce5894c9A1F34A7eeC5648',
    BEND_COMPETITION: '0xA1756D785F4abD4ec06dB71c65f18D25083CbF88',
    BEND_PROTOCOL_INCENTIVES_CONTROLLER: '0x26FC1f11E612366d3367fc0cbFfF9e819da91C8d',
    BEND_ETH_UNISWAP_POOL: '0x336ef4e633b1117DCA08C1a57f4139C62c32c935',
    BEND_COLLECTOR: '0x43078AbfB76bd24885Fd64eFFB22049f92a8c495',
    BOUND_PUNK_GATEWAY: '0x9f3A8d7F61F6407190fe5264ad88F88F39e214a8k',
    AIRDROP_DISTRIBUTION_CONTRACT: '0x6D187449A5664DD87E58A9d3b982989AaeA469BD',
    BEND_STAKE: '0x647C509AF2A2b2294bB79fCE12DaEc8e7cf938f7',
    BEND_EXCHANGE: '0x7e832eC8ad6F66E6C9ECE63acD94516Dd7fC537A',
    AUTHORIZATION_MANAGER: '0x422b1179b63F9327BEdedeA64B75C942744dFaC8',
    REDEEM_NFT: '0xb645d88807f932Aba29eE6B28e6A5F1467508498',
    STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE: '0x80d190Fa1b1bB5488baD69FD3A89bf52821d4CCA',
    ROYALTY_FEE_MANAGER: '0xe377f6eF3E86E532a49625F866750306A478245F',
    DOWNPAYMENT: '0x3710D54de90324C8BA4B534D1e3F0fCEDc679ca4',
    DOWNPAYMENT_BEND_EXCHANGE_ADAPTER: '0xfD8F1f5AAA388ed3bF28B8Adc91D45c8B9db1384',
    DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER: '0xe276C2EeD4Fbf6045BA3AdB0783b0BAf9C6F5cD1',
    DOWNPAYMENT_PUNK_ADAPTER: '0x95c9ee5c9DBfC187743AFF7Af0F647821e95066D',
    DOWNPAYMENT_SEAPORT_ADAPTER: '0x5c657B29BB3d89228Dbf489D67279D39ae862d9d',
    DOWNPAYMENT_X2Y2_ADAPTER: '0xc384bBd0a25DBE353dd8a09073D636744cFad15B'
  },
  [ChainId.RINKEBY]: {
    FEE_DISTRIBUTOR_ADDRESS: '0x25dCb84b92A05f7a45d78e1A876DD526CfA2fa2D',
    VE_BEND_ADDRESS: '0x13b5d4FC8AcFD8e273C3B401F1B2D9e984C05e0A',
    MERKLE_DISTRIBUTOR_ADDRESS: '0x631369f9A13d23142563fda08E6bB3fAa8c3852b',
    LEND_POOL_ADDRESSES_PROVIDER: '0xE55870eBB007a50B0dfAbAdB1a21e4bFcee5299b',
    LEND_POOL: '0xa8D0e90bCB533d70067EEc37601fa7D5C5b3F13E',
    LEND_POOL_LOAN: '0xbc622a62bAC3B7de0659A0Dc7b50E4d59aF11377',
    UI_POOL_DATA_PROVIDER: '0x63D7Dfd72e291e8df962e24b9f8EcE6d18d06a5a',
    WETH_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000001',
    PUNK_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000002',
    WETH_ADDRESS: '0xc778417e063141139fce010982780140aa0cd5ab',
    BEND_ADDRESS: '0xe375EC95ecd0ACd4c2a04Bfb0f576f4A89097734',
    CRYPTOPUNKS_ADDRESS: '0x6389eA3Cf6dE815ba76d7Cf4C6Db6A7093471bcb',
    WPUNKS_ADDRESS: '0x74e4418A41169Fb951Ca886976ccd8b36968c4Ab',
    BWETH: '0x162f6ef816c8b03193c50852fffb570d97ceea2f',
    BDebtWETH: '0x054fc05030a65bb30671f28ea5d668f56e4970d7',
    BEND_COMPETITION: '0x4647c9270D92247b149f7d244702235319bdee57',
    BEND_PROTOCOL_INCENTIVES_CONTROLLER: '0xEC087671c68c7fE8aAeDEf974B7C2aAe3BC6be3b',
    BEND_ETH_UNISWAP_POOL: '0x170DC266c6A65C4C686De29E5D4Fc27270373014',
    BEND_COLLECTOR: '0x7a02ee743aadca63d60945971b7ed12c7f26b6d2',
    BOUND_PUNK_GATEWAY: '0xF482E6E29c0fe449b2A7326CDe850dcf8CE62e32',
    AIRDROP_DISTRIBUTION_CONTRACT: '0x931d1664A61D4F4d47a262e321Ea11cbef50cb00',
    BEND_STAKE: '0xA8b8694D3ca39C301bC409b235df77918b9f1594',
    BEND_EXCHANGE: '0xFeCfD28A068BcB9AAeB1c830870093B24750B076',
    AUTHORIZATION_MANAGER: '0x43C18Fc06f3E6C94c0A1Ee602b45A1554d3e10C0',
    REDEEM_NFT: '0x22E3baBb76fed68eEb287753C84E5bF63113Fe56',
    STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE: '0xC2A7b92C31e8ADdA71e8AB8bfe23955053D508aB',
    ROYALTY_FEE_MANAGER: '0x784F94116b5784Aebf98EC08e4CA51A7890b76cf',
    DOWNPAYMENT: '0xFeF524B113c112B42B5a081217F7006973408579',
    DOWNPAYMENT_BEND_EXCHANGE_ADAPTER: '0x20445Eaa9bb1AD614372dDADF0A11b87C3809194',
    DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER: '0x228DF03DDF0cDb52C9c411c10555C42cF0168F81',
    DOWNPAYMENT_PUNK_ADAPTER: '0x953C1535CA684528A9e93F1BC820E863Db491188',
    DOWNPAYMENT_SEAPORT_ADAPTER: '0xb1182Ef7fE8d9Dcb86A92Ab3771cfEF5d00295A9',
    DOWNPAYMENT_X2Y2_ADAPTER: '0x5483Fdf46b082A304d8424483B38357dE20E6e71'
  },
  [ChainId.GÖRLI]: {
    FEE_DISTRIBUTOR_ADDRESS: '0x9e0EbD7AB8b6457E2461583c48CAc66589E1958E',
    VE_BEND_ADDRESS: '0x2e308F03bFd57B1b36570aDC710C6A130C27366E',
    MERKLE_DISTRIBUTOR_ADDRESS: '0x94150C5cb7881d25eD4360127ccBDA853bFb6a30',
    LEND_POOL_ADDRESSES_PROVIDER: '0x1cba0A3e18be7f210713c9AC9FE17955359cC99B',
    LEND_POOL: '0x84a47EaEca69f8B521C21739224251c8c4566Bbc',
    LEND_POOL_LOAN: '0x7F64c32a3c13Bd245a7141a607A7E60DA585BA86',
    UI_POOL_DATA_PROVIDER: '0x15073180CA8b933C7a8099e811FFAD0568eAb861',
    WETH_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000001',
    PUNK_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000002',
    WETH_ADDRESS: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    BEND_ADDRESS: '0xE15A78992dd4a9d6833eA7C9643650d3b0a2eD2B',
    CRYPTOPUNKS_ADDRESS: '0xBccC7a1E79215EC3FD36824615801BCeE0Df2eC3',
    WPUNKS_ADDRESS: '0xbeD1e8B430FD512b82A18cb121a8442F3889E505',
    BWETH: '0x57FEbd640424C85b72b4361fE557a781C8d2a509',
    BDebtWETH: '0x9aB83A4886dCE3C0c1011f9D248249DD3eF64784',
    BEND_COMPETITION: '',
    BEND_PROTOCOL_INCENTIVES_CONTROLLER: '0x292F693048208184320C01e0C223D624268e5EE7',
    BEND_ETH_UNISWAP_POOL: '0x1b2A26C8c107eD4a36957bCfbe07E5F6E6a1EF51',
    BEND_COLLECTOR: '0x32B08f895d93a207e8A5C9405870D780A43b25Dd',
    BOUND_PUNK_GATEWAY: '0x0f72Dae580E04AdAe973cD12C4f8FdCE8d23cAC6',
    AIRDROP_DISTRIBUTION_CONTRACT: '0x3CF52C9ce29a1df2adCd0Ce657BbBBB63A3f9D01',
    BEND_STAKE: '0x1f9b18D502d8A406564b0cF4e4B7ad9d4eEb2a52',
    BEND_EXCHANGE: '0xA897599f8dAf1170b6e1391f1De66f69BAB9617F',
    AUTHORIZATION_MANAGER: '0xB68d4a3f93A657E8A6Ff17a2fb4acfd4769474c7',
    REDEEM_NFT: '0xe431914f90224C2a0fB3d2166ac6F8D600750a4d',
    STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE: '0x2B72aA8191C674734668b7A6459e3314e786cB10',
    ROYALTY_FEE_MANAGER: '0x191D4c30DDf6CcC91E6DC4C52d49452Ecc48436E',
    DOWNPAYMENT: '0x383248b6323F76798e99C41dB4b7a540Da6CF789',
    DOWNPAYMENT_BEND_EXCHANGE_ADAPTER: '0x8c93A07e75E39673241Dcd5567cf533cF0a4faa2',
    DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER: '0xF60dFd915A54943dCF95C0b452133bb32686B54d',
    DOWNPAYMENT_PUNK_ADAPTER: '0x19afCD733a9946B85F14f528a675F861DF1406a6',
    DOWNPAYMENT_SEAPORT_ADAPTER: '0xE5f5A7B182805016Edf5C5F5FE73a5a3F83F4b0c',
    DOWNPAYMENT_X2Y2_ADAPTER: ''
  }
}

export const BEND_EXCHANGE = ADDRESSES[DEFAULT_CHAIN_ID].BEND_EXCHANGE
export const AUTHORIZATION_MANAGER = ADDRESSES[DEFAULT_CHAIN_ID].AUTHORIZATION_MANAGER
export const REDEEM_NFT = ADDRESSES[DEFAULT_CHAIN_ID].REDEEM_NFT
export const STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE = ADDRESSES[DEFAULT_CHAIN_ID].STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE
export const ROYALTY_FEE_MANAGER = ADDRESSES[DEFAULT_CHAIN_ID].ROYALTY_FEE_MANAGER

export const DOWNPAYMENT = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT
export const DOWNPAYMENT_BEND_EXCHANGE_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_BEND_EXCHANGE_ADAPTER
export const DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER
export const DOWNPAYMENT_PUNK_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_PUNK_ADAPTER
export const DOWNPAYMENT_SEAPORT_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_SEAPORT_ADAPTER
export const DOWNPAYMENT_X2Y2_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_X2Y2_ADAPTER

export const FEE_DISTRIBUTOR_ADDRESS = process.env.NEXT_PUBLIC_FEE_DISTRIBUTOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].FEE_DISTRIBUTOR_ADDRESS
export const VE_BEND_ADDRESS = process.env.NEXT_PUBLIC_VE_BEND_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].VE_BEND_ADDRESS
export const MERKLE_DISTRIBUTOR_ADDRESS = process.env.NEXT_PUBLIC_MERKLE_DISTRIBUTOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].MERKLE_DISTRIBUTOR_ADDRESS
export const LEND_POOL_ADDRESSES_PROVIDER = process.env.NEXT_PUBLIC_LEND_POOL_ADDRESSES_PROVIDER || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL_ADDRESSES_PROVIDER
export const LEND_POOL = process.env.NEXT_PUBLIC_LEND_POOL || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL
export const LEND_POOL_LOAN = process.env.NEXT_PUBLIC_LEND_POOL_LOAN || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL_LOAN
export const UI_POOL_DATA_PROVIDER = process.env.NEXT_PUBLIC_UI_POOL_DATA_PROVIDER || ADDRESSES[DEFAULT_CHAIN_ID].UI_POOL_DATA_PROVIDER

export const WETH_GATEWAY_ID = process.env.NEXT_PUBLIC_WETH_GATEWAY_ID || ADDRESSES[DEFAULT_CHAIN_ID].WETH_GATEWAY_ID
export const PUNK_GATEWAY_ID = process.env.NEXT_PUBLIC_PUNK_GATEWAY_ID || ADDRESSES[DEFAULT_CHAIN_ID].PUNK_GATEWAY_ID

export const WETH_ADDRESS = process.env.NEXT_PUBLIC_WETH_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WETH_ADDRESS
export const BEND_ADDRESS = process.env.NEXT_PUBLIC_BEND_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_ADDRESS
export const CRYPTOPUNKS_ADDRESS = process.env.NEXT_PUBLIC_CRYPTOPUNKS_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].CRYPTOPUNKS_ADDRESS
export const WPUNKS_ADDRESS = process.env.NEXT_PUBLIC_WPUNKS_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WPUNKS_ADDRESS

export const BWETH = process.env.NEXT_PUBLIC_BWETH || ADDRESSES[DEFAULT_CHAIN_ID].BWETH
export const BDebtWETH = process.env.NEXT_PUBLIC_BDebtWETH || ADDRESSES[DEFAULT_CHAIN_ID].BDebtWETH

export const BEND_COMPETITION = process.env.NEXT_PUBLIC_BEND_COMPETITION || ADDRESSES[DEFAULT_CHAIN_ID].BEND_COMPETITION
export const BEND_PROTOCOL_INCENTIVES_CONTROLLER =
  process.env.NEXT_PUBLIC_INCENTIVE_CONTROLLER || ADDRESSES[DEFAULT_CHAIN_ID].BEND_PROTOCOL_INCENTIVES_CONTROLLER

export const BEND_ETH_UNISWAP_POOL = process.env.NEXT_PUBLIC_BEND_ETH_UNISWAP_POOL || ADDRESSES[DEFAULT_CHAIN_ID].BEND_ETH_UNISWAP_POOL
export const BEND_COLLECTOR = process.env.NEXT_PUBLIC_BEND_COLLECTOR || ADDRESSES[DEFAULT_CHAIN_ID].BEND_COLLECTOR
export const BOUND_PUNK_GATEWAY = process.env.NEXT_PUBLIC_BOUND_PUNK_GATEWAY || ADDRESSES[DEFAULT_CHAIN_ID].BOUND_PUNK_GATEWAY

export const AIRDROP_DISTRIBUTION_CONTRACT = process.env.NEXT_PUBLIC_AIRDROP_DISTRIBUTION_CONTRACT || ADDRESSES[DEFAULT_CHAIN_ID].AIRDROP_DISTRIBUTION_CONTRACT

export const BEND_STAKE = process.env.NEXT_PUBLIC_BEND_STAKE || ADDRESSES[DEFAULT_CHAIN_ID].BEND_STAKE

export const MAX_BORROW_MULTIPLIER = 0.99

export const ENS_REGISTRAR_ADDRESSES = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'

export const DEFAULT_ADDED_GAS_CALC_PERCENT = 20
export const RECOMMENDED_MIN_GAS_LIMIT = {
  LendPool: {
    deposit: 300000,
    withdraw: 200000,
    borrow: 1000000,
    repay: 700000,
    auction: 500000,
    redeem: 500000,
    liquidate: 700000
  },
  WETHGateway: {
    depositETH: 350000,
    withdrawETH: 400000,
    borrowETH: 1000000,
    repayETH: 700000,
    auctionETH: 550000,
    redeemETH: 550000,
    liquidateETH: 700000
  },
  PunkGateway: {
    borrowETH: 1200000,
    repayETH: 900000,
    auctionETH: 650000,
    redeemETH: 650000,
    liquidateETH: 900000
  }
}

export const maxBendSupply = '10000000000000000000000000000' // in wei

export {
  LEND_POOL_ABI,
  ERC721_ABI,
  LEND_POOL_LOAN_ABI,
  WETH_GATEWAY_ABI,
  PUNK_GATEWAY_ABI,
  WRAPPED_PUNKS_ABI,
  CRYPTO_PUNKS_MARKET_ABI,
  UI_POOL_DATA_PROVIDER_ABI,
  BEND_COMPETITION_ABI,
  BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI,
  WETH_ABI,
  LEND_POOL_ADDRESS_PROVIDER_ABI,
  MERKLE_DISTRIBUTOR_ABI,
  VE_BEND_ABI,
  FEE_DISTRIBUTOR_ABI,
  UNISWAP_PAIR_ABI,
  DEBT_TOKEN_ABI,
  BNFT_ABI,
  BOUND_PUNK_GATEWAY_ABI,
  AIRDROP_DISTRIBUTION_CONTRACT_ABI,
  BEND_STAKE_ABI,
  ENS_PUBLIC_RESOLVER_ABI,
  ENS_ABI,
  EIP2981_ABI
}
