import React from 'react'
import { DrawerContext } from '.'

const useDrawer = (drawer: React.ReactNode): any => {
  const { onDismiss, onPresent, setActivePane } = React.useContext(DrawerContext)

  const handlePresent = React.useCallback(() => {
    onPresent(drawer)
  }, [drawer, onPresent])

  return [handlePresent, onDismiss, setActivePane]
}

export default useDrawer
