import { Contract } from '@ethersproject/contracts'
import { calculateGasMargin } from 'wallet-module'
import { DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT } from '../constants'

/**
 * @dev Allows users to borrow a specific `amount` of the reserve underlying asset
 * - E.g. User borrows 100 USDC, receiving the 100 USDC in his wallet
 *   and lock collateral asset in contract
 * @param contract The contract
 * @param asset The address of the underlying asset to borrow
 * @param amount The amount to be borrowed
 * @param punkId The punk ID
 * @param onBehalfOf Address of the user who will receive the loan. Should be the address of the borrower itself
 * calling the function if he wants to borrow against his own collateral
 * @param referralCode Code used to register the integrator originating the operation, for potential rewards.
 *   0 if the action is executed directly by the user, without any middle-man
 **/
export const borrow = async (contract: any, asset_address: string, amount: string, punkId: string, onBehalfOf_address: string, referralCode: string) => {
  console.debug('util punk borrow data', asset_address, amount, onBehalfOf_address, referralCode, punkId)
  const estimatedGas = await contract.estimateGas.borrow(asset_address, amount, punkId, onBehalfOf_address, referralCode).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.borrow(asset_address, amount, punkId, onBehalfOf_address, referralCode)
  })

  return contract
    .borrow(asset_address, amount, punkId, onBehalfOf_address, referralCode, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to borrow', error)
      throw error
    })
}

/**
 * @dev Allows users to borrow a specific `amount` of the reserve underlying asset
 * - E.g. User borrows 100 USDC, receiving the 100 USDC in his wallet
 *   and lock collateral asset in contract
 * @param contract The contract
 * @param amount The amount to be borrowed
 * @param punkId The punk ID
 * @param onBehalfOf Address of the user who will receive the loan. Should be the address of the borrower itself
 * calling the function if he wants to borrow against his own collateral
 * @param referralCode Code used to register the integrator originating the operation, for potential rewards.
 *   0 if the action is executed directly by the user, without any middle-man
 **/
export const borrowETH = async (contract: any, amount: string, punkId: string, onBehalfOf_address: string, referralCode: string) => {
  console.debug('util punk borrowETH data', amount, onBehalfOf_address, referralCode, punkId)
  const estimatedGas = await contract.estimateGas.borrowETH(amount, punkId, onBehalfOf_address, referralCode).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.borrowETH(amount, punkId, onBehalfOf_address, referralCode)
  })

  return contract
    .borrowETH(amount, punkId, onBehalfOf_address, referralCode, {
      gasLimit: calculateGasMargin(estimatedGas, 30, RECOMMENDED_MIN_GAS_LIMIT.PunkGateway.borrowETH)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to borrowETH', error)
      throw error
    })
}

/**
 * @dev Function to auction a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting auctiond, and receives
 *   the collateral asset
 * @param contract The contract
 * @param punkId The address of the underlying NFT used as collateral
 * @param bidPrice The token ID of the underlying NFT used as collateral
 * @param onBehalfOf Address of the user who will get the underlying NFT, same as msg.sender if the user
 *   wants to receive them on his own wallet, or a different address if the beneficiary of NFT
 *   is a different wallet
 * @return The auction amount, payback amount
 **/
export const auction = async (contract: any, punkId: string, bidPrice: string, onBehalfOf_address: string) => {
  console.debug('util auction data', punkId, bidPrice, onBehalfOf_address)
  const estimatedGas = await contract.estimateGas.auction(punkId, bidPrice, onBehalfOf_address).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.auction(punkId, bidPrice, onBehalfOf_address)
  })

  return contract
    .auction(punkId, bidPrice, onBehalfOf_address, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to auction', error)
      throw error
    })
}

/**
 * @dev Function to auction a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting auctiond, and receives
 *   the collateral asset
 * @param contract The contract
 * @param punkId The address of the underlying NFT used as collateral
 * @param bidPrice The token ID of the underlying NFT used as collateral
 * @param onBehalfOf Address of the user who will get the underlying NFT, same as msg.sender if the user
 *   wants to receive them on his own wallet, or a different address if the beneficiary of NFT
 *   is a different wallet
 * @return The auction amount, payback amount
 **/
export const auctionETH = async (contract: any, punkId: string, bidPrice: string, onBehalfOf_address: string) => {
  console.debug('util auctionETH data', punkId, bidPrice, onBehalfOf_address)
  const estimatedGas = await contract.estimateGas
    .auctionETH(punkId, onBehalfOf_address, {
      value: bidPrice
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.auctionETH(punkId, onBehalfOf_address, {
        value: bidPrice
      })
    })

  return contract
    .auctionETH(punkId, onBehalfOf_address, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.PunkGateway.auctionETH),
      value: bidPrice
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to auctionETH', error)
      throw error
    })
}

/**
 * @dev Function to liquidate a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting liquidated, and receives
 *   the collateral asset
 * @param contract The contract
 * @param punkId The address of the underlying NFT used as collateral
 * @param amount Amount to pay when the total borrowed debt value is greater than the bid price
 * @return tx
 **/
export const liquidate = async (contract: any, punkId: string, amount: string) => {
  console.debug('util liquidate data', punkId, amount)
  const estimatedGas = await contract.estimateGas.liquidate(punkId, amount).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.liquidate(punkId, amount)
  })

  return contract
    .liquidate(punkId, amount, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to liquidate', error)
      throw error
    })
}

/**
 * @dev Function to liquidate a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting liquidated, and receives
 *   the collateral asset
 * @param contract The contract
 * @param punkId The address of the underlying NFT used as collateral
 * @param amount Amount to pay when the total borrowed debt value is greater than the bid price
 * @return tx
 **/
export const liquidateETH = async (contract: any, punkId: string, amount: string) => {
  console.debug('util punk liquidateETH data', punkId, amount)
  const estimatedGas = await contract.estimateGas
    .liquidateETH(punkId, {
      value: amount
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.liquidateETH(punkId, {
        value: amount
      })
    })

  return contract
    .liquidateETH(punkId, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.PunkGateway.liquidateETH),
      value: amount
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to liquidateETH', error)
      throw error
    })
}

/**
 * It's a function that takes in a contract, punkId, amount, bidFine, and value, and returns a promise
 * that resolves to a response
 * @param {Contract} contract - Contract
 * @param {string} punkId - the nft token ID,
 * @param {string} amount - the amount of ETH to redeem
 * @param {string} bidFine - bid fine amount,
 * @returns The response from the contract.
 */
export const redeem = async (contract: Contract, punkId: string, amount: string, bidFine: string) => {
  console.debug('util redeem data', punkId, amount, bidFine)
  const estimatedGas = await contract.estimateGas.redeem(punkId, amount, bidFine).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.redeem(punkId, amount, bidFine)
  })

  return contract
    .redeem(punkId, amount, bidFine, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to redeem', error)
      throw error
    })
}

/**
 * It's a function that takes in a contract, punkId, amount, bidFine, and value, and returns a promise
 * that resolves to a response
 * @param {Contract} contract - Contract
 * @param {string} punkId - the nft token ID,
 * @param {string} amount - the amount of ETH to redeem
 * @param {string} bidFine - bid fine amount,
 * @param {string} value - the amount of ETH to send to the contract
 * @returns The response from the contract.
 */
export const redeemETH = async (contract: Contract, punkId: string, amount: string, bidFine: string, value: string) => {
  console.debug('util redeemETH punk data', punkId, amount, bidFine, value)
  const estimatedGas = await contract.estimateGas
    .redeemETH(punkId, amount, bidFine, {
      value
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.redeemETH(punkId, amount, bidFine, {
        value
      })
    })

  return contract
    .redeemETH(punkId, amount, bidFine, {
      gasLimit: calculateGasMargin(estimatedGas, 30, RECOMMENDED_MIN_GAS_LIMIT.PunkGateway.redeemETH),
      value
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to redeemETH', error)
      throw error
    })
}

/**
 * @dev Function to repay a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting repayd, and receives
 *   the collateral asset
 * @param contract The contract
 * @param punkId The address of the underlying NFT used as collateral
 * @param amount The amount
 * @return tx
 **/
export const repay = async (contract: any, punkId: string, amount: string) => {
  console.debug('util repay data', punkId, amount)
  const estimatedGas = await contract.estimateGas.repay(punkId, amount).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.repay(punkId, amount)
  })

  return contract
    .repay(punkId, amount, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to repay', error)
      throw error
    })
}

/**
 * @dev Function to repay a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting repayd, and receives
 *   the collateral asset
 * @param contract The contract
 * @param punkId The address of the underlying NFT used as collateral
 * @param amount The amount
 * @return tx
 **/
export const repayETH = async (contract: any, punkId: string, amount: string) => {
  console.debug('util repayETH punk data', punkId, amount)
  const estimatedGas = await contract.estimateGas
    .repayETH(punkId, amount, {
      value: amount
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.repayETH(punkId, amount, {
        value: amount
      })
    })

  return contract
    .repayETH(punkId, amount, {
      gasLimit: calculateGasMargin(estimatedGas, 30, RECOMMENDED_MIN_GAS_LIMIT.PunkGateway.repayETH),
      value: amount
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to repayETH', error)
      throw error
    })
}

/**
 * It's a function that takes in a contract, an array of asset addresses, an array of amounts, an array
 * of punkIds, an onBehalfOf address, and a referral code, and returns a promise that resolves to a
 * response
 * @param {Contract} contract - Contract
 * @param {string[]} asset_address - The address of the asset you want to borrow.
 * @param {string[]} amount - string[],
 * @param {string[]} punkId - string[],
 * @param {string} onBehalfOf_address - The address of the user who is borrowing the asset.
 * @param {string} referralCode - string
 * @returns The response is a transaction hash.
 */
export const batchBorrow = async (
  contract: Contract,
  asset_address: string[],
  amount: string[],
  punkId: string[],
  onBehalfOf_address: string,
  referralCode: string
) => {
  console.debug('util punk batchBorrow data', asset_address, amount, onBehalfOf_address, referralCode, punkId)
  const estimatedGas = await contract.estimateGas.batchBorrow(asset_address, amount, punkId, onBehalfOf_address, referralCode).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.batchBorrow(asset_address, amount, punkId, onBehalfOf_address, referralCode)
  })

  return contract
    .batchBorrow(asset_address, amount, punkId, onBehalfOf_address, referralCode, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to batchBorrow', error)
      throw error
    })
}

/**
 * It's a function that takes in a contract, an array of amounts, an array of punkIds, an
 * onBehalfOf_address, and a referralCode, and returns a promise that resolves to a response
 * @param {Contract} contract - Contract
 * @param {string[]} amount - string[]
 * @param {string[]} punkId - string[],
 * @param {string} onBehalfOf_address - address of the user who is borrowing the ETH
 * @param {string} referralCode - string
 * @returns The response is a transaction hash.
 */
export const batchBorrowETH = async (contract: Contract, amount: string[], punkId: string[], onBehalfOf_address: string, referralCode: string) => {
  console.debug('util punk batchBorrowETH data', amount, onBehalfOf_address, referralCode, punkId)
  const estimatedGas = await contract.estimateGas.batchBorrowETH(amount, punkId, onBehalfOf_address, referralCode).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.batchBorrowETH(amount, punkId, onBehalfOf_address, referralCode)
  })

  return contract
    .batchBorrowETH(amount, punkId, onBehalfOf_address, referralCode, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, punkId.length * RECOMMENDED_MIN_GAS_LIMIT.PunkGateway.borrowETH)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to batchBorrowETH', error)
      throw error
    })
}

/**
 * It takes an array of punkIds and an array of amounts, and calls the batchRepay function on the
 * contract
 * @param {Contract} contract - Contract
 * @param {string[]} punkId - string[]
 * @param {string[]} amount - string[]
 * @returns The response is a transaction object.
 */
export const batchRepay = async (contract: Contract, punkId: string[], amount: string[]) => {
  console.debug('util batchRepay data', punkId, amount)
  const estimatedGas = await contract.estimateGas.batchRepay(punkId, amount).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.batchRepay(punkId, amount)
  })

  return contract
    .batchRepay(punkId, amount, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to batchRepay', error)
      throw error
    })
}

/**
 * It takes an array of punkIds and an array of amounts, and then calls the batchRepayETH function on
 * the contract
 * @param {Contract} contract - Contract
 * @param {string[]} punkId - string[]
 * @param {string[]} amount - string[]
 * @param {string} value - string
 * @returns The response is a transaction hash.
 */
export const batchRepayETH = async (contract: Contract, punkId: string[], amount: string[], value: string) => {
  console.debug('util batchRepayETH punk data', punkId, amount, value)
  const estimatedGas = await contract.estimateGas
    .batchRepayETH(punkId, amount, {
      value
    })
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.batchRepayETH(punkId, amount, {
        value
      })
    })

  return contract
    .batchRepayETH(punkId, amount, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, punkId.length * RECOMMENDED_MIN_GAS_LIMIT.PunkGateway.repayETH),
      value
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to batchRepayETH', error)
      throw error
    })
}
