import React from 'react'

interface IIconLangEn {
  color?: string
  size?: number
}

const IconLangEn = React.forwardRef<SVGSVGElement, IIconLangEn>(({ size = 32, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <g>
        <circle fill={'#fff'} cx='10.1' cy='10' r='9.9' />
        <g>
          <path fill={'#EB0000'} d='M10.6,0.2v1.5h4.8C14,0.8,12.3,0.2,10.6,0.2z' />
          <path fill={'#EB0000'} d='M17.2,3.2h-6.6v1.6h7.9C18.1,4.2,17.7,3.6,17.2,3.2z' />
          <path fill={'#EB0000'} d='M19.2,6.2h-8.6v1.6h9.1C19.6,7.3,19.4,6.8,19.2,6.2z' />
          <path fill={'#EB0000'} d='M19.9,9.3h-9.3v1.6h9.3c0-0.3,0-0.6,0-0.9C20,9.8,19.9,9.6,19.9,9.3z' />
          <path fill={'#EB0000'} d='M1.1,14h18c0.2-0.5,0.4-1,0.5-1.6H0.5C0.7,13,0.9,13.5,1.1,14z' />
          <path fill={'#EB0000'} d='M3.2,17.1h13.7c0.5-0.5,1-1,1.3-1.6H1.9C2.3,16.1,2.7,16.6,3.2,17.1z' />
          <path fill={'#EB0000'} d='M10.1,19.9c1.8,0,3.4-0.5,4.9-1.3H5.2C6.7,19.4,8.3,19.9,10.1,19.9z' />
        </g>
        <path fill={'#233CAF'} d='M0.2,10c0,0.3,0,0.6,0,0.9h10.3V0.2c-0.2,0-0.3,0-0.5,0C4.6,0.1,0.2,4.6,0.2,10z' />
        <g>
          <polygon fill={'#fff'} points='6.7,0.6 6.6,1 6.1,1 6.5,1.3 6.4,1.7 6.7,1.4 7.1,1.7 6.9,1.3 7.3,1 6.9,1 			' />
          <polygon fill={'#fff'} points='8.9,1.7 9.2,1.4 9.6,1.7 9.5,1.3 9.8,1 9.4,1 9.2,0.6 9.1,1 8.7,1 9,1.3 			' />
          <polygon fill={'#fff'} points='5.5,1.7 5.3,2.1 4.9,2.1 5.2,2.4 5.1,2.8 5.5,2.5 5.8,2.8 5.7,2.4 6,2.1 5.6,2.1 			' />
          <polygon fill={'#fff'} points='8,1.7 7.8,2.1 7.4,2.1 7.8,2.4 7.6,2.8 8,2.5 8.4,2.8 8.2,2.4 8.6,2.1 8.1,2.1 			' />
          <polygon fill={'#fff'} points='4.2,2.8 4.1,3.2 3.6,3.2 4,3.5 3.8,3.9 4.2,3.6 4.6,3.9 4.4,3.5 4.8,3.2 4.3,3.2 			' />
          <polygon fill={'#fff'} points='6.7,2.8 6.6,3.2 6.1,3.2 6.5,3.5 6.4,3.9 6.7,3.6 7.1,3.9 6.9,3.5 7.3,3.2 6.9,3.2 			' />
          <polygon fill={'#fff'} points='9.2,2.8 9.1,3.2 8.7,3.2 9,3.5 8.9,3.9 9.2,3.6 9.6,3.9 9.5,3.5 9.8,3.2 9.4,3.2 			' />
          <polygon fill={'#fff'} points='2.9,3.9 2.8,4.3 2.3,4.3 2.7,4.5 2.6,5 2.9,4.7 3.3,5 3.2,4.5 3.5,4.3 3.1,4.3 			' />
          <polygon fill={'#fff'} points='5.5,3.9 5.3,4.3 4.9,4.3 5.2,4.5 5.1,5 5.5,4.7 5.8,5 5.7,4.5 6,4.3 5.6,4.3 			' />
          <polygon fill={'#fff'} points='8,3.9 7.8,4.3 7.4,4.3 7.8,4.5 7.6,5 8,4.7 8.4,5 8.2,4.5 8.6,4.3 8.1,4.3 			' />
          <polygon fill={'#fff'} points='4.2,4.9 4.1,5.4 3.6,5.4 4,5.6 3.8,6.1 4.2,5.8 4.6,6.1 4.4,5.6 4.8,5.4 4.3,5.4 			' />
          <polygon fill={'#fff'} points='6.7,4.9 6.6,5.4 6.1,5.4 6.5,5.6 6.4,6.1 6.7,5.8 7.1,6.1 6.9,5.6 7.3,5.4 6.9,5.4 			' />
          <polygon fill={'#fff'} points='9.2,4.9 9.1,5.4 8.7,5.4 9,5.6 8.9,6.1 9.2,5.8 9.6,6.1 9.5,5.6 9.8,5.4 9.4,5.4 			' />
          <polygon fill={'#fff'} points='2.9,6 2.8,6.4 2.3,6.4 2.7,6.7 2.6,7.1 2.9,6.9 3.3,7.1 3.2,6.7 3.5,6.4 3.1,6.4 			' />
          <polygon fill={'#fff'} points='5.5,6 5.3,6.4 4.9,6.4 5.2,6.7 5.1,7.1 5.5,6.9 5.8,7.1 5.7,6.7 6,6.4 5.6,6.4 			' />
          <polygon fill={'#fff'} points='8,6 7.8,6.4 7.4,6.4 7.8,6.7 7.6,7.1 8,6.9 8.4,7.1 8.2,6.7 8.6,6.4 8.1,6.4 			' />
          <polygon fill={'#fff'} points='1.7,7.1 1.5,7.5 1.1,7.5 1.4,7.8 1.3,8.2 1.7,8 2,8.2 1.9,7.8 2.2,7.5 1.8,7.5 			' />
          <polygon fill={'#fff'} points='4.2,7.1 4.1,7.5 3.6,7.5 4,7.8 3.8,8.2 4.2,8 4.6,8.2 4.4,7.8 4.8,7.5 4.3,7.5 			' />
          <polygon fill={'#fff'} points='6.7,7.1 6.6,7.5 6.1,7.5 6.5,7.8 6.4,8.2 6.7,8 7.1,8.2 6.9,7.8 7.3,7.5 6.9,7.5 			' />
          <polygon fill={'#fff'} points='9.2,7.1 9.1,7.5 8.7,7.5 9,7.8 8.9,8.2 9.2,8 9.6,8.2 9.5,7.8 9.8,7.5 9.4,7.5 			' />
          <polygon fill={'#fff'} points='5.6,8.6 5.5,8.2 5.3,8.6 4.9,8.6 5.2,8.9 5.1,9.3 5.5,9 5.8,9.3 5.7,8.9 6,8.6 			' />
          <polygon fill={'#fff'} points='8.1,8.6 8,8.2 7.8,8.6 7.4,8.6 7.8,8.9 7.6,9.3 8,9 8.4,9.3 8.2,8.9 8.6,8.6 			' />
          <polygon fill={'#fff'} points='1.8,9.7 1.7,9.3 1.5,9.7 1.1,9.7 1.4,10 1.3,10.4 1.7,10.1 2,10.4 1.9,10 2.2,9.7 			' />
          <polygon fill={'#fff'} points='4.3,9.7 4.2,9.3 4.1,9.7 3.6,9.7 4,10 3.8,10.4 4.2,10.1 4.6,10.4 4.4,10 4.8,9.7 			' />
          <polygon fill={'#fff'} points='6.9,9.7 6.7,9.3 6.6,9.7 6.1,9.7 6.5,10 6.4,10.4 6.7,10.1 7.1,10.4 6.9,10 7.3,9.7 			' />
          <polygon fill={'#fff'} points='9.4,9.7 9.2,9.3 9.1,9.7 8.7,9.7 9,10 8.9,10.4 9.2,10.1 9.6,10.4 9.5,10 9.8,9.7 			' />
          <path fill={'#fff'} d='M1.7,4.9L1.5,5.4H1.4c0,0.1-0.1,0.1-0.1,0.2l0.1,0.1L1.3,6.1l0.4-0.3L2,6.1L1.9,5.6l0.4-0.3H1.8L1.7,4.9z' />
          <path fill={'#fff'} d='M0.5,8.6L0.4,8.2C0.3,8.5,0.3,8.8,0.3,9.1L0.4,9l0.4,0.3L0.6,8.9L1,8.6H0.5z' />
          <polygon fill={'#fff'} points='3.1,8.6 2.9,8.2 2.8,8.6 2.3,8.6 2.7,8.9 2.6,9.3 2.9,9 3.3,9.3 3.2,8.9 3.5,8.6 			' />
        </g>
        <path
          fill={'#EAEAEA'}
          d='M10,20C4.5,20,0,15.5,0,10S4.5,0,10,0s10,4.5,10,10S15.5,20,10,20z M10,0.3c-5.4,0-9.7,4.4-9.7,9.7
          s4.4,9.7,9.7,9.7s9.7-4.4,9.7-9.7S15.4,0.3,10,0.3z'
        />
      </g>
    </svg>
  )
})

export default IconLangEn
