import { useState, createContext, useContext } from 'react'

/**
 * Interfaces & types
 */
export type IStep = string | number
export interface IState {
  step: IStep
  setStep?: React.Dispatch<React.SetStateAction<IStep>>
}

/**
 * Breadcrumbs context init
 * @param step
 */
export const WizardContext = createContext<IState>({
  step: 1,
  setStep: () => null
})

/**
 * useWizard hook
 * @summary changes state of the step param
 * @return {UseWizard}
 */
export const useWizard = (): IState => {
  const { step, setStep } = useContext(WizardContext)
  return {
    step,
    setStep
  }
}

/**
 * WizardContextProvider
 * @param {ReactElement} children
 * @return {ReactElement}
 */
const WizardContextProvider: React.FC = ({ children }): React.ReactElement => {
  const [step, setStep] = useState<IStep>(1)
  return (
    <WizardContext.Provider
      value={{
        step,
        setStep
      }}
    >
      {children}
    </WizardContext.Provider>
  )
}

export default WizardContextProvider
