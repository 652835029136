import React from 'react'
import { createPopper } from '@popperjs/core'
import { Flex, Box, FlexProps } from 'rebass/styled-components'
import styled from 'styled-components'
import useResponsive from 'hooks/common/useResponsive'

type PopoverProps = {
  overlay: string | React.ReactNode
  placement?: 'top' | 'right' | 'bottom' | 'left'
  background?: string
  color?: string
  wrapperStyle?: any
}

const Popover: React.FC<PopoverProps & FlexProps> = ({
  children,
  overlay,
  placement = 'top',
  background = 'primary',
  color = 'white',
  wrapperStyle,
  ...restprops
}) => {
  const [popoverShow, setPopoverShow] = React.useState(false)
  const btnRef = React.useRef<any>()
  const popoverRef = React.useRef<any>()
  const arrowRef = React.useRef<any>()
  const { isTouch } = useResponsive()
  const openTooltip = () => {
    createPopper(btnRef.current, popoverRef.current, {
      placement,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef.current
          }
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]
    })
    setPopoverShow(true)
  }
  const closeTooltip = () => {
    setPopoverShow(false)
  }

  if (isTouch) return <>{children}</>

  return (
    <TooltipWrapper style={wrapperStyle} onMouseEnter={openTooltip} onMouseLeave={closeTooltip}>
      <Flex flexWrap='wrap' sx={{ zIndex: 300, ...restprops.sx }}>
        <Flex justifyContent='center' width='100%'>
          <Box ref={btnRef} width='100%'>
            {children}
          </Box>

          <Box
            className='tooltip'
            sx={{
              display: popoverShow ? 'block' : 'none',
              bg: background,
              color,
              p: 10,
              py: 8,
              borderRadius: 5,
              fontSize: 'md',
              lineHeight: 'xl',
              fontWeight: 'normal',
              zIndex: 10
            }}
            ref={popoverRef}
          >
            {overlay}
            <Box className='arrow' data-popper-arrow ref={arrowRef} sx={{ '&::before': { bg: background } }}></Box>
          </Box>
        </Flex>
      </Flex>
    </TooltipWrapper>
  )
}

export default Popover

const TooltipWrapper = styled.div<{ style: any }>`
  display: inline-flex;
  width: auto;

  .tooltip[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
  }

  .tooltip[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
  }

  .tooltip[data-popper-placement^='left'] > .arrow {
    right: -4px;
  }

  .tooltip[data-popper-placement^='right'] > .arrow {
    left: -4px;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  .arrow::before {
    content: '';
    transform: rotate(45deg);
  }
`
