import { ApolloQueryResult, gql } from '@apollo/client'
import BigNumber from 'bignumber.js'
import { clientBendProtocol } from 'clients'
import { ETH } from 'constants/index'
import orderBy from 'lodash/orderBy'
import { useQuery, UseQueryResult } from 'react-query'
import { isWeth } from 'utils'
import getHomepageReserves from 'utils/api/get-homepage-reserves'

export interface Reserve {
  id: string
  underlyingAsset: string
  name: string
  symbol: string
  liquidityRate: BigNumber
  totalLiquidity: BigNumber
  decimals: number
  isFrozen: boolean
  isActive: boolean
  price: {
    priceInEth: BigNumber
  }
}

export interface ReservesResult {
  reserves: Array<Reserve>
}

export default function useReserves(replaceWETH = true): UseQueryResult<any, any> {
  return useQuery(['useReserves get bend reserves'], async () => {
    const {
      data: { reserves }
    }: ApolloQueryResult<ReservesResult> = await clientBendProtocol.query({
      query: gql`
        query Reserves {
          reserves(where: { isActive: true, isFrozen: false }) {
            id
            underlyingAsset
            name
            symbol
            liquidityRate
            totalLiquidity
            decimals
            isFrozen
            isActive
            price {
              priceInEth
            }
          }
        }
      `
    })

    const reservesWithOrderBy = reserves.map((reserve: Reserve) => ({
      ...reserve,
      symbol: replaceWETH && isWeth(reserve.underlyingAsset) ? ETH.symbol : reserve.symbol,
      name: replaceWETH && isWeth(reserve.underlyingAsset) ? ETH.name : reserve.name,
      orderBy: {
        totalLiquidityInEth: Number(
          new BigNumber(reserve.totalLiquidity)
            .dividedBy(`1e${reserve.decimals}`)
            .multipliedBy(new BigNumber(reserve.price.priceInEth).dividedBy(1e18))
            .dp(4, 1)
            .toFixed()
        )
      }
    }))

    return orderBy(reservesWithOrderBy, ['orderBy.totalLiquidityInEth'], ['desc'])
  })
}

export function useHomepageReserves({ searchReserves = '' }): UseQueryResult<any, any> {
  return useQuery(['get reserves markets', searchReserves], async () => await getHomepageReserves({ searchReserves }))
}
