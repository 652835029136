import React, { useEffect } from 'react'
import { Box } from 'rebass/styled-components'
import ClickAwayListener from 'react-click-away-listener'
import { DropdownProps } from './interfaces'
import useTheme from 'hooks/common/useTheme'
import useResponsive from 'hooks/common/useResponsive'
import { Modal, MotionBox, useModal } from 'theme/ui'
import SimpleBar from 'modules/simplebar-react'
import { AnimatePresence } from 'framer-motion'

export const useDropdown = () => {
  const [show, setShow] = React.useState(false)
  const handleToggle = React.useCallback(() => setShow(state => !state), [])
  const handleOpen = React.useCallback(() => setShow(true), [])
  const handleClose = React.useCallback(() => setShow(false), [])
  const handleScroll = React.useCallback(() => setShow(false), [])

  return {
    show,
    handleToggle,
    handleOpen,
    handleClose,
    handleScroll
  }
}

const DropDown: React.FC<DropdownProps> = ({
  buttonComponent,
  menuComponent,
  title,
  trigger = 'click',
  menuStyle,
  menuWrapperStyle,
  customStateProps,
  disableModal
  // ...restprops
}) => {
  const { colors, darkMode } = useTheme()
  const [show, setShow] = React.useState(false)
  const wrapper = React.useRef<HTMLElement>()
  const modalWrapper = React.useRef<HTMLElement>()
  const { isDesktop } = useResponsive()

  const handleToggle = React.useCallback(() => setShow(state => !state), [])
  const handleOpen = React.useCallback(() => setShow(true), [])
  const handleClose = React.useCallback(() => setShow(false), [])
  const handleScroll = React.useCallback(() => setShow(false), [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const [handleModal] = useModal(
    <Modal ref={modalWrapper} title={title}>
      <SimpleBar
        ref={wrapper}
        sx={{
          maxHeight: 'calc(100vh - 300px)',
          height: wrapper.current && wrapper.current?.offsetHeight,
          width: '100%',
          borderRadius: 'sm',
          p: [0],
          bg: darkMode ? 'white' : 'white'
        }}
      >
        <Box width={['90%', '95%', '95%', '100%']}>
          {React.isValidElement(menuComponent) &&
            React.cloneElement<any>(menuComponent, {
              show: true,
              handleToggle,
              handleOpen,
              handleClose
            })}
        </Box>
      </SimpleBar>
    </Modal>
  )

  const handleSubmenu = ({ show, handleToggle, handleOpen, handleClose }: any) => {
    return (
      React.isValidElement(menuComponent) &&
      React.cloneElement<any>(menuComponent, {
        show,
        handleToggle,
        handleOpen,
        handleClose
      })
    )
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box width={['100%']} sx={{ position: 'relative' }} onMouseLeave={isDesktop && trigger === 'hover' ? handleClose : undefined}>
        {trigger === 'hover' && (
          <Box sx={{ cursor: 'pointer', userSelect: 'none' }} onMouseEnter={isDesktop ? handleOpen : undefined} onClick={!isDesktop ? handleModal : undefined}>
            {React.isValidElement(buttonComponent) &&
              React.cloneElement<any>(buttonComponent, {
                show,
                handleToggle,
                handleOpen,
                handleClose
              })}
          </Box>
        )}
        {trigger === 'click' && (
          <Box sx={{ cursor: 'pointer', userSelect: 'none' }} onClick={isDesktop ? handleToggle : !disableModal ? handleModal : handleToggle}>
            {React.isValidElement(buttonComponent) &&
              React.cloneElement<any>(buttonComponent, {
                show,
                handleToggle,
                handleOpen,
                handleClose
              })}
          </Box>
        )}
        <AnimatePresence>
          {show && (
            <MotionBox
              initial={{
                opacity: 0,
                y: -10
              }}
              animate={{
                opacity: 1,
                y: 0
              }}
              exit={{
                opacity: 0,
                y: -5
              }}
              sx={{
                position: 'absolute',
                zIndex: 300,
                boxShadow: `0 4px 8px 0 ${darkMode ? colors.shadow[100] : colors.shadow[100]}`,
                // transitionProperty: 'all',
                // transitionDuration: '0.3s',
                // transitionTimingFunction: 'ease-in',
                // height: show ? wrapper.current && wrapper.current?.offsetHeight : 0,
                // width: show ? wrapper.current && wrapper.current?.offsetWidth : 0,
                // overflow: 'hidden',
                borderRadius: 'sm',
                ...menuStyle
              }}
              // {...restprops}
            >
              <Box
                ref={wrapper}
                sx={{
                  // height: wrapper.current && wrapper.current?.offsetHeight,
                  // width: wrapper.current && wrapper.current?.offsetWidth,
                  borderRadius: 'sm',
                  p: 15,
                  pl: 18,
                  bg: darkMode ? 'white' : 'white',
                  '.simplebar-track': {
                    mr: -10
                  },
                  ...menuWrapperStyle
                }}
              >
                {handleSubmenu(
                  customStateProps
                    ? { ...customStateProps }
                    : {
                        show,
                        handleToggle,
                        handleOpen,
                        handleClose
                      }
                )}
              </Box>
            </MotionBox>
          )}
        </AnimatePresence>
      </Box>
    </ClickAwayListener>
  )
}

export default DropDown
