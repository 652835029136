import { useCallback, useEffect, useState } from 'react'
import { usePrevious, SUPPORTED_WALLETS } from 'wallet-module'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'

export enum WalletSteps {
  CONNECTED = 1,
  SELECT_PROVIDER = 2,
  PENDING = 3
}

export const useWalletDisplay = () => {
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error } = useWeb3React()
  const [screenState, setScreenState] = useState(WalletSteps.CONNECTED)
  const [errorMsg, setErrorMsg] = useState('')
  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>()

  const [pendingErrorWrongNetwork, setPendingWrongNetwork] = useState<boolean>(false)
  const [pendingError, setPendingError] = useState<boolean>(false)

  const previousAccount = usePrevious(account)

  useEffect(() => {
    if (!account) {
      setScreenState(WalletSteps.SELECT_PROVIDER)
    }
  }, [account])

  const activePrevious = usePrevious(active)
  const connectorPrevious = usePrevious(connector)
  useEffect(() => {
    if ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error)) {
      setScreenState(WalletSteps.CONNECTED)
    }
    /*
    if (active && previousAccount) {
      setScreenState(WalletSteps.CONNECTED)
      console.log('setting screen 2')
    }
    */
  }, [active, error, connector, activePrevious, connectorPrevious, account, previousAccount])

  const reset = useCallback(() => {
    setErrorMsg('')
    setPendingError(false)
    setPendingWrongNetwork(false)
  }, [])

  const tryActivation = useCallback(
    async (connector: AbstractConnector | undefined) => {
      Object.keys(SUPPORTED_WALLETS).map(key => {
        if (connector === SUPPORTED_WALLETS[key].connector) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          return SUPPORTED_WALLETS[key].name
        }
        return true
      })
      reset()
      setPendingWallet(connector) // set wallet for pending view
      setScreenState(WalletSteps.PENDING)

      // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
      if (connector instanceof WalletConnectConnector) {
        window.localStorage.removeItem('walletconnect')
        connector.walletConnectProvider = undefined
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      connector &&
        activate(
          connector,
          (error: any) => {
            setErrorMsg(error)
            setPendingError(true)
            setPendingWrongNetwork(error instanceof UnsupportedChainIdError)
          },
          false
        ).catch(async (error: any) => {
          if (error) {
            console.log('connector error', error)
            setErrorMsg(error)
            setPendingError(true)
            setPendingWrongNetwork(error instanceof UnsupportedChainIdError)
            /*
            txToast(error, '', 'error')
            */
          } else if (error?.code === -32002) {
            console.log('connector error - Already processing eth_requestAccounts.', error.code)
          }

          // if (error instanceof UnsupportedChainIdError) {
          //   const hasSetup = await setupNetwork()
          //   if (hasSetup) {
          //     activate(connector)
          //   }
          //   // activate(connector) // a little janky...can't use setError because the connector isn't set
          // } else {
          //   setPendingError(true)
          // }
        })
    },
    [activate, reset]
  )

  return {
    screenState,
    setScreenState,
    errorMsg,
    tryActivation,
    connector,
    account,
    pendingError,
    setPendingError,
    pendingErrorWrongNetwork,
    setPendingWrongNetwork,
    pendingWallet
  }
}
