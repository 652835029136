var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value)
          })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator['throw'](value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
import { calculateGasMargin } from '..'
// Write
/**
 * @notice approve contract
 * @param tokenContract token Contract
 * @param contractAddress contract address
 */
export const approve = (tokenContract, contractAddress) =>
  __awaiter(void 0, void 0, void 0, function* () {
    //const maxUint256 = new BigNumber(2).pow(255).toFixed()
    const maxUint256 = '57896044618658097711785492504343953926634992332820282019728792003956564819968'
    //console.log('maxUint256', maxUint256)
    const estimatedGas = yield tokenContract.estimateGas.approve(contractAddress, maxUint256).catch(e => {
      console.log('e', e)
      // general fallback for tokens who restrict approval amounts
      return tokenContract.estimateGas.approve(contractAddress, maxUint256)
    })
    return tokenContract
      .approve(contractAddress, maxUint256, {
        gasLimit: calculateGasMargin(estimatedGas)
      })
      .then(response => {
        //console.log('approve', response)
        return response
      })
      .catch(error => {
        console.debug('Failed to approve', error)
        throw error
      })
  })
