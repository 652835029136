import React from 'react'

interface IIconSafu {
  color?: string
}

const IconSafu = React.forwardRef<SVGSVGElement, IIconSafu>(({ color = 'currentColor', ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={15.415} height={17.845} viewBox='0 0 15.415 17.845' fill={color} {...rest}>
      <g id='Safety' transform='translate(0.499 0.527)'>
        <path
          id='Shape'
          d='M0,9.609V2.4L7.206,0l7.209,2.4V9.609A7.208,7.208,0,1,1,0,9.609ZM1.351,3.416V9.609a5.856,5.856,0,1,0,11.713,0V3.416L7.206,1.352Zm5.154,7.149-.031-.031L4.589,8.648a.524.524,0,0,1,.742-.742L6.855,9.43,9.721,6.566a.5.5,0,0,1,.7,0l.041.041a.5.5,0,0,1,0,.7L7.206,10.564a.5.5,0,0,1-.7,0Z'
          transform='translate(0.001)'
          fill={color}
          stroke={color}
          strokeWidth='1'
        />
      </g>
    </svg>
  )
})

export default IconSafu
