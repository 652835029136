import React from 'react'
import moment from 'moment'
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Flex, Box, Text } from 'rebass/styled-components'
import { IWeekDay, IWeekdaysCarousel } from './interfaces'
import 'swiper/swiper.min.css'

SwiperCore.use([Navigation])

const WEEKDAY_FORMAT = 'dddd'
const DATE_FOMRAT = 'YYYY-MM-DD'

const WeekdaysCarousel: React.FC<IWeekdaysCarousel> = ({ days = 7, onClick, slidesToShow = 3.55, ...restprops }) => {
  const items = React.useMemo((): IWeekDay[] => {
    const data: IWeekDay[] = []
    for (let i = 0; i < days; i += 1) {
      const currentDate = moment().add(i, i === 0 ? 'day' : 'days')
      data.push({
        key: `weekdays-carousel-date-${currentDate.format(DATE_FOMRAT)}`,
        weekDay: currentDate.format(WEEKDAY_FORMAT),
        date: currentDate.format(DATE_FOMRAT),
        active: i === 0,
        onClick: () => onClick(currentDate.format(DATE_FOMRAT))
      })
    }

    return data
  }, [days, onClick])
  return (
    <Box width='100%' {...restprops}>
      <Swiper
        slidesPerView={slidesToShow}
        spaceBetween={22}
        navigation={{
          nextEl: '.weekdays-carousel-right',
          prevEl: '.weekdays-carousel-left'
        }}
      >
        {items.map((item: IWeekDay) => (
          <SwiperSlide key={item.key}>
            <WeekDay weekDay={item.weekDay} date={item.date} onClick={item.onClick} active={item.active} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export const WeekDay: React.FC<IWeekDay> = ({ weekDay, date, active, onClick }) => {
  return (
    <Flex
      onClick={onClick}
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        py: 20,
        px: 30,
        borderRadius: 'sm',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: active ? 'green.1000' : 'grey.300',
        bg: active ? 'green.1000' : null,
        color: active ? 'white' : null,
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'green.1000'
        },
        '&:hover, &:hover span': {
          color: active ? null : 'green.1000'
        }
      }}
    >
      <Text fontWeight='bold' fontSize='lg' lineHeight={'xl'}>
        {weekDay}
      </Text>
      <Text as='span' fontSize='sm' lineHeight={'sm'} color={active ? 'white' : 'grey.300'}>
        {date}
      </Text>
    </Flex>
  )
}

export default WeekdaysCarousel
