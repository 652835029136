import React from 'react'
import { Text } from 'rebass/styled-components'
import { IParagraph } from './interfaces'

const Paragraph: React.FC<IParagraph> = ({ text, children, ...restprops }) => (
  <Text as='p' mb={40} {...restprops}>
    {text && text}
    {children && children}
  </Text>
)

export default Paragraph
