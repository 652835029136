import React from 'react'
import _ from 'lodash'
import useTheme from 'hooks/common/useTheme'
import useResponsive from 'hooks/common/useResponsive'
import { ISpacer } from './interfaces'
import { SpacerBox } from './style.css'

const Spacer: React.FC<ISpacer> = ({ size }): React.ReactElement => {
  const { spacer } = useTheme()
  const { isMobile, isTablet, isLaptop, isDesktop } = useResponsive()

  if (size && _.isArray(size)) {
    if (isDesktop) return <SpacerBox size={size[3] ? spacer[size[3]] : size[2] ? spacer[size[2]] : size[1] ? spacer[size[1]] : spacer[size[0]]} />
    if (isLaptop) return <SpacerBox size={size[2] ? spacer[size[2]] : size[1] ? spacer[size[1]] : spacer[size[0]]} />
    if (isTablet) return <SpacerBox size={size[1] ? spacer[size[1]] : spacer[size[0]]} />
    if (isMobile) return <SpacerBox size={spacer[size[0]]} />
  }

  if (size && !_.isArray(size)) return <SpacerBox size={spacer[size]} />

  return <SpacerBox />
}

export default Spacer
