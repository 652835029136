import { DATE_FORMAT_UTC } from 'constants/index'
import { useNotifications } from 'contexts/notifications'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { Flex, FlexProps, Text } from 'rebass/styled-components'
import { IconInfoXl, Button } from 'theme/ui'
import isEmpty from 'lodash/isEmpty'

const HomepageNotifications: React.FC<FlexProps> = ({ ...restprops }) => {
  const { notifications, getTextColor } = useNotifications()
  const [hide, setHide] = useState(true)

  const handleSetHide = useCallback(() => {
    setHide(true)
    window.sessionStorage.setItem('_bend_display_notification', 'true')
  }, [])

  useEffect(() => {
    if (window.sessionStorage.getItem('_bend_display_notification') === 'true') {
      setHide(true)
      return
    }

    setHide(false)
  }, [])

  return (
    <>
      {isEmpty(notifications)
        ? null
        : notifications.map(notification =>
            hide ? null : (
              <Flex
                key={notification.key}
                variant='notification-wrapper'
                sx={{
                  bg: notification.color,
                  color: getTextColor(notification.color)
                }}
              >
                <Flex variant='notification-wrapper__container' sx={restprops.sx}>
                  <Flex variant='notification-wrapper__container__title'>
                    <IconInfoXl size={30} />
                    <Text ml={[0, 0, 0, 15]} fontWeight='bold' mt={[10, 10, 10, 0]} mb={[5, 5, 5, 0]}>
                      {notification.title}
                    </Text>
                    <Text sx={{ opacity: 0.5, ml: [5] }}>({moment.unix(notification.time).utc().format(DATE_FORMAT_UTC)})</Text>
                  </Flex>
                  <Flex variant='notification-wrapper__container__content'>{notification.content}</Flex>
                  <Flex>
                    {notification?.link && (
                      <Button href={notification.link} outlined color={getTextColor(notification.color)} newWindow={notification.external}>
                        Learn more
                      </Button>
                    )}

                    {notification.close && (
                      <Button
                        size='icon'
                        color='transparent'
                        buttonStyles={{
                          position: ['absolute', null, null, 'relative'],
                          top: [10, null, null, 'auto'],
                          right: [10, null, null, 'auto'],
                          ml: 15
                        }}
                        onClick={handleSetHide}
                      >
                        <X size={20} />
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )
          )}
    </>
  )
}

export default HomepageNotifications
