import useTheme from 'hooks/common/useTheme'
import { Flex, Box, Text } from 'rebass/styled-components'
import { useTranslation } from 'next-i18next'
import { LS_TOKEN } from 'constants/index'
import { Button, Spacer, NotificationSmall } from 'theme/ui'
import { useFormContext } from 'react-hook-form'
import { useBorrowContext } from '../context'
import { useCallback, useEffect, useMemo } from 'react'
import { Screen } from '..'
import SimpleBar from 'modules/simplebar-react'
import useBatchBorrow, { NotificationState } from 'modules/bend/hooks/useBatchBorrow'
import BigNumber from 'bignumber.js'
import { useUserContext } from 'contexts/user'
import { SetUpEmail } from 'components/common/set-up-email'
import { useModalContext } from 'components/common/modal/components/context'
import { BnftItem, NftCollateralSuccess } from '../details/nft-collateral'
import { useCheckNftApprovedForBorrowList } from 'modules/bend/hooks/useApprove'
import { WETH_ADDRESS } from 'modules/bend/constants'

export const ConfirmBorrowStep: React.FC = () => {
  const { colors } = useTheme()
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('common')
  const { watch, setValue } = useFormContext()
  const { account, handler, isSingle, stepNumber, setWizardStep } = useBorrowContext()
  const { errorMsg, notificationState, handleBatchBorrowETH, handlePunkBatchBorrowETH } = useBatchBorrow(account || '', NotificationState.CONFIRM)

  const { handleClose, updateComponent } = useModalContext()
  const { user } = useUserContext()
  // const [toggleModal] = useModal(
  //   <Modal noLayoutStyle width={620} padding={10}>
  //     <SetUpEmail mt={-20} mb={20} mx={-10} />
  //   </Modal>
  // )

  const bnftItems = watch('bnftItems')
  const totalBorrowAmount = watch('totalBorrowAmount')
  const type = watch('type')

  const bnftItemsParsed = useMemo((): Array<BnftItem> => {
    if (!bnftItems) return []
    return bnftItems.map((bnftItem: string) => JSON?.parse(bnftItem))
  }, [bnftItems])
  const { nftApproved } = useCheckNftApprovedForBorrowList({
    account,
    assetAddress: WETH_ADDRESS,
    list: bnftItemsParsed
  })

  const onSubmit = useCallback(() => {
    const addresses = bnftItemsParsed.map(bnftItem => bnftItem.address)
    const tokenIds = bnftItemsParsed.map(bnftItem => bnftItem.tokenID)
    const amounts = bnftItemsParsed.map(bnftItem => (bnftItem.amount ? new BigNumber(bnftItem.amount).multipliedBy(1e18).toFixed() : '0'))

    if (type !== 'erc721') {
      handlePunkBatchBorrowETH({ amounts, tokenIds })
    } else {
      handleBatchBorrowETH({ amounts, tokenIds, addresses })
    }
  }, [bnftItemsParsed, handleBatchBorrowETH, handlePunkBatchBorrowETH, type])

  useEffect(() => {
    if (notificationState === NotificationState.SUCCESS) {
      setWizardStep(5)
    }
  }, [notificationState, setWizardStep])

  useEffect(() => {
    if (window.localStorage.getItem(LS_TOKEN) && !user?.email && notificationState === NotificationState.SUCCESS) {
      updateComponent(<SetUpEmail collateralCount={bnftItems.length} totalBorrowAmount={totalBorrowAmount} />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationState, user?.email])

  const handleBack = useCallback(() => {
    if (!handler) return
    setValue('totalBorrowAmount', '0')
    if (isSingle) {
      if (stepNumber === 2) handler(Screen.ENTER_BORROW_AMOUNTS, 1)
      if (stepNumber === 3) handler(Screen.APPROVE_TOKENS, 2)
    } else {
      if (nftApproved) {
        handler(Screen.ENTER_BORROW_AMOUNTS, 2)
      } else {
        handler(Screen.APPROVE_TOKENS, 3)
      }
    }
  }, [handler, isSingle, nftApproved, setValue, stepNumber])

  return (
    <>
      <Flex flexDirection='column' px={20}>
        {(notificationState === NotificationState.CONFIRM || notificationState === NotificationState.CONFIRM_TX) && (
          <Text fontSize='md' fontWeight='bold' textAlign={'center'} mb={10}>
            {tc('label.confirm-and-borrow')}
          </Text>
        )}
        <Box>
          <Flex flexDirection='column' width='100%' sx={{ border: `1px solid ${colors.grey[300]}`, borderRadius: 'sm' }}>
            <SimpleBar sx={{ minHeight: isSingle ? [125, 125, 76] : 210, maxHeight: isSingle ? [125, 125, 76] : 210 }}>
              {notificationState !== NotificationState.APPROVE && notificationState !== NotificationState.APPROVED && (
                <Box px={20} pt={20} pb={10} width='100%' sx={{ display: 'grid', gridTemplateColumns: 'repeat(1fr)', gridGap: '10px', overflow: 'hidden' }}>
                  {bnftItemsParsed.map((bnftItem, index: number) => (
                    <NftCollateralSuccess key={`${bnftItem.address}+${bnftItem.tokenID}+${index}`} nft={bnftItem} />
                  ))}
                </Box>
              )}
            </SimpleBar>
          </Flex>
        </Box>
      </Flex>

      <Box width='100%' px={20} mt={20}>
        {notificationState === NotificationState.CONFIRM && (
          <NotificationSmall noicon>
            {isSingle ? (
              <Text
                as='span'
                dangerouslySetInnerHTML={{
                  __html: t('batch-borrow.confirm-single', { collateralCount: bnftItems.length, amount: totalBorrowAmount, assetTicker: 'ETH' })
                }}
              />
            ) : (
              <Text
                as='span'
                dangerouslySetInnerHTML={{
                  __html: t('batch-borrow.confirm', { collateralCount: bnftItems.length, amount: totalBorrowAmount, assetTicker: 'ETH' })
                }}
              />
            )}
          </NotificationSmall>
        )}
        {notificationState === NotificationState.CONFIRM_TX && (
          <NotificationSmall noicon>
            {isSingle ? (
              <Text
                as='span'
                dangerouslySetInnerHTML={{
                  __html: t('batch-borrow.confirm-single', { collateralCount: bnftItems.length, amount: totalBorrowAmount, assetTicker: 'ETH' })
                }}
              />
            ) : (
              <Text
                as='span'
                dangerouslySetInnerHTML={{
                  __html: t('batch-borrow.confirm', { collateralCount: bnftItems.length, amount: totalBorrowAmount, assetTicker: 'ETH' })
                }}
              />
            )}
          </NotificationSmall>
        )}
        {notificationState === NotificationState.PROCESSING && (
          <NotificationSmall loading>
            <Text
              as='span'
              dangerouslySetInnerHTML={{
                __html: t('batch-borrow.processing')
              }}
            />
          </NotificationSmall>
        )}
        {notificationState === NotificationState.ERROR && (
          <NotificationSmall type='fail' noicon>
            <Text as='span' dangerouslySetInnerHTML={{ __html: t('batch-borrow.step-error', { errorMsg }) }} />
          </NotificationSmall>
        )}
        {notificationState === NotificationState.SUCCESS && (
          <>
            <NotificationSmall type='success'>
              <Text
                as='span'
                dangerouslySetInnerHTML={{
                  __html: t('batch-borrow.step-success', { collateralCount: bnftItems.length, amount: totalBorrowAmount, assetTicker: 'ETH' })
                }}
              />
            </NotificationSmall>
            <NotificationSmall mt={20} flexDirection='column' noicon>
              <Text maxWidth={400} as='span' dangerouslySetInnerHTML={{ __html: t('batch-borrow.paragraph-remind-user-cancel-1') }}></Text>
              <Text mt={20} as='span' dangerouslySetInnerHTML={{ __html: t('borrow.paragraph-remind-user-cancel-2') }}></Text>
            </NotificationSmall>
          </>
        )}

        <Flex flexDirection={['row']} mt={20}>
          {notificationState === NotificationState.SUCCESS && (
            <Flex width='100%' justifyContent={'space-between'} flexDirection={['row']}>
              <Button type='button' flex={1} color='black' size='xl' outlined onClick={handleClose} mr={[10]}>
                {tc('button.close')}
              </Button>
            </Flex>
          )}
          {(notificationState === NotificationState.CONFIRM || notificationState === NotificationState.ERROR) && (
            <>
              <Button flex={1} color='black' size='xl' outlined onClick={handleBack}>
                {tc('button.back')}
              </Button>
              <Spacer size='sm' />
            </>
          )}
          {(notificationState === NotificationState.CONFIRM || notificationState === NotificationState.ERROR) && (
            <Button flex={1} color='primary' size='xl' onClick={onSubmit}>
              {tc('button.borrow-eth')}
            </Button>
          )}
          {notificationState === NotificationState.CONFIRM_TX && (
            <>
              <Button flex={1} color='black' size='xl' outlined disabled>
                {tc('button.back')}
              </Button>
              <Spacer size='sm' />
              <Button flex={1} color='primary' size='xl' disabled>
                {tc('button.borrow-eth')}
              </Button>
            </>
          )}
          {notificationState === NotificationState.PROCESSING && (
            <>
              <Button flex={1} color='black' size='xl' outlined disabled>
                {tc('button.back')}
              </Button>
              <Spacer size='sm' />
              <Button flex={1} color='primary' size='xl' disabled>
                {tc('button.borrow-eth')}
              </Button>
            </>
          )}
        </Flex>
      </Box>
    </>
  )
}
export default ConfirmBorrowStep
