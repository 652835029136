import { useActiveWeb3React } from 'modules/wallet-module'
import { useTranslation } from 'next-i18next'
import { Flex, Text } from 'rebass/styled-components'
import { Button, Container, Notification, useDrawer } from 'theme/ui'
import Sidebar from '../sidebar'
import { useWalletCheckContext } from './context'

const WalletCheck = () => {
  const { t } = useTranslation('common')
  const { account } = useActiveWeb3React()
  const [toggleSidebar, , setActivePane] = useDrawer(<Sidebar />)
  const { style } = useWalletCheckContext()

  if (account) return null

  return (
    <Container variant='card-body' justifyContent='center' flexDirection='column' sx={{ maxWidth: 1400, mt: [20], ...style }}>
      <Notification reducePadding type='default' noicon sx={{ py: [15], px: [15] }}>
        <Flex flexDirection={['column', 'column', 'column', 'row']} alignItems={['center']} width='100%' justifyContent={['space-between']}>
          <Text
            as='span'
            dangerouslySetInnerHTML={{
              __html: t('error-no-wallet-notification')
            }}
            sx={{ lineHeight: ['lg', 'lg', 'lg', 'md'] }}
          />
          <Button
            onClick={() => {
              toggleSidebar()
              setActivePane(0)
            }}
            color='primary'
            size='sm'
            buttonStyles={{ mt: [10, 10, 10, 0] }}
            fontWeight='bold'
          >
            {t('error-no-wallet-button')}
          </Button>
        </Flex>
      </Notification>
    </Container>
  )
}

export default WalletCheck
