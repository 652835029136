import { useMemo } from 'react'
import { useActiveWeb3React, useWallet } from '..'
import { ERC20_BYTES32_ABI, ERC20_ABI } from '../constants/abis/erc20'
import { getContract } from '../utils/contract'
// returns null on errors
export function useContract(address, ABI, withSignerIfPossible = true) {
  const { library, account } = useActiveWeb3React()
  const { wsProvider } = useWallet()
  return useMemo(() => {
    if (!address || !ABI) return null
    if (library) {
      try {
        return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
      } catch (error) {
        console.error('Failed to get contract', error)
        return null
      }
    } else {
      try {
        return getContract(address, ABI, wsProvider, withSignerIfPossible && account ? account : undefined)
      } catch (error) {
        console.error('Failed to get contract', error)
        return null
      }
    }
  }, [address, ABI, library, withSignerIfPossible, account, wsProvider])
}
export function useTokenContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}
export function useBytes32TokenContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}
