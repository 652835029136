import { useActiveWeb3React } from 'modules/wallet-module'
import { useQuery, UseQueryOptions } from 'react-query'
import { getActiveAuctions } from './data/auctions/get-active-auctions'
import { getLoans, LoansArgs } from './data/loans/get-loans'
import { ActiveAuctionsArgs } from './types'

type QueryKey = 'ActiveAuctions' | 'Loans'
type QueryArgs = ActiveAuctionsArgs | LoansArgs

const useBendQuery = (queryKey: QueryKey, args: QueryArgs, options?: Omit<UseQueryOptions<any[] | null, any[]>, 'queryKey' | 'queryFn'> | undefined) => {
  const { library } = useActiveWeb3React()
  return useQuery(
    [queryKey, args, library?._isProvider],
    async () => {
      switch (queryKey) {
        // Loans
        case 'Loans':
          return await getLoans({ ...args, provider: library } as LoansArgs)
        // Auctions
        case 'ActiveAuctions':
          return await getActiveAuctions({ ...args, provider: library } as ActiveAuctionsArgs)
        // Default case
        default:
          return null
      }
    },
    options
  )
}

export default useBendQuery
