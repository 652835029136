import { calculateGasMargin } from 'wallet-module'

/**
 * @dev Allows users to setApprovalForAll
 * @param contract WPunk contract
 * @param operator The operator
 * @param approved 0 / 1
 **/
export const setApprovalForAll = async (contract: any, operator: string, approved: string) => {
  console.debug('util punk setApprovalForAll data', contract, operator, approved)
  const estimatedGas = await contract.estimateGas.setApprovalForAll(operator, approved).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.setApprovalForAll(operator, approved)
  })

  return contract
    .setApprovalForAll(operator, approved, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to setApprovalForAll', error)
      throw error
    })
}

/**
 * @dev Allows users to registerProxy
 * @param contract WPunk contract
 **/
export const registerProxy = async (contract: any) => {
  console.debug('util punk registerProxy data', contract)
  const estimatedGas = await contract.estimateGas.registerProxy().catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.registerProxy()
  })

  return contract
    .registerProxy({
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to registerProxy', error)
      throw error
    })
}

/**
 * @dev Allows users to mint token
 * @param contract The contract
 * @param punkId The punk ID
 **/
export const mint = async (contract: any, punkId: string) => {
  console.debug('util token mint data', contract, punkId)
  const estimatedGas = await contract.estimateGas.mint(punkId).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.mint(punkId)
  })

  return contract
    .mint(punkId, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to mint', error)
      throw error
    })
}
