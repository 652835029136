import { useTranslation } from 'next-i18next'
import { Button, IconNotification, IconWallet, Spacer, useDrawer } from 'theme/ui'
import useTheme from 'hooks/common/useTheme'
import { Flex } from 'rebass/styled-components'
import Sidebar from 'components/common/sidebar'
import Popover from 'theme/ui/common/popover'
import useResponsive from 'hooks/common/useResponsive'
import NavButtonAccount from 'theme/ui/common/nav-button/nav-button-account'
import { accountRoute } from 'constants/routes'
import NewProfileMenuNotification from 'components/common/new-profile-menu-notification'
import usePlatform from 'contexts/platform'
import { useActiveWeb3React } from 'modules/wallet-module/lib/esm'

const Web3Status: React.FC = () => {
  const { t } = useTranslation('common')
  const { t: tc } = useTranslation('common')
  const { colors } = useTheme()
  const { account } = useActiveWeb3React()
  const [toggleSidebar, , setActivePane] = useDrawer(<Sidebar />)
  const { unreadedNotifications } = usePlatform()
  const { isTablet } = useResponsive()

  return (
    <>
      <NewProfileMenuNotification />
      <Flex alignItems='center' justifyContent='flex-end'>
        <NavButtonAccount {...accountRoute} />
        <Spacer size={isTablet ? 'md' : 'sm'} />
        <Popover placement='bottom' overlay={t('label.wallet')} background='primary'>
          <Button
            size='icon'
            color='blue.100'
            p={5.5}
            onClick={() => {
              toggleSidebar()
              setActivePane(0)
            }}
          >
            <IconWallet size={20} color={colors.primary} />
          </Button>
        </Popover>
        {isTablet && account && (
          <>
            <Spacer size='md' />
            <Popover placement='bottom' overlay={tc('label.notifications')}>
              <Button
                size='icon'
                color='blue.100'
                p={5.5}
                onClick={() => {
                  toggleSidebar()
                  setActivePane(1)
                }}
                buttonStyles={{ position: 'relative' }}
              >
                {(unreadedNotifications > 0 || unreadedNotifications === '99+') && (
                  <Flex
                    sx={{
                      position: 'absolute',
                      top: -10,
                      right: -10,
                      width: 20,
                      height: 20,
                      bg: 'red.1000',
                      color: 'white',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 8,
                      borderRadius: 15
                    }}
                  >
                    {unreadedNotifications}
                  </Flex>
                )}
                <IconNotification size={20} color={colors.primary} />
              </Button>
            </Popover>
          </>
        )}
      </Flex>
    </>
  )
}

export default Web3Status
