import SpacerDash from 'components/common/spacer-dash'
import useTheme from 'hooks/common/useTheme'
import { Flex, Box, Text, Image } from 'rebass/styled-components'
import { useTranslation } from 'next-i18next'
import { ethImagePath, SHOW_DECIMAL_PLACES } from 'constants/index'
import NumberFormat from 'components/common/number-format'
import { Checkbox, Label } from '@rebass/forms'
import { Button, MotionBox, NotificationSmall, SkeletonLine, Notification, useDrawer } from 'theme/ui'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useResponsive from 'hooks/common/useResponsive'
import { useQuery, UseQueryResult } from 'react-query'
import { useBorrowContext } from '../context'
import getBorrowCollections, { NftCollectionsReturn } from 'utils/api/get-batch-borrow-collections'
import { filter, isArray, isEmpty, orderBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import NftImage from 'components/common/nft-image'
import { useDataContext } from 'contexts/data'
import BigNumber from 'bignumber.js'
import SimpleBar from 'modules/simplebar-react'
import { Screen } from '..'
import NoRecords from 'components/common/no-records'
import { useBorrows } from 'hooks/dashboard/useBorrows'
import { useRouter } from 'next/router'
import { isPunk } from 'utils'
import { useModalContext } from 'components/common/modal/components/context'
import Sidebar from 'components/common/sidebar'
import NoWallet from 'theme/ui/icons/bend-icons/icons/icon-wallet-2'

export const SelectNftCollateralsStep: React.FC = () => {
  const { colors } = useTheme()
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('common')
  const { t: te } = useTranslation('common')
  const router = useRouter()
  const { account, handler, single } = useBorrowContext()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { watch, handleSubmit, setValue } = useFormContext()
  const { eth } = useDataContext()
  const [toggleSidebar, , setActivePane] = useDrawer(<Sidebar />)
  const { isTablet } = useResponsive()
  const { data }: UseQueryResult<NftCollectionsReturn, any> = useQuery(['get user collections', account, eth], async () => {
    if (!account || !eth) return []
    return await getBorrowCollections(account, eth)
  })
  const { handleClose } = useModalContext()
  const bnftItems = watch('bnftItems')
  const type = watch('type')
  const {
    borrows: { data: borrowsData }
  } = useBorrows({})

  const allData = useMemo(() => {
    if (!data?.collections || !borrowsData) {
      if (!account) {
        setIsLoading(false)
      }
      return {
        collections: [],
        cryptopunks: []
      }
    }
    let allCollections = [
      ...data.collections,
      ...borrowsData
        .filter((item: any) => item.state === 'Active' && item.availableToBorrow?.isGreaterThan(0.0001))
        .map((mapItem: any) => ({
          address: mapItem.underlyingAsset,
          tokenID: mapItem.tokenId,
          name: mapItem.assetName,
          amount: '0',
          availableToBorrow: new BigNumber(mapItem.availableToBorrow).dp(4, 1).toFixed(4),
          borrowed: mapItem.borrowed,
          key: `${mapItem.underlyingAsset}+${mapItem.tokenId}`
        }))
    ]

    allCollections = orderBy(allCollections, ['name', 'tokenID'])

    let cryptopunks = orderBy(data.cryptopunks, ['name', 'tokenID'])

    if (router?.query?.slug) {
      const [collectionAddress, tokenID] = router.query.slug

      allCollections = [
        ...allCollections.filter(collection => collection.key.toLowerCase() === `${collectionAddress.toLowerCase()}+${tokenID.toLowerCase()}`),
        ...allCollections.filter(collection => collection.key.toLowerCase() !== `${collectionAddress.toLowerCase()}+${tokenID.toLowerCase()}`)
      ]

      cryptopunks = [
        ...cryptopunks.filter(collection => collection.key.toLowerCase() === `${collectionAddress.toLowerCase()}+${tokenID.toLowerCase()}`),
        ...cryptopunks.filter(collection => collection.key.toLowerCase() !== `${collectionAddress.toLowerCase()}+${tokenID.toLowerCase()}`)
      ]
    }

    setIsLoading(false)
    return {
      collections: allCollections,
      cryptopunks: cryptopunks
    }
  }, [account, borrowsData, data?.collections, data?.cryptopunks, router.query.slug])

  const onSubmit = useCallback(
    data => {
      const parsed = data.bnftItems.map((bnftItem: string) => JSON?.parse(bnftItem))
      setValue(
        'bnftItems',
        orderBy(parsed, ['collectionName'], ['asc']).map(item => JSON.stringify(item))
      )
      if (handler) {
        handler(Screen.ENTER_BORROW_AMOUNTS, 2)
      }
    },
    [handler, setValue]
  )

  const totalAvailableToBorrow = useMemo(() => {
    if (!bnftItems || isEmpty(bnftItems)) return new BigNumber(0)
    let total = new BigNumber(0)
    for (let i = 0; i < bnftItems.length; i += 1) {
      const bnftItem = JSON.parse(bnftItems[i])
      total = total.plus(bnftItem.availableToBorrow)
    }
    setValue('totalAvailableToBorrow', total)
    return total
  }, [bnftItems, setValue])

  useEffect(() => {
    if (!router?.query?.slug || !allData) return
    const [collectionAddress, tokenID] = router.query.slug

    let collection = allData.collections.find(
      data => data.address.toLowerCase() === collectionAddress.toLowerCase() && data.tokenID.toLowerCase() === tokenID.toLowerCase()
    )

    if (isPunk(collectionAddress)) {
      collection = allData.cryptopunks.find(
        data => data.address.toLowerCase() === collectionAddress.toLowerCase() && data.tokenID.toLowerCase() === tokenID.toLowerCase()
      )
      setValue('type', 'cryptopunks')
    }

    if (collection) {
      setValue('selected', [collection.key])
      setValue('bnftItems', [JSON.stringify(collection)])
    }
  }, [allData, router, setValue])

  const isSingle = useMemo(() => single?.enabled, [single])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDirection='column' px={20}>
        <Flex flexDirection='column'>
          <Flex>
            <Button
              buttonStyles={{
                borderRadius: 'sm',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                width: '100%',
                color: type !== 'erc721' ? 'black' : 'white'
              }}
              outlined={type !== 'erc721'}
              color='primary'
              size='xl'
              value='erc721'
              onClick={e => {
                e.preventDefault()
                setValue('type', 'erc721')
                setValue('selected', [])
                setValue('bnftItems', [])
              }}
            >
              {isTablet ? 'ERC-721 NFTs' : 'ERC-721'}
            </Button>
            <Button
              buttonStyles={{
                borderRadius: 'sm',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                width: '100%',
                color: type === 'erc721' ? 'black' : 'white',
                border: `1px solid ${type === 'erc721' ? colors.grey[300] : 'primary'}`
              }}
              color='primary'
              size='xl'
              value='cryptopunks'
              outlined={type === 'erc721'}
              onClick={e => {
                e.preventDefault()
                setValue('type', 'cryptopunks')
                setValue('selected', [])
                setValue('bnftItems', [])
              }}
            >
              {isTablet ? 'Cryptopunk NFTs' : 'Cryptopunks'}
            </Button>
          </Flex>

          <NotificationSmall noicon my={20} sx={{ px: 3 }}>
            <Flex width='100%' flexDirection={'column'} lineHeight={1.6}>
              {isTablet && (
                <>
                  <Text as='span' dangerouslySetInnerHTML={{ __html: t('batch-borrow.step1-1') }} />
                  <Text as='span' dangerouslySetInnerHTML={{ __html: t('batch-borrow.step1-2') }} />
                </>
              )}
              <Text as='span' mt={5} dangerouslySetInnerHTML={{ __html: t('batch-borrow.step1-3') }} />
            </Flex>
          </NotificationSmall>

          <Box>
            <Flex flexDirection='column' width='100%'>
              <MotionBox
                initial={{ display: 'none' }}
                animate={{ display: type === 'erc721' ? 'block' : 'none' }}
                sx={{ borderRadius: 'sm', border: `1px solid ${colors.grey[300]}`, '& .simplebar-content': { maxWidth: '100%' } }}
              >
                <SimpleBar sx={{ minHeight: isSingle ? 76 : 210, maxHeight: isSingle ? 76 : 210 }}>
                  {isLoading && (
                    <Box pt={20} pb={10} px={20} width='100%' sx={{ display: 'grid', gridTemplateColumns: 'repeat(1fr)', gridGap: '10px', overflow: 'hidden' }}>
                      {[1, 2, 3].map(index => (
                        <NftCollateral
                          key={index}
                          nft={{
                            key: '',
                            collectionName: '',
                            tokenID: '',
                            address: '',
                            amount: '',
                            availableToBorrow: ''
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  {isArray(allData?.collections) && isEmpty(allData?.collections) && !isLoading && (
                    <NoRecords text={!account ? tc('label.wallet-not-connected') : tc('label.no-records')} icon={!account ? <NoWallet /> : null} />
                  )}
                  <Box pt={20} pb={10} px={20} width='100%' sx={{ display: 'grid', gridTemplateColumns: 'repeat(1fr)', gridGap: '10px', overflow: 'hidden' }}>
                    {allData?.collections?.map(ownerItem => (
                      <NftCollateral
                        key={ownerItem.key}
                        nft={{ ...ownerItem, collectionName: ownerItem.name, tokenID: ownerItem.tokenID, address: ownerItem.address }}
                      />
                    ))}
                  </Box>
                </SimpleBar>
              </MotionBox>

              <MotionBox
                initial={{ display: 'none' }}
                animate={{ display: type !== 'erc721' ? 'block' : 'none' }}
                sx={{ borderRadius: 'sm', border: `1px solid ${colors.grey[300]}` }}
              >
                <Box
                  as={SimpleBar}
                  sx={{ minHeight: isSingle ? 76 : 210, maxHeight: isSingle ? 76 : 210, '.simplebar-track.simplebar-vertical': { mr: 5, my: 5 } }}
                >
                  {isLoading && (
                    <Box px={20} pt={20} pb={10} width='100%' sx={{ display: 'grid', gridTemplateColumns: 'repeat(1fr)', gridGap: '10px', overflow: 'hidden' }}>
                      {[1, 2, 3].map(index => (
                        <NftCollateral
                          key={index}
                          nft={{
                            key: '',
                            collectionName: '',
                            tokenID: '',
                            address: '',
                            amount: '',
                            availableToBorrow: ''
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  {isArray(allData?.cryptopunks) && isEmpty(allData?.cryptopunks) && !isLoading && <NoRecords />}
                  <Box px={20} pt={20} pb={10} width='100%' sx={{ display: 'grid', gridTemplateColumns: 'repeat(1fr)', gridGap: '10px', overflow: 'hidden' }}>
                    {allData?.cryptopunks?.map(ownerItem => (
                      <NftCollateral
                        key={ownerItem.key}
                        nft={{ ...ownerItem, collectionName: ownerItem.name, tokenID: ownerItem.tokenID, address: ownerItem.address }}
                      />
                    ))}
                  </Box>
                </Box>
              </MotionBox>
            </Flex>
          </Box>

          <Notification reducePadding noicon py={12} mt={18}>
            <Flex width='100%' justifyContent='space-between' alignItems='center'>
              <Text fontWeight={'bold'} color='primary'>
                {tc('label.total-available-borrow')}
              </Text>
              <Box fontWeight={'bold'} color='primary' mt={'4px'}>
                <NumberFormat
                  number={totalAvailableToBorrow}
                  format={SHOW_DECIMAL_PLACES}
                  preffix={
                    <Flex sx={{ borderRadius: 'sm', width: 17, height: 17, overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                      <Image src={ethImagePath} width={17} height={17} />
                    </Flex>
                  }
                />
              </Box>
            </Flex>
          </Notification>
        </Flex>

        <Flex flexDirection={['row']} width='100%' mt={20}>
          <Button type='button' onClick={handleClose} flex={1} color='black' size='xl' mr={[10]} outlined>
            {tc('button.close')}
          </Button>
          {!account ? (
            <Button
              onClick={e => {
                e.preventDefault()
                toggleSidebar()
                setActivePane(0)
                handleClose()
              }}
              color='primary'
              size='xl'
              flex={1}
            >
              {te('error-no-wallet-button')}
            </Button>
          ) : (
            <Button type='submit' flex={1} color='primary' size='xl' disabled={isEmpty(bnftItems)}>
              {tc('button.continue')}
            </Button>
          )}
        </Flex>
      </Flex>
    </form>
  )
}

export default SelectNftCollateralsStep

interface INftCollateral {
  nft: {
    key: string
    collectionName: string
    tokenID: string
    address: string
    amount: string
    availableToBorrow: string
  }
}

const NftCollateral: React.FC<INftCollateral> = ({ nft }) => {
  const { colors } = useTheme()
  const { isTablet } = useResponsive()
  const { t: tc } = useTranslation('common')
  const { register, watch, setValue } = useFormContext()

  const bnftItems = watch('bnftItems')
  const selected = watch('selected')

  useEffect(() => {
    register('bnftItems')
  }, [register])

  const handleChange = useCallback(
    e => {
      if (e.currentTarget.checked) {
        setValue('bnftItems', [...bnftItems, e.currentTarget.value])
        const parsed = JSON.parse(e.currentTarget.value)
        setValue('selected', [...selected, parsed.key])
      } else {
        setValue(
          'bnftItems',
          filter(bnftItems, item => item !== e.currentTarget.value)
        )
        const parsed = JSON.parse(e.currentTarget.value)
        setValue(
          'selected',
          filter(selected, item => item !== parsed.key)
        )
      }
    },
    [bnftItems, selected, setValue]
  )

  return (
    <>
      {isTablet && (
        <Label>
          <Flex width='100%' justifyContent='flex-start'>
            <Flex alignItems='center' flex={1 / 16}>
              {nft.address === '' ? (
                <SkeletonLine width={16} height={16} sx={{ borderRadius: 'sm' }} />
              ) : (
                <Checkbox size={16} value={JSON.stringify(nft)} checked={selected?.includes(nft.key)} onChange={handleChange} />
              )}
            </Flex>
            <Flex alignItems='center' flex={1} maxWidth={50}>
              <Box sx={{ borderRadius: 'sm', overflow: 'hidden', width: 34, height: 34 }}>
                <NftImage collectionAddress={nft.address} tokenId={nft.tokenID} width={34} height={34} />
              </Box>
            </Flex>
            <Flex alignItems='center' flex={2 / 3}>
              <Text fontSize='md'>
                {nft.address === '' ? (
                  <SkeletonLine width={180} height={18} sx={{ borderRadius: 'sm' }} />
                ) : (
                  <Box lineHeight={1.7}>
                    <Text fontSize='sm' color='grey.300'>
                      {tc('label.collateral')}
                    </Text>
                    <Text fontSize='md' fontWeight='bold'>
                      {nft.collectionName} #{nft.tokenID}
                    </Text>
                  </Box>
                )}
              </Text>
            </Flex>
            <Flex
              alignItems='center'
              flex={1 / 3}
              justifyContent='flex-end'
              fontWeight={'bold'}
              sx={{ opacity: selected?.includes(nft.key) ? 1 : 0.5 }}
              fontSize='md'
            >
              {nft.address === '' ? (
                <SkeletonLine width={76} height={18} sx={{ borderRadius: 'sm' }} />
              ) : nft.availableToBorrow ? (
                <Flex alignItems={'flex-end'} flexDirection='column' lineHeight={1.7}>
                  <Text fontSize='sm' color='grey.300' fontWeight={'normal'}>
                    {tc('label.available-to-borrow')}
                  </Text>
                  <NumberFormat
                    number={nft.availableToBorrow}
                    format={SHOW_DECIMAL_PLACES}
                    preffix={
                      <Flex sx={{ borderRadius: 'sm', width: 16, height: 16, overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={ethImagePath} width={16} height={16} />
                      </Flex>
                    }
                  />
                </Flex>
              ) : (
                '--'
              )}
            </Flex>
          </Flex>
        </Label>
      )}

      {!isTablet && (
        <Label>
          <Flex width='100%' alignItems='center' flexDirection={'column'}>
            <Flex width='100%' justifyContent={'space-between'} alignItems='center'>
              <Flex alignItems='center'>
                {nft.address === '' ? (
                  <SkeletonLine width={18} height={18} sx={{ borderRadius: 'sm' }} mr={3} />
                ) : (
                  <Checkbox size={16} value={JSON.stringify(nft)} checked={selected?.includes(nft.key)} onChange={handleChange} />
                )}

                <Text fontSize='sm' fontWeight='bold' ml={3}>
                  {nft.address === '' ? (
                    <SkeletonLine width={180} height={18} sx={{ borderRadius: 'sm' }} />
                  ) : (
                    <>
                      {nft.collectionName} #{nft.tokenID}
                    </>
                  )}
                </Text>
              </Flex>

              <Flex alignItems='center' maxWidth={70}>
                <Box sx={{ borderRadius: 'sm', overflow: 'hidden', width: 34, height: 34 }}>
                  <NftImage collectionAddress={nft.address} tokenId={nft.tokenID} width={34} height={34} />
                </Box>
              </Flex>
            </Flex>
            <Flex width='100%' justifyContent={'space-between'} alignItems='center' mt={10} flex={1}>
              <Flex alignItems='center' justifyContent='flex-start' fontWeight={'bold'} flex={0.5} sx={{ opacity: selected?.includes(nft.key) ? 1 : 0.5 }}>
                {nft.address === '' ? (
                  <SkeletonLine width={135} height={18} sx={{ borderRadius: 'sm' }} />
                ) : (
                  <Text fontSize='sm'>{tc('label.available-to-borrow')}</Text>
                )}
              </Flex>
              <Flex alignItems='center' justifyContent='flex-end' flex={0.5} fontWeight='bold' fontSize='md'>
                {nft.address === '' ? (
                  <SkeletonLine width={76} height={17} sx={{ borderRadius: 'sm', ml: 5 }} />
                ) : nft.availableToBorrow ? (
                  <NumberFormat
                    number={nft.availableToBorrow}
                    format={SHOW_DECIMAL_PLACES}
                    preffix={
                      <Flex sx={{ borderRadius: 'sm', width: 17, height: 17, overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={ethImagePath} width={17} height={17} />
                      </Flex>
                    }
                  />
                ) : (
                  '--'
                )}
              </Flex>
            </Flex>
          </Flex>
        </Label>
      )}
      <Flex justifyContent='center' mx={-20} alignItems='center' sx={{ ':last-child': { display: 'none' } }}>
        <SpacerDash bgColor={colors.grey[100]} height={1} width='100%' />
      </Flex>
    </>
  )
}
