import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import { ModalContainerProps } from '../types'
import { useModalAppContext } from './context'

const ModalContainer: React.FC<ModalContainerProps> = ({ children, modalOpen }) => {
  const { setIsModalOpen } = useModalAppContext()

  useEffect(() => {
    setIsModalOpen(modalOpen)
  }, [modalOpen, setIsModalOpen])

  return (
    <AnimatePresence initial={false} exitBeforeEnter={true}>
      {modalOpen && children}
    </AnimatePresence>
  )
}

export default ModalContainer
