import React from 'react'
//import _ from 'lodash'
// Interfaces
import { BendContextProps, BendStateProps, ReducerActionProps } from './interfaces'
//import { useTranslation } from 'next-i18next'
// Components
// import { ReloadNotification } from '../components'

const initialState: BendStateProps = {
  userData: []
}

const reducer = (state: BendStateProps, { type, payload }: ReducerActionProps) => {
  switch (type) {
    case 'setUserData':
      return { ...state, userData: payload }
    case 'UPDATE':
      return { ...state, ...payload }
    default:
      return state
  }
}

export const BendContext = React.createContext<BendContextProps>({
  ...initialState
})

const BendContextProvider: React.FC = ({ children }) => {
  const [BendState, BendDispatch] = React.useReducer(reducer, initialState)

  return (
    <BendContext.Provider
      value={{
        ...BendState,
        BendDispatch
      }}
    >
      {children}
    </BendContext.Provider>
  )
}

export default BendContextProvider
