import useTheme from 'hooks/common/useTheme'
import React, { useMemo } from 'react'
import { Box, Flex, FlexProps } from 'rebass/styled-components'
import { Loader } from 'theme/ui'
import { IconSuccess, IconFail, IconVerify, IconInfoXl } from 'theme/ui/icons/bend-icons'

interface NotificationProps extends FlexProps {
  type?: 'default' | 'success' | 'fail' | 'verify'
  loading?: boolean
  noicon?: boolean
}

const NotificationSmall: React.FC<NotificationProps> = ({ children, type = 'default', loading = false, noicon = false, ...restprops }): React.ReactElement => {
  const [showLoader, setShowLoader] = React.useState(false)
  const { colors } = useTheme()
  React.useEffect(() => {
    if (loading) {
      setShowLoader(true)
    }

    if (!loading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false)
      }, 200)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [loading, showLoader])

  const config = useMemo(() => {
    switch (type) {
      default:
        return {
          style: {
            color: 'primary',
            bg: 'blue.200'
          },
          icon: (
            <Box mt={2}>
              <IconInfoXl size={14} />
            </Box>
          )
        }

      case 'fail':
        return {
          style: {
            color: 'red.1000',
            bg: 'red.200'
          },
          icon: <IconFail size={14} />
        }
      case 'success':
        return {
          style: {
            color: 'green.1000',
            bg: 'green.200'
          },
          icon: <IconSuccess size={14} />
        }
      case 'verify':
        return {
          style: {
            color: 'primary',
            bg: 'blue.200'
          },
          icon: <IconVerify size={14} />
        }
    }
  }, [type])

  return (
    <Flex
      alignItems='center'
      textAlign={'center'}
      justifyContent='center'
      fontSize={'s'}
      {...restprops}
      sx={{
        ...config.style,
        borderRadius: 'sm',
        wordBreak: 'break-word',
        WebkitHyphens: 'auto',
        MozHyphens: 'auto',
        /*   hyphens: 'auto', */
        position: 'relative',
        width: '100%',
        px: 10,
        py: 10,
        lineHeight: 1.5,
        ...restprops.sx
      }}
    >
      <Box mr={5} pt={5}>
        {!loading ? !noicon ? config.icon : null : <Loader color={colors.primary} size={14} />}
      </Box>
      {children}
    </Flex>
  )
}

export default NotificationSmall
