var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value)
          })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator['throw'](value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
import { useCallback, useContext } from 'react'
import { WalletContext } from '../contexts/wallet'
import { sendTransaction } from '../utils/contract'
import { useActiveWeb3React } from './useActiveWeb3React'
export function useWallet() {
  const wallet = useContext(WalletContext)
  return wallet
}
export const useSendTransaction = () => {
  const { library, account } = useActiveWeb3React()
  const handleAction = useCallback(
    (amount, to) =>
      __awaiter(void 0, void 0, void 0, function* () {
        if (!library || !account)
          return {
            message: 'error'
          }
        try {
          const tx = yield sendTransaction(library, account, amount, to)
          return tx
        } catch (e) {
          return e
        }
      }),
    [library, account]
  )
  return { onSendTransaction: handleAction }
}
