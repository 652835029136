import BigNumber from 'bignumber.js'
import { useModalContext } from 'components/common/modal/components/context'
import NumberFormat from 'components/common/number-format'
import { ethImagePath, SHOW_DECIMAL_PLACES } from 'constants/index'
import orderBy from 'lodash/orderBy'
import { WETH_ADDRESS } from 'modules/bend/constants'
import { useCheckNftApprovedForBorrowList } from 'modules/bend/hooks/useApprove'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { Box, Flex, Text, Image } from 'rebass/styled-components'
import { Input, Button, Notification, NotificationSmall } from 'theme/ui'
import Slider, { SliderContext } from 'theme/ui/forms/slider'
import { Screen } from '..'
import { useBorrowContext } from '../context'
import NftInfo from '../details/nft-info'

const EnterBorrowAmountsStep: React.FC = () => {
  const { t } = useTranslation('common')
  const { t: tc } = useTranslation('common')
  const { sliderValue, setSliderValue, getSliderPercent } = useContext(SliderContext)
  const [index, setIndex] = useState(0)
  const { watch, handleSubmit, reset, setValue, register } = useFormContext()
  const { handler, isSingle, stepNumber, account } = useBorrowContext()
  const { handleClose } = useModalContext()

  const bnftItems = watch('bnftItems')
  const totalBorrowAmount = watch('totalBorrowAmount')
  const amount = watch('amount')

  const bnftItemsParsed = useMemo(() => {
    if (!bnftItems) return []
    return bnftItems.map((bnftItem: string) => JSON?.parse(bnftItem))
  }, [bnftItems])
  const bnftItem = useMemo(() => bnftItemsParsed[index], [bnftItemsParsed, index])

  const { nftApproved } = useCheckNftApprovedForBorrowList({
    account,
    assetAddress: WETH_ADDRESS,
    list: bnftItemsParsed
  })

  useEffect(() => {
    if (!bnftItem) return
    setValue('amount', bnftItem.availableToBorrow)
    setSliderValue(100)
  }, [bnftItem, setSliderValue, setValue])

  useEffect(() => {
    if (!bnftItem?.availableToBorrow) return
    const percent = getSliderPercent(amount, bnftItem.availableToBorrow)
    setSliderValue(percent)
  }, [amount, getSliderPercent, setSliderValue, bnftItem?.availableToBorrow])

  const onSubmit = useCallback(
    data => {
      if (!handler) return
      const parsed = data.bnftItems.map((bnftItem: string) => JSON?.parse(bnftItem))
      parsed[index].amount = data.amount
      setValue('totalBorrowAmount', new BigNumber(totalBorrowAmount).plus(data.amount).toFixed(4))
      setValue(
        'bnftItems',
        orderBy(parsed, ['collectionName'], ['asc']).map(item => JSON.stringify(item))
      )
      if (index === bnftItemsParsed.length - 1) {
        if (isSingle) {
          if (stepNumber === 2) handler(Screen.CONFIRM_BORROW, 2)
          if (stepNumber === 3) handler(Screen.APPROVE_TOKENS, 2)
        } else {
          if (nftApproved) {
            handler(Screen.CONFIRM_BORROW, 4)
          } else {
            handler(Screen.APPROVE_TOKENS, 3)
          }
        }
      } else {
        setIndex(currentIndex => (currentIndex += 1))
      }
    },
    [handler, index, setValue, totalBorrowAmount, bnftItemsParsed.length, isSingle, stepNumber, nftApproved]
  )

  const handleBack = useCallback(() => {
    if (!handler) return

    handler(Screen.SELECT_NFT_COLLATERALS, 1)
    reset()
  }, [handler, reset])

  const SliderWrapper = useCallback(
    () => (
      <Slider
        name='slider'
        defaultValue={sliderValue}
        step={1}
        onAfterChange={(sliderVal: number) => {
          setSliderValue(sliderVal)
          if (sliderVal === 0) {
            setValue('amount', '')
          } else {
            setValue('amount', Number(new BigNumber(bnftItem?.availableToBorrow).multipliedBy(sliderVal).dividedBy(100).dp(4, 1)).toFixed(4))
          }
        }}
        min={0}
        max={100}
        marks={{
          0: `0%`,
          25: '25%',
          50: '50%',
          75: '75%',
          100: '100%'
        }}
        hideMaxLabel
      />
    ),
    [bnftItem?.availableToBorrow, setSliderValue, setValue, sliderValue]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDirection={'column'} px={20}>
        <Text fontSize={'lg'} fontWeight='bold' textAlign={'center'} mb={10}>
          {tc('label.enter-borrow-amount')}{' '}
          {!isSingle && (
            <>
              {index + 1} / {bnftItems.length}
            </>
          )}
        </Text>
        {bnftItem && <NftInfo bnftItem={bnftItem} amount={amount} />}
        <Box my={20}>
          <Input
            size='lg'
            autoComplete='off'
            type='number'
            step='any'
            placeholder='0.00'
            left={
              <Text mb={-5} fontSize={'s'} color='grey.300'>
                {tc('label.amount')}
              </Text>
            }
            right={
              <Flex mb={-5} color='grey.300' fontSize={'s'}>
                <Text mr={5}>{tc('label.available-to-borrow')}:</Text> <NumberFormat number={bnftItem?.availableToBorrow} format={SHOW_DECIMAL_PLACES} />
              </Flex>
            }
            endAdornment={
              <Flex mr={20} alignItems='center'>
                <Button
                  type='button'
                  size='sm'
                  buttonStyles={{
                    py: 3,
                    px: 5,
                    borderRadius: 'xs'
                  }}
                  fontWeight='bold'
                  onClick={() => setValue('amount', bnftItem?.availableToBorrow)}
                >
                  <Text fontSize='xs'>{tc('label.max').toUpperCase()}</Text>
                </Button>
                <Flex width={24} height={24} sx={{ borderRadius: 'icon', overflow: 'hidden' }} alignItems='center' justifyContent='center' mx={5}>
                  <Image src={ethImagePath} width={24} height={24} />
                </Flex>
                <Text fontWeight='bold' fontSize='lg'>
                  ETH
                </Text>
              </Flex>
            }
            sx={{ fontWeight: 700 }}
            styles={{
              maxHeight: 75
            }}
            {...register('amount')}
          />
        </Box>
        <Box mx={10} mb={20}>
          <SliderWrapper />
        </Box>

        {sliderValue === 100 && (
          <NotificationSmall noicon mt={20} flexDirection='column'>
            <Text lineHeight={1.5} as='span' dangerouslySetInnerHTML={{ __html: t('borrow.max-warning') }} />
            <Text as='span' dangerouslySetInnerHTML={{ __html: t('borrow.max-warning-1') }} />
          </NotificationSmall>
        )}

        <Notification px={20} py={10} noicon reducePadding mt={10}>
          <Flex width='100%' justifyContent='space-between' alignItems='center'>
            <Text fontWeight={'bold'} color='primary'>
              {tc('label.current-total-borrow-amount')}
            </Text>

            <Box fontWeight={'bold'} color='primary' mt={'3px'}>
              <NumberFormat
                number={new BigNumber(totalBorrowAmount).plus(amount)}
                format={SHOW_DECIMAL_PLACES}
                preffix={
                  <Flex sx={{ borderRadius: 'sm', width: 17, height: 17, overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                    <Image src={ethImagePath} width={17} height={17} />
                  </Flex>
                }
              />
            </Box>
          </Flex>
        </Notification>

        <Flex flexDirection={['row']} mt={20}>
          {isSingle ? (
            <Button type='button' size='xl' flex={1} outlined onClick={handleClose} mr={[10]}>
              {tc('button.close')}
            </Button>
          ) : (
            <Button type='button' size='xl' flex={1} outlined onClick={handleBack} mr={[10]}>
              {tc('button.back')}
            </Button>
          )}
          <Button
            type='submit'
            flex={1}
            size='xl'
            color='primary'
            disabled={!amount || amount === '0' || new BigNumber(amount).gt(bnftItem?.availableToBorrow)}
          >
            {index === bnftItemsParsed.length - 1 ? tc('button.continue') : tc('button.next')}
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}

export default EnterBorrowAmountsStep
