import NumberFormat from 'components/common/number-format'
import { ethImagePath, percentImagePath, SHOW_DECIMAL_PLACES } from 'constants/index'
import useTheme from 'hooks/common/useTheme'
import React, { useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { Flex, Box, Text, Image } from 'rebass/styled-components'
import { HealthIndicator, HealthFactor, SkeletonLine } from 'theme/ui'
import useResponsive from 'hooks/common/useResponsive'
import { useCollateral } from 'hooks/dashboard/useCollaterals'
import BigNumber from 'bignumber.js'
import { useDataContext } from 'contexts/data'
import NftImage from 'components/common/nft-image'
import BorrowApy from 'components/common/subscriptions/borrow-apy'

type BorrowCollateralInfoProps = {
  bnftItem: {
    address: string
    tokenID: string
    reserveId: string
    availableToBorrow: string | BigNumber
    borrowed?: BigNumber
  }
  amount: BigNumber
}

const BorrowCollateralInfo: React.FC<BorrowCollateralInfoProps> = ({ bnftItem, amount }) => {
  const { colors } = useTheme()
  const { t } = useTranslation('common')
  const { isTablet } = useResponsive()
  const { eth } = useDataContext()

  const { collateral } = useCollateral({ collectionAddress: bnftItem.address, tokenId: bnftItem.tokenID, reserveId: eth.id })
  const { data, isLoading } = collateral

  const healthFactor = useMemo(() => {
    if (!data?.liquidationRatio || !data?.floorPrice) return new BigNumber(0)
    return data.floorPrice.multipliedBy(data.liquidationRatio.dividedBy(1e2)).dividedBy(new BigNumber(amount).plus(bnftItem.borrowed || 0))
  }, [data?.liquidationRatio, data?.floorPrice, amount, bnftItem.borrowed])

  const liquidationPrice = useMemo(() => {
    if (!data?.liquidationRatio) return new BigNumber(0)
    const totalAmount = new BigNumber(amount).plus(bnftItem.borrowed || 0)
    return totalAmount.dividedBy(data.liquidationRatio.dividedBy(1e2))
  }, [amount, data?.liquidationRatio, bnftItem.borrowed])

  return (
    <Flex flexDirection='column' sx={{ border: `1px solid ${colors.grey[300]}`, borderRadius: 'sm' }}>
      <Box>
        <Flex
          px={[20, 20, 25]}
          py={[10]}
          width='100%'
          alignItems='center'
          justifyContent={['space-between']}
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <Box
            width='100%'
            alignItems='flex-start'
            sx={{
              display: 'grid',
              gridGap: ['20px auto'],
              gridTemplateColumns: `repeat(${isTablet ? 'auto-fit' : 2}, minmax(95px, 1fr))`
            }}
          >
            <Flex
              minWidth={220}
              flexDirection='row'
              alignItems='center'
              textAlign={['left']}
              sx={{
                gridColumn: ['span 2']
              }}
            >
              <Box
                mr={10}
                alignItems='center'
                width={35}
                height={35}
                sx={{
                  borderRadius: 'sm',
                  overflow: 'hidden'
                }}
              >
                {data ? (
                  <NftImage collectionAddress={data.collectionAddress} tokenId={data.tokenId} width={35} height={35} />
                ) : (
                  <SkeletonLine width={[35]} height={[35]} sx={{ borderRadius: 'sm' }} />
                )}
              </Box>

              <Box alignItems='flex-start'>
                <Text fontSize='s' color='grey.300'>
                  {t('label.collateral')}
                </Text>
                <Flex fontSize='md' fontWeight='bold'>
                  <Text fontSize='md' fontWeight='bold' textAlign={['right', 'right', 'left']}>
                    {!data || isLoading ? (
                      <SkeletonLine width={[40, 40, 80]} height={[18]} sx={{ borderRadius: 'sm' }} />
                    ) : (
                      <Text fontSize={'md'} fontWeight='bold'>
                        {data?.assetName} #{data?.tokenId}
                      </Text>
                    )}
                  </Text>
                </Flex>
              </Box>
            </Flex>

            <Flex flexDirection='column' textAlign='left' ml={[0, 0, 20]}>
              <Text fontSize='s' color='grey.300'>
                {t('label.utilization-rate')}
              </Text>
              <Text fontSize='md' fontWeight='bold'>
                {isLoading ? (
                  <SkeletonLine width={[40, 40, 80]} height={[18]} sx={{ borderRadius: 'sm' }} />
                ) : (
                  <NumberFormat
                    number={new BigNumber(eth.utilizationRate).multipliedBy(1e2)}
                    format={SHOW_DECIMAL_PLACES}
                    suffix='%'
                    suffixMargin={{ ml: 5 }}
                    preffix={
                      <Flex sx={{ borderRadius: 'sm', width: 16, height: 16, overflow: 'hidden', alignItems: 'center', justifyContent: 'center', mt: '-2px' }}>
                        <Image src={percentImagePath} width={16} height={16} />
                      </Flex>
                    }
                  />
                )}
              </Text>
            </Flex>

            <Flex flexDirection='column' ml={[0, 0, 20]}>
              <Text fontSize='s' color='grey.300' textAlign={['right', 'right', 'left']}>
                {t('label.borrow-apr')}
              </Text>
              <Text fontSize='md' fontWeight='bold' textAlign={['right', 'right', 'left']}>
                {isLoading ? (
                  <SkeletonLine width={[40, 40, 80]} height={[18]} sx={{ borderRadius: 'sm' }} />
                ) : (
                  <BorrowApy
                    reserveId={eth.id}
                    initialValue={new BigNumber(100).multipliedBy(new BigNumber(eth.variableBorrowRate).dividedBy(`1e27`)).dp(4, 1)}
                    suffix='%'
                    suffixMargin={{ ml: 5 }}
                    preffix={
                      <Flex sx={{ borderRadius: 'sm', width: 16, height: 16, overflow: 'hidden', alignItems: 'center', justifyContent: 'center', mt: '-2px' }}>
                        <Image src={percentImagePath} width={16} height={16} />
                      </Flex>
                    }
                  />
                )}
              </Text>
            </Flex>

            <Flex flexDirection='column' textAlign={['left']}>
              <Text fontSize='s' color='grey.300'>
                {t('label.floor-price')}
              </Text>
              <Flex fontSize='md' fontWeight='bold'>
                <Text fontSize='md' fontWeight='bold' textAlign={['right', 'right', 'left']}>
                  {isLoading ? (
                    <SkeletonLine width={[40, 40, 80]} height={[18]} sx={{ borderRadius: 'sm' }} />
                  ) : (
                    <NumberFormat
                      number={data ? data.floorPrice : 0}
                      format={SHOW_DECIMAL_PLACES}
                      preffix={
                        <Flex
                          sx={{ borderRadius: 'sm', width: 16, height: 16, overflow: 'hidden', alignItems: 'center', justifyContent: 'center', mt: '-2px' }}
                        >
                          <Image src={ethImagePath} width={16} height={16} />
                        </Flex>
                      }
                    />
                  )}
                </Text>
              </Flex>
            </Flex>

            <Flex flexDirection='column'>
              <Text fontSize='s' color='grey.300' textAlign={['right', 'right', 'left']} minWidth={127}>
                {t('label.available-to-borrow')}
              </Text>
              <Text fontSize='md' fontWeight='bold' textAlign={['right', 'right', 'left']}>
                {isLoading ? (
                  <SkeletonLine width={[40, 40, 80]} height={[18]} sx={{ borderRadius: 'sm' }} />
                ) : (
                  <NumberFormat
                    number={data ? new BigNumber(bnftItem.availableToBorrow).dp(4, 1) : 0}
                    format={SHOW_DECIMAL_PLACES}
                    preffix={
                      <Flex sx={{ borderRadius: 'sm', width: 16, height: 16, overflow: 'hidden', alignItems: 'center', justifyContent: 'center', mt: '-2px' }}>
                        <Image src={ethImagePath} width={16} height={16} />
                      </Flex>
                    }
                  />
                )}
              </Text>
            </Flex>

            <Flex flexDirection='column' ml={[0, 0, 20]}>
              <Text fontSize='s' color='grey.300' textAlign={['left', 'left', 'left']}>
                {t('label.liquidation-price')}
              </Text>
              <Text fontSize='md' fontWeight='bold' textAlign={['left', 'left', 'left']}>
                {isLoading ? (
                  <SkeletonLine width={[40, 40, 80]} height={[18]} sx={{ borderRadius: 'sm' }} />
                ) : (
                  <NumberFormat
                    number={data ? (liquidationPrice.gt(data.floorPrice) ? data.floorPrice : liquidationPrice) : 0}
                    format={SHOW_DECIMAL_PLACES}
                    preffix={
                      <Flex sx={{ borderRadius: 'sm', width: 16, height: 16, overflow: 'hidden', alignItems: 'center', justifyContent: 'center', mt: '-2px' }}>
                        <Image src={ethImagePath} width={16} height={16} />
                      </Flex>
                    }
                  />
                )}
              </Text>
            </Flex>

            <Flex flexDirection='column' ml={[0, 0, 20]} textAlign={['right', 'right', 'left']}>
              <Text fontSize='s' color='grey.300'>
                {t('label.health-factor')}
              </Text>
              <Flex alignItems='center' textAlign={['right', 'right', 'left']} fontSize='md' fontWeight='bold'>
                {isLoading ? (
                  <SkeletonLine width={[40, 40, 80]} height={[18]} sx={{ borderRadius: 'sm' }} />
                ) : (
                  <>
                    <HealthIndicator healthFactor={healthFactor} mb={3} />
                    <HealthFactor healthFactor={healthFactor} ml={5} />
                  </>
                )}
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default BorrowCollateralInfo
