import React, { useEffect, useMemo, useRef } from 'react'
import { useActiveWeb3React, useBlockNumber, CancelledError, retry, RetryableError } from 'wallet-module'
import { useDebounce } from '../hooks'
import { useMulticallContract } from '../hooks/useContract'
import { parseCallKey, chunkArray, toCallKey } from '../utils'
import { DEFAULT_CHAIN_ID } from 'constants/index'
const showDebug = false
const initialState = {
  callResults: {}
}
const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'UPDATE':
      return { ...state, ...payload }
    default:
      return state
  }
}
export const MulticallContext = React.createContext({
  ...initialState
})
const CALL_CHUNK_SIZE = 500
async function fetchChunk(multicallContract, chunk, minBlockNumber) {
  console.debug('Fetching chunk', chunk, minBlockNumber)
  let resultsBlockNumber, returnData
  try {
    ;[resultsBlockNumber, returnData] = await multicallContract.aggregate(chunk.map(obj => [obj.address, obj.callData]))
  } catch (error) {
    console.debug('Failed to fetch chunk inside retry', error)
    throw error
  }
  if (resultsBlockNumber.toNumber() < minBlockNumber) {
    console.debug(`Fetched results for old block number: ${resultsBlockNumber.toString()} vs. ${minBlockNumber}`)
    throw new RetryableError('Fetched for old block number')
  }
  return { results: returnData, blockNumber: resultsBlockNumber.toNumber() }
}
export function activeListeningKeys(allListeners, chainId) {
  if (!allListeners || !chainId) return {}
  if (showDebug) console.log('context activeListeningKeys allListeners', JSON.stringify(allListeners))
  if (showDebug) console.log('context activeListeningKeys chainId', chainId)
  const listeners = allListeners[chainId]
  if (showDebug) console.log('context activeListeningKeys listeners', JSON.stringify(listeners))
  if (!listeners) return {}
  const returnKeys = Object.keys(listeners).reduce((memo, callKey) => {
    const keyListeners = listeners[callKey]
    if (showDebug) console.log('context activeListeningKeys memo', memo)
    if (showDebug) console.log('context activeListeningKeys callKey', callKey)
    if (showDebug) console.log('context activeListeningKeys keyListeners', keyListeners)
    memo[callKey] = Object.keys(keyListeners)
      .filter(key => {
        const blocksPerFetch = parseInt(key)
        if (blocksPerFetch <= 0) return false
        return keyListeners[blocksPerFetch] > 0
      })
      .reduce((previousMin, current) => {
        return Math.min(previousMin, parseInt(current))
      }, Infinity)
    if (showDebug) console.log('context activeListeningKeys memo return', memo)
    return memo
  }, {})
  if (showDebug) console.log('context activeListeningKeys returnKeys', returnKeys)
  return returnKeys
}
export function outdatedListeningKeys(callResults, listeningKeys, chainId, latestBlockNumber) {
  if (!chainId || !latestBlockNumber) return []
  const results = callResults[chainId]
  if (!results) return Object.keys(listeningKeys)
  return Object.keys(listeningKeys).filter(callKey => {
    const blocksPerFetch = listeningKeys[callKey]
    const data = callResults[chainId][callKey]
    if (!data) return true
    const minDataBlockNumber = latestBlockNumber - (blocksPerFetch - 1)
    if (data.fetchingBlockNumber && data.fetchingBlockNumber >= minDataBlockNumber) return false
    return !data.blockNumber || data.blockNumber < minDataBlockNumber
  })
}
const MulticallContextProvider = ({ children }) => {
  const [MulticallState, MulticallDispatch] = React.useReducer(reducer, initialState)
  const { callListeners, callResults } = MulticallState
  if (showDebug) console.log('context init callListeners', JSON.stringify(callListeners))
  if (showDebug) console.log('context init callResults', JSON.stringify(callResults))
  const debouncedListeners = useDebounce(callListeners, 300)
  const latestBlockNumber = useBlockNumber()
  const multicallContract = useMulticallContract()
  const cancellations = useRef()
  const listeningKeys = useMemo(() => {
    if (showDebug) console.log('context listeningKeys got callListeners', JSON.stringify(callListeners))
    if (showDebug) console.log('context listeningKeys got callListeners debouncedListeners', JSON.stringify(debouncedListeners))
    const activeKeys = activeListeningKeys(debouncedListeners, DEFAULT_CHAIN_ID)
    if (showDebug) console.log('context listeningKeys return', JSON.stringify(activeKeys))
    return activeKeys
  }, [callListeners, JSON.stringify(debouncedListeners), debouncedListeners])
  const unserializedOutdatedCallKeys = useMemo(() => {
    if (showDebug) console.log('context unserializedOutdatedCallKeys input listeningKeys', listeningKeys)
    if (showDebug)
      console.log(
        'context unserializedOutdatedCallKeys callResults listeningKeys latestBlockNumber chainId',
        callResults,
        listeningKeys,
        latestBlockNumber,
        DEFAULT_CHAIN_ID
      )
    return outdatedListeningKeys(callResults, listeningKeys, DEFAULT_CHAIN_ID, latestBlockNumber)
  }, [callResults, listeningKeys, latestBlockNumber])
  const serializedOutdatedCallKeys = useMemo(() => JSON.stringify(unserializedOutdatedCallKeys.sort()), [unserializedOutdatedCallKeys])
  useEffect(() => {
    var _a, _b, _c, _d
    if (showDebug) console.log('context multicall')
    if (!latestBlockNumber || !multicallContract) return
    if (showDebug) console.log('context multicall latestBlockNumber', latestBlockNumber)
    const outdatedCallKeys = JSON.parse(serializedOutdatedCallKeys)
    if (outdatedCallKeys.length === 0) return
    const calls = outdatedCallKeys.map(key => parseCallKey(key))
    if (showDebug) console.log('context outdatedCallKeys', outdatedCallKeys)
    if (showDebug) console.log('context calls', calls)
    const chunkedCalls = chunkArray(calls, CALL_CHUNK_SIZE)
    if (showDebug) console.log('context chunkedCalls', chunkedCalls)
    if (((_a = cancellations.current) === null || _a === void 0 ? void 0 : _a.blockNumber) !== latestBlockNumber) {
      ;(_c = (_b = cancellations.current) === null || _b === void 0 ? void 0 : _b.cancellations) === null || _c === void 0 ? void 0 : _c.forEach(c => c())
    }
    if (showDebug) console.log('context multicall calls chainId fetchingBlockNumber', calls, DEFAULT_CHAIN_ID, latestBlockNumber)
    const newCallResults = callResults !== null && callResults !== void 0 ? callResults : {}
    newCallResults[DEFAULT_CHAIN_ID] = (_d = callResults[DEFAULT_CHAIN_ID]) !== null && _d !== void 0 ? _d : {}
    calls.forEach(call => {
      var _a
      const callKey = toCallKey(call)
      const current = newCallResults[DEFAULT_CHAIN_ID][callKey]
      if (!current) {
        newCallResults[DEFAULT_CHAIN_ID][callKey] = {
          fetchingBlockNumber: latestBlockNumber
        }
      } else {
        if (((_a = current.fetchingBlockNumber) !== null && _a !== void 0 ? _a : 0) >= latestBlockNumber) return
        newCallResults[DEFAULT_CHAIN_ID][callKey].fetchingBlockNumber = latestBlockNumber
        if (current === null || current === void 0 ? void 0 : current.callback) current.callback(current)
      }
    })
    MulticallDispatch({
      type: 'UPDATE',
      payload: {
        callResults: newCallResults
      }
    })
    cancellations.current = {
      blockNumber: latestBlockNumber,
      cancellations: chunkedCalls.map((chunk, index) => {
        const { cancel, promise } = retry(() => fetchChunk(multicallContract, chunk, latestBlockNumber), {
          n: Infinity,
          minWait: 2500,
          maxWait: 3500
        })
        promise
          .then(({ results: returnData, blockNumber: fetchBlockNumber }) => {
            var _a
            cancellations.current = {
              cancellations: [],
              blockNumber: latestBlockNumber
            }
            const firstCallKeyIndex = chunkedCalls.slice(0, index).reduce((memo, curr) => memo + curr.length, 0)
            const lastCallKeyIndex = firstCallKeyIndex + returnData.length
            if (showDebug) console.log('context returnData', returnData)
            const results = outdatedCallKeys.slice(firstCallKeyIndex, lastCallKeyIndex).reduce((memo, callKey, i) => {
              var _a
              memo[callKey] = (_a = returnData[i]) !== null && _a !== void 0 ? _a : null
              return memo
            }, {})
            if (showDebug) console.log('context outdatedCallKeys', outdatedCallKeys)
            if (showDebug) console.log('context results', results)
            if (showDebug) console.log('context callResults', callResults)
            const newCallResults = callResults !== null && callResults !== void 0 ? callResults : {}
            newCallResults[DEFAULT_CHAIN_ID] = (_a = callResults[DEFAULT_CHAIN_ID]) !== null && _a !== void 0 ? _a : {}
            Object.keys(results).forEach(callKey => {
              var _a
              const current = newCallResults[DEFAULT_CHAIN_ID][callKey]
              if (((_a = current === null || current === void 0 ? void 0 : current.blockNumber) !== null && _a !== void 0 ? _a : 0) > fetchBlockNumber) return
              newCallResults[DEFAULT_CHAIN_ID][callKey] = {
                data: results[callKey],
                blockNumber: fetchBlockNumber
              }
            })
            if (showDebug) console.log('context newCallResults', newCallResults === null || newCallResults === void 0 ? void 0 : newCallResults[4])
            MulticallDispatch({
              type: 'UPDATE',
              payload: {
                callResults: newCallResults
              }
            })
          })
          .catch(error => {
            var _a
            if (error instanceof CancelledError) {
              console.debug('Cancelled fetch for blockNumber', latestBlockNumber)
              return
            }
            console.error('Failed to fetch multicall chunk', chunk, DEFAULT_CHAIN_ID, error)
            const newCallResults = callResults !== null && callResults !== void 0 ? callResults : {}
            newCallResults[DEFAULT_CHAIN_ID] = (_a = callResults[DEFAULT_CHAIN_ID]) !== null && _a !== void 0 ? _a : {}
            calls.forEach(call => {
              const callKey = toCallKey(call)
              const current = newCallResults[DEFAULT_CHAIN_ID][callKey]
              if (!current) return
              if (current.fetchingBlockNumber === latestBlockNumber) {
                delete current.fetchingBlockNumber
                current.data = null
                current.blockNumber = latestBlockNumber
              }
            })
            MulticallDispatch({
              type: 'UPDATE',
              payload: {
                callResults: newCallResults
              }
            })
          })
        return cancel
      })
    }
  }, [multicallContract, MulticallDispatch, callResults, serializedOutdatedCallKeys, latestBlockNumber])
  return React.createElement(
    MulticallContext.Provider,
    {
      value: {
        ...MulticallState,
        MulticallDispatch
      }
    },
    children
  )
}
export default MulticallContextProvider
