import { Avatar, Container, IconBorrow } from 'theme/ui'
import { Box, Text, Flex } from 'rebass/styled-components'
import BorrowProvider, { SingleBorrowType } from './context'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import SelectNftCollateralsStep from './steps/step1'
import EnterBorrowAmountsStep from './steps/step2'
import ApproveTokensStep from './steps/step3'
import { FormProvider, useForm } from 'react-hook-form'
import BigNumber from 'bignumber.js'
import { SliderContextProvider } from 'theme/ui/forms/slider'
import WizardTimelineThreeStep from './details/wizard-timeline-three-step'
import WizardTimelineFourStep from './/details/wizard-timeline-four-step'
import { MAX_BORROW_MULTIPLIER } from 'modules/bend/constants'
import { isPunk } from 'utils'
import useResponsive from 'hooks/common/useResponsive'
import ConfirmBorrowStep from './steps/step4'
import { useCheckNftApprovedForBorrow } from 'modules/bend/hooks/useApprove'
import { WizardTimelineTwoStep } from './details/wizard-timeline-two-step'

export enum Screen {
  SELECT_NFT_COLLATERALS = 0,
  ENTER_BORROW_AMOUNTS = 1,
  APPROVE_TOKENS = 2,
  CONFIRM_BORROW = 3
}

type BorrowComponentProps = {
  account: string | undefined | null
  single?: SingleBorrowType
  isModal?: boolean
}

const BorrowComponent: React.FC<BorrowComponentProps> = ({ account, single }) => {
  const [screenState, setScreenState] = useState<Screen>(Screen.SELECT_NFT_COLLATERALS)
  const [stepNumber, setStepNumber] = useState<undefined | number>(undefined)
  const [approveStep, setApproveStep] = useState<3 | 4 | 5>(3)
  const [wizardStep, setWizardStep] = useState(1)
  const { isTablet } = useResponsive()
  const formMethods = useForm({
    defaultValues: {
      bnftItems: [] as string[],
      totalAvailableToBorrow: new BigNumber(0),
      selectAll: false,
      totalBorrowAmount: '0',
      borrowAllowance: [],
      approvedAddress: [],
      selected: [],
      type: 'erc721'
    },
    mode: 'onChange'
  })
  const [approvingList, setApproveList] = useState([''])
  const [approvedList, setApprovedList] = useState([''])
  const isSingle = useMemo(() => single?.enabled, [single])

  const { nftApproved, delegationApproved } = useCheckNftApprovedForBorrow({
    account,
    collectionAddress: isSingle ? single?.nftAsset?.nftItemInfo.collectionAddress : undefined,
    tokenID: isSingle ? single?.nftAsset?.nftItemInfo.tokenID : undefined
  })

  const handler = useCallback((step: Screen, wizardStep: number) => {
    return [setScreenState(step), setWizardStep(wizardStep)]
  }, [])

  const { setValue } = formMethods

  useEffect(() => {
    if (!isSingle || !single?.nftAsset) return
    setValue('bnftItems', [
      JSON.stringify({
        address: single?.nftAsset?.nftItemInfo.collectionAddress,
        tokenID: single?.nftAsset?.nftItemInfo.tokenID,
        name: single?.nftAsset?.nftItemInfo.nftItem.collection.name,
        amount: '0',
        availableToBorrow: new BigNumber(single?.nftAsset?.availableToBorrow || 0).multipliedBy(MAX_BORROW_MULTIPLIER).dp(4, 1).toFixed(4),
        key: `${single?.nftAsset?.nftItemInfo.collectionAddress}+${single?.nftAsset?.nftItemInfo.tokenID}`,
        borrowed: new BigNumber(single.nftAsset.nftItemInfo.loan?.currentAmount || 0).dividedBy(1e18)
      })
    ])
    if (isPunk(single?.nftAsset?.nftItemInfo.collectionAddress)) setValue('type', 'cryptopunks')
  }, [setValue, isSingle, single?.nftAsset])

  useEffect(() => {
    if (stepNumber !== undefined) return
    if (isSingle && single?.nftAsset) {
      if (nftApproved === undefined || delegationApproved === undefined) return
      if (nftApproved && delegationApproved) setStepNumber(2)
      else setStepNumber(3)
    } else {
      setStepNumber(4)
    }
  }, [delegationApproved, isSingle, nftApproved, single, stepNumber])

  return (
    <FormProvider {...formMethods}>
      <BorrowProvider
        value={{
          screenState,
          setScreenState,
          account,
          setApproveStep,
          approvingList,
          setApproveList,
          approvedList,
          setApprovedList,
          setWizardStep,
          approveStep,
          wizardStep,
          handler,
          single,
          isSingle,
          stepNumber
        }}
      >
        <Container variant={'card-body-modal'} flexDirection='row' maxWidth={562} justifyContent='center'>
          <Box variant={!isTablet ? 'card5-modal' : 'card5'} flexDirection='column' alignItems='center' minWidth={['100%', '100%', 562]}>
            <BorrowHeader isSingle={isSingle} />

            {isSingle &&
              (() => {
                switch (screenState) {
                  default:
                  case Screen.ENTER_BORROW_AMOUNTS:
                    return (
                      <SliderContextProvider>
                        {stepNumber === 3 && <WizardTimelineThreeStep />}
                        {stepNumber === 2 && <WizardTimelineTwoStep />}
                        <EnterBorrowAmountsStep />
                      </SliderContextProvider>
                    )
                  case Screen.APPROVE_TOKENS:
                    return (
                      <>
                        <WizardTimelineThreeStep />
                        <ApproveTokensStep />
                      </>
                    )
                  case Screen.CONFIRM_BORROW:
                    return (
                      <>
                        {stepNumber === 3 && <WizardTimelineThreeStep />}
                        {stepNumber === 2 && <WizardTimelineTwoStep />}
                        <ConfirmBorrowStep />
                      </>
                    )
                }
              })()}

            {!isSingle &&
              (() => {
                switch (screenState) {
                  default:
                  case Screen.SELECT_NFT_COLLATERALS:
                    return (
                      <>
                        <WizardTimelineFourStep />
                        <SelectNftCollateralsStep />
                      </>
                    )
                  case Screen.ENTER_BORROW_AMOUNTS:
                    return (
                      <SliderContextProvider>
                        <WizardTimelineFourStep />
                        <EnterBorrowAmountsStep />
                      </SliderContextProvider>
                    )
                  case Screen.APPROVE_TOKENS:
                    return (
                      <>
                        <WizardTimelineFourStep />
                        <ApproveTokensStep />
                      </>
                    )
                  case Screen.CONFIRM_BORROW:
                    return (
                      <>
                        <WizardTimelineFourStep />
                        <ConfirmBorrowStep />
                      </>
                    )
                }
              })()}
          </Box>
        </Container>
      </BorrowProvider>
    </FormProvider>
  )
}

export default BorrowComponent

interface IBorrowHeader {
  isSingle?: boolean
}

export const BorrowHeader: React.FC<IBorrowHeader> = ({ isSingle }) => {
  const { t } = useTranslation('common')
  return (
    <Flex m={20} alignItems='flex-start'>
      <Avatar
        minWidth={40}
        iconSize={40}
        backgroundColor='primary'
        sx={{ borderRadius: 'sm' }}
        icon={
          <Flex justifyContent='center' alignItems='center'>
            <IconBorrow color='white' size={22} />
          </Flex>
        }
      />
      <Box ml={20}>
        <Text fontWeight='bold' fontSize='xl'>
          {t('borrow-eth.title')}
        </Text>
        <Text fontSize='md' lineHeight='xl' color='grey.300'>
          {isSingle ? t('single-borrow.subtitle') : t('batch-borrow.subtitle')}
        </Text>
      </Box>
    </Flex>
  )
}
