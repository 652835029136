import { useCallback, useMemo } from 'react'

import { useSingleCallResult } from 'multicall-module'
import { useERC721Contract } from '.'
import { setApprovalForAll } from '../utils/IERC721Detailed'
import get from 'lodash/get'
import { LEND_POOL, LEND_POOL_LOAN } from '../constants'
import { useILendPoolContract, useLendPoolLoanContract } from './'

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useGetApproved(
  ERC721contract_address: string,
  tokenId: string
): {
  tokenId: string
  approvedAddress: string
} {
  const contract = useERC721Contract(ERC721contract_address, false)

  const { result } = useSingleCallResult(contract, 'getApproved', [tokenId])
  const data = result?.[0]

  return useMemo(
    () =>
      data
        ? {
            tokenId,
            approvedAddress: data.toString()
          }
        : {
            tokenId,
            approvedAddress: ''
          },
    [data, tokenId]
  )
}

export const useSetApprovalForAll = (ERC721contract_address: string, operator_address: string) => {
  const ERC721contract = useERC721Contract(ERC721contract_address)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await setApprovalForAll(ERC721contract, operator_address)
      return tx
    } catch (e) {
      if (typeof e === 'string') throw { msg: e }
      else return e
    }
  }, [ERC721contract, operator_address])

  return { onSetApprovalForAll: handleApprove }
}

/**
 * It returns a boolean that indicates whether the account is the owner of the token
 * @param account - the address of the account you want to check
 * @param collection - the address of the collection you want to check
 * @param tokenId - the number of the tokenId you want to check
 * @returns A boolean value that is either true or false.
 */
export const useERC721isOwner = ({ account, collection, tokenId }: { account: string; collection: string; tokenId: string }) => {
  const ERC721contract = useERC721Contract(collection)
  const { result: ownerERC721Result } = useSingleCallResult(ERC721contract, 'ownerOf', [tokenId])

  const LendPoolContract = useILendPoolContract(LEND_POOL)
  const { result: getNftDebtDataResult } = useSingleCallResult(LendPoolContract, 'getNftDebtData', [collection, tokenId])

  const loanId = useMemo(() => {
    if (getNftDebtDataResult === undefined) return undefined
    return getNftDebtDataResult.loanId?.toString()
  }, [getNftDebtDataResult])

  const LendPoolLoanContract = useLendPoolLoanContract(LEND_POOL_LOAN)
  const { result: getLoanResult } = useSingleCallResult(loanId ? LendPoolLoanContract : undefined, 'getLoan', [loanId])

  const borrower = useMemo(() => {
    if (getLoanResult === undefined) return undefined
    const loanData = getLoanResult?.loanData
    return loanData?.borrower.toLowerCase()
  }, [getLoanResult])

  const isOwner = useMemo(() => {
    if (!ownerERC721Result || !borrower) return undefined
    const owner = String(get(ownerERC721Result, 0)).toLowerCase()
    if (owner === account.toLowerCase()) return true
    else if (borrower === account.toLowerCase()) return true
    else return false
  }, [account, borrower, ownerERC721Result])

  return isOwner
}
