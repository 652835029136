import BigNumber from 'bignumber.js'
import usePlatform from 'contexts/platform'
import get from 'lodash/get'
import { useSingleCallResult } from 'modules/multicall-module'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BDebtWETH } from '../constants'
import { useCryptoPunkContract, useDebtTokenContract, useERC721Contract } from './useContract'
import { useApproveDelegation } from './useDebtToken'
import { handleError } from '../utils/handleError'
import { useWallet } from 'modules/wallet-module'
import { useSetApprovalForAll } from './useErc721'
import { isPunk, isWeth } from 'utils'
import { useOfferPunkForSaleToAddress, usePunkBatchBorrowETH } from './usePunkGateway'
import { useBatchBorrowETH } from './useIWETHGateway'
import { useTranslation } from 'next-i18next'
import { useModalContext } from 'components/common/modal/components/context'
import { useAppNotificationContext } from 'components/common/app-notofications/context'

export enum ApproveButtonState {
  APPROVE = 'approve',
  APPROVED = 'approved',
  LOADING = 'loading',
  PROCESSING = 'PROCESSING'
}

export enum NotificationState {
  APPROVE = 'approve',
  APPROVED = 'approved',
  CONFIRM = 'confirm',
  CONFIRM_TX = 'confirm_TX',
  ERROR = 'error',
  SUCCESS = 'success',
  PROCESSING = 'processing'
}

type Price = {
  priceInEth: BigNumber
  oracle: Oracle
}

type Oracle = {
  usdPriceEth: BigNumber
}

export interface Reserve {
  id: string
  name: string
  symbol: string
  address: string
  availableLiquidity: BigNumber
  totalCurrentVariableDebt: BigNumber
  variableBorrowRate: BigNumber
  utilizationRate: BigNumber
  decimals: number
  price: Price
}

export function useBatchBorrowDelegate(account: string) {
  //const { t: tc } = useTranslation('common')
  const { addTransaction } = useWallet()
  const [txHash, setTxHash] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [buttonState, setButtonState] = useState<ApproveButtonState>(ApproveButtonState.LOADING)
  const [notificationState, setNotificationState] = useState<NotificationState>(NotificationState.APPROVE)

  const debtTokenContract = useDebtTokenContract(BDebtWETH)
  const {
    config: { WETH_GATEWAY }
  } = usePlatform()

  const { result: borrowAllowanceResult } = useSingleCallResult(debtTokenContract, 'borrowAllowance', [account, WETH_GATEWAY])
  const borrowAllowance = useMemo(() => new BigNumber(get(borrowAllowanceResult, 0)?.toString()), [borrowAllowanceResult])

  const { onApproveDelegation } = useApproveDelegation(BDebtWETH, WETH_GATEWAY)
  const handleApproveDelegation = useCallback(async () => {
    setTxHash('')
    try {
      setButtonState(ApproveButtonState.PROCESSING)
      const tx = await onApproveDelegation()
      // user rejected tx or didn't go thru
      if (!tx || tx.message) {
        setButtonState(ApproveButtonState.APPROVE)

        setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : tx?.message)
      } else {
        setTxHash(tx.hash)
        setButtonState(ApproveButtonState.PROCESSING)
        addTransaction(tx, ({ status }: { status: string }) => {
          if (status === 'success') {
            setButtonState(ApproveButtonState.APPROVED)
          } else {
            setButtonState(ApproveButtonState.APPROVE)
          }
        })
      }
    } catch (e: any) {
      console.log(e)
      setErrorMsg(e.msg)
      setButtonState(ApproveButtonState.APPROVE)
    }
  }, [onApproveDelegation, addTransaction])

  useEffect(() => {
    if (borrowAllowance.isNaN()) return
    if (buttonState === ApproveButtonState.LOADING) {
      if (borrowAllowance.gt(0)) setButtonState(ApproveButtonState.APPROVED)
      else setButtonState(ApproveButtonState.APPROVE)
    }
  }, [borrowAllowance, buttonState])

  return {
    handleApproveDelegation,
    txHash,
    buttonState,
    errorMsg,
    borrowAllowance,
    notificationState,
    setNotificationState
  }
}

export function useBatchBorrowApprove(
  ILendPool_address: string,
  assetAddress: string,
  account: string,
  collectionAddress: string,
  tokenId: string,
  approvingList: any,
  setApproveList: any,
  approvedList: any,
  setApprovedList: any
) {
  const { addTransaction } = useWallet()
  const [txHash, setTxHash] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [buttonState, setButtonState] = useState<ApproveButtonState>(ApproveButtonState.LOADING)
  const [notificationState, setNotificationState] = useState<NotificationState>(NotificationState.APPROVE)

  const {
    config: { WETH_GATEWAY, PUNK_GATEWAY, isLoadingConfig }
  } = usePlatform()

  const contract = useERC721Contract(collectionAddress, false)

  const gateway = useMemo(
    () => (isPunk(collectionAddress) ? PUNK_GATEWAY : isWeth(assetAddress) ? WETH_GATEWAY : ILendPool_address),
    [ILendPool_address, PUNK_GATEWAY, WETH_GATEWAY, assetAddress, collectionAddress]
  )

  const { result: approvedAddress } = useSingleCallResult(
    !isPunk(collectionAddress) ? contract : undefined,
    'isApprovedForAll',
    [account, gateway],
    {},
    isLoadingConfig
  )

  const CryptoPunkContract = useCryptoPunkContract(collectionAddress, false)
  const { result: cryptoPunk } = useSingleCallResult(isPunk(collectionAddress) ? CryptoPunkContract : undefined, 'punksOfferedForSale', [tokenId])

  const { onSetApprovalForAll } = useSetApprovalForAll(collectionAddress, gateway)
  const handleApprove = useCallback(async () => {
    setTxHash('')
    setApproveList([...approvingList, collectionAddress])
    try {
      setButtonState(ApproveButtonState.PROCESSING)
      const tx = await onSetApprovalForAll()
      // user rejected tx or didn't go thru
      if (!tx || tx.message) {
        setButtonState(ApproveButtonState.APPROVE)
        setApproveList(approvingList.filter((item: any) => item !== collectionAddress))
        setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : tx?.message)
      } else {
        setTxHash(tx.hash)
        setButtonState(ApproveButtonState.PROCESSING)
        addTransaction(tx, ({ status }: { status: string }) => {
          if (status === 'success') {
            setButtonState(ApproveButtonState.APPROVED)
            setApprovedList([...approvedList, collectionAddress])
          } else {
            setButtonState(ApproveButtonState.APPROVE)
          }

          setApproveList(approvingList.filter((item: any) => item !== collectionAddress))
        })
      }
    } catch (e: any) {
      console.log(e)
      setButtonState(ApproveButtonState.APPROVE)
      setErrorMsg(e.msg)
      setApproveList(approvingList.filter((item: any) => item !== collectionAddress))
    }
  }, [addTransaction, approvedList, approvingList, collectionAddress, onSetApprovalForAll, setApproveList, setApprovedList])

  const { onApprovePunk } = useOfferPunkForSaleToAddress(collectionAddress ?? '')
  const handleApprovePunk = useCallback(async () => {
    setTxHash('')
    try {
      setButtonState(ApproveButtonState.PROCESSING)
      const tx = await onApprovePunk(tokenId, PUNK_GATEWAY)
      // user rejected tx or didn't go thru
      if (!tx || tx.message) {
        setButtonState(ApproveButtonState.APPROVE)
      } else {
        setTxHash(tx.hash)
        setButtonState(ApproveButtonState.PROCESSING)
        addTransaction(tx, ({ status }: { status: string }) => {
          if (status === 'success') {
            setButtonState(ApproveButtonState.APPROVED)
            setApprovedList([...approvedList, tokenId])
          } else {
            setButtonState(ApproveButtonState.APPROVE)
          }
        })
      }
    } catch (e: any) {
      console.log(e)
      setButtonState(ApproveButtonState.APPROVE)
    }
  }, [onApprovePunk, tokenId, PUNK_GATEWAY, addTransaction, setApprovedList, approvedList])

  useEffect(() => {
    if (!approvedList) return
    if (buttonState === ApproveButtonState.APPROVED) {
      if (isPunk(collectionAddress)) {
        if (!approvedList?.find((item: string) => item === tokenId)) setApprovedList([...approvedList, tokenId])
      } else {
        if (!approvedList?.find((item: string) => item === collectionAddress)) setApprovedList([...approvedList, collectionAddress])
      }
    }
    if (buttonState === ApproveButtonState.LOADING) {
      if (isPunk(collectionAddress)) {
        if (!cryptoPunk) return
        if (get(cryptoPunk, 0) && get(cryptoPunk, 4) === PUNK_GATEWAY) {
          setButtonState(ApproveButtonState.APPROVED)
          setApprovedList([...approvedList, tokenId])
        } else setButtonState(ApproveButtonState.APPROVE)
      } else {
        if (!approvedAddress) return
        if (get(approvedAddress, 0)) {
          setButtonState(ApproveButtonState.APPROVED)
          setApprovedList([...approvedList, collectionAddress])
        } else setButtonState(ApproveButtonState.APPROVE)
      }
    }
  }, [PUNK_GATEWAY, approvedAddress, approvedList, buttonState, collectionAddress, cryptoPunk, setApprovedList, tokenId])

  //console.log('buttonState buttonState', buttonState, approvedList)
  useEffect(() => {
    //console.log('approvingList approvingList', approvingList)
    if (!approvingList || buttonState === ApproveButtonState.APPROVED || buttonState === ApproveButtonState.LOADING || isPunk(collectionAddress)) return
    if (approvingList?.find((item: string) => item === collectionAddress)) {
      setButtonState(ApproveButtonState.PROCESSING)
    } else {
      if (get(approvedAddress, 0)) setButtonState(ApproveButtonState.APPROVED)
      else setButtonState(ApproveButtonState.APPROVE)
    }
  }, [approvedAddress, approvingList, buttonState, collectionAddress])

  return {
    handleApprove: isPunk(collectionAddress) ? handleApprovePunk : handleApprove,
    txHash,
    buttonState,
    errorMsg,
    approvedAddress,
    notificationState,
    setNotificationState
  }
}

export default function useBatchBorrow(account: string, defaultNotificationState = NotificationState.APPROVE) {
  const { t: tc } = useTranslation('common')
  const { addTransaction } = useWallet()
  const [txHash, setTxHash] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [notificationState, setNotificationState] = useState<NotificationState>(defaultNotificationState)
  const { enableKiosk, disableKiosk } = useModalContext()
  const { addAppNotification } = useAppNotificationContext()

  const {
    config: { WETH_GATEWAY, PUNK_GATEWAY }
  } = usePlatform()

  const { onBatchBorrowETH } = useBatchBorrowETH(WETH_GATEWAY)
  const handleBatchBorrowETH = useCallback(
    async ({ amounts, tokenIds, addresses }: { amounts: string[]; tokenIds: string[]; addresses: string[] }) => {
      setTxHash('')
      try {
        setNotificationState(NotificationState.CONFIRM_TX)
        enableKiosk?.()
        const tx = await onBatchBorrowETH(amounts, addresses, tokenIds, account, '0')
        // user rejected tx or didn't go thru
        if (!tx || tx.message) {
          setNotificationState(NotificationState.ERROR)
          setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : tx?.message)
          disableKiosk?.()
        } else {
          setTxHash(tx.hash)
          setNotificationState(NotificationState.PROCESSING)
          addTransaction(tx, ({ status }: { status: string }) => {
            if (status === 'success') {
              setNotificationState(NotificationState.SUCCESS)
              disableKiosk?.()
              addAppNotification?.({
                key: `borrow-succes-message-${tx.hash}`,
                message: tc('label.app-notification.tx-success-message'),
                close: true,
                type: 'success'
              })
            } else {
              setNotificationState(NotificationState.ERROR)
              setErrorMsg(tc('label.tx-failed'))
              disableKiosk?.()
            }
          })
        }
      } catch (e: any) {
        console.log(e)
        setErrorMsg(e.msg)
        setNotificationState(NotificationState.ERROR)
        disableKiosk?.()
      }
    },
    [enableKiosk, onBatchBorrowETH, account, disableKiosk, addTransaction, addAppNotification, tc]
  )

  const { onPunkBatchBorrowETH } = usePunkBatchBorrowETH(PUNK_GATEWAY)
  const handlePunkBatchBorrowETH = useCallback(
    async ({ amounts, tokenIds }: { amounts: string[]; tokenIds: string[] }) => {
      setTxHash('')
      try {
        setNotificationState(NotificationState.CONFIRM_TX)
        enableKiosk?.()
        const tx = await onPunkBatchBorrowETH(amounts, tokenIds, account, '0')
        // user rejected tx or didn't go thru
        if (!tx || tx.message) {
          setNotificationState(NotificationState.ERROR)
          setErrorMsg(tx?.error?.message ? handleError({ errorMessage: tx?.error?.message }) : tx?.message)
          disableKiosk?.()
        } else {
          setTxHash(tx.hash)
          setNotificationState(NotificationState.PROCESSING)
          addTransaction(tx, ({ status }: { status: string }) => {
            if (status === 'success') {
              setNotificationState(NotificationState.SUCCESS)
              addAppNotification?.({
                key: `borrow-succes-message-${tx.hash}`,
                message: tc('label.app-notification.tx-success-message'),
                close: true,
                type: 'success'
              })
              disableKiosk?.()
            } else {
              setNotificationState(NotificationState.ERROR)
              setErrorMsg(tc('label.tx-failed'))
              disableKiosk?.()
            }
          })
        }
      } catch (e: any) {
        console.log(e)
        setErrorMsg(e.msg)
        setNotificationState(NotificationState.ERROR)
        disableKiosk?.()
      }
    },
    [enableKiosk, onPunkBatchBorrowETH, account, disableKiosk, addTransaction, addAppNotification, tc]
  )

  return {
    txHash,
    errorMsg,
    notificationState,
    setNotificationState,
    handleBatchBorrowETH,
    handlePunkBatchBorrowETH
  }
}
