import BigNumber from 'bignumber.js'
import { DATE_FORMAT, DEFAULT_CHAIN_ID, ENDPOINTS, ETH, ETH_ADDRESS, IPFS_GATEWAY, nftImageUrlType, REDEEM_TIME } from 'constants/index'
import { permalink } from 'constants/routes'
import { CollectionPayload, EndpointName, ReservePayload, Reserve as ReserveMarkets } from 'constants/types'
import { Reserve } from 'hooks/common/useUserReserves'
import { find, isArray, isEmpty } from 'lodash'
import { CRYPTOPUNKS_ADDRESS, WETH_ADDRESS, WPUNKS_ADDRESS } from 'modules/bend/constants'
import { ChainId } from 'wallet-module'
import moment from 'moment'
import { Collection } from './api/get-homepage-collections'

export const getPlaceholderUrl = (imageUrl: any): string => {
  return `/api/placeholder?src=${imageUrl}`
}

export const getNftImageUrl = (url: string, type: string = nftImageUrlType.ipfsUrl): string => {
  const ipfsLink = `${IPFS_GATEWAY}${url?.replace('ipfs://', '')}`
  if (type === nftImageUrlType.ipfsUrl) return ipfsLink
  if (type === nftImageUrlType.httpUrl) return url

  return ipfsLink
}

export const parseIpfsLink = (link: string) => link.replace('ipfs://', IPFS_GATEWAY)

export const getEndpoint = (key: EndpointName, chainId: string = DEFAULT_CHAIN_ID): string => ENDPOINTS[chainId || '4'][key]

export const getReserveIcon = (address: string): string => `/images/elements/${address}.svg`

export const parseCollectionAddress = (address: string): string =>
  address?.toLowerCase() === CRYPTOPUNKS_ADDRESS.toLowerCase() ? WPUNKS_ADDRESS.toLowerCase() : address?.toLowerCase()

export const getOpenseaLink = (address: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.RINKEBY):
      return `https://testnets.opensea.io/assets?search[query]=${address}`
    case String(ChainId.GÖRLI):
      return `https://testnets.opensea.io/assets?search[query]=${address}`
    default:
      return `https://opensea.io/assets?search[query]=${address}`
  }
}

export const getLooksRareCollectionLink = (address: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.RINKEBY):
      return `https://rinkeby.looksrare.org/collections/${address}`
    case String(ChainId.GÖRLI):
      return `https://goerli.looksrare.org/collections/${address}`
    default:
      return `https://looksrare.org/collections/${address}`
  }
}

export const getX2Y2CollectionLink = (address: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.RINKEBY):
      return `https://rinkeby.looksrare.org/accounts/${address}`
    case String(ChainId.GÖRLI):
      return `https://goerli.looksrare.org/accounts/${address}`
    default:
      return `https://x2y2.io/collection/${address}/items`
  }
}

export const getX2Y2BoundNFTCollectionLink = (bnftToken: string, address: string) => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.RINKEBY):
      return `https://rinkeby.looksrare.org/accounts/${bnftToken}`
    case String(ChainId.GÖRLI):
      return `https://goerli.looksrare.org/accounts/${bnftToken}`
    default:
      return `https://x2y2.io/user/${bnftToken}/items?contract=1_${address}`
  }
}

export const getX2Y2OwnerLink = (address: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.RINKEBY):
      return `https://rinkeby.looksrare.org/accounts/${address}`
    case String(ChainId.GÖRLI):
      return `https://goerli.looksrare.org/accounts/${address}`
    default:
      return `https://x2y2.io/user/${address}/items`
  }
}

export const getPunksNftLink = (tokenID: string): string => `https://cryptopunks.app/cryptopunks/details/${tokenID}`

/* export const getX2Y2NFTLink = (address: string, tokenId: string): string =>
  DEFAULT_CHAIN_ID === '5' ? `https://rinkeby.looksrare.org/collections/${address}/${tokenId}` : `https://x2y2.io/eth/${address}/${tokenId}` */

export const getBendNFTLink = (address: string, tokenId: string): string => `${permalink.asset}/${address}/${tokenId}`

export const getOpenseaLinkBoundNft = (address: string, tokenId: string): string => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.RINKEBY):
      return `https://testnets.opensea.io/assets/${address}/${tokenId}`
    case String(ChainId.GÖRLI):
      return `https://testnets.opensea.io/assets/${address}/${tokenId}`
    default:
      return `https://opensea.io/assets/${address}/${tokenId}`
  }
}

export const getEtherscanUrl = (address: string, type = 'token') => {
  switch (DEFAULT_CHAIN_ID) {
    case String(ChainId.RINKEBY):
      return `https://rinkeby.etherscan.io/${type}/${address}`
    case String(ChainId.GÖRLI):
      return `https://goerli.etherscan.io/${type}/${address}`
    default:
      return `https://etherscan.io/${type}/${address}`
  }
}

export const isWeth = (address: string | undefined): boolean => WETH_ADDRESS.toLowerCase() === address?.toLowerCase()
export const isEth = (address: string | undefined): boolean => ETH_ADDRESS.toLowerCase() === address?.toLowerCase()
export const isPunk = (address: string | undefined): boolean => CRYPTOPUNKS_ADDRESS.toLowerCase() === address?.toLowerCase()
export const isWPunk = (address: string | undefined): boolean => WPUNKS_ADDRESS.toLowerCase() === address?.toLowerCase()
const hours = (numDays: number) => numDays * 3600
export const getBidEndTimestamp = (bidStartTime: number, auctionDuration: number): number => Number(bidStartTime) + hours(auctionDuration)
export const getTokenIdFromCollateralKey = (key: string): string => key?.split('-')[0]
export const getCollectionAddressFromCollateralKey = (key: string): string => key?.split('-')[1]
export const getTokenIdAndCollectionAddressFromCollateralKey = (key: string): string[] => key?.split('-')
export const changeNetwork = async () => {
  const provider: any = window.ethereum
  if (provider) {
    const chainId = parseInt(DEFAULT_CHAIN_ID, 10)
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`
          }
        ]
      })
      return true
    } catch (error) {
      console.error('Failed to switch the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't switch network on metamask because window.ethereum is undefined")
    return false
  }
}

export interface CustomTokenProps {
  address: string | undefined
  decimals: number
  symbol: string
  image?: string
}
export const addCustomToken = async ({ address, decimals, symbol, image = '' }: CustomTokenProps) => {
  const provider: any = window.ethereum
  if (provider) {
    try {
      const customToken = await provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address,
            symbol,
            decimals,
            image
          }
        }
      })

      if (customToken) {
        console.log(`${symbol} has been added to MetaMask.`)
      } else {
        console.log('Your loss!')
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const getUserReserve = (userReserves: Array<Reserve>, id: string): Reserve | undefined => find(userReserves, userReserve => userReserve.id === id)

type NotificationTypeProps = 'loan_warn_3' | 'loan_warn_2' | 'loan_warn_1a' | 'loan_warn_1b' | 'loan_warn_4a' | 'loan_warn_5a' | 'loan_warn_5b'
type NotificationResult = {
  healthFactor?: string
  collectionName?: string
  collateral?: string
  highestBid?: string
  reserve?: string
  redeemTime?: number
}
export const getNotificationProps = (type: NotificationTypeProps, notification: any): NotificationResult => {
  // console.log(notification)
  switch (type) {
    default:
    case 'loan_warn_1a':
      return {
        healthFactor: new BigNumber(notification.body[type].loan.healthFactor).dp(2).toString(),
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID,
        redeemTime: REDEEM_TIME
      }
    case 'loan_warn_1b':
      return {
        healthFactor: new BigNumber(notification.body[type].loan.healthFactor).dp(2).toString()
      }
    case 'loan_warn_2':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID
      }
    case 'loan_warn_3':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID,
        highestBid: new BigNumber(notification.body[type].loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
    case 'loan_warn_4a':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID
      }
    case 'loan_warn_5a':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID,
        highestBid: new BigNumber(notification.body[type].loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
    case 'loan_warn_5b':
      return {
        collectionName: notification.body[type].nftCollection.name,
        collateral: notification.body[type].nftItem.tokenID,
        highestBid: new BigNumber(notification.body[type].loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
  }
}

/**
 * Generates reserve payload.
 * @param {Reserve} reserve
 * @return {ReservePayload}
 */
export const reservePayload = (reserve: ReserveMarkets, distributionManager: string | undefined): ReservePayload => {
  return {
    key: reserve.id,
    id: reserve.id,
    address: reserve.underlyingAsset,
    assetName: isWeth(reserve.underlyingAsset) ? ETH.name : reserve.name,
    assetTicker: isWeth(reserve.underlyingAsset) ? ETH.symbol : reserve.symbol,
    assetLogo: getReserveIcon(reserve.underlyingAsset),
    totalLiquidity: new BigNumber(reserve.totalLiquidity).dividedBy(`1e${reserve.decimals}`),
    depositAPY: new BigNumber(100).multipliedBy(new BigNumber(reserve.liquidityRate).dividedBy(`1e27`)),
    totalBorrows: new BigNumber(reserve.totalCurrentVariableDebt).dividedBy(`1e${reserve.decimals}`),
    borrowAPY: new BigNumber(100).multipliedBy(new BigNumber(reserve.variableBorrowRate).dividedBy(`1e27`)),
    active: reserve.isActive && !reserve.isFrozen,
    priceInEth: new BigNumber(reserve.price.priceInEth).dividedBy(1e18),
    bToken: reserve.bToken.id,
    debtToken: reserve.debtToken.id,
    totalBTokenSupply: new BigNumber(reserve.totalBTokenSupply).dividedBy(`1e${reserve.decimals}`),
    availableLiquidity: new BigNumber(reserve.availableLiquidity).dividedBy(`1e${reserve.decimals}`),
    distributionManager,
    utilizationRate: reserve.utilizationRate
  }
}

/**
 * Generates collection payload.
 * @param {Collection} collection
 * @return {CollectionPayload}
 */
export const collectionPayload = (collection: Collection, eth: any): CollectionPayload => {
  return {
    key: collection.address,
    id: collection.id,
    // collectionName: isWPunk(collection.address) ? PUNKS_NAME : collection.name,
    collectionName: collection.name,
    address: collection.address,
    totalCollaterals: collection.realTotalSupply || collection.totalSupply,
    collectionImages: collection.openseaImageURL,
    floorPrice: collection.floorPrice,
    /**
     * availableToBorrow = baseLTVasCollateral*priceInEth
     */
    availableToBorrow: collection.availableToBorrow,
    priceInEth: collection.floorPrice,
    activeCollaterals: collection.activeCollaterals,
    priceHint: collection.priceHint,
    eth,
    bnftToken: collection.bnftToken,
    orderBy: {
      totalSupply: collection.orderBy.totalSupply
    }
  }
}

type ReferralCodeType = 'deposit' | 'borrow'

export const getReferralCode = (type: ReferralCodeType, account: string) => {
  if (type === 'deposit') return `${window.location.origin}${permalink.deposit}?referral=${account}`
  return `${window.location.origin}${permalink.borrow}?referral=${account}`
}

const ethAddressPattern = /^0x[a-fA-F0-9]{40}$/
export const isEthAddress = (address: string): boolean => ethAddressPattern.test(address)

type CalculateBidFineProps = {
  minBidFine: string
  amount: BigNumber
  redeemFine: string
}
export const calculateBidFine = ({ minBidFine, amount, redeemFine }: CalculateBidFineProps): BigNumber => {
  const minBidFineBig = new BigNumber(minBidFine).dividedBy(1e4)
  const bidFine = new BigNumber(amount).multipliedBy(new BigNumber(redeemFine).dividedBy(1e4)).multipliedBy(1.01)
  return minBidFineBig.isGreaterThan(bidFine) ? minBidFineBig : bidFine
}

export const calculateRoyaltyFee = (input: string | number) => new BigNumber(input).dividedBy(1e2).dp(2).toFixed()
export const calculateTradingFee = (input: string | number) => new BigNumber(input).dividedBy(1e2).dp(2).toFixed()

export const expirationHelper = (endDate: number) => {
  if (!endDate) return '--'
  const currentTime = moment()
  const endTime = moment.unix(endDate)
  const difference = endTime.diff(currentTime, 'minutes', false)
  const differenceSec = endTime.diff(currentTime, 'seconds', false)

  if (Number(difference) > 43200) return endTime.format(DATE_FORMAT)
  if (Number(difference) < 43200 && Number(difference) > 1440)
    return `${Math.floor(Number(difference) / 1440)} ${Math.floor(Number(difference) / 1440) === 1 ? 'day' : 'days'}`
  if (Number(difference) < 1440 && Number(difference) > 60) return `${Math.floor(Number(difference) / 60)} hours`
  if (difference < 0) return 'Expired'
  return `${Math.floor(Number(differenceSec) / 60)} min ${((Number(differenceSec) / 60 - Math.floor(Number(differenceSec) / 60)) * 60).toFixed()} sec`
}

export const formatUnixTimestamp = (endTime: number) => {
  const currentTime = moment()
  const futureTime = moment.unix(endTime)
  const difference = futureTime.diff(currentTime, 'minutes', false)
  if (difference < 0) return 'Expired'
  return moment.unix(endTime).format('DD/MM/YYYY')
}

export const gtCurrentTime = (time: number | undefined): Boolean => (!time ? false : moment.unix(time).isAfter(moment()))

export const checkOldSignature = (signature: any) => {
  if (!signature) return
  if (signature?.slice(-2) === '00') {
    return signature?.slice(0, -2) + '1b'
  }
  if (signature?.slice(-2) === '01') {
    return signature?.slice(0, -2) + '1c'
  }

  return signature
}

export const generateFilterUrlAppend = (data: any, urlAppend: string) => {
  Object.keys(data).map(key => {
    if (data[key]) urlAppend = `${urlAppend}${isArray(data[key]) ? (!isEmpty(data[key]) ? `&${key}=${data[key]}` : '') : `&${key}=${data[key]}`}`
  })

  return urlAppend
}

export const cutZeroDecimals = (input: string) => {
  if (input.slice(-3) === '.00') return input.substring(0, input.length - 3)
  if (input.slice(-5) === '.0000') return input.substring(0, input.length - 5)
  return input
}

export const uuid = (): string => {
  const hashTable = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  const uuid = []
  for (let i = 0; i < 35; i++) {
    if (i === 7 || i === 12 || i === 17 || i === 22) {
      uuid[i] = '-'
    } else {
      uuid[i] = hashTable[Math.floor(Math.random() * hashTable.length - 1)]
    }
  }
  return uuid.join('')
}
