import { calculateGasMargin } from 'wallet-module'

/**
 * @dev Allows users to offerPunkForSaleToAddress
 * @param contract The contract
 * @param minSalePriceInWei The minSalePriceInWei to be offerPunkForSaleToAddressed
 * @param punkId The punk ID
 * @param to_address Address of the user who will receive the loan. Should be the address of the offerPunkForSaleToAddresser itself
 * calling the function if he wants to offerPunkForSaleToAddress against his own collateral
 **/
export const offerPunkForSaleToAddress = async (contract: any, minSalePriceInWei: string, punkId: string, to_address: string) => {
  console.debug('util punk offerPunkForSaleToAddress data', contract, minSalePriceInWei, to_address, punkId)
  const estimatedGas = await contract.estimateGas.offerPunkForSaleToAddress(punkId, minSalePriceInWei, to_address).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.offerPunkForSaleToAddress(punkId, minSalePriceInWei, to_address)
  })

  return contract
    .offerPunkForSaleToAddress(punkId, minSalePriceInWei, to_address, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to offerPunkForSaleToAddress', error)
      throw error
    })
}

/**
 * @dev Allows users to getPunk
 * @param contract The contract
 * @param punkId The punk ID
 **/
export const getPunk = async (contract: any, punkId: string) => {
  console.debug('util punk getPunk data', contract, punkId)
  const estimatedGas = await contract.estimateGas.getPunk(punkId).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.getPunk(punkId)
  })

  return contract
    .getPunk(punkId, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to getPunk', error)
      throw error
    })
}

/**
 * @dev Allows users to transfer Punk
 * @param contract The contract
 * @param to_address The to_address
 * @param punkId The punk ID
 **/
export const transferPunk = async (contract: any, to_address: string, punkId: string) => {
  console.debug('util punk transferPunk data', contract, to_address, punkId)
  const estimatedGas = await contract.estimateGas.transferPunk(to_address, punkId).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.transferPunk(to_address, punkId)
  })

  return contract
    .transferPunk(to_address, punkId, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to transferPunk', error)
      throw error
    })
}
