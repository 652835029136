import { permalink } from 'constants/routes'
import Link from 'components/common/link'
import { useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { Box, Text, Flex } from 'rebass/styled-components'
import { Avatar, IconNotification, IconLiquidate, IconSettings, IconBNFT, IconNFTCustodyHeader, IconDownPayment, IconEth, IconBorrow } from 'theme/ui'
import { SidebarContext } from '..'

export const QuickLinks: React.FC = () => {
  const { t: tc } = useTranslation('common')
  const { onDismiss } = useContext(SidebarContext)

  return (
    <Flex fontWeight='bold' color='black' flexDirection='column'>
      <Text fontSize={13}>{tc('label.quicklinks')}</Text>
      <Box
        mt={20}
        mb={10}
        fontSize={13}
        sx={{
          display: 'grid',
          gridGap: '15px 10px',
          gridTemplateColumns: `repeat(2, minmax(150px, 1fr))`
        }}
      >
        <Link href={permalink.liquidityDepositEth} passHref>
          <Avatar
            icon={<IconEth size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            text={<Text>{tc('label.deposit-eth')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.batchBorrow} passHref>
          <Avatar
            icon={<IconBorrow size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            text={<Text>{tc('label.borrow-eth')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.nfts} passHref>
          <Avatar
            icon={<IconBNFT size={18} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            text={<Text>{tc('label.my-nfts')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.nftDashboardCustody} passHref>
          <Avatar
            icon={<IconNFTCustodyHeader size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            text={<Text>{tc('label.nft-custody')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.liquidityExplore} passHref>
          <Avatar
            icon={<IconDownPayment size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            href=''
            text={<Text>{tc('label.downpayment')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.auctionAvailableToAuction} passHref>
          <Avatar
            icon={<IconLiquidate size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            href=''
            text={<Text>{tc('label.auctions')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.dashboardSettings} passHref>
          <Avatar
            icon={<IconSettings size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            href=''
            text={<Text>{tc('label.settings')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>

        <Link href={permalink.myNotifications} passHref>
          <Avatar
            icon={<IconNotification size={16} color='black' />}
            variant='link'
            iconSize={32}
            hasBorderRadius={8}
            hasBackground
            iconBackground='grey.5'
            href=''
            text={<Text>{tc('label.notifications')}</Text>}
            onClick={() => onDismiss!()}
          />
        </Link>
      </Box>
    </Flex>
  )
}
