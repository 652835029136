import React from 'react'
import { Flex, FlexProps } from 'rebass/styled-components'
import { routes } from '../../../constants'
import { IRoutes } from 'constants/types'
import NavButton from 'theme/ui/common/nav-button'
import NavButtonLanding from 'theme/ui/common/nav-button/nav-button-landing'

const Navigation: React.FC<FlexProps & { landing?: boolean }> = ({ landing, ...resprops }) => {
  return (
    <Flex alignItems='center' {...resprops}>
      {routes.map((route: IRoutes, index) =>
        landing ? <NavButtonLanding key={`nav-button-${index}`} {...route} /> : <NavButton key={`nav-button-${index}`} {...route} />
      )}
    </Flex>
  )
}

export default Navigation
