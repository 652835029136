import React from 'react'
import { Text } from 'rebass/styled-components'
import { IHeading } from './interfaces'

const Heading: React.FC<IHeading> = ({ text, ...restprops }) => (
  <Text as='h1' fontFamily='header' fontSize={['xxxl', 'xxxl', 'xxxl', 'xxxxl']} lineHeight='xxxxl' letterSpacing={5} mb={40} {...restprops}>
    {text && text.toLowerCase()}
  </Text>
)

export default Heading
