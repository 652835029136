import useTheme from 'hooks/common/useTheme'
import { useState } from 'react'
import { Icon as IconProps, X } from 'react-feather'
import { useTranslation } from 'next-i18next'
import { Flex, Text, Link } from 'rebass/styled-components'
import styled from 'styled-components'
import { AnimatePresence, Button, IconInfo, MotionFlex } from 'theme/ui'

const TestnetUsageIcon = () => {
  const { t: tc } = useTranslation('common')
  const [show, setShow] = useState(false)
  const { colors } = useTheme()
  return (
    <MotionFlex
      // initial={{
      //   width: 65,
      //   height: 65,
      //   borderRadius: 'sm'.5
      // }}
      animate={{
        width: show ? 250 : 65,
        height: show ? 200 : 65,
        borderRadius: show ? 16 : 32.5,
        padding: show ? 20 : 0,
        transition: {
          delay: show ? 0 : 0.3
        }
      }}
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 501,
        bg: 'red.1000',
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: `0 4px 8px 0 ${colors.shadow[100]}`
      }}
    >
      <Flex sx={{ position: 'relative', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100%' }}>
        <AnimatePresence>
          {show && (
            <MotionFlex
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.25 } }}
              exit={{ opacity: 0 }}
              sx={{
                width: 19,
                height: 19,
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 20
              }}
            >
              <CloseIcon
                cursor='pointer'
                color={colors.white}
                onClick={() => {
                  setShow((state: boolean) => !state)
                }}
              />
            </MotionFlex>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {show && (
            <MotionFlex
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.25 } }}
              exit={{ opacity: 0 }}
              width='100%'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              color='white'
            >
              <IconInfo size={29} />
              <Text color='white' fontWeight='medium' fontSize={[11, 11, 13]} textAlign='center' lineHeight={1.1} mt={20}>
                {tc('label.testnet-notification-switch-mainnet')}
                <Link color='white' ml={4} href={'https://www.benddao.xyz/'} sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}>
                  benddao.xyz
                </Link>
                .
              </Text>
            </MotionFlex>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {!show && (
            <MotionFlex
              initial={{ opacity: 0, width: 0, height: 0 }}
              animate={{ opacity: 1, width: 29, height: 29, transition: { delay: 0.5 } }}
              exit={{ opacity: 0, width: 0, height: 0, transition: { delay: 0, duration: 0 } }}
            >
              <Button size='icon' color='red.1000' fontColor='white' onClick={() => setShow(state => !state)}>
                <IconInfo size={29} />
              </Button>
            </MotionFlex>
          )}
        </AnimatePresence>
      </Flex>
    </MotionFlex>
  )
}

const CloseIcon = styled<IconProps>(X)``

export default TestnetUsageIcon
