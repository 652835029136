import { ApolloQueryResult, gql } from '@apollo/client'
import BigNumber from 'bignumber.js'
import { clientNftApi } from 'clients'
import { ReservePayload } from 'constants/types'
import { filter, isEmpty } from 'lodash'
import { MAX_BORROW_MULTIPLIER } from 'modules/bend/constants'
import { isPunk } from 'utils'

type OwnerItem = {
  tokenID: string
  collectionAddress: string
}

type NftCollection = {
  name: string
  address: string
  ownerItems: Array<OwnerItem & { key: string }>
  nftData: {
    ltv: BigNumber
  }
  floorPrice: BigNumber
}

type NftCollectionsData = {
  nftCollections: Array<NftCollection>
}

export type NftCollectionReturn = {
  key: string
  name: string
  address: string
  tokenID: string
  availableToBorrow: string
  amount: string
}

export type NftCollectionsReturn = {
  collections: Array<NftCollectionReturn>
  cryptopunks: Array<NftCollectionReturn>
}

export default async function getBatchBorrowCollections(account: string, reserve: ReservePayload): Promise<NftCollectionsReturn> {
  const {
    data: { nftCollections }
  }: ApolloQueryResult<NftCollectionsData> = await clientNftApi.query({
    query: gql`
      query UserCollections($account: String!) {
        nftCollections(bendNFTIsActive: true, bendNFTIsFrozen: false, orderBy: { field: name, direction: asc }) {
          name
          address
          ownerItems(ownerAddress: $account) {
            tokenID
            collectionAddress
          }
          nftData {
            ltv
          }
          floorPrice
        }
      }
    `,
    variables: {
      account
    }
  })

  const result = []

  for (let i = 0; i < nftCollections.length; i += 1) {
    if (!isEmpty(nftCollections[i].ownerItems)) {
      const collection = nftCollections[i]
      for (let j = 0; j < nftCollections[i].ownerItems.length; j += 1) {
        const ownerItem = nftCollections[i].ownerItems[j]
        const availableToBorrow = new BigNumber(new BigNumber(collection.floorPrice).multipliedBy(MAX_BORROW_MULTIPLIER).dividedBy(1e18))
          .dividedBy(new BigNumber(reserve.priceInEth))
          .multipliedBy(new BigNumber(collection.nftData.ltv).dividedBy(1e4))
        result.push({
          key: `${ownerItem.collectionAddress.toLowerCase()}+${ownerItem.tokenID}`,
          name: collection.name,
          address: collection.address.toLowerCase(),
          tokenID: ownerItem.tokenID,
          availableToBorrow: availableToBorrow.dp(4, 1).toFixed(4),
          amount: '0'
        })
      }
    }
  }

  return {
    collections: filter(result, collection => !isPunk(collection.address)),
    cryptopunks: filter(result, collection => isPunk(collection.address))
  }
}
