import { useTranslation } from 'next-i18next'
import { Box, Flex, Text, Image } from 'rebass/styled-components'
import { Input, Button } from 'theme/ui'
import { SHOW_DECIMAL_PLACES, wethImagePath } from 'constants/index'
import NumberFormat from 'components/common/number-format'
import { useCallback, useContext, useEffect, useState } from 'react'
import Slider, { SliderContext } from 'theme/ui/forms/slider'

export const WrapStep7: React.FC<any> = ({ handleBack, handleUnWrap, balance }) => {
  const { t: tc } = useTranslation('common')
  const [amount, setAmount] = useState('')
  const { sliderValue, setSliderValue, getSliderPercent } = useContext(SliderContext)

  useEffect(() => {
    const percent = getSliderPercent(amount, balance)
    setSliderValue(percent)
  }, [amount, balance, getSliderPercent, setSliderValue])

  const SliderWrapper = useCallback(
    () => (
      <Slider
        name='slider'
        defaultValue={sliderValue}
        step={1}
        onAfterChange={(amount: any) => {
          setSliderValue(amount)
          if (Number(amount) === 0) {
            setAmount('')
          } else {
            setAmount(balance.multipliedBy(amount).dividedBy(100).dp(4, 1))
          }
        }}
        min={0}
        max={100}
        marks={{
          0: `0%`,
          25: '25%',
          50: '50%',
          75: '75%',
          100: '100%'
        }}
        hideMaxLabel
      />
    ),
    [balance, setSliderValue, sliderValue]
  )
  return (
    <Flex flexDirection={'column'} flex={1}>
      <Flex flexDirection={'column'} flex={1} mt={10} mb={7}>
        <Input
          size='lg'
          type='decimal'
          autoComplete='off'
          step='any'
          placeholder='0.00'
          left={<Text fontWeight='normal'>{tc('label.amount')}</Text>}
          right={
            <Flex fontWeight='normal'>
              <Text mr={5}>{tc('label.max')}:</Text> <NumberFormat number={balance} format={4} />
            </Flex>
          }
          onChange={e => setAmount(e.currentTarget.value.replace(',', '.'))}
          onBlur={e => {
            if (Number(e.target.value) < 0) {
              setAmount('')
              return
            }
            setAmount(Number(e.target.value).toFixed(SHOW_DECIMAL_PLACES))
          }}
          max={balance.dp(4, 1)}
          value={amount}
          endAdornment={
            <Flex mr={20} alignItems='center'>
              <Button size='sm' fontWeight='bold' height={24} type='max' onClick={() => [setAmount(balance.dp(4, 1)), setSliderValue(100)]}>
                {tc('label.max').toUpperCase()}
              </Button>
              <Flex width={24} height={24} sx={{ borderRadius: 'icon', overflow: 'hidden' }} alignItems='center' justifyContent='center' mx={5}>
                <Image src={wethImagePath} width={24} height={24} />
              </Flex>
              <Text>WETH</Text>
            </Flex>
          }
          sx={{ fontWeight: 700 }}
        />
      </Flex>
      <Box mx={10} mt={10} mb={50} fontWeight='normal'>
        <SliderWrapper />
      </Box>
      <Flex justifyContent={'space-between'}>
        <Button color='black' outlined size='md' fontWeight={'bold'} flex={0.45} onClick={() => handleBack()}>
          {tc('button.back')}
        </Button>
        <Button
          color='primary'
          size='md'
          fontWeight={'bold'}
          flex={0.45}
          onClick={() => handleUnWrap({ amount })}
          disabled={Number(amount) <= 0 || Number(amount) > balance ? true : false}
        >
          {tc('button.unwrap')}
        </Button>
      </Flex>
    </Flex>
  )
}
