import { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { Box, BoxProps as BoxPropsRebass } from 'rebass/styled-components'
interface HealthIndicatorProps extends BoxPropsRebass {
  healthFactor: number | BigNumber
  size?: number
}

const HealthIndicator: React.FC<HealthIndicatorProps> = ({ healthFactor, size = 17, ...restprops }) => (
  <Box {...restprops} mb={1}>
    {healthFactor > 3 && <Box size={size} bg='green.1000' flex={1} sx={{ borderRadius: 'sm' }} />}
    {healthFactor > 2 && healthFactor <= 3 && <Box size={size} bg='green.1100' sx={{ borderRadius: 'sm' }} />}
    {healthFactor > 1.5 && healthFactor <= 2 && <Box size={size} bg='yellow.1000' sx={{ borderRadius: 'sm' }} />}
    {healthFactor > 1 && healthFactor <= 1.5 && <Box size={size} bg='red.1100' sx={{ borderRadius: 'sm' }} />}
    {healthFactor <= 1 && <Box size={size} bg='red.1000' sx={{ borderRadius: 'sm' }} />}
  </Box>
)

export default HealthIndicator

type HealthFactorProps = HealthIndicatorProps

export const HealthFactor: React.FC<HealthFactorProps> = ({ healthFactor, ...restprops }) => {
  const data = useMemo(() => {
    const bn = new BigNumber(healthFactor)
    if (!bn.isFinite()) return 0
    if (bn.gte(1000000000000)) return `${bn.dividedBy(1e12).dp(2, 1).toFixed(2)} T`
    if (bn.gte(1000000000)) return `${bn.dividedBy(1e9).dp(2, 1).toFixed(2)} B`
    if (bn.gte(1000000)) return `${bn.dividedBy(1e6).dp(2, 1).toFixed(2)} M`
    if (bn.gte(1000)) return `${bn.dividedBy(1e3).dp(2, 1).toFixed(2)} K`
    return bn.dp(2, 1).toFixed(2)
  }, [healthFactor])
  return <Box {...restprops}>{data}</Box>
}
