import { useMemo, forwardRef } from 'react'
import Link from 'components/common/link'
import { useRouter } from 'next/router'
import { Flex, Text, Link as LinkRebass, FlexProps, Box } from 'rebass/styled-components'
import { IRoutes } from 'constants/types'
import useTheme from 'hooks/common/useTheme'
import useResponsive from 'hooks/common/useResponsive'
import Icon from '../../icons/icon'
import { useTranslation } from 'next-i18next'
import isEmpty from 'lodash/isEmpty'
import DropDown from '../../../../components/common/dropw-down'
import useWindowPosition from 'hooks/common/useWindowPosition'
import { LANDING_HEADER_BOTTOM, LANDING_HEADER_HEIGHT, WINDOW_POSITION_TRIGGER } from 'components/common/header/landing-header'
import SpacerDash from 'components/common/spacer-dash'

interface INavButton extends IRoutes {}

const NavButtonLanding: React.FC<INavButton> = props => {
  const { route, external, onClick, type } = props
  const { isTouch } = useResponsive()

  return type === 'separator' ? null : external ? (
    <LinkRebass
      href={route}
      target='_blank'
      rel='noopener noreferrer'
      onClick={onClick}
      sx={{
        '&:hover': {
          opacity: 1
        }
      }}
    >
      <Box>
        <LinkComponent {...props} />
      </Box>
    </LinkRebass>
  ) : (
    <Flex alignItems={'center'}>
      {/* {children ? (
        <LinkComponent onClick={onClick} {...props} />
      ) : (
        <Link href={route} passHref>
          <LinkComponent onClick={onClick} as='a' {...props} />
        </Link>
      )} */}
      {isTouch ? <LinkComponent onClick={onClick} as='a' {...props} /> : <LinkComponent onClick={onClick} {...props} />}
    </Flex>
  )
}

export default NavButtonLanding

const LinkComponent = forwardRef<HTMLElement, INavButton & FlexProps>(({ route, customRoute, icon, name, children, ...restprops }, ref) => {
  const { colors } = useTheme()
  const { pathname } = useRouter()
  const { isDesktop } = useResponsive()

  const activePathname = useMemo(() => {
    if (!pathname) return null
    if (pathname === '/[locale]') return '/'
    const path = pathname.split('/')
    return `/${path[2]}/${path[3]}`
  }, [pathname])
  const windowPosition = useWindowPosition()

  return (
    <Flex sx={{ position: 'relative', flex: !isDesktop ? '0 0 74px' : undefined, justifyContent: 'center' }} ref={ref} {...restprops}>
      {children && !isEmpty(children) ? (
        <DropDown
          trigger={isDesktop ? 'hover' : 'click'}
          buttonComponent={
            <Link href={customRoute ? customRoute : route} passHref>
              <Flex
                as='a'
                sx={{
                  flexDirection: ['column', 'column', 'column', 'column', 'row'],
                  justifyContent: 'center',
                  alignItems: 'center',
                  px: [0, 0, 0, 14, 15],
                  color: windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'header-label',
                  transition: 'all 0.3s',
                  height: windowPosition > WINDOW_POSITION_TRIGGER ? LANDING_HEADER_HEIGHT - 20 : LANDING_HEADER_HEIGHT,
                  '& svg': {
                    transition: 'all 0.3s',
                    color: activePathname === route ? 'white' : windowPosition > LANDING_HEADER_BOTTOM ? 'grey.300' : 'header-label'
                  },
                  '&:hover': {
                    cursor: 'pointer',
                    color: windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'white',
                    '& svg': {
                      color: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white'
                    }
                  }
                }}
              >
                {isDesktop && icon ? <Icon icon={icon} size={20} /> : <Icon icon={icon} size={20} color={colors.white} />}
                <Text
                  as='span'
                  fontSize={['sm', 'sm', 'md']}
                  lineHeight='md'
                  fontWeight={['700']}
                  mt={['10px', '10px', '10px', '10px', 0]}
                  ml={[0, 0, 0, 0, 10]}
                  textAlign={'center'}
                >
                  {name}
                </Text>
              </Flex>
            </Link>
          }
          menuComponent={
            <Flex flexDirection='column'>
              {children.map(child => {
                return child.type === 'separator' ? (
                  <Box key={child.name} my={10}>
                    <SpacerDash width='100%' height={1} bgColor={windowPosition > LANDING_HEADER_BOTTOM ? 'grey.100' : 'separator-dark'} />
                  </Box>
                ) : child.external ? (
                  <LinkRebass
                    key={child.name}
                    href={child.route}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={child.onClick}
                    sx={{
                      '&:hover': {
                        opacity: 1
                      }
                    }}
                  >
                    <SubmenuButton child={child} />
                  </LinkRebass>
                ) : (
                  <Link key={child.name} href={child.route} passHref>
                    <SubmenuButton as='a' child={child} />
                  </Link>
                )
              })}
            </Flex>
          }
          menuStyle={{
            minWidth: 230,
            maxWidth: 280,
            borderRadius: 0,
            zIndex: 1001,
            top: LANDING_HEADER_HEIGHT - 30,
            left: -10
          }}
          menuWrapperStyle={{
            bg: windowPosition > LANDING_HEADER_BOTTOM ? 'white' : 'header1',
            boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.16)',
            px: 20,
            borderRadius: 0
          }}
        />
      ) : (
        <Link key={name} href={route} passHref>
          <Flex
            as='a'
            sx={{
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              justifyContent: 'center',
              alignItems: 'center',
              px: [0, 0, 0, 14, 15],
              color: windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'white',
              transition: 'all 0.3s',
              height: windowPosition > WINDOW_POSITION_TRIGGER ? LANDING_HEADER_HEIGHT - 40 : LANDING_HEADER_HEIGHT,
              '& svg': {
                transition: 'all 0.3s',
                color: activePathname === route ? (windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white') : 'header-label'
              },
              '&:hover': {
                cursor: 'pointer',
                color: windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'white',
                '& svg': {
                  color: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white'
                }
              }
            }}
          >
            {isDesktop && icon ? <Icon icon={icon} size={20} /> : <Icon icon={icon} size={20} color={colors.white} />}
            <Text
              as='span'
              fontSize={['sm', 'sm', 'md']}
              lineHeight='md'
              fontWeight={['700']}
              mt={['10px', '10px', '10px', '10px', 0]}
              ml={[0, 0, 0, 0, 10]}
              textAlign={'center'}
            >
              {name}
            </Text>
          </Flex>
        </Link>
      )}
    </Flex>
  )
})

type SubmenuProps = { child: INavButton }

const SubmenuButton = forwardRef<HTMLElement, SubmenuProps & FlexProps>(({ child, ...restprops }, ref) => {
  const { t: tc } = useTranslation('common')
  const { pathname } = useRouter()

  const activePathname = useMemo(() => {
    if (!pathname) return null
    return `/`
  }, [pathname])

  const windowPosition = useWindowPosition()

  return (
    <Flex
      fontSize={['lg', null, null, null, 'md', 'md']}
      lineHeight='md'
      fontWeight={['700']}
      my={[10]}
      alignItems='center'
      px={[10, 30, 30, 30, 0]}
      sx={{
        color: windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'header-label',
        transition: 'all 0.3s',
        '& svg': {
          transition: 'all 0.3s',
          color:
            activePathname === `/[locale]${child.route}`
              ? windowPosition > LANDING_HEADER_BOTTOM
                ? 'primary'
                : 'white'
              : windowPosition > LANDING_HEADER_BOTTOM
              ? 'grey.300'
              : 'header-label'
        },
        '&:hover': {
          color: windowPosition > LANDING_HEADER_BOTTOM ? 'black' : 'white',
          cursor: 'pointer',
          '& svg': {
            transition: 'all 0.3s',
            color: windowPosition > LANDING_HEADER_BOTTOM ? 'primary' : 'white'
          }
        }
      }}
      ref={ref}
      {...restprops}
    >
      <Icon icon={child.icon} size={20} />
      <Text ml={10}>{child.name}</Text>
      {child.soon && (
        <Flex
          sx={{
            bg: 'primary',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 16,
            // height: 14,
            lineHeight: '14px',
            width: 35,
            ml: 10
          }}
        >
          <Text fontSize={8}>{tc('label.soon').toUpperCase()}</Text>
        </Flex>
      )}
      {child.isNew && (
        <Flex
          sx={{
            bg: 'primary',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 16,
            // height: 14,
            lineHeight: '14px',
            width: 35,
            ml: 8
          }}
        >
          <Text fontSize={8}>{tc('label.new').toUpperCase()}</Text>
        </Flex>
      )}
    </Flex>
  )
})
