import { INumberFormat } from './types'
import routes from './routes'
import BigNumber from 'bignumber.js'
import { BEND_ADDRESS, VE_BEND_ADDRESS, WETH_ADDRESS } from 'modules/bend/constants'
import { CustomTokenProps } from 'utils'
import { JsonRpcProvider } from '@ethersproject/providers'

export const rpc = new JsonRpcProvider(process.env.NEXT_PUBLIC_NETWORK_URL)

export const CURRENT_PAGE_ORIGIN = typeof window === 'undefined' ? '' : window.location.origin

export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL ?? 'https://testnet.benddao.xyz'

export const DEFAULT_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID || '5'

export const SHOW_DECIMAL_PLACES = 4

export const AIRDROP_END_TIME = 1655571423000
export const BLOCK_START_TIME = 1647690682000
export const SUPPORTED_NETWORKS = [
  // {
  //   chainId: '1',
  //   name: 'Ethereum Mainnet'
  // },
  // {
  //   chainId: '4',
  //   name: 'Ethereum Rinkeby Testnet'
  // },
  {
    chainId: '5',
    name: 'Ethereum Görli Testnet'
  }
]

export const ENDPOINTS: {
  [chainId: string]: {
    [endpoint: string]: string
  }
} = {
  '1': {
    BEND_PROTOCOL: 'https://bend-subgraph-mainnet.benddao.xyz/subgraphs/name/bend/bend-protocol',
    BEND_PROTOCOL_WS: 'wss://ws-bend-subgraph-mainnet.benddao.xyz/subgraphs/name/bend/bend-protocol',
    BEND: '/api',
    BEND_NFTAPI: 'https://mainnet-api.benddao.xyz/graphql/query',
    BEND_NFTAPI_WS: 'wss://mainnet-api.benddao.xyz/graphql/query',
    AIRDROP: 'https://d3c95q2tflpu4m.cloudfront.net/airdrop-mainnet',
    BEND_BNFT: 'https://bend-subgraph-mainnet.benddao.xyz/subgraphs/name/bend/bnft-protocol',
    NFT_INFO: 'https://bend-nft-info.benddao.xyz/query',
    CMS: 'https://cms.benddao.xyz/graphql'
  },
  '4': {
    BEND_PROTOCOL: 'https://bend-subgraph-rinkeby.benddao.xyz/subgraphs/name/bend/bend-protocol',
    BEND_PROTOCOL_WS: 'wss://ws-bend-subgraph-rinkeby.benddao.xyz/subgraphs/name/bend/bend-protocol',
    BEND: '/api',
    BEND_NFTAPI: 'https://bend-goservice-rinkeby.benddao.xyz/graphql/query',
    BEND_NFTAPI_WS: 'wss://bend-goservice-rinkeby.benddao.xyz/graphql/query',
    AIRDROP: ' https://bendnft.s3.ap-southeast-1.amazonaws.com/airdrop',
    BEND_BNFT: 'https://bend-subgraph-rinkeby.benddao.xyz/subgraphs/name/bend/bnft-protocol',
    NFT_INFO: 'https://bend-nft-info.benddao.xyz/query',
    CMS: 'https://cms-dev.benddao.xyz/graphql'
  },
  '5': {
    BEND_PROTOCOL: 'https://bend-subgraph-goerli.benddao.xyz/subgraphs/name/bend/bend-protocol',
    BEND_PROTOCOL_WS: 'wss://ws-bend-subgraph-goerli.benddao.xyz/subgraphs/name/bend/bend-protocol',
    BEND: '/api',
    BEND_NFTAPI: 'https://goerli-api.benddao.xyz/graphql/query',
    BEND_NFTAPI_WS: 'wss://goerli-api.benddao.xyz/graphql/query',
    AIRDROP: ' https://bendnft.s3.ap-southeast-1.amazonaws.com/airdrop',
    BEND_BNFT: 'https://bend-subgraph-goerli.benddao.xyz/subgraphs/name/boundnft/boundnft-protocol',
    NFT_INFO: 'https://bend-nft-info.benddao.xyz/query',
    CMS: 'https://cms-dev.benddao.xyz/graphql'
  }
}

export const IPFS_GATEWAY = 'https://ipfs.io/ipfs/'

export const DEFAULT_LANGUAGE = 'en'
export const DATE_FORMAT_AND_TIME = 'YYYY/MM/DD HH:mm'
export const DATE_FORMAT_UTC = 'MMM Do, hh:mm a z'
export const DATE_FORMAT = 'YYYY/MM/DD'
export const ETH_ADDRESS = '0x0000000000000000000000000000000000000000'
export const PUNKS_NAME = process.env.NEXT_PUBLIC_PUNKS_NAME?.toLowerCase() || 'CRYPTOPUNKS'
export const PUNKS_SYMBOL = process.env.NEXT_PUBLIC_PUNKS_SYMBOL?.toLowerCase() || 'PUNKS'
export const PUNKS_IMAGE =
  process.env.NEXT_PUBLIC_PUNKS_IMAGE ||
  'https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE'
export const MIN_CLAIM_REWARD = '0'
export const ETH = {
  id: ETH_ADDRESS,
  symbol: 'ETH',
  name: 'Ethereum',
  underlyingAsset: ETH_ADDRESS,
  totalLiquidity: new BigNumber(0),
  liquidityRate: new BigNumber(0),
  variableBorrowRate: new BigNumber(0),
  decimals: new BigNumber(0),
  scaledBTokenBalance: new BigNumber(0),
  currentBTokenBalance: new BigNumber(0),
  scaledVariableDebt: new BigNumber(0),
  currentVariableDebt: new BigNumber(0),
  variableBorrowIndex: new BigNumber(0),
  price: {
    priceInEth: new BigNumber(0),
    oracle: {
      usdPriceEth: new BigNumber(0)
    }
  }
}
export const ethImagePath = '/images/elements/0xc778417e063141139fce010982780140aa0cd5ab.svg'
export const ethImageDarkPath = '/images/elements/0xc778417e063141139fce010982780140aa0cd5ab-dark.svg'
export const bendImagePath = '/images/elements/bend.svg'
export const aaveImagePath = '/images/elements/aave.png'
export const bendXlImagePath = '/images/elements/bend-xl.svg'
export const wethImagePath = '/images/elements/0xc778417e063141139fce010982780140aa0cd5ab-dark.svg'
export const wethImagePathXl = '/images/elements/weth.svg'
export const bendSmallImagePath = '/images/elements/bend-small-1.svg'
export const boundNftImagePath = '/images/elements/boundnft-small.svg'
export const bendXsImagePath = '/images/elements/bend-xs.svg'
export const veBendXsImagePath = '/images/elements/vebend-xs.svg'
export const ContractAddress = '0x0d02755a5700414B26FF040e1dE35D337DF56218'
export const MaycCollectionImagePath = '/images/elements/mayc_collection.png'
export const openSeaImagePath = '/images/elements/opensea.png'
export const etherscanImagePath = '/images/elements/etherscan.png'
export const looksRareImagePath = '/images/elements/looksrare.png'
export const x2y2ImagePath = '/images/elements/x2y2.png'
export const punksImagePath = '/images/elements/punks.png'
export const punksXlImagePath = '/images/marketplace-cryptopunk.png'
export const looksXlRareImagePath = '/images/marketplace-looksrare.png'
export const openSeaImagePathXl = '/images/marketplace-opensea.png'
export const bendDaoImagePathXl = '/images/elements/benddao-xl.png'
export const percentImagePath = '/images/elements/percent.png'

export const wpImagePath = '/images/wp-logo.svg'

export const BEND_TOKEN: CustomTokenProps = {
  address: BEND_ADDRESS,
  decimals: 18,
  symbol: 'BEND',
  image: 'https://d3c95q2tflpu4m.cloudfront.net/fe/BEND_Circle_300px.png'
}

export const VEBEND_TOKEN: CustomTokenProps = {
  address: VE_BEND_ADDRESS,
  decimals: 18,
  symbol: 'veBEND',
  image: 'https://d3c95q2tflpu4m.cloudfront.net/fe/veBEND_Circle_300px.png'
}

export const WETH_TOKEN: CustomTokenProps = {
  address: WETH_ADDRESS,
  decimals: 18,
  symbol: 'WETH',
  image: 'https://bend-cms.s3.ap-southeast-1.amazonaws.com/weth300px_ecb8b5d982.png?updated_at=2022-10-14T11:51:11.212Z'
}

export const NUMBER_FORMAT: INumberFormat = {
  '0': '0,0',
  '2': '0,0.00',
  '3': '0,0.000',
  '4': '0,0.0000',
  '5': '0,0.00000',
  '6': '0,0.000000',
  '7': '0,0.0000000',
  '8': '0,0.00000000'
}

export enum nftImageUrlType {
  ipfsUrl = 'ipfs_url',
  httpUrl = 'http_url'
}

export { routes }

export const BEND_TWITTER_LINK = 'https://twitter.com/benddao'
export const BEND_DISCORD_LINK = 'https://discord.gg/benddao'
export const BEND_TELEGRAM_LINK = 'https://t.me/BendDAO'
export const BEND_MEDIUM_LINK = 'https://medium.com/@BendDAO'
export const BEND_BNFT_LINK = 'https://docs.benddao.xyz/portal/protocol-overview/boundnft'
export const BEND_ETH_LINK = 'https://docs.benddao.xyz/portal/protocol-overview/bendeth'
export const BEND_SECURITY_LINK = 'https://docs.benddao.xyz/risk/'
export const BEND_TOKENOMICS_LINK = 'https://docs.benddao.xyz/portal/governance/bendenomics'
export const BEND_DOCS_LINK = 'https://docs.benddao.xyz'
export const BEND_DEVELOPERS_LINK = 'https://docs.benddao.xyz/developers'
export const BEND_GITHUB_LINK = 'https://github.com/BendDAO'
export const BEND_DOCS_DEPOSIT_LINK = 'https://docs.benddao.xyz/portal/#deposit-eth-to-earn-yields'
export const BEND_DOCS_BORROW_LINK = 'https://docs.benddao.xyz/portal/#nft-as-collaterals-to-borrow-eth'
export const BEND_DOCS_LOCKER = 'https://docs.benddao.xyz/portal/governance/fee-collection-and-distribution'
export const BEND_DOCS_AUCTION = 'https://docs.benddao.xyz/portal/protocol-overview/auction'
export const BEND_DEWORK_LINK = 'https://app.dework.xyz/benddao'
export const BEND_DUNE_ANALYTICS_LINK = 'https://dune.com/cgq0123/Bend-DAO'
export const BEND_DOCS_FLASH_CLAIM = 'https://docs.benddao.xyz/portal/faq/flash-claim-faq'
export const BEND_DOWNPAYMENT_DOCS_LINK = 'https://docs.benddao.xyz/portal/nft-liquidity/nft-down-payment-buyer'
export const BEND_DOCS_REWARDS_LINK = 'https://docs.benddao.xyz/portal/user-guides/rewards'
export const LS_TOKEN = 'token'
export const BEND_AUDIT_LINK = 'https://docs.benddao.xyz/portal/risk/security-and-audits#audits'
export const BEND_DOCS_AIRDROP_LINK = 'https://docs.benddao.xyz/portal/airdrop'
export const BEND_EMAIL = 'support@benddao.xyz'
export const COOKIE_USER_DENIED_LOGIN = '_bend_user_denied_login'
export const BEND_UNISWAP_LINK = 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0d02755a5700414B26FF040e1dE35D337DF56218&chain=mainnet'
export const BEND_CONTRACT = '0x0d02755a5700414b26ff040e1de35d337df56218'
export const BEND_BUG_BOUNTY = 'https://immunefi.com/bounty/benddao/'
export const UNISWAP_V2_ADD_LINK = 'https://app.uniswap.org/#/add/v2/'
export const BENDDAO_NFT_COLLECTION = 'https://looksrare.org/accounts/0x4D62360CEcF722A7888b1f97D4c7e8b170071248'
export const BEND_ASSETS_LINK = 'https://github.com/BendDAO/bend-brand-logo-assets'
export const WRAPPEDPUNKS = 'https://www.wrappedpunks.com/'
export const OPENSEA_LINK = 'https://opensea.io/'
export const LOOKSRARE_LINK = 'https://looksrare.org/'
export const X2Y2_LINK = 'https://x2y2.io/'
export const CRYPTOPUNKS_LINK = 'https://cryptopunks.app/cryptopunks/forsale'
export const CRYPTOPUNKS_LANDING_LINK = 'https://cryptopunks.app/'
export const GRID_MIN_WIDTH = '240px'
export const SKELETONS = [...Array(18).keys()]
export const COLLECTION_SKELETONS = [...Array(9).keys()]
export const REDEEM_TIME = DEFAULT_CHAIN_ID === '1' ? 24 : 4
export const AUCTION_TIME = 24

export const YEAR = 31536000
export const MONTH = 2629743
export const WEEK = 604800
export const DAY = 86400
export const HOUR = 3600

export const LANGUAGE_NAMESPACES = ['common']
