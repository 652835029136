import React, { FC, useEffect, useMemo, useState } from 'react'
import { find, isArray } from 'lodash'
import { Box, Flex, Text } from 'rebass/styled-components'
import { IPane, IPaneKey, IPaneSwitch, IPaneSwitchHeader } from './interfaces'
import { BreadcrumbsReducer } from 'constants/types'
import useTheme from 'hooks/common/useTheme'
import usePlatform from 'contexts/platform'
import router, { useRouter } from 'next/router'
// import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import useResponsive from 'hooks/common/useResponsive'
import { permalink } from 'constants/routes'
import { Menu } from 'react-feather'
import { useDrawer } from '../drawer'
import Button from 'theme/ui/forms/button'
import { SidebarDash } from 'components/common/sidebar'
import Link from 'components/common/link'
import { IconDashboard } from 'theme/ui/icons'
import { useTranslation } from 'next-i18next'
import { useRedirectLocale } from 'hooks/common/useRedirectLocale'

const PaneSwitch: FC<IPaneSwitch> = ({ panes, hasArrows = false, ...restprops }) => {
  const [active, setActive] = useState<IPaneKey>(
    (isArray(panes) && panes.filter((pane: IPane) => pane.active).length) > 0 ? panes.filter((pane: IPane) => pane.active)[0].key : panes[0].key
  )
  const [pane, setPane] = useState<React.ReactNode | null>(null)
  const { isMobile, isDesktop } = useResponsive()
  useEffect(() => {
    for (let i = 0; i < panes.length; i += 1) {
      if (panes[i].key === active) setPane(panes[i].content)
    }
  }, [active, panes])

  const {
    query: { activeSubmenu }
  } = useRouter()

  useEffect(() => {
    if (!activeSubmenu) return
    for (let i = 0; i < panes.length; i += 1) {
      if (panes[i].key === activeSubmenu) {
        setPane(panes[i].content)
        setActive(panes[i].key)
      }
    }
  }, [activeSubmenu, panes])

  if (!panes.length) throw new Error('Panes cannot be empty.')
  return (
    <Flex flexDirection='column' {...restprops}>
      {isDesktop && (
        <Flex justifyContent='center'>
          {panes.map((pane: IPane, index: number) => (
            <PaneSwitchHeader
              key={pane.key}
              hasArrows={hasArrows}
              pane={pane}
              active={active}
              setActive={setActive}
              setPane={setPane}
              first={index === 0}
              last={index === panes.length - 1}
            />
          ))}
        </Flex>
      )}

      {isMobile && !isDesktop && (
        <Box>
          <MobilePaneSwitchHeader panes={panes} active={active} />
          {/* <Swiper
            slidesPerView={isTablet ? 5 : 3}
            scrollbar={{ draggable: false }}
            navigation={{
              nextEl: '.weekdays-carousel-right',
              prevEl: '.weekdays-carousel-left'
            }}
          >
            {panes.map((pane: IPane, index: number) => (
              <SwiperSlide key={pane.key}>
                <PaneSwitchHeader
                  key={pane.key}
                  hasArrows={false}
                  pane={pane}
                  active={active}
                  setActive={setActive}
                  setPane={setPane}
                  first={false}
                  last={false}
                />
              </SwiperSlide>
            ))}
          </Swiper> */}
        </Box>
      )}
      <Box mt={30}>{pane}</Box>
    </Flex>
  )
}

type MobilePaneSwitchHeaderProps = {
  panes: IPane[]
  active: IPaneKey
}

const MobilePaneSwitchHeader: React.FC<MobilePaneSwitchHeaderProps> = ({ panes, active }) => {
  const [toggleSidebar] = useDrawer(<Sidebar panes={panes} />)

  const title = useMemo(() => find(panes, (pane: IPane) => pane.key === active)?.title, [active, panes])

  return (
    <Box bg='primary' color='white' px={50} py={15}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text fontSize='lg' fontWeight='bold'>
          {title}
        </Text>
        <Button size='icon' outlined color='white' buttonStyles={{ borderColor: 'transparent' }} onClick={toggleSidebar}>
          <Menu />
        </Button>
      </Flex>
    </Box>
  )
}

type SidebarProps = {
  panes: IPane[]
  onDismiss?: any
}

const Sidebar: React.FC<SidebarProps> = ({ panes, onDismiss }) => {
  const { t: tc } = useTranslation()
  const { colors } = useTheme()
  const [changeColor, setChangeColor] = useState<string>()

  const handleClick = (key: any) => {
    setChangeColor(key.toString())
    onDismiss()
  }
  return (
    <Flex flexDirection='column' flex={1}>
      <Box
        sx={{
          flexDirection: ['row'],
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 'lg',
          '& svg': {
            transition: 'all 0.3s',
            color: 'primary'
          }
        }}
        mb={12}
      >
        <Flex alignItems={'center'}>
          <IconDashboard size={20} color={colors.grey[300]} />
          <Text fontWeight={'bold'} ml={10} fontSize={12}>
            {tc('title.dashboard')}
          </Text>
        </Flex>
      </Box>
      <Box>
        <SidebarDash />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridGap: '10px 30px',
          gridTemplateColumns: `repeat(2, minmax(120px, 120px))`
        }}
      >
        {panes.map((pane: IPane) => (
          <Link key={pane.key} href={`${permalink.dashboard}/${pane.key}`} passHref>
            <Flex
              sx={{ '&:hover': { color: 'primary', cursor: 'pointer' } }}
              alignItems='center'
              justifyContent={'flex-start'}
              onClick={() => handleClick(pane.key)}
              onMouseEnter={() => setChangeColor(pane.key.toString())}
              onMouseLeave={() => setChangeColor('')}
            >
              <Box width={'100%'} maxWidth={2} height={10} backgroundColor={changeColor !== pane.key ? 'grey.300' : 'primary'} mr={16} />
              <Text fontWeight='bold' fontSize={13} sx={{ textTransform: 'initial' }} mt={2}>
                {pane.title}
              </Text>
            </Flex>
          </Link>
        ))}
      </Box>
    </Flex>
  )
}

const PaneSwitchHeader: React.FC<IPaneSwitchHeader> = ({ pane, active, first, last }) => {
  const { fontSizes, borderRadius, colors } = useTheme()
  const { formatLink } = useRedirectLocale()
  const {
    breadcrumbs: { dispatch }
  } = usePlatform()
  const { key, title, menuTitle } = pane
  useEffect(() => {
    if (key === active) {
      dispatch({
        action: BreadcrumbsReducer.update,
        payload: menuTitle
      })
    }

    return () => {
      dispatch({
        action: BreadcrumbsReducer.update,
        payload: ''
      })
    }
  }, [active, dispatch, key, menuTitle, title])

  return (
    <Flex flex={['100px', '100px', '100px', '0 0 155px']} flexDirection='column' alignItems='center' justifyContent='flex-start' mt={[0, 0, 0, -20]}>
      <Flex
        justifyContent='center'
        alignItems='center'
        onClick={() => {
          if (key === active) return
          router.push(formatLink(`${permalink.dashboard}/${key}`))
        }}
        sx={{
          width: ['100%'],
          cursor: 'pointer',
          bg: key === active ? 'primary' : 'white',
          color: key === active ? 'white' : 'black',
          fontSize: 'md',
          fontWeight: 'bold',
          transition: 'all 0.2s ease-in-out',
          lineHeight: fontSizes.lg,
          py: 13,
          boxShadow: `0 4px 8px 0 ${colors.shadow[100]}`,
          borderTopLeftRadius: first ? borderRadius[16] : undefined,
          borderBottomLeftRadius: first ? borderRadius[16] : undefined,
          borderTopRightRadius: last ? borderRadius[16] : undefined,
          borderBottomRightRadius: last ? borderRadius[16] : undefined,
          ':hover': { boxShadow: `0 8px 8px 0 ${colors.shadow[100]}`, transition: 'all 0.2s ease-in-out' },
          zIndex: 1000
        }}
      >
        {title}
      </Flex>
    </Flex>
  )
}

export default PaneSwitch
