/* import { shortenTxHash } from 'modules/bend/utils/shortenTxHash'
 */ import { useTranslation } from 'next-i18next'
import { Flex, Text } from 'rebass'
import { Button, Notification } from 'theme/ui'

export const WrapStep9: React.FC<any> = (/* { txHash } */) => {
  const { t } = useTranslation('common')
  return (
    <Flex flexDirection={'column'} flex={1} mt={20}>
      <Flex flex={1} mb={18}>
        <Notification loading={true} reducePadding>
          <Text fontWeight={'normal'}>{t('deposit.paragraph.tx-pending')}</Text>
          {/*          <Text fontWeight={'normal'}>{shortenTxHash(txHash!)}</Text> */}
        </Notification>
      </Flex>
      <Button color='black' outlined size='md' fontWeight={'bold'} disabled loading />
    </Flex>
  )
}
