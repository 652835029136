import React, { useEffect, useRef } from 'react'
import SimpleBarJS from './lib/simplebar-core.esm'
import { Box } from 'rebass/styled-components'

const SimpleBar = React.forwardRef(({ children, scrollableNodeProps = {}, setIsVisibleVertical, ...otherProps }: any, ref: any) => {
  const instance = useRef<any>()
  let scrollableNodeRef = useRef()
  const elRef = useRef()
  const contentNodeRef = useRef<any>()
  const verticalRef = useRef<HTMLDivElement>()

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    scrollableNodeRef = scrollableNodeProps.ref || scrollableNodeRef
    const refInstance = ref?.current || elRef.current
    if (refInstance) {
      instance.current = new SimpleBarJS(refInstance, {
        ...(scrollableNodeRef && {
          scrollableNode: scrollableNodeRef.current
        }),
        ...(contentNodeRef.current && {
          contentNode: contentNodeRef.current
        })
      })

      if (setIsVisibleVertical) {
        setIsVisibleVertical(!!verticalRef.current?.clientHeight)
      }

      // if (ref) {
      //   ref.current = instance
      // }
    }

    return () => {
      instance.current.unMount()
      instance.current = null
    }
  }, [])

  return (
    <Box ref={ref || elRef} data-simplebar {...otherProps}>
      <Box className='simplebar-wrapper'>
        <Box className='simplebar-height-auto-observer-wrapper'>
          <Box className='simplebar-height-auto-observer' />
        </Box>
        <Box className='simplebar-mask'>
          <Box className='simplebar-offset'>
            {typeof children === 'function' ? (
              children({ scrollableNodeRef, contentNodeRef })
            ) : (
              <Box {...scrollableNodeProps} className={`simplebar-content-wrapper${scrollableNodeProps.className ? ` ${scrollableNodeProps.className}` : ''}`}>
                <Box className='simplebar-content' sx={{ maxWidth: verticalRef.current?.clientHeight ? 'calc(100% - 20px)' : undefined }}>
                  {children}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box className='simplebar-placeholder' />
      </Box>
      <Box className='simplebar-track simplebar-horizontal'>
        <Box className='simplebar-scrollbar' />
      </Box>
      <Box className='simplebar-track simplebar-vertical'>
        <Box className='simplebar-scrollbar' ref={verticalRef} />
      </Box>
    </Box>
  )
})

SimpleBar.displayName = 'SimpleBar'

export default SimpleBar
