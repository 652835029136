import React from 'react'
// Hooks
import { Box, Text, Link as LinkRebass, Flex, Image } from 'rebass/styled-components'
import {
  Avatar,
  // Button,
  Container,
  IconBtoken,
  IconDiscord,
  IconDoc,
  IconGithub,
  IconMedium,
  IconSecurity,
  IconTwitter,
  // Input,
  MotionBox,
  MotionFlex,
  IconDework,
  IconDuneAnalytics,
  IconBugBounty,
  IconGovernance,
  IconForum,
  IconAnalytics
} from 'theme/ui'
import Link from 'components/common/link'
//import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import useResponsive from 'hooks/common/useResponsive'
import router from 'next/router'
import { permalink } from 'constants/routes'
import {
  BEND_TWITTER_LINK,
  BEND_DISCORD_LINK,
  BEND_MEDIUM_LINK,
  BEND_SECURITY_LINK,
  BEND_DOCS_LINK,
  BEND_GITHUB_LINK,
  BEND_EMAIL,
  BEND_DEWORK_LINK,
  BEND_DUNE_ANALYTICS_LINK,
  BEND_BUG_BOUNTY,
  BEND_ASSETS_LINK
} from 'constants/index'
import { Mail } from 'react-feather'

const Footer: React.FC = () => {
  //  const router = useRouter()
  const { t } = useTranslation('common')
  const { t: tc } = useTranslation('common')
  const { isDesktop, isMobile, isTablet, isLaptop } = useResponsive()
  const [bottomMargin, setBottomMargin] = React.useState(Number)

  React.useEffect(() => {
    if (isDesktop) {
      setBottomMargin(0)
    }
    if (isLaptop && !isDesktop) {
      setBottomMargin(80)
    }
    if (router.pathname === '/' && isLaptop && !isDesktop) {
      setBottomMargin(80)
    }
    if (isTablet && !isLaptop) {
      setBottomMargin(80)
    }
    if (!isTablet && isMobile) {
      setBottomMargin(50)
    }
    if (!isDesktop && !isMobile) {
      setBottomMargin(40)
    }
  }, [isDesktop, isLaptop, isMobile, isTablet])

  return (
    <MotionFlex
      width='100%'
      height='100%'
      justifyContent='center'
      alignSelf='bottom'
      sx={{
        background: 'linear-gradient(0deg, rgba(9,21,36,1) 0%, rgba(64,74,96,1) 100%)',
        overflow: 'hidden'
      }}
    >
      <Container
        maxWidth={[1400]}
        justifyContent={['center', 'center', 'center', 'space-between']}
        py={[20, 20, 20, 20, 40]}
        flexDirection={['column', 'column', 'column', 'row']}
        sx={{ zIndex: 21 }}
        width='100%'
        mb={bottomMargin}
      >
        {isDesktop && (
          <MotionFlex flexDirection='column' justifyContent={['flex-start']} width='100%' flex={1}>
            <MotionFlex alignItems='center' maxWidth={280} mb={25}>
              <Link href='/' passHref>
                <Flex sx={{ ':hover': { opacity: 0.8, cursor: 'pointer', transition: 'ease-in-out 0.3s' } }} alignItems='center' height={61}>
                  <Box mr={18} alignItems='center'>
                    <Image src={'/images/bend-logo-footer.svg'} width={140} height={75} />
                  </Box>
                  <Box fontWeight='bold' alignItems='center'>
                    <Image src={'/images/bend-logo-text-full-white.svg'} width={242} height={58} />
                  </Box>
                </Flex>
              </Link>
            </MotionFlex>
            <MotionBox color='white' fontFamily='body' width={381}>
              <Text fontWeight={'bold'} mb={5} fontSize={16}>
                {t('paragraph.data-ownership')}
              </Text>
              <Text fontWeight={'bold'} mb={20} fontSize={16}>
                {t('paragraph.bend-empowers')}
              </Text>
              <Text mb={20} fontSize={13}>
                {t('paragraph.footer-one')}
              </Text>
              <Text mb={40} fontSize={13}>
                {t('paragraph.footer-email-us')}
                <LinkRebass fontWeight={'bold'} color='white' target='_blank' href={`mailto:${BEND_EMAIL}`} rel='noopener noreferrer'>
                  {BEND_EMAIL}
                </LinkRebass>
              </Text>
            </MotionBox>
          </MotionFlex>
        )}

        <MotionFlex width='100%' flex={1} flexDirection='column' ml={[0, 0, 0, 0, '-10%']} px={[20, 20, 40, 40, 0]}>
          <MotionBox fontWeight='bold' mb={50}>
            <Text fontSize={16} color='white' sx={{ opacity: 0.5 }}>
              {t('label.join-community')}
            </Text>
            <Text fontSize={32} mt={12} color='white'>
              {t('label.follow-our-channels')}
            </Text>
          </MotionBox>
          <MotionBox
            sx={{
              display: 'grid',
              gridGap: '20px',
              gridTemplateColumns: `repeat(${!isTablet ? 2 : 4}, minmax(154.7px, 154.7px))`
            }}
            mb={30}
          >
            <Avatar
              icon={<IconTwitter color='white' />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_TWITTER_LINK}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.twitter')}</Box>}
            />
            <Avatar
              icon={<IconDiscord color='white' />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_DISCORD_LINK}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.discord')}</Box>}
            />
            <Avatar
              icon={<IconMedium color='white' />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_MEDIUM_LINK}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.medium')}</Box>}
            />

            <Avatar
              icon={<IconGithub color='white' />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_GITHUB_LINK}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.github')}</Box>}
            />
            <Avatar
              icon={<IconSecurity color='white' />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_SECURITY_LINK}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.security')}</Box>}
            />
            <Avatar
              icon={<IconDoc color='white' />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_DOCS_LINK}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.docs')}</Box>}
            />
            <Avatar
              icon={<Mail color='white' size={18} />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={`mailto:${BEND_EMAIL}`}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.email-us')}</Box>}
            />
            <Avatar
              icon={<IconDework color='white' size={16} />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_DEWORK_LINK}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.dework')}</Box>}
            />
            <Avatar
              icon={<IconDuneAnalytics color='white' size={18} />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_DUNE_ANALYTICS_LINK}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.dune-analytics')}</Box>}
            />
            <Avatar
              icon={<IconBugBounty color='white' />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_BUG_BOUNTY}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.bug-bounty')}</Box>}
            />
            <Avatar
              icon={<IconGovernance color='white' />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={permalink.governance}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.governance')}</Box>}
            />
            <Avatar
              icon={<IconForum color='white' />}
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={permalink.forum}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.forum')}</Box>}
            />
            <Avatar
              icon={
                <Flex alignItems={'center'} width={31.71} justifyContent={'center'}>
                  <IconBtoken color='white' />
                </Flex>
              }
              variant='footer-link'
              iconSize={31.71}
              hasBorderRadius={8}
              hasBackground
              iconBackground='primary'
              as='a'
              href={BEND_ASSETS_LINK}
              target='_blank'
              rel='noopener noreferrer'
              text={<Box variant='footer-text'>{tc('label.bend-assets')}</Box>}
            />
            {router.pathname === '/' && (
              <Avatar
                icon={
                  <Flex alignItems={'center'} width={31.71} justifyContent={'center'}>
                    <IconAnalytics size={19} color='white' />
                  </Flex>
                }
                variant='footer-link'
                iconSize={31.71}
                hasBorderRadius={8}
                hasBackground
                iconBackground='primary'
                as='a'
                href={permalink.analytics}
                target='_blank'
                rel='noopener noreferrer'
                text={<Box variant='footer-text'>{tc('label.analytics')}</Box>}
              />
            )}
          </MotionBox>
        </MotionFlex>
      </Container>
    </MotionFlex>
  )
}

export default Footer
