import { calculateGasMargin } from 'wallet-module'
/**
 * @dev approveDelegation the given amount of the token to the given address. Reverts if the inputs are invalid.
 * @param contract contract
 * @param delegate Address to approveDelegation to
 **/
export const approveDelegation = async (contract: any, delegate: string) => {
  const maxUint256 = '57896044618658097711785492504343953926634992332820282019728792003956564819968'
  console.debug('util approveDelegation data', delegate)
  const estimatedGas = await contract.estimateGas.approveDelegation(delegate, maxUint256).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.approveDelegation(delegate, maxUint256)
  })

  return contract
    .approveDelegation(delegate, maxUint256, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to approveDelegation', error)
      throw error
    })
}
