import { ApolloQueryResult, gql } from '@apollo/client'
import BigNumber from 'bignumber.js'
import { clientBendProtocol, clientNftApi } from 'clients'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { LoanState } from 'constants/types'
import { find, isEmpty, orderBy as lodashOrderBy } from 'lodash'
import { SimpleLoansData } from 'modules/bend/classes/SimpleLoansData'
import { LEND_POOL_ADDRESSES_PROVIDER, WETH_ADDRESS } from 'modules/bend/constants'
import moment from 'moment'
import { getBidEndTimestamp } from 'utils'
import { ActiveAuctionsArgs, ActiveAuctionsOrderBy, LoansByBidderAddressResult, NftLoanByBidderAddress, NftLoansResult, OrderDirection } from '../../types'

const QUERY_LOANS_BY_BIDDER_ADDRESS_FRAGMENT = `
  id
  loanId
  bidStartTimestamp
  nftAsset {
    liquidationThreshold
    underlyingAsset
    auctionDuration
    symbol
    price {
      priceInEth
    }
  }
  nftTokenId
  state
  bidderAddress
  bidPrice
  currentAmount
  reserveAsset {
    underlyingAsset
    decimals
    price {
      priceInEth
    }
  }
`

const QUERY_LOANS_BY_BIDDER_ADDRESS = gql`
  query LoansByBidderAddress($bidderAddress: String!, $state: LoanState!) {
    loans(where: { bidderAddress: $bidderAddress, state: $state }) {
      ${QUERY_LOANS_BY_BIDDER_ADDRESS_FRAGMENT}
    }
  }
`

const QUERY_LOANS_BY_BIDDER_ADDRESS_TESTNET = gql`
  query LoansByBidderAddress($bidderAddress: String!, $state: LoanState!, $reserve: String) {
    loans(where: { bidderAddress: $bidderAddress, state: $state, reserveAsset: $reserve }) {
      ${QUERY_LOANS_BY_BIDDER_ADDRESS_FRAGMENT}
    }
  }
`

const QUERY_LOANS_BY_BIDDER_ADDRESS_NFT_ASSET = gql`
  query LoansByBidderAddress($bidderAddress: String!, $state: LoanState!, $nftAsset_in: [String!]) {
    loans(where: { bidderAddress: $bidderAddress, state: $state, nftAsset_in: $nftAsset_in }) {
      ${QUERY_LOANS_BY_BIDDER_ADDRESS_FRAGMENT}
    }
  }
`

const QUERY_LOANS_BY_BIDDER_ADDRESS_NFT_ASSET_TESTNET = gql`
  query LoansByBidderAddress($bidderAddress: String!, $state: LoanState!, $reserve: String, $nftAsset_in: [String!]) {
    loans(where: { bidderAddress: $bidderAddress, state: $state, reserveAsset: $reserve, nftAsset_in: $nftAsset_in }) {
      ${QUERY_LOANS_BY_BIDDER_ADDRESS_FRAGMENT}
    }
  }
`

const QUERY_NFTLOANS_BY_BIDDER_ADDRESS = gql`
  query ($state: LoanState, $loanID_in: [String!]) {
    loans(state: $state, loanID_in: $loanID_in) {
      loanID
      state
      healthFactor
      nftTokenID
      nftItem {
        image
        imageType
        collection {
          address
          name
          openseaImageURL
        }
      }
    }
  }
`

export const getActiveAuctions = async (args: ActiveAuctionsArgs) => {
  const { account, nftAsset_in } = args

  if (!account) return []

  let { orderDirection, orderBy } = args
  if (typeof orderDirection === 'undefined') {
    orderDirection = OrderDirection.asc
  }

  if (typeof orderBy === 'undefined') {
    orderBy = ActiveAuctionsOrderBy.name
  }

  const {
    data: { loans }
  }: ApolloQueryResult<LoansByBidderAddressResult> = await clientBendProtocol.query({
    query:
      DEFAULT_CHAIN_ID === '1'
        ? isEmpty(nftAsset_in)
          ? QUERY_LOANS_BY_BIDDER_ADDRESS
          : QUERY_LOANS_BY_BIDDER_ADDRESS_NFT_ASSET
        : isEmpty(nftAsset_in)
        ? QUERY_LOANS_BY_BIDDER_ADDRESS_TESTNET
        : QUERY_LOANS_BY_BIDDER_ADDRESS_NFT_ASSET_TESTNET,
    variables: {
      bidderAddress: account,
      state: LoanState.Auction,
      reserve: WETH_ADDRESS.toLowerCase() + LEND_POOL_ADDRESSES_PROVIDER.toLowerCase(),
      nftAsset_in: nftAsset_in?.map((nftAsset: string) => nftAsset.toLowerCase() + LEND_POOL_ADDRESSES_PROVIDER.toLowerCase())
    }
  })

  if (isEmpty(loans)) return []

  const {
    data: { loans: nftLoans }
  }: ApolloQueryResult<NftLoansResult> = await clientNftApi.query({
    query: QUERY_NFTLOANS_BY_BIDDER_ADDRESS,
    variables: {
      state: LoanState.auction,
      loanID_in: loans.map(loan => loan.loanId)
    }
  })

  const payload = []
  for (let i = 0; i < loans.length; i += 1) {
    const loan = loans[i]
    const now = moment(moment().unix())
    const bidEndTime = moment(getBidEndTimestamp(loan.bidStartTimestamp, loan.nftAsset.auctionDuration))
    const nft: NftLoanByBidderAddress | undefined = find(nftLoans, (nftLoan: NftLoanByBidderAddress) => nftLoan.loanID === loan.loanId)

    const LoansData = new SimpleLoansData([loan.nftAsset.underlyingAsset], [loan.nftTokenId], args?.provider)
    await LoansData.init()
    const currentAmount = (await LoansData.getTotalDebtInReserve()).dividedBy(`1e${loan.reserveAsset.decimals}`)
    const floorPrice = new BigNumber(loan.nftAsset.price.priceInEth)
      .dividedBy(1e18)
      .dividedBy(new BigNumber(loan.reserveAsset.price.priceInEth).dividedBy(1e18))
    const liquidationThreshold = new BigNumber(loan.nftAsset.liquidationThreshold).dividedBy(1e4)
    const healthFactor = floorPrice.multipliedBy(liquidationThreshold).dividedBy(currentAmount).dp(2).toNumber()
    const borrowed = new BigNumber(loan.currentAmount).dividedBy(`1e${loan.reserveAsset.decimals}`).dp(4)
    const bidPrice = new BigNumber(loan.bidPrice).dividedBy(1e18)

    payload.push({
      ...loan,
      key: `${loan.nftTokenId}-${nft?.nftItem.collection.address}`,
      id: loan.id,
      healthFactor,
      image: nft?.nftItem.image,
      imageType: nft?.nftItem.imageType,
      openseaImageURL: nft?.nftItem.collection.openseaImageURL,
      collectionName: nft?.nftItem.collection.name,
      collectionUnderlyingAsset: nft?.nftItem.collection.address,
      bidPrice: bidPrice.toFixed(),
      borrowed: borrowed.toFixed(),
      auctionEnded: !loan.bidStartTimestamp ? false : now.isSameOrAfter(bidEndTime),
      floorPrice: new BigNumber(loan.nftAsset.price.priceInEth)
        .dividedBy(1e18)
        .dividedBy(new BigNumber(loan.reserveAsset.price.priceInEth).dividedBy(1e18))
        .dp(4)
        .toFixed(),
      orderBy: {
        name: nft?.nftItem.collection.name,
        tokenID: isNaN(Number(loan.nftTokenId)) ? loan.nftTokenId : Number(loan.nftTokenId),
        floorPrice: floorPrice.toNumber(),
        borrowed: borrowed.toNumber(),
        healthFactor,
        bidPrice: bidPrice.toNumber()
      }
    })
  }

  return lodashOrderBy(payload, [`orderBy.${orderBy}`], [orderDirection])
}
