import { useMemo } from 'react'
import { useBlockNumber } from 'wallet-module'
import { isValidMethodArgs, toCallState } from '../utils'
import { useCallsData } from './useCallsData'
const showDebug = false
export function useMultipleContractSingleData(addresses, contractInterface, methodName, callInputs, options) {
  const fragment = useMemo(() => contractInterface.getFunction(methodName), [contractInterface, methodName])
  const callData = useMemo(() => {
    var _a
    if (
      ((_a = fragment === null || fragment === void 0 ? void 0 : fragment.inputs) === null || _a === void 0 ? void 0 : _a.length) !==
      (callInputs === null || callInputs === void 0 ? void 0 : callInputs.length)
    )
      return undefined
    return fragment && isValidMethodArgs(callInputs) ? contractInterface.encodeFunctionData(fragment, callInputs) : undefined
  }, [callInputs, contractInterface, fragment])
  if (showDebug) console.log('useMultipleContractSingleData callData', callData)
  const calls = useMemo(
    () =>
      fragment && addresses && addresses.length > 0 && callData
        ? addresses.map(address => {
            return address && callData
              ? {
                  address,
                  callData
                }
              : undefined
          })
        : [],
    [addresses, callData, fragment]
  )
  if (showDebug) console.log('useMultipleContractSingleData calls', calls)
  if (showDebug) console.log('useMultipleContractSingleData options', options)
  const results = useCallsData(calls, options)
  if (showDebug) console.log('useMultipleContractSingleData results', JSON.stringify(results))
  const latestBlockNumber = useBlockNumber()
  return useMemo(() => {
    return results.map(result => toCallState(result, contractInterface, fragment, latestBlockNumber))
  }, [fragment, results, contractInterface, latestBlockNumber])
}
