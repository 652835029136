import { gql } from '@apollo/client'
import BigNumber from 'bignumber.js'
import { clientNftApi } from 'clients'
import { REDEEM_TIME } from 'constants/index'
import { useUserContext } from 'contexts/user'
import { useCallback } from 'react'
import { useQuery } from 'react-query'

type UseNotificationsProps = {
  skip?: number
  first?: number
  isRead?: boolean | null
}

const body = `
  loan {
    loanID
    state
    borrower
    nftTokenID
    nftAsset
    reserveAsset
    healthFactor
    bidPrice
  }
  nftCollection {
    address
    name
    openseaImageURL
    symbol
    totalSupply
  }
  nftItem {
    collectionAddress
    ownerAddress
    tokenID
    tokenURI
    image
    imageType
  }
`

export default function useNotifications({ skip = 0, first = 10, isRead = null }: UseNotificationsProps) {
  const { isLoggedIn, token, user } = useUserContext()
  const notifications = useQuery(
    ['get user notifications', isLoggedIn, token, skip, first, isRead],
    async () => {
      const {
        data: { notifications }
      } = await clientNftApi.query({
        query: gql`
          query Notifications($first: Int, $skip: Int, $isRead: Boolean) {
            notifications(first: $first, skip: $skip, isRead: $isRead) {
              id
              uniqueKey
              address
              topic
              createdAt
              body {
                loan_warn_1a {
                  ${body}
                }
                loan_warn_1b {
                  ${body}
                }
                loan_warn_2 {
                  ${body}
                }
                loan_warn_3 {
                  ${body}
                }
                loan_warn_4a {
                  ${body}
                }
                loan_warn_4b {
                  ${body}
                }
                loan_warn_5a {
                  ${body}
                }
                loan_warn_5b {
                  ${body}
                }
              }
              readAt
            }
          }
        `,
        variables: {
          skip,
          first,
          isRead
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      })

      return notifications
    },
    {
      enabled: !!isLoggedIn && !!token
    }
  )

  const handleMarkAsRead = useCallback(
    async (id: string) => {
      if (!token) return false
      try {
        const {
          data: {
            readNotificationByID: { ok }
          }
        } = await clientNftApi.mutate({
          mutation: gql`
            mutation MarkAsRead($id: String!) {
              readNotificationByID(request: { id: $id }) {
                ok
              }
            }
          `,
          variables: {
            id
          },
          context: {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        })

        return ok
      } catch (error) {
        return {
          error
        }
      }
    },
    [token]
  )

  const handleMarkAllAsRead = useCallback(async () => {
    if (!token) return false
    if (!user?.address) return false
    try {
      const {
        data: {
          readNotificationByID: { ok }
        }
      } = await clientNftApi.mutate({
        mutation: gql`
          mutation MarkAllAsRead($address: String!) {
            readAllNotification(request: { address: $address }) {
              ok
            }
          }
        `,
        variables: {
          address: user.address
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      })

      return ok
    } catch (error) {
      return {
        error
      }
    }
  }, [token, user?.address])

  return {
    notifications,
    handleMarkAsRead,
    handleMarkAllAsRead
  }
}

// type NotificationTypeProps = 'loan_warn_3' | 'loan_warn_2' | 'loan_warn_1a' | 'loan_warn_1b' | 'loan_warn_4a' | 'loan_warn_5a' | 'loan_warn_5b'

export type NotificationResult = {
  healthFactor?: string
  collectionName?: string
  collateral?: string
  highestBid?: string
  reserve?: string
  redeemTime?: number
}

export const useNotificationProps = (notification: any): NotificationResult => {
  // console.log('notification', notification)
  switch (notification.topic) {
    default:
    case 'loan_warn_1a':
      return {
        healthFactor: new BigNumber(notification.body[notification.topic]?.loan.healthFactor).dp(2).toString(),
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID
      }
    case 'loan_warn_1b':
      return {
        healthFactor: new BigNumber(notification.body[notification.topic]?.loan.healthFactor).dp(2).toString()
      }
    case 'loan_warn_2':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID,
        redeemTime: REDEEM_TIME
      }
    case 'loan_warn_3':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID,
        highestBid: new BigNumber(notification.body[notification.topic]?.loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
    case 'loan_warn_4a':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID
      }
    case 'loan_warn_5a':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID,
        highestBid: new BigNumber(notification.body[notification.topic]?.loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
    case 'loan_warn_5b':
      return {
        collectionName: notification.body[notification.topic]?.nftCollection.name,
        collateral: notification.body[notification.topic]?.nftItem.tokenID,
        highestBid: new BigNumber(notification.body[notification.topic]?.loan.bidPrice).dividedBy(1e18).dp(2).toString(),
        reserve: 'ETH'
      }
  }
}
