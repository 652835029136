import React from 'react'
import { Flex } from 'rebass/styled-components'
import useCopyClipboard from 'hooks/common/useCopyClipboard'
import { Button, IButton, IconCopy } from 'theme/ui'
import useTheme from 'hooks/common/useTheme'

interface CopyHelperProps extends IButton {
  toCopy: string
  hasIconOnCopy?: boolean
}

const CopyHelper: React.FC<CopyHelperProps> = ({ toCopy, children, ...restprops }) => {
  const [isCopied, setCopied] = useCopyClipboard()
  const { colors } = useTheme()

  return (
    <Button size='icon' onClick={() => setCopied(toCopy)} {...restprops}>
      <Flex flexDirection='row' alignItems='center'>
        {isCopied && /* hasIconOnCopy && */ <IconCopy color={colors.blue[1000]} />}
        {/*   {isCopied && !hasIconOnCopy && <Flex px={23}>{tw('wallet.label.copied')}</Flex>} */}
        {isCopied ? '' : children}
      </Flex>
    </Button>
  )
}

export default CopyHelper
