import { ApolloQueryResult, gql } from '@apollo/client'
import BigNumber from 'bignumber.js'
import { clientBendProtocol } from 'clients'
import { isArray, last } from 'lodash'
import { reservePayload } from 'utils'

/**
 * GraphQL Queries
 */
const RESERVE_FRAGMENT = `
 id
 decimals
 underlyingAsset
 symbol
 name
 totalLiquidity
 totalCurrentVariableDebt
 totalBTokenSupply
 liquidityRate
 variableBorrowRate
 isActive
 isFrozen
 utilizationRate
 availableLiquidity
 price {
   priceInEth
 }
 bToken {
   id
 }
 debtToken {
   id
 }
`

const QUERY_RESERVES = gql`
 query Reserves {
   reserves (where: { isActive: true, isFrozen: false }) {
     ${RESERVE_FRAGMENT}
   }
   distributionManagers {
     id
   }
 }
`

const QUERY_SEARCH_RESERVES = gql`
 query Reserves($search: String!) {
   bendSearch(text: $search) {
     ${RESERVE_FRAGMENT}
   }
   distributionManagers {
     id
   }
 }
`
type Price = {
  priceInEth: BigNumber
  oracle: {
    usdPriceEth: BigNumber
  }
}

export type Reserve = {
  id: string
  decimals: number
  isActive: boolean
  isFrozen: boolean
  liquidityRate: BigNumber
  name: string
  symbol: string
  totalLiquidity: BigNumber
  underlyingAsset: string
  variableBorrowRate: BigNumber
  totalCurrentVariableDebt: BigNumber
  price: Price
  totalBTokenSupply: BigNumber
  utilizationRate: BigNumber
  availableLiquidity: string
  bToken: {
    id: string
  }
  debtToken: {
    id: string
  }
}
type DistributionManager = {
  id: string
}
type ReserveData = {
  reserves: Reserve[]
  bendSearch: Reserve[]
  distributionManagers: DistributionManager[]
}

type GetHomepageReservesProps = {
  searchReserves?: string
}

/**
 * It queries the Bend Protocol GraphQL API for a list of reserves, and returns the data in a format
 * that's easier to work with
 * @param {GetHomepageReservesProps}  - GetHomepageReservesProps
 * @returns An array of reservePayload objects
 */
export default async function getHomepageReserves({ searchReserves }: GetHomepageReservesProps) {
  const { data }: ApolloQueryResult<ReserveData> = await clientBendProtocol.query({
    query: searchReserves ? QUERY_SEARCH_RESERVES : QUERY_RESERVES,
    variables: {
      search: `${searchReserves}:*`
    }
  })

  if (data.reserves && isArray(data.reserves)) return data.reserves.map((reserve: Reserve) => reservePayload(reserve, last(data.distributionManagers)?.id))

  if (data.bendSearch && isArray(data.bendSearch))
    return data.bendSearch.map((reserve: Reserve) => reservePayload(reserve, last(data.distributionManagers)?.id))

  return []
}
