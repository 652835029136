import React from 'react'
import { Button as ButtonRebass, Flex } from 'rebass/styled-components'
import Link from 'components/common/link'
// Interfaces
import { IButton } from './interfaces'
// Styles
import { Loader } from 'theme/ui'
import useTheme from 'hooks/common/useTheme'

const Button = React.forwardRef<HTMLButtonElement, IButton>(
  (
    {
      loading,
      disabled,
      children,
      href,
      size,
      newWindow = false,
      outlined = false,
      color = 'black',
      fontColor = 'white',
      rounded = true,
      onClick,
      buttonStyles,
      external,
      ...rest
    },
    ref
  ): React.ReactElement => {
    const [showLoader, setShowLoader] = React.useState<boolean>(false)
    const { radii } = useTheme()

    React.useEffect(() => {
      if (loading) {
        setShowLoader(true)
      }

      if (!loading && showLoader) {
        const timeout = setTimeout(() => {
          setShowLoader(false)
        }, 200)

        return () => {
          clearTimeout(timeout)
        }
      }
    }, [loading, showLoader])

    const styles = React.useMemo(() => {
      let sizes = {}

      switch (size) {
        default:
        case 'md':
          sizes = {
            px: 30,
            py: '10px'
          }
          break
        case 'sm':
          sizes = {
            px: 15,
            py: '6px'
          }
          break
        case 'lg':
          sizes = {
            px: 30,
            py: '10px',
            fontWeight: 700
          }
          break
        case 'xl':
          sizes = {
            px: 30,
            py: '11px',
            fontSize: 'md',
            lineHeight: 'lg',
            fontWeight: 700
          }
          break
        case 'icon':
          sizes = {
            p: 0,
            borderRadius: rounded ? radii['sm'] : 0,
            borderColor: color ? color : 'transparent'
          }
          break
        case 'icon-round':
          sizes = {
            p: 0,
            borderRadius: rounded ? radii['sm'] : 0,
            borderColor: color ? color : 'transparent'
          }
          break
      }
      return {
        color: outlined ? color : fontColor,
        bg: outlined ? 'transparent' : color,
        borderColor: color,
        borderRadius: rounded ? radii['sm'] : 0,
        ...sizes
      }
    }, [color, fontColor, outlined, radii, rounded, size])

    return !href ? (
      <ButtonRebass
        variant={outlined ? 'outlined' : 'default'}
        disabled={disabled}
        sx={{ ...styles, ...buttonStyles }}
        ref={ref}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => (loading ? event.preventDefault() : onClick && onClick(event))}
        {...rest}
      >
        <Flex justifyContent='center' alignItems='center'>
          {showLoader ? <Loader color={outlined ? color : fontColor} size={size === 'xl' ? 16 : 12} /> : children}
        </Flex>
      </ButtonRebass>
    ) : external ? (
      <ButtonRebass
        as='a'
        href={href}
        variant={outlined ? 'outlined' : 'default'}
        target={newWindow ? '_blank' : undefined}
        rel={newWindow ? 'noopener noreferrer' : undefined}
        sx={{ ...styles, ...buttonStyles, '&:hover': { textDecoration: 'none' } }}
        ref={ref}
        {...rest}
      >
        <Flex justifyContent='center' alignItems='center'>
          {showLoader ? <Loader color={outlined ? color : fontColor} size={size === 'xl' ? 16 : 12} /> : children}
        </Flex>
      </ButtonRebass>
    ) : (
      <Link href={href} passHref>
        <ButtonRebass
          as='a'
          variant={outlined ? 'outlined' : 'default'}
          target={newWindow ? '_blank' : undefined}
          rel={newWindow ? 'noopener noreferrer' : undefined}
          sx={{ ...styles, ...buttonStyles, '&:hover': { textDecoration: 'none' } }}
          onClick={onClick}
          ref={ref}
          {...rest}
        >
          <Flex justifyContent='center' alignItems='center' height='100%'>
            {showLoader ? <Loader color={outlined ? color : fontColor} size={size === 'xl' ? 16 : 12} /> : children}
          </Flex>
        </ButtonRebass>
      </Link>
    )
  }
)

export default Button
