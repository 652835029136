import { useCallback } from 'react'
import { auctionETH, batchBorrowETH, batchRepayETH, borrowETH, depositETH, liquidateETH, redeemETH, repayETH, withdrawETH } from '../utils/IWETHGateway'
import { useWethGatewayContract } from './'

export const useDepositETH = (WethGateway_address: string) => {
  const contract = useWethGatewayContract(WethGateway_address)

  const handleAction = useCallback(
    async (amount: string, onBehalfOf_address: string, referralCode: string) => {
      try {
        const tx = await depositETH(contract, amount, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onDepositETH: handleAction }
}

export const useWithdrawETH = (WethGateway_address: string) => {
  const contract = useWethGatewayContract(WethGateway_address)

  const handleAction = useCallback(
    async (amount: string, to_address: string) => {
      try {
        const tx = await withdrawETH(contract, amount, to_address)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onWithdrawETH: handleAction }
}

export const useBorrowETH = (WethGateway_address: string) => {
  const contract = useWethGatewayContract(WethGateway_address)

  const handleAction = useCallback(
    async (amount: string, nftAsset_address: string, nftTokenId: string, onBehalfOf_address: string, referralCode: string) => {
      try {
        const tx = await borrowETH(contract, amount, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onBorrowETH: handleAction }
}

export const useRepayETH = (WethGateway_address: string) => {
  const contract = useWethGatewayContract(WethGateway_address)

  const handleAction = useCallback(
    async (nftAsset_address: string, nftTokenId: string, amount: string) => {
      try {
        const tx = await repayETH(contract, nftAsset_address, nftTokenId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onRepayETH: handleAction }
}

/**
 * It takes in the address of the WETH Gateway contract, and returns a function that takes in the
 * address of the NFT asset, the token ID of the NFT, the amount of ETH to redeem, the bid fine, and
 * the value of the transaction, and returns a transaction object
 * @param {string} WethGateway_address - The address of the WethGateway contract
 * @returns The function onRedeemETH is being returned.
 */
export const useRedeemETH = (WethGateway_address: string) => {
  const contract = useWethGatewayContract(WethGateway_address)

  const handleAction = useCallback(
    async (nftAsset_address: string, nftTokenId: string, amount: string, bidFine: string, value: string) => {
      if (!contract) return
      try {
        const tx = await redeemETH(contract, nftAsset_address, nftTokenId, amount, bidFine, value)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onRedeemETH: handleAction }
}

export const useLiquidateETH = (WethGateway_address: string) => {
  const contract = useWethGatewayContract(WethGateway_address)

  const handleAction = useCallback(
    async (nftAsset_address: string, nftTokenId: string, amount: string) => {
      try {
        const tx = await liquidateETH(contract, nftAsset_address, nftTokenId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onLiquidateETH: handleAction }
}

export const useAuctionETH = (WethGateway_address: string) => {
  const contract = useWethGatewayContract(WethGateway_address)

  const handleAction = useCallback(
    async (nftAsset_address: string, nftTokenId: string, amount: string, onBehalfOf_address: string) => {
      try {
        const tx = await auctionETH(contract, nftAsset_address, nftTokenId, amount, onBehalfOf_address)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onAuctionETH: handleAction }
}

/**
 * It's a function that takes in an array of amounts, an array of nftAsset_addresses, an array of
 * nftTokenIds, an onBehalfOf_address, and a referralCode, and returns a transaction
 * @param {string} WethGateway_address - The address of the WETH Gateway contract
 * @returns The function onBatchBorrowETH is being returned.
 */
export const useBatchBorrowETH = (WethGateway_address: string) => {
  const contract = useWethGatewayContract(WethGateway_address)

  const handleAction = useCallback(
    async (amounts: string[], nftAsset_address: string[], nftTokenId: string[], onBehalfOf_address: string, referralCode: string) => {
      if (!contract) return
      try {
        const tx = await batchBorrowETH(contract, amounts, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onBatchBorrowETH: handleAction }
}

/**
 * It takes in an array of NFT addresses, an array of NFT token IDs, an array of amounts, and a value,
 * and returns a transaction
 * @param {string} WethGateway_address - The address of the WETH Gateway contract
 * @returns an object with a key of onBatchRepayETH and a value of handleAction.
 */
export const useBatchRepayETH = (WethGateway_address: string) => {
  const contract = useWethGatewayContract(WethGateway_address)

  const handleAction = useCallback(
    async (nftAsset_address: string[], nftTokenId: string[], amount: string[], value: string) => {
      if (!contract) return
      try {
        const tx = await batchRepayETH(contract, nftAsset_address, nftTokenId, amount, value)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onBatchRepayETH: handleAction }
}
