import React from 'react'
import { Flex } from 'rebass/styled-components'
import useTheme from 'hooks/common/useTheme'
import { ILabel } from './interfaces'

const Label: React.FC<ILabel> = ({ children, outlined, size, color, backgroundColor, ...restprops }): React.ReactElement => {
  const { fontSizes, fontWeight: fontWeights } = useTheme()
  const px = React.useMemo(() => {
    switch (size) {
      default:
      case 'md':
        return [15, 15, 15, 20]
      case 'xl':
        return [20, 20, 20, 30]
      case 'sm':
        return [6]
    }
  }, [size])
  const py = React.useMemo(() => {
    switch (size) {
      default:
      case 'md':
        return '10px'
      case 'xl':
        return 16
      case 'sm':
        return '4px'
    }
  }, [size])
  const fontSize = React.useMemo(() => {
    switch (size) {
      default:
      case 'md':
        return fontSizes.sm
      case 'xl':
        return fontSizes.lg
      case 'sm':
        return fontSizes.sm
    }
  }, [fontSizes.lg, fontSizes.sm, size])
  const fontWeight = React.useMemo(() => {
    switch (size) {
      default:
      case 'md':
        return fontWeights.regular
      case 'xl':
        return fontWeights.bold
      case 'sm':
        return fontWeights.bold
    }
  }, [fontWeights.bold, fontWeights.regular, size])
  switch (outlined) {
    default:
    case false:
      return (
        <Flex
          {...restprops}
          sx={{
            px,
            py,
            bg: backgroundColor ? backgroundColor : 'primary',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: backgroundColor ? backgroundColor : 'primary',
            color: color ? color : 'white',
            fontSize,
            lineHeight: fontSize,
            fontWeight,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'sm',
            ...restprops.sx
          }}
        >
          {children}
        </Flex>
      )
    case true:
      return (
        <Flex
          {...restprops}
          sx={{
            px,
            py,
            bg: 'transparent',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: color ? color : 'primary',
            color: color ? color : 'primary',
            fontSize,
            lineHeight: fontSize,
            fontWeight,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'sm',
            flex: 1,
            ...restprops.sx
          }}
        >
          {children}
        </Flex>
      )
  }
}

export default Label
