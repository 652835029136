import { ChainId } from 'wallet-module'
import MULTICALL_ABI from './abis/multicall.json'
const MULTICALL_NETWORKS = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BSC]: '0xe348b292e8eA5FAB54340656f3D374b259D658b8',
  [ChainId.BSC_TESTNET]: '0xe348b292e8eA5FAB54340656f3D374b259D658b8',
  [ChainId.OKEX]: '0x10e4d06de48a0433ca1aade41bef945ff54652d0',
  [ChainId.OKEX_TESTNET]: '0xBd09DF438AccA57476BcFe70A333B3204DB4C6e7',
  [ChainId.HECO]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.HECO_TESTNET]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821'
}
export { MULTICALL_ABI, MULTICALL_NETWORKS }
export const NEVER_RELOAD = {
  blocksPerFetch: Infinity
}
