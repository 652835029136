import { ApolloClient, DefaultOptions, gql, HttpLink, InMemoryCache, split } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { LS_TOKEN, COOKIE_USER_DENIED_LOGIN } from 'constants/index'
import { getCookie } from 'cookies-next'
import useVerifyAddressModal from 'hooks/common/useVerfiyAddressModal'
import { useActiveWeb3React } from 'modules/wallet-module/lib/esm'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { DrawerContext } from 'theme/ui'
import { getEndpoint } from 'utils'
import usePlatform from '../../../contexts/platform'
import { UserReducer, useUserContext } from '../../../contexts/user'
import decode, { JwtPayload } from 'jwt-decode'
import moment from 'moment'

const ValidateToken: React.FC = () => {
  const { token } = useUserContext()
  const { setUnreadedNotifications } = usePlatform()
  const { account } = useActiveWeb3React()
  const handleVerifyModal = useVerifyAddressModal()
  const { refreshToken, dispatch } = useUserContext()
  const { isOpen } = useContext(DrawerContext)

  const httpLinkBendNftApi = useMemo(() => new HttpLink({ uri: getEndpoint('BEND_NFTAPI') }), [])
  const wsLinkNftApi = useMemo(() => {
    return typeof window !== 'undefined' || !token
      ? new WebSocketLink(
          new SubscriptionClient(getEndpoint('BEND_NFTAPI_WS') + `?token=${token}`, {
            lazy: true,
            reconnect: false
          })
        )
      : httpLinkBendNftApi
  }, [httpLinkBendNftApi, token])

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors }: ErrorResponse) => {
        if (!!graphQLErrors?.length) {
          graphQLErrors.map(graphQLError => {
            console.log('❌ GraphQL error:', graphQLError.message)
          })
        }
      }),
    []
  )

  const defaultOptions: DefaultOptions = useMemo(
    () => ({
      watchQuery: {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      reconnect: false
    }),
    []
  )

  const splitLinkNft = useMemo(() => {
    return typeof window !== 'undefined'
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query)
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          },
          wsLinkNftApi,
          httpLinkBendNftApi
        )
      : httpLinkBendNftApi
  }, [httpLinkBendNftApi, wsLinkNftApi])

  const clientNftApi = useMemo(() => {
    return new ApolloClient({
      ssrMode: typeof window === 'undefined',
      link: errorLink.concat(splitLinkNft),
      cache: new InMemoryCache(),
      defaultOptions
    })
  }, [defaultOptions, errorLink, splitLinkNft])

  const subscriptionRef = useRef<any>()
  const tokenRef = useRef<string | null>()

  useEffect(() => {
    // if token does not exist, but current exists, unsubscribe and set current to null
    if (!token && subscriptionRef.current) {
      subscriptionRef.current.unsubscribe()
      subscriptionRef.current = null
      setUnreadedNotifications(0)
    }

    if (!token || !clientNftApi) return

    // if token changed, unsubscribe and set current to null
    if (tokenRef.current !== token && subscriptionRef.current) {
      subscriptionRef.current.unsubscribe()
      subscriptionRef.current = null
      setUnreadedNotifications(0)
    }
    try {
      subscriptionRef.current = clientNftApi
        .subscribe({
          query: gql`
            subscription UnreadCount {
              unreadCount {
                count
              }
            }
          `
        })
        .subscribe(({ data }: any) => {
          setUnreadedNotifications(data.unreadCount.count > 99 ? '99+' : data.unreadCount.count)
          tokenRef.current = token
        })
    } catch (error) {
      // if error and current exists, unsubscribe and set current to null
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }
      setUnreadedNotifications(0)
      console.log('subscription error', error)
    }

    return () => {
      // unsubscribe on unmount
      if (subscriptionRef.current) subscriptionRef.current.unsubscribe()
      setUnreadedNotifications(0)
    }
  }, [clientNftApi, setUnreadedNotifications, token])

  /* A function that is used to validate the token or refresh */
  const validateToken = useCallback(
    (token: string | null) => {
      if (!token) {
        setTimeout(() => {
          handleVerifyModal({ expired: false })
        }, 1500)
        return
      }

      const jwt: JwtPayload = decode(token)

      if (jwt?.exp && jwt.exp < moment().unix()) {
        console.log('token expired', new Date().toLocaleTimeString())

        if (subscriptionRef.current) {
          subscriptionRef.current.unsubscribe()
          subscriptionRef.current = null
        }

        window.localStorage.removeItem(LS_TOKEN)
        dispatch({
          action: UserReducer.refreshToken,
          payload: {
            token: undefined
          }
        })
        setUnreadedNotifications(0)
        handleVerifyModal({ expired: true })

        return
      }

      if (jwt?.exp && jwt.exp - moment().unix() < 3600) {
        if (subscriptionRef.current) {
          subscriptionRef.current.unsubscribe()
          subscriptionRef.current = null
        }

        refreshToken(token)
          .then((refreshedToken: string) => {
            console.log('token refreshed', new Date().toLocaleTimeString())
            window.localStorage.setItem(LS_TOKEN, refreshedToken)
            dispatch({
              action: UserReducer.refreshToken,
              payload: {
                token: refreshedToken
              }
            })
          })
          .catch((error: any) => {
            console.log('refresh token error', error)
            window.localStorage.removeItem(LS_TOKEN)
            if (subscriptionRef.current) {
              subscriptionRef.current.unsubscribe()
              subscriptionRef.current = null
            }
            dispatch({
              action: UserReducer.refreshToken,
              payload: {
                token: undefined
              }
            })
            setUnreadedNotifications(0)
            handleVerifyModal({ expired: false })
          })

        return
      }

      return
    },
    [dispatch, handleVerifyModal, refreshToken, setUnreadedNotifications]
  )

  /* Checking if the user has a token, and if they do, it will validate the token. */
  useEffect(() => {
    if (!account) return
    if (isOpen) return
    if (!!getCookie(COOKIE_USER_DENIED_LOGIN)) return

    const token = window.localStorage.getItem(LS_TOKEN)
    validateToken(token)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isOpen])

  /* Checking if the user has a token, and if they do, it will validate the token. */
  useEffect(() => {
    window.onfocus = () => {
      if (token) {
        validateToken(token)
      }
    }
  }, [token, validateToken])

  return null
}

export default ValidateToken
