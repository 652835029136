import { useUserContext } from 'contexts/user'
import { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import { Button } from 'theme/ui'
import { HEADER_HEIGHT } from '../header'
import { getCookie, setCookies } from 'cookies-next'
import moment from 'moment'
import { useTranslation } from 'next-i18next'
import { useActiveWeb3React } from 'modules/wallet-module'

const COOKIE_PROFILE_MENU_NOTIFICATION = 'profile-menu-notification-displayed'

const NewProfileMenuNotification: React.FC = () => {
  const { t } = useTranslation('common')
  const [displayMessage, setDisplayMessage] = useState(false)
  const { isLoggedIn } = useUserContext()
  const currentTime = moment().unix()
  const { account } = useActiveWeb3React()

  const handleCloseMessage = useCallback(() => {
    if (!account) return
    setCookies(COOKIE_PROFILE_MENU_NOTIFICATION, 'true', {
      expires: moment().add(1, 'month').toDate()
    })
    document.body.classList.remove('modal-open')
    setDisplayMessage(false)
  }, [account])

  useEffect(() => {
    if (currentTime > 1666008000 || !account || !isLoggedIn) return
    const cookie = getCookie(COOKIE_PROFILE_MENU_NOTIFICATION) as Boolean
    if (!!!cookie) {
      setDisplayMessage(true)
      document.body.classList.add('modal-open')
    }
  }, [displayMessage, currentTime, account, isLoggedIn])

  return isLoggedIn && displayMessage ? (
    <Flex
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 10000
      }}
    >
      <Flex sx={{ width: '100%' }}>
        <Flex
          sx={{
            width: '100%',
            height: HEADER_HEIGHT,
            alignItems: 'flex-end'
          }}
        >
          <Flex
            sx={{
              width: ['calc(100% - 115px)', 'calc(100% - 115px)', 'calc(100% - 165px)'],
              height: HEADER_HEIGHT,
              bg: 'backdrop'
            }}
          />
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: HEADER_HEIGHT
            }}
          >
            <Flex
              sx={{
                width: 16,
                height: 16,
                bg: 'backdrop'
              }}
            >
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderTopLeftRadius: 'sm',
                  overflow: 'hidden',
                  bg: 'white'
                }}
              />
            </Flex>
            <Flex
              sx={{
                width: 16,
                height: 16,
                bg: 'backdrop'
              }}
            >
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderBottomLeftRadius: 'sm',
                  overflow: 'hidden',
                  bg: 'white'
                }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          sx={{
            position: 'absolute',
            top: HEADER_HEIGHT,
            left: 0,
            width: '100%',
            height: '100vh',
            bg: 'backdrop'
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: `calc(${HEADER_HEIGHT}px + 20px)`,
            right: 15,
            width: 230,
            padding: 20,
            bg: 'blue.100',
            borderRadius: 'sm',
            fontSize: 'sm',
            lineHeight: 'xl',
            '&::before': {
              content: "''",
              display: 'block',
              position: 'absolute',
              left: ['calc(162.5px - 5px)', 'calc(162.5px - 5px)', 'calc(115px - 5px)'],
              top: [-9],
              borderBottom: '10px solid',
              borderBottomColor: 'blue.100',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent'
            }
          }}
        >
          <Text color='primary' fontWeight='bold'>
            {t('label.attention')}
          </Text>

          <Text mt={12} fontWeight='bold'>
            {t('label.text1')}
          </Text>

          <Text mt={12}>{t('label.text2')}</Text>
          <Flex sx={{ justifyContent: 'center', mt: 20 }}>
            <Button color='primary' size='sm' onClick={handleCloseMessage}>
              {t('label.close')}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  ) : null
}

export default NewProfileMenuNotification
