import React from 'react'
import Link from 'components/common/link'
import { Flex, Box, Text, Image } from 'rebass/styled-components'
import { Button } from 'theme/ui'
import useTheme from 'hooks/common/useTheme'

interface OptionProps {
  link?: string | null
  onClick?: any
  color: string
  header: React.ReactNode
  icon: any
  active?: boolean
  id: string
}

export const Option: React.FC<OptionProps> = ({ link = null, onClick = null, header, icon, id }) => {
  const { colors } = useTheme()
  const content = (
    <Button id={id} color='black' onClick={onClick} width='100%' outlined>
      <Flex alignItems='center' justifyContent='space-between' width='100%'>
        <Flex flexDirection='column' alignItems='flex-start'>
          <Text fontSize='lg' fontWeight='bold' color='black'>
            {header}
          </Text>
        </Flex>
        <Box
          sx={{
            '.wallet-icon': {
              bg: colors.white,
              padding: '3px !important'
            }
          }}
        >
          <Image src={icon} alt='Icon' width={26} height={26} className='wallet-icon' />
        </Box>
      </Flex>
    </Button>
  )
  if (link) {
    return <Link href={link}>{content}</Link>
  }

  return content
}

export default Option
