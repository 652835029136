import { VerifyAddress } from 'components/common/verify-address'
import { COOKIE_USER_DENIED_LOGIN } from 'constants/index'
import { useUserContext } from 'contexts/user'
import { setCookies } from 'cookies-next'
import { useCallback, useContext } from 'react'
import { Modal, ModalContext } from 'theme/ui'

type HandleVerifyModalProps = {
  expired?: boolean
}

const useVerifyAddressModal = () => {
  const { handleLogin } = useUserContext()
  const { onPresent, onDismiss } = useContext(ModalContext)
  const handleVerifyModal = useCallback(
    ({ expired = false }: HandleVerifyModalProps) => {
      onPresent(
        <Modal noLayoutStyle width={360} padding={10}>
          <VerifyAddress
            mt={-20}
            mb={20}
            mx={-10}
            expired={expired}
            onClick={async () => {
              const login: any = await handleLogin()
              // console.log(login)
              if (login) {
                onDismiss()
                return
              }
              const now = new Date()
              setCookies(COOKIE_USER_DENIED_LOGIN, 'true', { expires: new Date(now.setDate(now.getDate() + 30)) })
            }}
          />
        </Modal>,
        {
          onClose: () => {
            const now = new Date()
            setCookies(COOKIE_USER_DENIED_LOGIN, 'true', { expires: new Date(now.setDate(now.getDate() + 30)) })
          },
          preventBgClose: true
        }
      )
    },
    [handleLogin, onDismiss, onPresent]
  )

  return handleVerifyModal
}

export default useVerifyAddressModal
