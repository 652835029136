import { createContext, FC, useContext } from 'react'

type RefetchContextType = {
  refetch: () => void
}

type RefetchProviderProps = {
  refetch: () => void
}

const RefetchContext = createContext<RefetchContextType>({
  refetch: () => {}
})

export const useRefetchContext = () => useContext(RefetchContext)

const RefetchProvider: FC<RefetchProviderProps> = ({ children, refetch }) => <RefetchContext.Provider value={{ refetch }}>{children}</RefetchContext.Provider>

export default RefetchProvider
