import { useMemo } from 'react'
import { OperationVariables } from '@apollo/client'
import { useActiveWeb3React } from 'modules/wallet-module'
import { getNftImageUrl } from 'utils'
import { DocumentNode } from 'graphql'
import BigNumber from 'bignumber.js'
import { LoanState } from 'constants/types'
import useBendQuery from 'modules/bend/hooks/bend-query'

type Borrow = {
  id: string
  loanId: string
  nftAsset: {
    underlyingAsset: string
    name: string
  }
  image: string
  imageType: string
  nftTokenId: string
  assetIcon: string
  assetName: string
  reserveAsset: {
    underlyingAsset: string
    variableBorrowRate: BigNumber
  }
  borrowed: BigNumber
  healthFactor: number
  state: LoanState
  availableToBorrow: BigNumber
  currentAmount: BigNumber
  order: {
    id: string
    status: string
    nftItem: {
      collectionAddress: string
      tokenID: string
    }
  }
}

type UseBorrowsProps = {
  query?: DocumentNode
  variables?: OperationVariables
}

export const useBorrows = ({ variables = {} }: UseBorrowsProps) => {
  const { account } = useActiveWeb3React()
  const loans = useBendQuery(
    'Loans',
    { account, ...variables },
    {
      enabled: !!account,
      onError: error => {
        console.log('❌ get user borrows graphql error', error)
      }
    }
  )

  return {
    borrows: useMemo(
      () => ({
        data: loans?.data?.map((borrow: Borrow) => ({
          key: borrow.id,
          loanId: borrow.loanId,
          underlyingAsset: borrow.nftAsset.underlyingAsset,
          tokenId: borrow.nftTokenId,
          assetIcon: getNftImageUrl(borrow.image, borrow.imageType),
          assetName: borrow.nftAsset.name,
          apy: new BigNumber(borrow.reserveAsset.variableBorrowRate),
          reserveAsset: borrow.reserveAsset.underlyingAsset,
          borrowed: new BigNumber(borrow.currentAmount),
          healthFactor: borrow.healthFactor,
          state: borrow.state,
          availableToBorrow: new BigNumber(borrow.availableToBorrow),
          order: borrow.order
        })),
        isLoading: loans?.isLoading,
        isError: loans?.isError,
        error: loans?.error
      }),
      [loans?.data, loans?.error, loans?.isError, loans?.isLoading]
    )
  }
}
