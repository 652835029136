import { JsonRpcProvider } from '@ethersproject/providers'
import { nftImageUrlType } from 'constants/index'
import { LoanState } from 'constants/types'

type BigInt = string

export type Collection = {
  name: string
  symbol: string
  openseaImageURL: string
}

export type NftAsset = {
  symbol: string
  name: string
  nftAsset: string
  underlyingAsset: string
  baseLTVasCollateral: BigInt
  liquidationThreshold: BigInt
  price: BigInt
}

export type Collaterals_NftOrder = {
  id: string
  status: string
  nftItem: {
    collectionAddress: string
    tokenID: string
  }
}

export type CollateralResult =
  | {
      nftId: string
      tokenID: string
      ownerAddress: string
      collectionAddress: string
      collection: Collection
      nftAsset: NftAsset
      floorPrice: string
      image: string
      imageType: string
      bnftToken: string
      isActive: boolean
      isFrozen: boolean
    }
  | null
  | undefined

export type NftItem = {
  nftId: string
  collectionAddress: string
  ownerAddress: string
  tokenID: string
  image: string
  imageType: nftImageUrlType
  nftAsset: NftAsset
  floorPrice: BigInt
  availableToBorrow: BigInt
  collection: Collection
  bnftToken: string
}

export type NftItemsResult = {
  nftItems: Array<NftItem>
  reserveId: string
  nftOrders: Array<Collaterals_NftOrder>
}

export type NftPrice = {
  priceInEth: BigInt
  oracle?: {
    usdPriceEth: BigInt
  }
}

export type Nfts = {
  id: string
  baseLTVasCollateral: BigInt
  price: NftPrice
  liquidationThreshold: BigInt
  name: string
  symbol: string
  underlyingAsset: string
  bnftToken: string
  isActive: boolean
  isFrozen: boolean
}

export type CollectionDetails = {
  nftId: string
  collectionAddress: string
  ownerAddress: string
  tokenID: string
  image: string
  imageType: nftImageUrlType
  nftAsset: NftAsset
  floorPrice: string
  availableToBorrow: string
  collection: Collection
  bnftToken: string
}

export type ReserveResult = {
  reserve: {
    id: string
    name: string
    underlyingAsset: string
    decimals: number
    variableBorrowRate: BigInt
    price: {
      priceInEth: BigInt
    }
  }
}

export enum OrderDirection {
  asc = 'asc',
  desc = 'desc'
}

export enum CollateralOrderBy {
  name = 'name',
  tokenID = 'tokenID',
  floorPrice = 'floorPrice',
  price = 'price',
  symbol = 'symbol'
}

export type CollateralsArgs = {
  account: string
  orderDirection: OrderDirection
  orderBy: CollateralOrderBy
  reserveId: string
  filter: Array<string>
}

export type ActiveAuctionsArgs = {
  account: string | null | undefined
  orderBy: ActiveAuctionsOrderBy
  orderDirection: OrderDirection
  nftAsset_in?: Array<string>
  provider?: JsonRpcProvider
}

export type Price = {
  priceInEth: BigInt
  oracle: {
    usdPriceEth: BigInt
  }
}

export type LoanByBidderAddress = {
  id: string
  loanId: string
  nftAsset: {
    liquidationThreshold: BigInt
    underlyingAsset: string
    auctionDuration: number
    symbol: string
    price: Price
  }
  nftTokenId: string
  state: LoanState
  bidderAddress: string
  bidPrice: BigInt
  currentAmount: BigInt
  reserveAsset: {
    underlyingAsset: string
    decimals: number
    price: Price
  }
  bidStartTimestamp: number
}

export type LoansByBidderAddressResult = {
  loans: LoanByBidderAddress[]
}

export type NftLoanByBidderAddress = {
  loanID: string
  state: LoanState
  healthFactor: number
  nftTokenID: string
  nftItem: {
    image: string
    imageType: string
    collection: {
      name: string
      address: string
      openseaImageURL: string
    }
  }
}

export type NftLoansResult = {
  loans: NftLoanByBidderAddress[]
}

export enum ActiveAuctionsOrderBy {
  name = 'name',
  tokenID = 'tokenID',
  floorPrice = 'floorPrice',
  borrowed = 'borrowed',
  healthFactor = 'healthFactor',
  bidPrice = 'bidPrice'
}

export enum DashboardNftsOrderBy {
  name = 'name',
  tokenID = 'tokenID',
  floorPrice = 'floorPrice',
  price = 'price',
  symbol = 'symbol'
}

export type DashboardNftsArgs = {
  account: string
  orderDirection: OrderDirection
  orderBy: DashboardNftsOrderBy
  reserveId: string
  filter: Array<string>
}

export type DashboardCollateralsNftItemResult = {
  collectionAddress: string
  tokenID: string
  collection: {
    name: string
    symbol: string
    nftData: {
      ltv: number
      isActive: boolean
      isFrozen: boolean
    }
    floorPrice: string
  }
}

export type DashboardCollateralsNftItemData = {
  nftItems: Array<DashboardCollateralsNftItemResult>
}
