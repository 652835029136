import { Label as LabelRebass, Textarea as TextareaRebass } from '@rebass/forms'
import { Flex } from 'rebass/styled-components'
// Hooks
import useTheme from 'hooks/common/useTheme'
// Interfaces
import { TextAreaProps } from './interfaces'
import { forwardRef } from 'react'

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { left, name, styles, labelStyles, size = 'lg', borderColor, disabled = false, errors, textAreaNotActive = false, textAreaValue, ...restprops },
    ref
  ): React.ReactElement => {
    const { darkMode, colors, fontSizes, fonts } = useTheme()

    switch (size) {
      default:
      case 'lg':
        return (
          <Flex
            height='100%'
            flexDirection='column'
            justifyContent='center'
            flex={1}
            sx={{
              width: '100%',
              borderColor: errors && errors[name!] ? 'red.1000' : borderColor ? borderColor : colors.black,
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: 'sm',
              transition: 'all ease-in-out 0.2s',
              ...styles
            }}
          >
            {left && (
              <Flex alignItems='center' justifyContent='space-between'>
                {left && (
                  <LabelRebass
                    color={errors && errors[name!] ? colors.red['1000'] : colors.grey['300']}
                    fontSize={fontSizes.sm}
                    width='auto'
                    ml={20}
                    htmlFor={name}
                    sx={{ lineHeight: fontSizes.sm, ...labelStyles }}
                  >
                    {left}
                  </LabelRebass>
                )}
              </Flex>
            )}
            <Flex alignItems='center' justifyContent={'space-between'}>
              {textAreaNotActive ? (
                <Flex mx={20} py={5} flex={1} alignItems='center' justifyContent={'space-between'}>
                  {textAreaValue}
                </Flex>
              ) : (
                <>
                  <TextareaRebass
                    disabled={disabled}
                    flex={1}
                    name={name}
                    {...restprops}
                    sx={{
                      width: '100%',
                      textAlign: 'left',
                      borderColor: 'transparent',
                      borderRadius: 'sm',
                      borderWidth: '1px',
                      px: 20,
                      py: '4px',
                      fontFamily: fonts.body,
                      fontSize: fontSizes.lg,
                      '&::placeholder': {
                        color: darkMode ? colors.grey[300] : colors.grey[300]
                      },
                      '&:focus': {
                        outlineStyle: 'none'
                      },
                      ...restprops?.sx
                    }}
                    ref={ref}
                  />
                </>
              )}
            </Flex>
          </Flex>
        )
    }
  }
)

export default TextArea
