import { ApolloQueryResult, gql } from '@apollo/client'
import BigNumber from 'bignumber.js'
import { clientBendProtocol, clientNftApi } from 'clients'
import isArray from 'lodash/isArray'
import { SimpleLoansData } from 'modules/bend/classes/SimpleLoansData'
import { LEND_POOL_ADDRESSES_PROVIDER } from 'modules/bend/constants'
import { NftActivityListNftType } from './get-marketplace-nft-activity'

export type GetNftAssetNftItemInfo = {
  type: 'in_loan' | 'original'
  ownerAddress: string
  collectionAddress: string
  tokenID: string
  nftItem: {
    tokenURIRaw: string
    storageImageUrl: string
    collection: {
      name: string
      floorPrice: BigNumber
      openseaImageURL: string
      creator: string
      releaseDate: string
      totalSupply: number
      royaltyFee: string
      nftData: {
        ltv: string
        liquidationThreshold: number
      }
    }
  }
  nftOrder: {
    id: string
    endTime: number
    price: BigNumber
    minPercentageToSeller: number
    status: 'created' | 'completed' | 'canceled' | 'dangling'
    makerAddress: string
  }
  loan: {
    subgraphID: string
    bidStartTimestamp: number
    currentAmount: string
    bidPrice: string
    state: 'created' | 'active' | 'auction' | 'repaid' | 'defaulted'
    bidderAddress: string
  } | null
}

export type GetNftAssetNftActivities = {
  key: string
  type: NftActivityListNftType
  fromAddress: string
  toAddress: string
  activityTime: string
  txHash: string
  nftOrder: {
    price: BigNumber
  }
}

export type GetNftAssetNftAsset = {
  nftItemInfo: GetNftAssetNftItemInfo
  availableToBorrow: string
  healthFactor: number
}

export async function getNftAsset(collectionAddress: string, tokenID: string): Promise<GetNftAssetNftAsset> {
  const { data }: ApolloQueryResult<GetNftAssetNftAsset> = await clientNftApi.query({
    query: gql`
      query NftAsset($collectionAddress: String, $tokenID: String) {
        nftItemInfo(collectionAddress: $collectionAddress, tokenID: $tokenID) {
          type
          ownerAddress
          collectionAddress
          tokenID
          nftItem {
            tokenURIRaw
            storageImageUrl
            collection {
              name
              floorPrice
              openseaImageURL
              creator
              releaseDate
              totalSupply
              royaltyFee
              nftData {
                ltv
                liquidationThreshold
              }
            }
          }
          nftOrder {
            id
            endTime
            price
            minPercentageToSeller
            status
            makerAddress
          }
          loan {
            subgraphID
            bidStartTimestamp
            currentAmount
            bidPrice
            state
            bidderAddress
          }
        }
      }
    `,
    variables: {
      collectionAddress,
      tokenID
    }
  })

  const LoansData = new SimpleLoansData([data.nftItemInfo.collectionAddress], [data.nftItemInfo.tokenID])
  await LoansData.init()
  const availableBorrowsInReserve = await LoansData.getAvailableBorrowsInReserve()

  let availableToBorrow = data.nftItemInfo.nftItem.collection.nftData
    ? new BigNumber(data.nftItemInfo.nftItem.collection.floorPrice)
        .dividedBy(1e18)
        .multipliedBy(new BigNumber(data.nftItemInfo.nftItem.collection.nftData.ltv).dividedBy(1e4))
        .toFixed()
    : new BigNumber(-1).toFixed()

  if (data.nftItemInfo.type === 'in_loan') {
    availableToBorrow = availableBorrowsInReserve.dividedBy(1e18).toFixed()
  }

  const floorPrice = new BigNumber(data.nftItemInfo.nftItem.collection.floorPrice).dividedBy(1e18)
  const liquidationThreshold = new BigNumber(data.nftItemInfo.nftItem.collection.nftData.liquidationThreshold).dividedBy(1e4)
  const currentAmount = new BigNumber(data.nftItemInfo?.loan?.currentAmount || 0).dividedBy(1e18)
  const healthFactor = floorPrice.multipliedBy(liquidationThreshold).dividedBy(currentAmount).dp(2).toNumber()

  return { ...data, availableToBorrow, healthFactor }
}

export type GetNftActivities = {
  key: string
  type: NftActivityListNftType
  fromAddress: string
  toAddress: string
  activityTime: string
  txHash: string
  nftOrder: {
    price: BigNumber
  }
}

type GetNftActivities_Data = {
  nftActivities: Array<GetNftActivities>
}

export const numberOfActivities = 10

/**
 * It returns an array of activities for a given NFT
 * @param {string | undefined} collectionAddress - The address of the collection that the NFT belongs
 * to.
 * @param {string | undefined} tokenID - The ID of the NFT you want to get the activities for.
 * @param [skip=0] - The number of activities to skip.
 * @returns An array of GetNftActivities
 */
export async function getNftActivities(collectionAddress: string | undefined, tokenID: string | undefined, skip = 0): Promise<Array<GetNftActivities>> {
  if (!collectionAddress || !tokenID) return []
  const {
    data: { nftActivities }
  }: ApolloQueryResult<GetNftActivities_Data> = await clientNftApi.query({
    query: gql`
      query NftActivities($first: Int!, $skip: Int, $collectionAddress: String, $tokenID: String) {
        nftActivities(
          first: $first
          skip: $skip
          collectionAddress: $collectionAddress
          tokenID: $tokenID
          type_in: [mint, transfer, bnft_mint, bnft_burn, sale, list, cancel_listing]
        ) {
          key
          type
          fromAddress
          toAddress
          activityTime
          txHash
          nftOrder {
            price
          }
        }
      }
    `,
    variables: {
      first: numberOfActivities,
      skip,
      collectionAddress,
      tokenID
    }
  })

  if (!isArray(nftActivities)) return []

  return nftActivities
}

export type AuctionHistory = {
  id: string
  bidPrice: string
  timestamp: number
  onBehalfOf: {
    id: string
  }
  loan: {
    loanId: string
  }
  reserve: {
    name: string
    symbol: string
    decimals: number
    underlyingAsset: string
  }
}

type Auctions = {
  auctions: Array<AuctionHistory>
}

export const numberOfAuctionHistoryItems = 10

export async function getNftBiddingHistory(nftAsset: string | undefined, tokenId: string | undefined, skip = 0): Promise<Array<AuctionHistory>> {
  if (!nftAsset || !tokenId) return []

  const {
    data: { auctions }
  }: ApolloQueryResult<Auctions> = await clientBendProtocol.query({
    query: gql`
      query AuctionHistory($nftAsset: Bytes!, $tokenId: String, $first: Int, $skip: Int) {
        auctions(where: { nftAsset: $nftAsset, nftTokenId: $tokenId }, orderDirection: desc, orderBy: timestamp, first: $first, skip: $skip) {
          id
          nftTokenId
          bidPrice
          timestamp
          onBehalfOf {
            id
          }
          loan {
            loanId
          }
          reserve {
            name
            symbol
            decimals
            underlyingAsset
          }
        }
      }
    `,
    variables: {
      nftAsset: nftAsset.toLowerCase() + LEND_POOL_ADDRESSES_PROVIDER.toLowerCase(),
      tokenId,
      first: numberOfAuctionHistoryItems,
      skip
    }
  })

  if (!isArray(auctions)) return []

  return auctions
}

type GetNftSampleNftAsset_Result = {
  collectionAddress: string
  tokenID: string
}

export const getNftSampleNftAsset = async (): Promise<Array<GetNftSampleNftAsset_Result>> => {
  const {
    data: { nftItems }
  } = await clientNftApi.query({
    query: gql`
      query NftItem {
        nftItems(first: 1, isInLendPool: true) {
          collectionAddress
          tokenID
        }
      }
    `
  })

  const [nft] = nftItems

  return [nft.collectionAddress, nft.tokenID]
}
