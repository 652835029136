import SpacerDash from 'components/common/spacer-dash'
import useTheme from 'hooks/common/useTheme'
import { Flex, Text, Image } from 'rebass/styled-components'
import { useTranslation } from 'next-i18next'
import { ethImagePath, SHOW_DECIMAL_PLACES } from 'constants/index'
import NumberFormat from 'components/common/number-format'
import { Spacer } from 'theme/ui'
import useResponsive from 'hooks/common/useResponsive'
import ApproveButton from '../details/approve-button'
import { useBorrowContext } from '../context'
import NftImage from 'components/common/nft-image'
import { useBatchBorrowApprove } from 'modules/bend/hooks/useBatchBorrow'
import { LEND_POOL, WETH_ADDRESS } from 'modules/bend/constants'

export type BnftItem = {
  collectionName: string
  name?: string
  tokenID: string
  address: string
  amount?: string
}

interface INftCollateral {
  nft: BnftItem
  buttonStateDelegate: any
  handleApproveDelegation: any
}

export const NftCollateral: React.FC<INftCollateral> = ({ nft, buttonStateDelegate, handleApproveDelegation }) => {
  const { colors } = useTheme()
  const { isTablet } = useResponsive()
  const { t: tc } = useTranslation('common')
  const { account, approvingList, setApproveList, approvedList, setApprovedList } = useBorrowContext()

  const { buttonState, handleApprove } = useBatchBorrowApprove(
    LEND_POOL,
    WETH_ADDRESS,
    account || '',
    nft.address,
    nft.tokenID,
    approvingList,
    setApproveList,
    approvedList,
    setApprovedList
  )
  return (
    <>
      {isTablet && (
        <Flex width='100%' justifyContent='flex-start'>
          <Flex alignItems='center' flex={0.4 / 4} maxWidth={50}>
            <Flex sx={{ borderRadius: 'sm', overflow: 'hidden', width: 34, height: 34 }}>
              <NftImage collectionAddress={nft.address} tokenId={nft.tokenID} width={34} height={34} />
            </Flex>
          </Flex>
          <Flex alignItems='flex-start' flex={1.6 / 4} flexDirection='column' lineHeight={1.5}>
            <Text fontSize='sm' color='grey.300'>
              {nft.name} #{nft.tokenID}
            </Text>
            <Text fontSize='md' fontWeight='bold'>
              {tc('label.debt-token-and-nft-token')}
            </Text>
          </Flex>
          <Flex alignItems='center' flex={1 / 4} justifyContent='flex-end'>
            <ApproveButton state={buttonStateDelegate} handleClick={handleApproveDelegation} />
          </Flex>
          <Flex alignItems='center' flex={1 / 4} justifyContent='flex-end'>
            <ApproveButton state={buttonState} handleClick={handleApprove} />
          </Flex>
        </Flex>
      )}
      {!isTablet && (
        <Flex width='100%' justifyContent='flex-start' flexDirection={'column'} alignItems='center'>
          <Flex width='100%' justifyContent={'space-between'} alignItems='center'>
            <Flex alignItems='flex-start' flexDirection={'column'}>
              <Text fontSize='md' fontWeight='bold'>
                {nft.name}
              </Text>
              <Text fontSize='md' fontWeight='bold'>
                #{nft.tokenID}
              </Text>
            </Flex>

            <Flex alignItems='center' maxWidth={70} sx={{ borderRadius: 'sm', overflow: 'hidden', width: 42, height: 42 }}>
              <Flex sx={{ borderRadius: 'sm', overflow: 'hidden', width: 42, height: 42 }}>
                <NftImage collectionAddress={nft.address} tokenId={nft.tokenID} width={42} height={42} />
              </Flex>
            </Flex>
          </Flex>
          <Flex width='100%' justifyContent={'center'} mt={30} mb={10} alignItems='center'>
            <Flex alignItems='center' fontWeight={'bold'}>
              <ApproveButton state={buttonStateDelegate} handleClick={handleApproveDelegation} />
            </Flex>
            <Spacer size='xl' />
            <Flex alignItems='center' fontWeight={'bold'}>
              <ApproveButton state={buttonState} handleClick={handleApprove} />
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex justifyContent={'center'} alignItems='center' sx={{ ':last-child': { display: 'none' } }}>
        <SpacerDash bgColor={colors.grey[100]} height={1} width='100%' />
      </Flex>
    </>
  )
}

export const NftCollateralSuccess: React.FC<any> = ({ nft }) => {
  const { colors } = useTheme()
  const { isTablet } = useResponsive()
  const { t: tc } = useTranslation('common')

  return (
    <>
      {isTablet && (
        <Flex width='100%' justifyContent='center'>
          <Flex alignItems='flex-start' flex={1} maxWidth={50}>
            <Flex sx={{ borderRadius: 'sm', overflow: 'hidden', width: 34, height: 34 }}>
              <NftImage collectionAddress={nft.address} tokenId={nft.tokenID} width={34} height={34} />
            </Flex>
          </Flex>
          <Flex alignItems='flex-start' flex={2 / 3} flexDirection='column' lineHeight={1.5}>
            <Text fontSize='sm' color='grey.300' fontWeight={'regular'}>
              {tc('label.collateral')}
            </Text>
            <Text fontSize='md' fontWeight='bold'>
              {nft.name} #{nft.tokenID}
            </Text>
          </Flex>
          <Flex alignItems='flex-end' flex={1 / 3} justifyContent='flex-end' fontWeight={'bold'} fontSize='md' flexDirection={'column'} lineHeight={1.5}>
            <Text fontSize='sm' color='grey.300' fontWeight={'normal'}>
              {tc('label.borrow-amount')}
            </Text>
            <NumberFormat
              number={Number(nft.amount)}
              format={SHOW_DECIMAL_PLACES}
              preffix={
                <Flex sx={{ borderRadius: 'sm', width: 16, height: 16, overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                  <Image src={ethImagePath} width={16} height={16} />
                </Flex>
              }
            />
          </Flex>
        </Flex>
      )}

      {!isTablet && (
        <Flex width='100%' alignItems='center' flexDirection={'column'}>
          <Flex width='100%' justifyContent={'space-between'} alignItems='center'>
            <Flex alignItems='flex-start' flexDirection={'column'}>
              <Text fontSize='md' fontWeight='bold'>
                {nft.name}
              </Text>
              <Text fontSize='md' fontWeight='bold'>
                #{nft.tokenID}
              </Text>
            </Flex>

            <Flex alignItems='center' maxWidth={70}>
              <Flex sx={{ borderRadius: 'sm', overflow: 'hidden', width: 42, height: 42 }}>
                <NftImage collectionAddress={nft.address} tokenId={nft.tokenID} width={42} height={42} />
              </Flex>
            </Flex>
          </Flex>
          <Flex width='100%' justifyContent={'space-between'} alignItems='center' mt={10} flex={1}>
            <Flex alignItems='center' justifyContent='flex-start' fontWeight={'bold'} flex={0.5} opacity={0.5}>
              <Text fontSize='sm' fontWeight={'normal'}>
                {tc('label.borrow-amount')}
              </Text>
            </Flex>
            <Flex alignItems='center' justifyContent='flex-end' flex={0.5} fontWeight='bold' fontSize='md'>
              <NumberFormat
                number={Number(nft.amount)}
                format={SHOW_DECIMAL_PLACES}
                preffix={
                  <Flex sx={{ borderRadius: 'sm', width: 16, height: 16, overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                    <Image src={ethImagePath} width={16} height={16} />
                  </Flex>
                }
              />
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex width='115%' justifyContent={'center'} mx={-40} alignItems='center' sx={{ ':last-child': { display: 'none' } }}>
        <SpacerDash bgColor={colors.grey[100]} height={1} width='100%' />
      </Flex>
    </>
  )
}
