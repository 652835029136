var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value)
          })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator['throw'](value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
import { Contract } from '@ethersproject/contracts'
import { AddressZero } from '@ethersproject/constants'
import { parseEther } from '@ethersproject/units'
import { isAddress } from '.'
// account is not optional
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked()
}
// account is optional
export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library
}
// account is optional
export function getContract(address, ABI, library, account) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return new Contract(address, ABI, getProviderOrSigner(library, account))
}
export function calculateGasMargin(value, addPercent = 20, recommendedMinLimit = 0) {
  const calc = value.add(value.mul(addPercent).div(100))
  //console.debug(`calculateGasMargin add ${addPercent}% to ${value} return ${calc} recommendedMinLimit ${recommendedMinLimit}, use recommended? ${(recommendedMinLimit > calc)}`)
  return recommendedMinLimit > calc ? recommendedMinLimit : calc
}
export const sendTransaction = (library, account, amount, to) =>
  __awaiter(void 0, void 0, void 0, function* () {
    const signer = getSigner(library, account)
    const estimatedGas = yield signer
      .estimateGas({
        value: parseEther(amount),
        to: to
      })
      .catch(e => {
        console.log('estimatedGas failed', e)
        return signer.estimateGas({
          value: parseEther(amount),
          to: to
        })
      })
    return signer
      .sendTransaction({
        gasLimit: calculateGasMargin(estimatedGas),
        value: parseEther(amount),
        to: to
      })
      .then(response => {
        return response
      })
      .catch(error => {
        console.debug('Failed to sendTransaction', error)
        throw error
      })
  })
