import { useQuery, UseQueryResult } from 'react-query'
import { clientBendProtocol, clientNftApi } from 'clients'
import { gql, ApolloQueryResult, OperationVariables } from '@apollo/client'
import { useActiveWeb3React } from 'modules/wallet-module'
import { getNftImageUrl, isPunk, parseCollectionAddress } from 'utils'
import { DocumentNode } from 'graphql'
import BigNumber from 'bignumber.js'
import { nftImageUrlType, PUNKS_SYMBOL } from 'constants/index'
import { CollateralOrderBy, Collaterals_NftOrder, CollectionDetails, NftItem, NftItemsResult, Nfts, OrderDirection, ReserveResult } from 'constants/types'
import { filter, find, get, isEmpty, orderBy as lodashOrderBy } from 'lodash'
import { MAX_BORROW_MULTIPLIER, WPUNKS_ADDRESS } from 'modules/bend/constants'
import moment from 'moment'

export type Collateral = {
  tokenID: string
  nftId: string
  collectionAddress: string
  image: string
  imageType: string
  floorPrice: BigNumber
  availableToBorrow: BigNumber
  collateralRatio: BigNumber
  liquidationRatio: BigNumber
  bnftToken: string
  nftAsset: {
    nftAsset: string
    underlyingAsset: string
    baseLTVasCollateral: number
    liquidationThreshold: BigNumber
  }
  collection: {
    name: string
    symbol: string
  }
  reserveAsset: {
    id: string
    underlyingAsset: string
    decimals: string
  }
  order: {
    id: string
    status: string
    nftItem: {
      collectionAddress: string
      tokenID: string
    }
  }
}

type UseCollateralsProps = {
  query?: DocumentNode
  reserveId: string
  variables?: OperationVariables
}

type UseCollateralProps = {
  query?: DocumentNode
  collectionAddress: string
  tokenId: string
  reserveId: string
  variables?: OperationVariables
}

// type CollateralResult = {
//   collateral: Collateral
// }

type NftItemResult = {
  nftItem: NftItem
}

export type UseCollateralResult = {
  key: string
  nftId: string
  underlyingAsset: string
  tokenId: string
  collectionAddress: string
  collectionName: string
  symbol: string
  assetIcon: string
  assetName: string
  floorPrice: BigNumber
  availableToBorrow: BigNumber
  reserveAsset: string
  reserveAssetId: string
  collateralRatio: BigNumber
  bnftToken: string
  liquidationRatio: BigNumber
}

type Collaterals_NftOrder_Data = {
  nftOrders: Array<Collaterals_NftOrder>
}

const NFTITEMS_BY_OWNER_ADDRESS = gql`
  query NftItemsByOwnerAddress($address: String!, $collectionAddress_in: [String!]) {
    nftItems(ownerAddress: $address, collectionAddress_in: $collectionAddress_in) {
      collectionAddress
      tokenID
      image
      imageType
      collection {
        name
        symbol
        openseaImageURL
      }
    }
  }
`

const QUERY_NFTS_BY_UNDERLYINGASSET = gql`
  query NftsByUnderlyingAsset($underlyingAssets: [Bytes!]) {
    nfts(where: { underlyingAsset_in: $underlyingAssets, isActive: true, isFrozen: false }) {
      id
      symbol
      name
      underlyingAsset
      liquidationThreshold
      baseLTVasCollateral
      bnftToken {
        id
      }
      price {
        priceInEth
      }
      isActive
      isFrozen
    }
  }
`

const NFTITEM_BY_COLLECTION_ADDRESS_TOKEN_ID = gql`
  query NftItemByCollectionAddressTokenId($collectionAddress: String!, $tokenID: String!) {
    nftItem(collectionAddress: $collectionAddress, tokenID: $tokenID) {
      collectionAddress
      tokenID
      image
      imageType
      collection {
        name
        symbol
        openseaImageURL
      }
    }
  }
`

const QUERY_RESERVE_BY_ID = gql`
  query ReserveById($reserveId: Bytes!) {
    reserve(id: $reserveId) {
      id
      name
      underlyingAsset
      decimals
      price {
        priceInEth
      }
    }
  }
`

const parseCollateral = (collateral: Collateral) => ({
  key: `${collateral.nftAsset?.nftAsset}${collateral.tokenID}`,
  nftId: collateral.nftId,
  underlyingAsset: collateral.nftAsset?.nftAsset,
  tokenId: collateral.tokenID,
  collectionAddress: collateral.collectionAddress,
  collectionName: collateral.collection.name,
  symbol: isPunk(collateral.collectionAddress) ? PUNKS_SYMBOL : collateral.collection.symbol,
  assetIcon: getNftImageUrl(collateral.image, collateral.imageType),
  assetName: collateral?.collection.name,
  floorPrice: new BigNumber(collateral.floorPrice).dividedBy(`1e${collateral.reserveAsset.decimals}`),
  availableToBorrow: new BigNumber(collateral.availableToBorrow).dividedBy(`1e${collateral.reserveAsset.decimals}`),
  reserveAsset: collateral?.reserveAsset.underlyingAsset,
  reserveAssetId: collateral?.reserveAsset.id,
  collateralRatio: new BigNumber(collateral.nftAsset.baseLTVasCollateral).dividedBy(1e2),
  bnftToken: collateral.bnftToken,
  liquidationRatio: new BigNumber(collateral.nftAsset.liquidationThreshold).dividedBy(1e2),
  order: collateral.order
})

const getCollateralDetails = async ({ nftItems, reserveId, nftOrders }: NftItemsResult) => {
  if (isEmpty(nftItems)) return []

  const {
    data: { nfts }
  }: ApolloQueryResult<{ nfts: Nfts[] }> = await clientBendProtocol.query({
    query: QUERY_NFTS_BY_UNDERLYINGASSET,
    variables: {
      underlyingAssets: nftItems.map((item: CollectionDetails) => {
        return parseCollectionAddress(item.collectionAddress)
      })
    }
  })

  const {
    data: { reserve }
  }: ApolloQueryResult<ReserveResult> = await clientBendProtocol.query({
    query: QUERY_RESERVE_BY_ID,
    variables: {
      reserveId
    }
  })

  return nftItems.map((nftItem: any) => {
    const currentNft = find(
      nfts,
      nft =>
        nft.underlyingAsset.toLowerCase() ===
        (isPunk(nftItem.collectionAddress.toLocaleLowerCase()) ? WPUNKS_ADDRESS.toLowerCase() : nftItem.collectionAddress.toLocaleLowerCase())
    )

    if (typeof currentNft === 'undefined') return

    const nftOrder = nftOrders.find(
      order =>
        nftItem.collectionAddress.toLowerCase() === order.nftItem.collectionAddress.toLowerCase() &&
        nftItem.tokenID === order.nftItem.tokenID &&
        order.status === 'created'
    )

    const floorPrice = new BigNumber(new BigNumber(currentNft!.price.priceInEth)).dividedBy(new BigNumber(reserve.price.priceInEth))
    const availableToBorrow = new BigNumber(new BigNumber(currentNft!.price.priceInEth))
      .dividedBy(new BigNumber(reserve.price.priceInEth))
      .multipliedBy(new BigNumber(currentNft!.baseLTVasCollateral).dividedBy(1e4))
      .multipliedBy(MAX_BORROW_MULTIPLIER)
    return {
      nftId: currentNft!.id,
      tokenID: nftItem.tokenID,
      ownerAddress: nftItem.ownerAddress,
      collectionAddress: nftItem.collectionAddress,
      name: nftItem.collection.name,
      symbol: nftItem.collection.symbol,
      collection: {
        name: nftItem.collection.name,
        symbol: nftItem.collection.symbol,
        openseaImageURL: nftItem.collection.openseaImageURL
      },
      nftAsset: {
        symbol: nftItem.collection.symbol,
        name: nftItem.collection.name,
        nftAsset: nftItem.collectionAddress,
        underlyingAsset: nftItem.collectionAddress,
        baseLTVasCollateral: currentNft!.baseLTVasCollateral,
        liquidationThreshold: currentNft!.liquidationThreshold,
        price: currentNft!.price.priceInEth
      },
      reserveAsset: {
        id: reserve.id,
        name: reserve.name,
        underlyingAsset: reserve.underlyingAsset,
        decimals: reserve.decimals,
        price: reserve.price.priceInEth
      },
      floorPrice: floorPrice.multipliedBy(1e18).dp(18, 1).toFixed(),
      availableToBorrow: availableToBorrow.multipliedBy(`1e${reserve.decimals}`).dp(18, 1).toFixed(),
      image: nftItem.image ? nftItem.image : '',
      imageType: nftItem.imageType ? nftItem.imageType : nftImageUrlType.ipfsUrl,
      bnftToken: currentNft!.bnftToken,
      isActive: currentNft!.isActive,
      isFrozen: currentNft!.isFrozen,
      order: isEmpty(nftOrder) ? null : nftOrder,
      orderBy: {
        price: Number(new BigNumber(currentNft!.price.priceInEth).dividedBy(1e18).dp(4, 1).toFixed()),
        floorPrice: Number(floorPrice.dp(4, 1).toFixed()),
        availableToBorrow: Number(availableToBorrow.dp(4, 1).toFixed()),
        name: nftItem.collection.name,
        symbol: nftItem.collection.symbol,
        collateralRatio: Number(new BigNumber(currentNft!.baseLTVasCollateral).dividedBy(1e2).toFixed()),
        tokenID: Number(nftItem.tokenID)
      }
    }
  })
}

export const useCollaterals = (props: UseCollateralsProps) => {
  const { account } = useActiveWeb3React()
  const collaterals: UseQueryResult<Array<UseCollateralResult>, any> = useQuery(
    ['get user collaterals', props?.reserveId, props?.variables, account],
    async () => {
      // if (!reserveId) return []
      const { reserveId } = props
      let { orderDirection, orderBy } = props?.variables || {}
      const { filter: collectionAddress_in } = props?.variables || {}

      if (typeof orderDirection === 'undefined') {
        orderDirection = OrderDirection.asc
      }

      if (typeof orderBy === 'undefined') {
        orderBy = CollateralOrderBy.name
      }

      const {
        data: { nftItems }
      }: ApolloQueryResult<NftItemsResult> = await clientNftApi.query({
        query: NFTITEMS_BY_OWNER_ADDRESS,
        variables: {
          address: account?.toLocaleLowerCase(),
          collectionAddress_in: collectionAddress_in ? collectionAddress_in.map((collection: any) => (isPunk(collection) ? WPUNKS_ADDRESS : collection)) : []
        }
      })

      if (isEmpty(nftItems)) {
        return []
      }

      const {
        data: { nftOrders }
      }: ApolloQueryResult<Collaterals_NftOrder_Data> = await clientNftApi.query({
        query: gql`
          query nftOrders($makerAddress: String, $endTime_gt: Int) {
            nftOrders(makerAddress: $makerAddress, endTime_gt: $endTime_gt) {
              id
              status
              nftItem {
                collectionAddress
                tokenID
              }
            }
          }
        `,
        variables: {
          makerAddress: account?.toLowerCase(),
          endTime_gt: moment().unix()
        }
      })

      const collaterals = await getCollateralDetails({
        nftItems,
        reserveId,
        nftOrders
      })

      const result = filter(lodashOrderBy(collaterals, [`orderBy.${orderBy}`], [orderDirection]), item => typeof item !== 'undefined')

      return result.map((collateral: any) => parseCollateral(collateral))
    },
    {
      enabled: !!account && !!props?.reserveId,
      onError: error => {
        console.log('❌ get user collaterals graphql error', error)
      }
    }
  )

  return {
    collaterals
  }
}

export const useCollateral = ({ query, collectionAddress, tokenId, reserveId, variables = {} }: UseCollateralProps) => {
  const collateralQuery: UseQueryResult<UseCollateralResult, any> = useQuery(
    ['get user collateral', collectionAddress, tokenId, reserveId, variables, query],
    async () => {
      if (!collectionAddress || !tokenId || !reserveId) return null
      const {
        data: { nftItem }
      }: ApolloQueryResult<NftItemResult> = await clientNftApi.query({
        query: NFTITEM_BY_COLLECTION_ADDRESS_TOKEN_ID,
        variables: {
          collectionAddress,
          tokenID: tokenId
        }
      })

      const {
        data: { nftOrders }
      }: ApolloQueryResult<Collaterals_NftOrder_Data> = await clientNftApi.query({
        query: gql`
          query nftOrders($collectionAddress: String, $tokenID: String, $endTime_gt: Int) {
            nftOrders(collectionAddress: $collectionAddress, tokenID: $tokenID, endTime_gt: $endTime_gt) {
              status
              nftItem {
                collectionAddress
                tokenID
              }
            }
          }
        `,
        variables: {
          collectionAddress,
          tokenID: tokenId,
          endTime_gt: moment().unix()
        }
      })

      const result: any = get(
        await getCollateralDetails({
          nftItems: [nftItem],
          reserveId,
          nftOrders
        }),
        0
      )

      return parseCollateral(result)
    },
    {
      enabled: !!collectionAddress && !!tokenId && !!reserveId,
      onError: error => {
        console.log('❌ get user collaterals graphql error', error)
      }
    }
  )

  return {
    collateral: collateralQuery
  }
}
