import { MotionFlex } from 'theme/ui'

import { BackdropProps } from '../types'

const Backdrop: React.FC<BackdropProps> = ({ children, onClick }) => {
  return (
    <MotionFlex
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        bg: 'backdrop',
        alignItems: ['flex-end', 'flex-end', 'center'],
        justifyContent: ['center'],
        overflowY: 'hidden',
        zIndex: 30000
      }}
    >
      {children}
    </MotionFlex>
  )
}

export default Backdrop
