import useResponsive from 'hooks/common/useResponsive'
import { createContext, useContext, useState } from 'react'

const initialState = {
  title: '',
  isMobile: false,
  setTitle: () => {}
}

interface MobileContextProps {
  title: string
  setTitle: React.Dispatch<string>
  isMobile: boolean
}

export const MobileContext = createContext<MobileContextProps>(initialState)

export const useMobile = () => useContext(MobileContext)

const MobileContextProvider: React.FC = ({ children }) => {
  const [title, setTitle] = useState(initialState.title)
  const { isTablet } = useResponsive()
  return (
    <MobileContext.Provider
      value={{
        title,
        setTitle,
        isMobile: !isTablet
      }}
    >
      {children}
    </MobileContext.Provider>
  )
}

export default MobileContextProvider
