import { useMemo, forwardRef } from 'react'
import Link from 'components/common/link'
import { useRouter } from 'next/router'
import { Flex, Text, Link as LinkRebass, FlexProps, Box } from 'rebass/styled-components'
import { IRoutes } from '../../../../constants/types'
import useTheme from 'hooks/common/useTheme'
import useResponsive from 'hooks/common/useResponsive'
import Icon from '../../icons/icon'
import { permalink } from 'constants/routes'
import { useTranslation } from 'next-i18next'
import isEmpty from 'lodash/isEmpty'
import DropDown from '../../../../components/common/dropw-down'
import useWindowPosition from 'hooks/common/useWindowPosition'
import { HEADER_HEIGHT, WINDOW_POSITION_TRIGGER } from 'components/common/header'
import SpacerDash from 'components/common/spacer-dash'
import BorrowButtonNav from 'theme/ui/common/nav-button/borrow-button-nav'

interface INavButton extends IRoutes {}

const NavButton: React.FC<INavButton> = props => {
  const { route, external, onClick, soon, isNew, type } = props
  const { isTouch } = useResponsive()
  const { t: tc } = useTranslation('common')
  return type === 'separator' ? null : external ? (
    <LinkRebass
      href={route}
      target='_blank'
      rel='noopener noreferrer'
      onClick={onClick}
      sx={{
        '&:hover': {
          opacity: 1
        }
      }}
    >
      <Box>
        <LinkComponent {...props} />
      </Box>
    </LinkRebass>
  ) : (
    <Flex alignItems={'center'}>
      {/* {children ? (
        <LinkComponent onClick={onClick} {...props} />
      ) : (
        <Link href={route} passHref>
          <LinkComponent onClick={onClick} as='a' {...props} />
        </Link>
      )} */}
      {isTouch ? <LinkComponent onClick={onClick} as='a' {...props} /> : <LinkComponent onClick={onClick} {...props} />}

      {soon && (
        <Flex
          mt={-40}
          ml={-30}
          sx={{
            bg: 'primary',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'sm',
            transform: 'rotate(25deg)',
            height: 14,
            width: 35
          }}
        >
          <Text fontSize={8}>{tc('label.soon').toUpperCase()}</Text>
        </Flex>
      )}
      {isNew && (
        <Flex
          mt={-30}
          ml={-33}
          sx={{
            bg: 'primary',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'sm',
            transform: 'rotate(20deg)',
            height: 14,
            width: 33
          }}
        >
          <Text fontSize={8} textAlign='center'>
            {tc('label.new').toUpperCase()}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default NavButton

const LinkComponent = forwardRef<HTMLElement, INavButton & FlexProps>(({ route, customRoute, icon, name, children, ...restprops }, ref) => {
  const { colors } = useTheme()
  const { pathname } = useRouter()
  const { isDesktop } = useResponsive()

  const activePathname = useMemo(() => {
    if (!pathname) return null
    if (pathname === permalink.home) return '/'
    const path = pathname.split('/')
    return `/${path[2]}`
  }, [pathname])

  const windowPosition = useWindowPosition()

  const isOnMyNfts = useMemo(() => {
    if (!pathname) return null
    if (pathname === permalink.home) return '/'
    const path = pathname.split('/')
    return `/${path[2]}/${path[3]}` === '/account/my-nfts'
  }, [pathname])

  return (
    <Flex sx={{ position: 'relative', flex: !isDesktop ? '0 0 74px' : undefined, justifyContent: 'center' }} ref={ref} {...restprops}>
      {children && !isEmpty(children) ? (
        <DropDown
          trigger={isDesktop ? 'hover' : 'click'}
          title={'More'}
          buttonComponent={
            route === '#' ? (
              <Flex
                sx={{
                  flexDirection: ['column', 'column', 'column', 'column', 'row'],
                  justifyContent: 'center',
                  alignItems: 'center',
                  px: [0, 0, 0, 14, 15],
                  height: windowPosition > WINDOW_POSITION_TRIGGER ? HEADER_HEIGHT - 20 : HEADER_HEIGHT,
                  '&:after': {
                    content: "''",
                    position: 'absolute',
                    left: activePathname === route ? 0 : '50%',
                    bottom: 0,
                    width: activePathname === route ? '100%' : 0,
                    height: 3,
                    bg: activePathname === route ? ['white', 'white', 'white', 'white', 'primary'] : 'transparent',
                    transition: 'all 0.3s'
                  },
                  '& svg': {
                    transition: 'all 0.3s',
                    color: activePathname === route ? 'primary' : 'grey.300'
                  },
                  '&:hover': {
                    cursor: 'pointer',
                    '&:after': {
                      left: 0,
                      width: '100%',
                      bg: ['white', 'white', 'white', 'white', 'primary']
                    },
                    '& svg': {
                      color: 'primary'
                    }
                  }
                }}
              >
                {isDesktop && icon ? <Icon icon={icon} size={20} /> : <Icon icon={icon} size={20} color={colors.white} />}
                <Text
                  as='span'
                  fontSize={['sm', 'sm', 'md']}
                  lineHeight='md'
                  fontWeight={['700']}
                  mt={['10px', '10px', '10px', '10px', 0]}
                  ml={[0, 0, 0, 0, 10]}
                  color={['white', 'white', 'white', 'white', 'black']}
                  textAlign={'center'}
                >
                  {name}
                </Text>
              </Flex>
            ) : (
              <Link href={customRoute ? customRoute : route} passHref>
                <Flex
                  as='a'
                  sx={{
                    flexDirection: ['column', 'column', 'column', 'column', 'row'],
                    justifyContent: 'center',
                    alignItems: 'center',
                    px: [0, 0, 0, 14, 15],
                    height: windowPosition > WINDOW_POSITION_TRIGGER ? HEADER_HEIGHT - 20 : HEADER_HEIGHT,
                    '&:after': {
                      content: "''",
                      position: 'absolute',
                      left: activePathname === route ? 0 : '50%',
                      bottom: 0,
                      width: activePathname === route ? '100%' : 0,
                      height: 3,
                      bg: activePathname === route ? ['white', 'white', 'white', 'white', 'primary'] : 'transparent',
                      transition: 'all 0.3s'
                    },
                    '& svg': {
                      transition: 'all 0.3s',
                      color: activePathname === route ? 'primary' : 'grey.300'
                    },
                    '&:hover': {
                      cursor: 'pointer',
                      '&:after': {
                        left: 0,
                        width: '100%',
                        bg: ['white', 'white', 'white', 'white', 'primary']
                      },
                      '& svg': {
                        color: 'primary'
                      }
                    }
                  }}
                >
                  {isDesktop && icon ? <Icon icon={icon} size={20} /> : <Icon icon={icon} size={20} color={colors.white} />}
                  <Text
                    as='span'
                    fontSize={['sm', 'sm', 'md']}
                    lineHeight='md'
                    fontWeight={['700']}
                    mt={['10px', '10px', '10px', '10px', 0]}
                    ml={[0, 0, 0, 0, 10]}
                    color={['white', 'white', 'white', 'white', 'black']}
                    textAlign={'center'}
                  >
                    {name}
                  </Text>
                </Flex>
              </Link>
            )
          }
          menuComponent={
            <Flex flexDirection='column'>
              {children.map(child => {
                return child.type === 'separator' ? (
                  <Box key={child.name} my={10}>
                    <SpacerDash width='100%' height={1} bgColor='grey.100' />
                  </Box>
                ) : child.external ? (
                  <LinkRebass
                    key={child.name}
                    href={child.route}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={child.onClick}
                    sx={{
                      '&:hover': {
                        opacity: 1
                      }
                    }}
                  >
                    <SubmenuButton child={child} />
                  </LinkRebass>
                ) : // MODAL BUTTON WHEN ON DASHBOARD MY NFTS
                isOnMyNfts && child.route === permalink.batchBorrow ? (
                  <BorrowButtonNav key={child.name} child={child} />
                ) : (
                  <Link key={child.name} href={child.route} passHref>
                    <SubmenuButton as='a' child={child} />
                  </Link>
                )
              })}
            </Flex>
          }
          menuStyle={{
            minWidth: 230,
            maxWidth: 280,
            borderRadius: 0,
            zIndex: 1001,
            top: windowPosition > WINDOW_POSITION_TRIGGER ? HEADER_HEIGHT - 20 : HEADER_HEIGHT,
            left: -10
          }}
          menuWrapperStyle={{
            bg: 'white',
            boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.16)',
            px: 20,
            borderRadius: 0
          }}
        />
      ) : (
        <Link key={name} href={route} passHref>
          <Flex
            as='a'
            sx={{
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              justifyContent: 'center',
              alignItems: 'center',
              px: [0, 0, 0, 14, 15],
              height: windowPosition > WINDOW_POSITION_TRIGGER ? HEADER_HEIGHT - 20 : HEADER_HEIGHT,
              '&:after': {
                content: "''",
                position: 'absolute',
                left: activePathname === `/[local]${route}` ? 0 : '50%',
                bottom: 0,
                width: activePathname === `/[local]${route}` ? '100%' : 0,
                height: 3,
                bg: activePathname === `/[local]${route}` ? ['white', 'white', 'white', 'white', 'primary'] : 'transparent',
                transition: 'all 0.3s'
              },
              '& svg': {
                transition: 'all 0.3s',
                color: activePathname === `/[local]${route}` ? 'primary' : 'grey.300'
              },
              '&:hover': {
                cursor: 'pointer',
                '&:after': {
                  left: 0,
                  width: '100%',
                  bg: ['white', 'white', 'white', 'white', 'primary']
                },
                '& svg': {
                  color: 'primary'
                }
              }
            }}
          >
            {isDesktop && icon ? <Icon icon={icon} size={20} /> : <Icon icon={icon} size={20} color={colors.white} />}
            <Text
              as='span'
              fontSize={['sm', 'sm', 'md']}
              lineHeight='md'
              fontWeight={['700']}
              mt={['10px', '10px', '10px', '10px', 0]}
              ml={[0, 0, 0, 0, 10]}
              color={['white', 'white', 'white', 'white', 'black']}
              textAlign={'center'}
            >
              {name}
            </Text>
          </Flex>
        </Link>
      )}
    </Flex>
  )
})

type SubmenuProps = { child: INavButton }

const SubmenuButton = forwardRef<HTMLElement, SubmenuProps & FlexProps>(({ child, ...restprops }, ref) => {
  const { t: tc } = useTranslation('common')
  const { pathname } = useRouter()

  const activePathname = useMemo(() => {
    if (!pathname) return null
    if (pathname === permalink.home) return '/'
    const path = pathname.split('/')
    if (
      `/${path[2]}/${path[3]}` === permalink.liquidity ||
      `/${path[2]}/${path[3]}` === permalink.liquidity ||
      `/${path[2]}/${path[3]}` === permalink.auction
    ) {
      return `/${path[2]}/${path[3]}/${path[4]}`
    }
    if (`/${path[2]}/${path[3]}/${path[4]}` === permalink.downpayment) {
      return `/${path[2]}/${path[3]}/${path[4]}`
    }
    if (`/${path[2]}/${path[3]}` === permalink.dashboard) {
      return `/${path[2]}/${path[3]}/${path[4]}`
    }
    if (`/${path[2]}/${path[3]}` === permalink.yielding) {
      return `/${path[2]}/${path[3]}/${path[4]}`
    }
    if (`/${path[2]}` === permalink.flashClaim || `/${path[2]}` === permalink.analytics) {
      return `/${path[2]}`
    }
    return `/${path[2]}/${path[3]}`
  }, [pathname])

  return (
    <Flex
      fontSize={['lg', null, null, null, 'md', 'md']}
      lineHeight='md'
      fontWeight={['700']}
      my={[10]}
      alignItems='center'
      color='grey.300'
      px={[10, 30, 30, 30, 0]}
      sx={{
        '& svg': {
          transition: 'all 0.3s',
          color: activePathname === `${child.route}` ? 'primary' : 'grey.300'
        },
        '&:hover': {
          cursor: 'pointer',
          '& svg': {
            transition: 'all 0.3s',
            color: 'primary'
          }
        }
      }}
      ref={ref}
      {...restprops}
    >
      <Icon icon={child.icon} size={20} />
      <Text ml={10} color={['black']}>
        {child.name}
      </Text>
      {child.soon && (
        <Flex
          sx={{
            bg: 'primary',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'sm',
            // height: 14,
            lineHeight: '14px',
            width: 35,
            ml: 10
          }}
        >
          <Text fontSize={8}>{tc('label.soon').toUpperCase()}</Text>
        </Flex>
      )}
      {child.isNew && (
        <Flex
          sx={{
            bg: 'primary',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'sm',
            // height: 14,
            lineHeight: '14px',
            width: 35,
            ml: 8
          }}
        >
          <Text fontSize={8}>{tc('label.new').toUpperCase()}</Text>
        </Flex>
      )}
    </Flex>
  )
})
