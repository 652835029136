import { useCallback } from 'react'
import { offerPunkForSaleToAddress } from '../utils/CryptoPunk'
import {
  borrowETH,
  borrow,
  auctionETH,
  auction,
  liquidate,
  liquidateETH,
  redeemETH,
  redeem,
  repayETH,
  repay,
  batchBorrowETH,
  batchBorrow,
  batchRepayETH,
  batchRepay
} from '../utils/IPunkGateway'
import { setApprovalForAll } from '../utils/WPunk'
import { usePunkGatewayContract } from './'
import { useCryptoPunkContract, useWPunkContract } from './useContract'

export const usePunkBorrowETH = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (amount: string, punkId: string, onBehalfOf_address: string, referralCode: string) => {
      try {
        const tx = await borrowETH(contract, amount, punkId, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onPunkBorrowETH: handleAction }
}

export const usePunkBorrow = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (asset_address: string, amount: string, punkId: string, onBehalfOf_address: string, referralCode: string) => {
      try {
        const tx = await borrow(contract, asset_address, amount, punkId, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onPunkBorrow: handleAction }
}

export const useOfferPunkForSaleToAddress = (CryptoPunk_address: string) => {
  //console.log('useOfferPunkForSaleToAddress CryptoPunk_address', CryptoPunk_address)
  const contract = useCryptoPunkContract(CryptoPunk_address)
  //console.log('useOfferPunkForSaleToAddress contract', contract)

  const handleAction = useCallback(
    async (punkId: string, onBehalfOf_address: string) => {
      try {
        const tx = await offerPunkForSaleToAddress(contract, '0', punkId, onBehalfOf_address)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onApprovePunk: handleAction }
}

export const useAuctionETHPunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (amount: string, punkId: string, onBehalfOf_address: string) => {
      try {
        const tx = await auctionETH(contract, punkId, amount, onBehalfOf_address)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onAuctionETHPunk: handleAction }
}

export const useAuctionPunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (amount: string, punkId: string, onBehalfOf_address: string) => {
      try {
        const tx = await auction(contract, punkId, amount, onBehalfOf_address)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onAuctionPunk: handleAction }
}

export const useLiquidateETHPunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (punkId: string, amount: string) => {
      try {
        const tx = await liquidateETH(contract, punkId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onLiquidateETHPunk: handleAction }
}

export const useLiquidatePunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (punkId: string, amount: string) => {
      try {
        const tx = await liquidate(contract, punkId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onLiquidatePunk: handleAction }
}

/**
 * It takes in a punkId, amount, bidFine, and value, and returns a function that takes in
 * the token ID of the NFT, the amount of ETH to redeem, the bid fine, and
 * the value of the transaction, and returns a transaction object
 * @param {string} PunkGateway_address - The address of the PunkGateway contract
 * @returns The function onRedeemETHPunk is being returned.
 */
export const useRedeemETHPunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (punkId: string, amount: string, bidFine: string, value: string) => {
      if (!contract) return
      try {
        const tx = await redeemETH(contract, punkId, amount, bidFine, value)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onRedeemETHPunk: handleAction }
}

/**
 * It takes a punkId, amount, and bigFine as arguments, and returns a transaction object
 * @param {string} PunkGateway_address - The address of the PunkGateway contract
 * @returns The function onRedeemPunk is being returned.
 */
export const useRedeemPunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (punkId: string, amount: string, bidFine: string) => {
      try {
        if (!contract) return
        const tx = await redeem(contract, punkId, amount, bidFine)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onRedeemPunk: handleAction }
}

export const useRepayETHPunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (punkId: string, amount: string) => {
      try {
        const tx = await repayETH(contract, punkId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onRepayETHPunk: handleAction }
}

export const useRepayPunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (punkId: string, amount: string) => {
      try {
        const tx = await repay(contract, punkId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onRepayPunk: handleAction }
}

export const useSetApprovalForAll = (WPunk_address: string) => {
  const contract = useWPunkContract(WPunk_address)

  const handleAction = useCallback(
    async (operator: string, approved: string) => {
      try {
        const tx = await setApprovalForAll(contract, operator, approved)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onSetApprovalForAll: handleAction }
}

export const usePunkBatchBorrowETH = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (amount: string[], punkId: string[], onBehalfOf_address: string, referralCode: string) => {
      try {
        if (!contract) return
        const tx = await batchBorrowETH(contract, amount, punkId, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onPunkBatchBorrowETH: handleAction }
}

export const usePunkBatchBorrow = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (asset_address: string[], amount: string[], punkId: string[], onBehalfOf_address: string, referralCode: string) => {
      try {
        if (!contract) return
        const tx = await batchBorrow(contract, asset_address, amount, punkId, onBehalfOf_address, referralCode)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onPunkBatchBorrow: handleAction }
}

export const useBatchRepayETHPunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (punkId: string[], amount: string[], value: string) => {
      try {
        if (!contract) return
        const tx = await batchRepayETH(contract, punkId, amount, value)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onBatchRepayETHPunk: handleAction }
}

export const useBatchRepayPunk = (PunkGateway_address: string) => {
  const contract = usePunkGatewayContract(PunkGateway_address)

  const handleAction = useCallback(
    async (punkId: string[], amount: string[]) => {
      try {
        if (!contract) return
        const tx = await batchRepay(contract, punkId, amount)
        return tx
      } catch (e) {
        if (typeof e === 'string') throw { msg: e }
        else return e
      }
    },
    [contract]
  )

  return { onBatchRepayPunk: handleAction }
}
