import React from 'react'

interface IIconAirdrop {
  color?: string
  size?: number
}

const IconAirdrop = React.forwardRef<SVGSVGElement, IIconAirdrop>(({ color = 'currentColor', size = 30, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 26 31.708'
      fill={color}
      stroke={color}
      strokeWidth='0.0'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_2114' data-name='Rectangle 2114' width='26' height='31.708' fill='#fff' />
        </clipPath>
      </defs>
      <g id='Group_5302-2' data-name='Group 5302' clipPath='url(clip-path)'>
        <path
          id='Path_2398'
          data-name='Path 2398'
          d='M26,13A13,13,0,0,0,0,13v.482l10.067,7.083H7.429V31.708H18.571V20.565H15.933L26,13.482Zm-2.022-1.911a6.648,6.648,0,0,0-6.07.109,22.262,22.262,0,0,0-2-8.956,11.177,11.177,0,0,1,8.072,8.847M8.211,13.553l2.441,5.153L2.479,12.955a4.77,4.77,0,0,1,5.732.6M13,1.857c.5,0,1.3.9,1.964,2.9a24.89,24.89,0,0,1,1.083,6.387,6.63,6.63,0,0,0-6.094,0,24.9,24.9,0,0,1,1.083-6.387c.668-2,1.468-2.9,1.964-2.9m2.885,11.378L13,19.325l-2.885-6.089a4.757,4.757,0,0,1,5.77,0m1.9.318a4.77,4.77,0,0,1,5.732-.6l-8.173,5.751Zm-7.7-11.311a22.266,22.266,0,0,0-2,8.956,6.648,6.648,0,0,0-6.07-.109,11.177,11.177,0,0,1,8.072-8.847m6.62,27.609H9.286V22.422h7.429Z'
          fill='#fff'
        />
      </g>
    </svg>
  )
})

export default IconAirdrop
