import { useCallback } from 'react'
import { useDebtTokenContract } from '.'

import { approveDelegation } from '../utils/DebtToken'

export const useApproveDelegation = (contract_address: string, delegator_address: string) => {
  const ERC721contract = useDebtTokenContract(contract_address)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approveDelegation(ERC721contract, delegator_address)
      return tx
    } catch (e) {
      if (typeof e === 'string') throw { msg: e }
      else return e
    }
  }, [ERC721contract, delegator_address])

  return { onApproveDelegation: handleApprove }
}
