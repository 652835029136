import { Modal, ModalContainer, useModal } from 'components/common/modal'
import { IRoutes } from 'constants/types'
import { useRefetchContext } from 'contexts/refetch'
import useResponsive from 'hooks/common/useResponsive'
import { useActiveWeb3React } from 'modules/wallet-module'
import { useTranslation } from 'next-i18next'
import { useQuery } from 'react-query'
import { Flex, Text } from 'rebass/styled-components'
import { Icon } from 'theme/ui'
import { getNftAsset } from 'utils/api/get-nft-asset'
import BorrowComponent from '../../../../components/app/asset/loan-wizards/borrow'

interface INavButton extends IRoutes {}

type BorrowButtonProps = {
  collectionAddress?: string
  tokenID?: string
  child: INavButton
}
const BorrowButton: React.FC<BorrowButtonProps> = ({ collectionAddress, tokenID, child, ...rest }) => {
  const { t } = useTranslation('common')
  const { account } = useActiveWeb3React()
  const { open, close, modalOpen } = useModal()
  const { isDesktop } = useResponsive()
  const { data: nftAsset, isLoading } = useQuery(
    ['get borrow button collection data by address and token id', collectionAddress, tokenID],
    async () => await getNftAsset(collectionAddress as string, tokenID as string),
    {
      enabled: !!(collectionAddress && tokenID)
    }
  )
  const { refetch } = useRefetchContext()
  return (
    <>
      <Flex
        fontSize={['md']}
        lineHeight='md'
        fontWeight={['700']}
        my={[10]}
        alignItems='center'
        color='grey.300'
        px={[10, 30, 30, 30, 0]}
        sx={{
          position: 'relative',
          flex: !isDesktop ? '0 0 74px' : undefined,
          '& svg': {
            transition: 'all 0.3s',
            color: 'primary'
          },
          '&:hover': {
            cursor: 'pointer',
            '& svg': {
              transition: 'all 0.3s',
              color: 'primary'
            }
          }
        }}
        onClick={open}
        disabled={isLoading}
        {...rest}
      >
        <Icon icon={child?.icon} size={20} />
        <Text ml={10} color={['black']}>
          {t('button.borrow-eth')}
        </Text>
      </Flex>
      <ModalContainer modalOpen={modalOpen}>
        <Modal
          handleClose={() => {
            close()
            refetch()
          }}
        >
          <BorrowComponent
            account={account ? account : ''}
            isModal
            single={{
              enabled: false,
              nftAsset
            }}
          />
        </Modal>
      </ModalContainer>
    </>
  )
}

export default BorrowButton
