import BigNumber from 'bignumber.js'
import { ChartOptions } from 'lightweight-charts'
import { nftImageUrlType } from 'constants/index'
import { Moment } from 'moment'
import { Borrows_NftOrder } from 'modules/bend/hooks/bend-query/data/loans/get-loans'

export interface INumberFormat {
  [x: string]: string
}

export interface IUseTheme {
  [x: string]: any
}

export interface IPermalink {
  [x: string]: string
}

export interface IRoute {
  name: string
  icon: string
  route: string
  auth?: boolean
  external?: boolean
  onClick?: any
  soon?: boolean
  isNew?: boolean
  onlyMobile?: boolean
  parent?: string
  type: 'nav-item' | 'separator'
  customRoute?: string
}

export interface IRoutes extends IRoute {
  children?: IRoute[]
}

export type IterationKey = number | string

export type IHeaderHeight = number[] | string[]

export type ImageWidthOrHeight = number | string

export enum NftStatusType {
  LISTED = 'listed',
  NOTLISTED = 'notlisted'
}

export type NftAuthorType = {
  name: string
  address: string
}

export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
  GIF = 'gif'
}

export type NftMediaSize = {
  width: number
  height: number
}

export type NftMediaType = {
  type: MediaType | string
  url: string
  size: NftMediaSize
}

export interface ITradeTokenList {
  key: string
  name: string
  symbol: string
  icon: string
  status: NftTokenStatus
}

export enum NftTokenStatus {
  PRIVATIZING = 'privatizing',
  PRIVATE = 'private'
}

export interface IActiveAuction {
  key: any
  id: string
  collectionUnderlyingAsset: string
  collectionName: string
  nftAsset: {
    symbol: string
    underlyingAsset: string
  }
  image: string
  imageType: nftImageUrlType
  openseaImageURL: string
  floorPrice: BigNumber
  borrowed: BigNumber
  nftTokenId: string
  healthFactor: BigNumber
  apy: BigNumber
  bidPrice: BigNumber
  reserveAsset: {
    underlyingAsset: string
    decimals: number
  }
  auctionEnded: boolean
}

export interface ILiquidateResult {
  key: IterationKey
  id: string
  collectionUnderlyingAsset: string
  collectionName: string
  collectionImage: string
  collectionImageType: nftImageUrlType
  openseaImageURL: string
  floorPrice: BigNumber
  borrowed: BigNumber
  tokenId: string
  healthFactor: number
  apy: BigNumber
  latestBidPrice: BigNumber
  reserve: string
  auctionEnded: boolean
  bidEndTime: Moment
  bidStartTimestamp: number
  bidFine: BigNumber
  bidderAddress: string
  ltv: string
  order: {
    id: string
    price: string
    status: 'created' | 'completed' | 'canceled'
  }
}

export interface IBuyoutListItem {
  key: IterationKey
  art: NftMediaType
  title: string
  author: string
  trend: any
  roi: number
  price: number | string
  state: NftTokenStatus
}

export type IBuyoutList = IBuyoutListItem[]

export enum Currency {
  USDT = 'usdt'
}

export interface ILineChartDataItem {
  time: string | number
  value: number
}

export type ILineChartData = ILineChartDataItem[]
export interface ILineChart {
  data: ILineChartData
  chartId: string
  width?: number
  height?: number
  chartOptions?: ChartOptions
}

export interface IMarketNftCollection {
  key: any
  collectionName: string
  collectionImage: any
  totalCollaterals: any
  floorPrice: any
  availableToBorrow: any
  active: boolean
  lastestBid: string | number
  collectionImages?: string
}

export interface IMarketReserve {
  key: any
  assetName: string
  address: string
  assetTicker: string
  assetLogo: string
  totalLiquidity: any
  availableLiquidity: any
  depositAPY: any
  totalBorrows: any
  borrowAPY: number
  active: boolean
  bToken: string
  totalBTokenSupply: BigNumber
  distributionManager: string
  debtToken: string
  utilizationRate: any
}

export interface ReducerActions<T> {
  action: T
  payload: any
}

export interface IDashboardMyDeposit {
  key: string | number
  id: string
  underlyingAsset: string
  symbol: string
  assetName: string
  assetIcon: string
  assetAddress: string
  walletBallance: BigNumber | string | number
  liquidityRate: BigNumber
  currentBTokenBalance: BigNumber
  totalLiquidity: BigNumber
  isActive: boolean
  isFrozen: boolean
  decimals: number
  apy: BigNumber
  lifetimeDeposits: BigNumber
  lifetimeWithdrawals: BigNumber
}

export interface IDashboardMyOffers {
  id: string
  key: string | number
  collectionName: string
  collectionAddress: string
  tokenID: string
  offerFrom: string
  offerAmount: BigNumber
  floorDifference: number
  expiration: number
  subRowComponent?: any
}

export interface IReleasePlanColumns {
  month: string | number
  daoTreasury: number | string
  communityIncentive: number | string
  devTeam: string | number
  released: boolean
  sumBendSupply: number | string
  procentMaxSupply: number | string
}

export interface IIncentiveAssets {
  key: string | number
  id: string
  assetName: string
  assetIcon: string
  assetAddress: string
  walletBallance: BigNumber
  depositApr: BigNumber
  borrowApr: BigNumber
  bToken: string
  distributionManager: string
  totalBTokenSupply: BigNumber
  totalDebtTokenSupply: BigNumber
  claim: BigNumber
  currentBTokenBalance: BigNumber
  currentTotalDebt: BigNumber
}

export interface IDashboardMyBorrowing {
  key: string
  id: string
  nftId: string
  loanId: string
  tokenId: string
  collectionAddress: string
  assetIcon: string
  assetName: string
  floorPrice: BigNumber
  availableToBorrow: BigNumber
  apy: BigNumber
  borrowed: BigNumber
  healthFactor: BigNumber
  underlyingAsset: string
  reserveAsset: string
  reserveAssetId: string
  assetAddress?: string
  collateralRatio: BigNumber
  state: LoanState
  order: {
    id: string
    status: string
    nftItem: {
      collectionAddress: string
      tokenID: string
    }
  }
}

export interface INFTsColumnsCustodyPage {
  key: string
  id: string
  nftId: string
  loanId: string
  tokenId: string
  collectionAddress: string
  assetIcon: string
  assetName: string
  floorPrice: BigNumber
  availableToBorrow: BigNumber
  apy: BigNumber
  borrowed: BigNumber
  healthFactor: BigNumber
  underlyingAsset: string
  reserveAsset: string
  reserveAssetId: string
  assetAddress?: string
  collateralRatio: BigNumber
  state: LoanState
  collectionName: string
}

export interface NftsColumnsCustodyPage {
  tokenID: string
  collectionAddress: string
  activeReserveUnderlyingId: string
  tokenURI: string
  image: string
  imageType: string
  collection: {
    name: string
  }
}

export interface IBidsHistory {
  key: string | number
  liquidator: string
  bidPrice: BigNumber
  time: string
  reserveUnderlyingasset: string
}

export enum DataReducer {
  setMarketReserves,
  setMarketReservesError,
  setMarketNftCollections,
  setMarketNftCollectionsError,
  setDashboardMyDeposits,
  setDashboardMyDepositsETH,
  setDashboardMyDepositsCount,
  setDashboardMyBorrowings,
  setDashboardMyBorrowingsCount,
  setDashboardActiveReserve,
  cleanupReserves,
  addToReservesBalance,
  addToBorrowedBalance,
  setCollateralKey,
  setShowAllCollaterals,
  setUsdPriceEth,
  cleanUpTotalValueDeposited,
  cleanup,
  setUsdPriceBend,
  setEth
}

export enum LoanState {
  None = 'None',
  none = 'none',
  Created = 'Created',
  created = 'created',
  Active = 'Active',
  active = 'active',
  Repaid = 'Repaid',
  repaid = 'repaid',
  Defaulted = 'Defaulted',
  defaulted = 'defaulted',
  Auction = 'Auction',
  auction = 'auction'
}

export enum BreadcrumbsReducer {
  update
}

export type EndpointName = 'BEND_PROTOCOL' | 'BEND_PROTOCOL_WS' | 'BEND' | 'BEND_NFTAPI' | 'BEND_NFTAPI_WS' | 'AIRDROP' | 'BEND_BNFT' | 'NFT_INFO' | 'CMS'

export interface Endpoint {
  [x: string]: string
}

export interface Endpoints {
  [x: string]: EndpointName
}

export enum OrderDirectionType {
  desc = 'desc',
  asc = 'asc'
}

export interface CollectionPayload {
  key: string
  id: string
  collectionName: string
  totalCollaterals: string | number | BigNumber
  collectionImages: string
  floorPrice: BigNumber
  availableToBorrow: BigNumber
  address: string
  priceInEth: BigNumber
  activeCollaterals: number
  eth: any
  priceHint: string
  bnftToken: string
  orderBy: {
    totalSupply: number
  }
}

export type Price = {
  priceInEth: BigNumber
  oracle: {
    usdPriceEth: BigNumber
  }
}
export interface Reserve {
  id: string
  decimals: number
  isActive: boolean
  isFrozen: boolean
  liquidityRate: BigNumber
  name: string
  symbol: string
  totalLiquidity: BigNumber
  underlyingAsset: string
  variableBorrowRate: BigNumber
  totalCurrentVariableDebt: BigNumber
  price: Price
  totalBTokenSupply: BigNumber
  availableLiquidity: string
  bToken: {
    id: string
  }
  debtToken: {
    id: string
  }
  utilizationRate: BigNumber
}

export interface ReservePayload {
  key: string
  id: string
  address: string
  assetName: string
  assetTicker: string
  assetLogo: string
  totalLiquidity: BigNumber
  depositAPY: BigNumber
  totalBorrows: BigNumber
  borrowAPY: BigNumber
  active: boolean
  priceInEth: BigNumber
  bToken: string
  debtToken: string
  totalBTokenSupply: BigNumber
  distributionManager: string | undefined
  utilizationRate: BigNumber
  availableLiquidity: BigNumber
}

export type MetaTagsProps = {
  title?: string
  description?: string
  image?: string
}

export type Collection = {
  name: string
  symbol: string
  openseaImageURL: string
}

export type NftAsset = {
  symbol: string
  name: string
  nftAsset: string
  underlyingAsset: string
  baseLTVasCollateral: string
  liquidationThreshold: string
  price: string
}

export type CollateralResult =
  | {
      nftId: string
      tokenID: string
      ownerAddress: string
      collectionAddress: string
      collection: Collection
      nftAsset: NftAsset
      floorPrice: string
      image: string
      imageType: string
      bnftToken: string
      isActive: boolean
      isFrozen: boolean
    }
  | null
  | undefined

export type NftItem = {
  nftId: string
  collectionAddress: string
  ownerAddress: string
  tokenID: string
  image: string
  imageType: nftImageUrlType
  nftAsset: NftAsset
  floorPrice: string
  availableToBorrow: string
  collection: Collection
  bnftToken: string
}

export type Collaterals_NftOrder = {
  id: string
  status: string
  nftItem: {
    collectionAddress: string
    tokenID: string
  }
}

export type NftItemsResult = {
  nftItems: Array<NftItem>
  reserveId: string
  nftOrders: Array<Collaterals_NftOrder>
}

export type NftPrice = {
  priceInEth: string
  oracle?: {
    usdPriceEth: string
  }
}

export type Nfts = {
  id: string
  baseLTVasCollateral: string
  price: NftPrice
  liquidationThreshold: string
  name: string
  symbol: string
  underlyingAsset: string
  bnftToken: string
  isActive: boolean
  isFrozen: boolean
}

export type CollectionDetails = {
  nftId: string
  collectionAddress: string
  ownerAddress: string
  tokenID: string
  image: string
  imageType: nftImageUrlType
  nftAsset: NftAsset
  floorPrice: string
  availableToBorrow: string
  collection: Collection
  bnftToken: string
}

export type ReserveResult = {
  reserve: {
    id: string
    name: string
    underlyingAsset: string
    decimals: number
    variableBorrowRate: string
    price: {
      priceInEth: string
    }
  }
}

export enum OrderDirection {
  asc = 'asc',
  desc = 'desc'
}

export enum CollateralOrderBy {
  name = 'name',
  tokenID = 'tokenID',
  floorPrice = 'floorPrice',
  price = 'price',
  symbol = 'symbol'
}

export type CollateralsArgs = {
  account: string
  orderDirection: OrderDirection
  orderBy: CollateralOrderBy
  reserveId: string
  filter: Array<string>
}

export type ActiveAuctionsArgs = {
  account: string
  orderBy: ActiveAuctionsOrderBy
  orderDirection: OrderDirection
  nftAsset_in?: Array<string>
}

export type LoanByBidderAddress = {
  id: string
  loanId: string
  nftAsset: {
    liquidationThreshold: string
    underlyingAsset: string
    auctionDuration: number
    symbol: string
    price: Price
  }
  nftTokenId: string
  state: LoanState
  bidderAddress: string
  bidPrice: string
  currentAmount: string
  reserveAsset: {
    underlyingAsset: string
    decimals: number
    price: Price
  }
  bidStartTimestamp: number
}

export type LoansByBidderAddressResult = {
  loans: LoanByBidderAddress[]
}

export type NftLoanByBidderAddress = {
  loanID: string
  state: LoanState
  healthFactor: number
  nftTokenID: string
  nftItem: {
    image: string
    imageType: string
    collection: {
      name: string
      address: string
      openseaImageURL: string
    }
  }
}

export type NftLoansResult = {
  loans: NftLoanByBidderAddress[]
}

export enum ActiveAuctionsOrderBy {
  name = 'name',
  tokenID = 'tokenID',
  floorPrice = 'floorPrice',
  borrowed = 'borrowed',
  healthFactor = 'healthFactor',
  bidPrice = 'bidPrice'
}

export enum DashboardNftsOrderBy {
  name = 'name',
  tokenID = 'tokenID',
  floorPrice = 'floorPrice',
  price = 'price',
  symbol = 'symbol'
}

export type DashboardNftsArgs = {
  account: string
  orderDirection: OrderDirection
  orderBy: DashboardNftsOrderBy
  reserveId: string
  filter: Array<string>
}

export type DashboardCollateralsNftItemResult = {
  collectionAddress: string
  tokenID: string
  collection: {
    name: string
    symbol: string
    nftData: {
      ltv: number
      isActive: boolean
      isFrozen: boolean
    }
    floorPrice: string
  }
}

export type DashboardCollateralsNftItemData = {
  nftItems: Array<DashboardCollateralsNftItemResult>
}

export type LoanReserveAsset = {
  underlyingAsset: string
  variableBorrowRate: string
  decimals: number
  name: string
  symbol: string
  price: NftPrice
}

export type NftAsset2 = {
  symbol: string
  name: string
  nftAsset?: string
  underlyingAsset: string
  baseLTVasCollateral: string
  liquidationThreshold: string
  price: NftPrice
  redeemFine: string
  minBidFine: string
  redeemDuration: string
  auctionDuration: string
}

export type Loan = {
  id: string
  reserveAsset: LoanReserveAsset
  nftTokenId: string
  currentAmount: string
  nftAsset: NftAsset2
  image: string
  // imageType: nftImageUrlType
  imageType: string
  openseaImageURL: string
  loanId: string
  healthFactor: number
  state: LoanState
  availableToBorrow: string
  order: Borrows_NftOrder | null | undefined
  orderBy: any
  bidStartTimestamp: number
  bidPrice: string
}
