import { Flex, Box, Text, Link } from 'rebass'
import { SUPPORTED_WALLETS, injected } from 'wallet-module'
import Option from './option'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'next-i18next'
import { Spacer, Button } from 'theme/ui'
import useTheme from 'hooks/common/useTheme'

export const WalletStep2: React.FC<any> = ({ handleBack, account, connector, tryActivation }) => {
  const { t } = useTranslation('common')
  const { colors } = useTheme()
  // get wallets user can switch too, depending on device/browser
  const getOptions = () => {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask
    return Object.keys(SUPPORTED_WALLETS).map(key => {
      const option = SUPPORTED_WALLETS[key]
      // check for mobile options
      if (isMobile) {
        if (!window.web3 && !window.ethereum && option.mobile) {
          return (
            <Option
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                option.connector !== connector && !option.href && tryActivation(option.connector)
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector && option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              icon={`/images/${option.iconName}`}
            />
          )
        }
        return null
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={'Install Metamask'}
                link={'https://metamask.io/'}
                icon={'/images/metamask.png'}
              />
            )
          } else {
            return null //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              option.connector === connector ? handleBack() : !option.href && tryActivation(option.connector)
            }}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            icon={`/images/${option.iconName}`}
          />
        )
      )
    })
  }

  return (
    <>
      {account ? (
        <Box>
          <Text fontWeight={'bold'} fontSize={13}>
            {t('wallet.label.change-provider')}
          </Text>
          <Spacer size='xl' />
        </Box>
      ) : (
        <Box>
          <Text fontWeight={'bold'} fontSize={13}>
            {t('wallet.label.connect-to-a-wallet')}
          </Text>
          <Spacer size='xl' />
        </Box>
      )}
      <Box>
        <Box
          sx={{
            display: 'grid',
            gridGap: '10px'
          }}
        >
          {getOptions()}
        </Box>
        {account && (
          <Box width='100%' mt={20}>
            <Button onClick={handleBack} size='md' outlined fontWeight='bold' width='100%'>
              {t('wallet.button.back')}
            </Button>
          </Box>
        )}
        <Flex mt={20} fontSize={12}>
          <Text sx={{ opacity: 0.5 }}>{t('wallet.label.new-to-ethereum')}? &nbsp;</Text>
          <Link href='https://ethereum.org/wallets/' target='_blank' rel='noopener noreferrer'>
            <Text color={colors.primary} sx={{ '&:hover': { cursor: 'pointer', opacity: 0.8, transition: 'ease-in-out 0.2s' }, zIndex: 200 }}>
              {t('wallet.label.learn-more-about-wallets')}
            </Text>
          </Link>
        </Flex>
      </Box>
    </>
  )
}
