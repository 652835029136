import SpacerDash from 'components/common/spacer-dash'
import useTheme from 'hooks/common/useTheme'
import { useTranslation } from 'next-i18next'
import { Flex, Text, Box } from 'rebass/styled-components'
import { IconCheck } from 'theme/ui'
import { useBorrowContext } from '../context'

export const WizardTimelineTwoStep: React.FC = () => {
  const { colors } = useTheme()
  const { t: tc } = useTranslation('common')
  const { wizardStep: step } = useBorrowContext()

  return (
    <Box width='100%' mb={20}>
      <Flex width={'100%'} justifyContent='center' alignItems={'center'} fontWeight='bold' flex={1}>
        <Flex flex={1 / 2} justifyContent='center' alignItems='center' flexDirection={'column'}>
          <Text fontSize='sm' lineHeight={['lg', 'lg', 'xl']} mt={1.5} textAlign='center' color={step === 1 ? 'black' : 'grey.500'}>
            {tc('label.enter-borrow-amounts')}
          </Text>
        </Flex>

        <Flex flex={1 / 2} justifyContent='center'>
          <Text fontSize='sm' lineHeight={['lg', 'lg', 'xl']} mt={1.5} textAlign='center' color={step === 2 ? 'black' : 'grey.500'}>
            {tc('label.borrow-eth')}
          </Text>
        </Flex>
      </Flex>

      <Flex width='100%' mt={20} flexDirection='column'>
        <SpacerDash bgColor={colors.grey[5]} height={4} width={'100%'} />
        <Flex width={'100%'} justifyContent='center' alignItems={'center'} mt={-15} fontWeight='bold' flex={1}>
          <Flex flex={1 / 2} justifyContent='center' alignItems='center' flexDirection={'column'}>
            <Box width={27} height={27} backgroundColor={step === 1 ? colors.primary : 'green.1000'} sx={{ borderRadius: 36, zIndex: 100 }} alignItems='center'>
              {step === 1 ? (
                <Text fontSize={12} mt={[2.5, 3, 3, 1.5]} textAlign='center' color={'white'}>
                  1
                </Text>
              ) : (
                <Box ml={7} mt={[3]}>
                  <IconCheck color='white' />{' '}
                </Box>
              )}
            </Box>
          </Flex>

          <Flex flex={1 / 2} justifyContent='center' alignItems='center' flexDirection={'column'}>
            <Box
              width={27}
              height={27}
              backgroundColor={step === 2 ? colors.primary : step && step > 2 ? 'green.1000' : 'grey.5'}
              sx={{ borderRadius: 36, zIndex: 100 }}
              alignItems='center'
            >
              {step && step <= 2 ? (
                <Text fontSize={12} mt={[2.5, 3, 3, 1.5]} textAlign='center' color={step === 2 ? 'white' : 'grey.500'}>
                  2
                </Text>
              ) : (
                <Box ml={7} mt={[3]}>
                  <IconCheck color='white' />{' '}
                </Box>
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
