import { useMemo } from 'react'
import { useBlockNumber } from 'wallet-module'
import { isValidMethodArgs, toCallState } from '../utils'
import { useCallsData } from '.'
export function useSingleCallResult(contract, methodName, inputs, options, isLoading = false) {
  const fragment = useMemo(() => {
    var _a
    return (_a = contract === null || contract === void 0 ? void 0 : contract.interface) === null || _a === void 0 ? void 0 : _a.getFunction(methodName)
  }, [contract, methodName])
  const calls = useMemo(() => {
    if (isLoading || fragment?.inputs?.length !== inputs?.length) return []
    try {
      return contract && fragment && isValidMethodArgs(inputs)
        ? [
            {
              address: contract.address,
              callData: contract.interface.encodeFunctionData(fragment, inputs)
            }
          ]
        : []
    } catch (e) {
      console.log('e', e)
      return []
    }
  }, [contract, fragment, inputs])
  const result = useCallsData(calls, options)[0]
  const latestBlockNumber = useBlockNumber()
  return useMemo(() => {
    return toCallState(result, contract === null || contract === void 0 ? void 0 : contract.interface, fragment, latestBlockNumber)
  }, [result, contract, fragment, latestBlockNumber])
}
