import React, { isValidElement, useState } from 'react'
import { Flex, Box, Text } from 'rebass/styled-components'
import _, { isEmpty } from 'lodash'
// Components
import { ChevronDown, ChevronUp, Minus, Plus } from 'react-feather'
// iterfaces
import { ITable, ITableColumn } from './interfaces'
import { rem } from 'polished'
import useTheme from 'hooks/common/useTheme'
import useResponsive from 'hooks/common/useResponsive'
import { IconError, SkeletonLine } from 'theme/ui'
import { useTranslation } from 'next-i18next'
import SpacerDash from 'components/common/spacer-dash'

const SORT_DIRECTION: {
  [x: string]: 'asc' | 'desc'
} = {
  ASC: 'asc',
  DESC: 'desc'
}

const Table: React.FC<ITable> = ({
  columns,
  subRowComponent,
  dataSource,
  loading,
  loadingNumRows = 5,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollbarsStyle,
  tableHeaderStyle,
  tableHeaderVariant,
  sortable,
  defaultSortHeader = '',
  mobile,
  mobileHasButtons = false,
  tabletHasTable = false,
  noRecords,
  customRowStyle,
  customRowStyleOpen,
  skeletonHeight,
  ...restprops
}) => {
  const [defaultWidth, setDefaultWidth] = React.useState<number>(0)
  const [sortHeader, setSortHeader] = React.useState<string | undefined>(columns[0]?.sortBy)
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>(SORT_DIRECTION.ASC)
  const { colors } = useTheme()
  const { isTablet, isLaptop } = useResponsive()

  const [selected, setSelected] = useState<any[]>([])
  const { t } = useTranslation('common')

  React.useEffect(() => {
    if (!columns.length) return
    setDefaultWidth(100 / columns.length)
  }, [columns.length])

  React.useEffect(() => {
    if (!defaultSortHeader) return
    setSortHeader(defaultSortHeader)
  }, [defaultSortHeader])

  const toggleDetails = React.useCallback(
    (item: string | number) =>
      selected.includes(item) ? setSelected(state => state.filter((record: string | number) => record !== item)) : setSelected(state => [item, ...state]),
    [selected]
  )

  const mobileColumns = React.useMemo(() => {
    const header = _.slice(columns, 0, 2)
    const content = mobileHasButtons ? _.slice(columns, 2, -1) : _.slice(columns, 2)
    const footer = mobileHasButtons ? [_.last(columns)] : null

    return {
      header,
      content,
      footer
    }
  }, [columns, mobileHasButtons])

  return (
    <Flex variant='table'>
      {(isLaptop || !mobile || (tabletHasTable ? isTablet : null)) && (
        <Flex variant={tableHeaderVariant ? tableHeaderVariant : 'table-header'} {...tableHeaderStyle}>
          {columns &&
            columns.map((col: ITableColumn) => (
              <Box key={col.key} flex={col.width ? `1 1 ${col.width}` : `1 1 ${defaultWidth}%`} sx={{ ...col.styles }}>
                {sortable ? (
                  <Flex
                    flexDirection='row'
                    alignItems='center'
                    justifyContent={!col.align ? 'flex-start' : col.align === 'left' ? 'flex-start' : col.align === 'right' ? 'flex-end' : 'center'}
                  >
                    {col?.hideSort ? (
                      <Text as='span'>{col.title}</Text>
                    ) : (
                      <>
                        <Text
                          as='span'
                          onClick={() => {
                            setSortHeader(col?.sortBy)
                            setSortDirection(SORT_DIRECTION.ASC)
                          }}
                          sx={{ cursor: !col?.hideSort ? 'pointer' : 'default', ...col.styles }}
                        >
                          {col.title}
                        </Text>
                        {sortHeader === col?.sortBy && (
                          <>
                            {sortDirection === SORT_DIRECTION.ASC ? (
                              <Text as='span' onClick={() => setSortDirection(SORT_DIRECTION.DESC)} sx={{ cursor: 'pointer' }} ml='3px'>
                                <ChevronUp height={rem('13px')} width={rem('13px')} />
                              </Text>
                            ) : (
                              <Text as='span' onClick={() => setSortDirection(SORT_DIRECTION.ASC)} sx={{ cursor: 'pointer' }} ml='3px'>
                                <ChevronDown height={rem('13px')} width={rem('13px')} />
                              </Text>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Flex>
                ) : (
                  <Text textAlign={col.align}>{col.title}</Text>
                )}
              </Box>
            ))}
        </Flex>
      )}
      {loading ? (
        isLaptop ? (
          [...Array(loadingNumRows).keys()].map((index: number) => (
            <Flex
              key={`table-skeleton-${index}`}
              variant={tableHeaderVariant ? tableHeaderVariant : 'table-row'}
              {...tableHeaderStyle}
              minHeight={skeletonHeight ? skeletonHeight : 82}
            >
              {columns &&
                columns.map((col: ITableColumn) => (
                  <Box key={col.key} flex={col.width ? `1 1 ${col.width}` : `1 1 ${defaultWidth}%`} sx={{ ...col.styles }}>
                    <Text textAlign={col.align}>
                      <SkeletonLine height={24} width='calc(100% / 2)' mb={-5} sx={{ borderRadius: 'sm' }} />
                    </Text>
                  </Box>
                ))}
            </Flex>
          ))
        ) : isTablet ? (
          [1, 2, 3].map((index: number) => (
            <Box key={index} mb={20}>
              <Flex justifyContent='space-between' variant='card'>
                <Flex width='100%' mt={20} mb={20}>
                  {[1, 2, 3].map(index => (
                    <Box key={index} mr={40}>
                      <SkeletonLine height={40} width={100} sx={{ borderRadius: 'sm' }} mb={20} />
                      <SkeletonLine height={40} width={100} sx={{ borderRadius: 'sm' }} />
                    </Box>
                  ))}
                </Flex>
                <Flex width='100%' alignItems='flex-end' flexDirection={'column'} mb={20}>
                  <SkeletonLine height={35} width={150} sx={{ borderRadius: 'sm' }} mt={30} />
                  <SkeletonLine height={35} width={150} sx={{ borderRadius: 'sm' }} mt={20} />
                </Flex>
              </Flex>
            </Box>
          ))
        ) : (
          [1, 2, 3].map((index: number) => (
            <Box key={index} mb={20}>
              <Flex flexDirection={'column'} justifyContent='space-between' variant='card'>
                <Flex justifyContent={'space-between'} mb={20}>
                  <SkeletonLine height={40} width={200} sx={{ borderRadius: 'sm' }} />
                  <SkeletonLine height={40} width={40} sx={{ borderRadius: 'sm' }} />
                </Flex>
                {[1, 2, 3, 4].map(index => (
                  <Flex key={index} width='100%' justifyContent={'space-between'} mb={5}>
                    <SkeletonLine height={18} width={80} sx={{ borderRadius: 'sm' }} />
                    <SkeletonLine height={18} width={80} sx={{ borderRadius: 'sm' }} />
                  </Flex>
                ))}
                <Flex width='100%' justifyContent='center'>
                  <SkeletonLine height={35} width={250} sx={{ borderRadius: 'sm' }} mt={20} />
                </Flex>
              </Flex>
            </Box>
          ))
        )
      ) : !loading && !dataSource.length ? (
        isValidElement(noRecords) ? (
          noRecords
        ) : (
          <Flex flexDirection='column' alignItems='center' justifyContent='center' minHeight={160}>
            <IconError color='grey.300' size={32} />
            <Box color='grey.300' fontSize='md' mt={20}>
              {t('label.no-records')}
            </Box>
          </Flex>
        )
      ) : (
        <>
          {(isLaptop || !mobile || (tabletHasTable ? isTablet : null)) && dataSource?.length > 0 && (
            <Box {...restprops}>
              <Flex flexDirection='column' sx={{ position: 'relative' }}>
                {_.orderBy(dataSource, [sortHeader], [sortDirection])?.map((source: any, i: number) =>
                  _.isArray(source)
                    ? source?.map(
                        (data: any) =>
                          !data?.hide && (
                            <Flex flexDirection='column' key={data?.key}>
                              <Flex width={30} alignItems='center' justifyContent='center' sx={{ cursor: 'pointer' }}>
                                {selected === source ? <ChevronUp /> : <ChevronDown />}
                              </Flex>
                              {columns &&
                                columns?.map((col: ITableColumn) => (
                                  <Box key={col?.key} flex={col?.width ? `1 1 ${col?.width}` : `1 1 ${defaultWidth}%`} sx={{ ...col.styles }}>
                                    {col?.Render ? col?.Render(data, i, loading) : <Text textAlign={col.align}>{data[col.dataIndex!]}</Text>}
                                  </Box>
                                ))}
                            </Flex>
                          )
                      )
                    : !source?.hide && (
                        <React.Fragment key={source?.key}>
                          <Flex
                            variant={
                              customRowStyle
                                ? selected.includes(source.key)
                                  ? customRowStyleOpen
                                    ? customRowStyleOpen
                                    : 'table-row-open'
                                  : customRowStyle
                                : 'table-row'
                            }
                            backgroundColor={source.active || source.active === undefined ? 'white' : colors.body}
                          >
                            {subRowComponent ? (
                              <Flex
                                ml={-10}
                                width={20}
                                mr={40}
                                alignItems='center'
                                justifyContent='center'
                                sx={{ cursor: isEmpty(source?.subRowData) ? 'default' : 'pointer', opacity: isEmpty(source?.subRowData) ? 0.3 : 1 }}
                                onClick={() => (isEmpty(source?.subRowData) ? null : toggleDetails(source.key))}
                              >
                                <Box>{selected.includes(source.key) ? <Minus height={15} color='black' /> : <Plus height={15} color='black' />}</Box>
                              </Flex>
                            ) : null}
                            {columns &&
                              columns.map((col: ITableColumn) => (
                                <Box
                                  ml={subRowComponent ? -25 : 0}
                                  key={col?.key}
                                  flex={col?.width ? `1 1 ${col?.width}` : `1 1 ${defaultWidth}%`}
                                  sx={{ ...col.styles }}
                                >
                                  {col?.Render ? col?.Render(source, i, loading) : <Text textAlign={col?.align}>{source?.[col.dataIndex!]}</Text>}
                                </Box>
                              ))}
                          </Flex>
                          <Flex width='100%'>
                            {subRowComponent && selected.includes(source.key) && (
                              <Box width='100%' mt={-10}>
                                <SpacerDash bgColor={colors.grey[5]} height={'1px'} width='100%' />
                                <Flex width='100%'>{subRowComponent(source)}</Flex>
                              </Box>
                            )}
                          </Flex>
                        </React.Fragment>
                      )
                )}
              </Flex>
            </Box>
          )}
          {!tabletHasTable && mobile && isTablet && !isLaptop && dataSource?.length > 0 && (
            <Box {...restprops}>
              {_.orderBy(dataSource, [sortHeader], [sortDirection])?.map((source: any, i: number) =>
                _.isArray(source)
                  ? source?.map(
                      (data: any) =>
                        !data?.hide && (
                          <Flex flexDirection='column' key={data?.key}>
                            <Flex width={30} alignItems='center' justifyContent='center' sx={{ cursor: 'pointer' }}>
                              {selected === source ? <ChevronUp /> : <ChevronDown />}
                            </Flex>
                            {columns &&
                              columns?.map((col: ITableColumn) => (
                                <Box key={col?.key} flex={col?.width ? `1 1 ${col?.width}` : `1 1 ${defaultWidth}%`} sx={{ ...col.styles }}>
                                  {col?.Render ? col?.Render(data, i, loading) : <Text textAlign={col.align}>{data[col.dataIndex!]}</Text>}
                                </Box>
                              ))}
                          </Flex>
                        )
                    )
                  : !source?.hide && (
                      <React.Fragment key={source?.key}>
                        <Flex
                          variant='table-row'
                          backgroundColor={source.active || source.active === undefined ? 'white' : colors.body}
                          justifyContent='space-between'
                        >
                          <Box
                            sx={{
                              display: 'grid',
                              gridGap: 20,
                              gridTemplateColumns: 'repeat(3, minmax(160px, 1fr))',
                              py: 22
                            }}
                          >
                            <Flex alignItems='center' sx={{ ...mobileColumns.header[0].styles }}>
                              {mobileColumns.header[0].Render ? mobileColumns.header[0].Render(source, i, loading) : source?.[columns[0].dataIndex!]}
                              <Flex flexDirection='column' ml={20}>
                                {mobileColumns.header[1].title && (
                                  <Box variant={'table-header-column-mobile'} textAlign={mobileColumns.header[1].align}>
                                    {mobileColumns.header[1].title}
                                  </Box>
                                )}
                                {mobileColumns.header[1].Render ? mobileColumns.header[1].Render(source, i, loading) : source?.[columns[1].dataIndex!]}
                              </Flex>
                            </Flex>
                            {mobileColumns &&
                              mobileColumns.content &&
                              mobileColumns.content.map((col: ITableColumn) => (
                                <Flex key={col?.key} sx={{ ...col.styles }}>
                                  {col?.Render ? (
                                    <Flex flexDirection='column'>
                                      {col.title && (
                                        <Box variant={'table-header-column-mobile'} textAlign={col.align}>
                                          {col.title}
                                        </Box>
                                      )}
                                      {col?.Render(source, i, loading)}
                                    </Flex>
                                  ) : (
                                    <Text textAlign={col?.align}>{source?.[col.dataIndex!]}</Text>
                                  )}
                                </Flex>
                              ))}
                          </Box>
                          <Flex flex='0 0 150px' alignItems='center'>
                            {mobileColumns &&
                              mobileColumns.footer &&
                              mobileColumns.footer.map((col: any) => (
                                <Flex key={col?.key} flex={1} sx={{ ...col.styles }} justifyContent='flex-end'>
                                  {col?.Render ? (
                                    <Flex flexDirection='column'>{col?.Render(source, i, loading)}</Flex>
                                  ) : (
                                    <Text textAlign={col?.align}>{source?.[col.dataIndex!]}</Text>
                                  )}
                                </Flex>
                              ))}
                          </Flex>
                          {subRowComponent ? (
                            <Flex width={30} alignItems='center' justifyContent='center' sx={{ cursor: 'pointer' }} onClick={() => toggleDetails(source.key)}>
                              {selected.includes(source.key) ? <ChevronUp height={16} /> : <ChevronDown height={16} />}
                            </Flex>
                          ) : null}
                        </Flex>
                        {subRowComponent && selected.includes(source.key) && subRowComponent(source)}
                      </React.Fragment>
                    )
              )}
            </Box>
          )}
          {mobile && !isTablet && dataSource?.length > 0 && (
            <Box {...restprops}>
              {_.orderBy(dataSource, [sortHeader], [sortDirection])?.map((source: any, i: number) =>
                _.isArray(source)
                  ? source?.map(
                      (data: any) =>
                        !data?.hide && (
                          <Flex flexDirection='column' key={data?.key}>
                            <Flex width={30} alignItems='center' justifyContent='center' sx={{ cursor: 'pointer' }}>
                              {selected === source ? <ChevronUp /> : <ChevronDown />}
                            </Flex>
                            {columns &&
                              columns?.map((col: ITableColumn) => (
                                <Box key={col?.key} flex={col?.width ? `1 1 ${col?.width}` : `1 1 ${defaultWidth}%`} sx={{ ...col.styles }}>
                                  {col?.Render ? col?.Render(data, i) : <Text textAlign={col.align}>{data[col.dataIndex!]}</Text>}
                                </Box>
                              ))}
                          </Flex>
                        )
                    )
                  : !source?.hide && (
                      <React.Fragment key={source?.key}>
                        <Flex variant='table-row' backgroundColor={source.active || source.active === undefined ? 'white' : colors.body} flexDirection='column'>
                          <Flex width='100%' alignItems='center' justifyContent='space-between' sx={{ ...mobileColumns.header[0].styles }}>
                            <Flex flexDirection='column' flex={1}>
                              {mobileColumns.header[1].title && (
                                <Box variant={'table-header-column-mobile'} textAlign={mobileColumns.header[1].align}>
                                  {mobileColumns.header[1].title}
                                </Box>
                              )}
                              {mobileColumns.header[1].Render ? mobileColumns.header[1].Render(source, i, loading) : source?.[columns[1].dataIndex!]}
                            </Flex>

                            <Flex flex={1} justifyContent='flex-end'>
                              {mobileColumns.header[0].Render ? mobileColumns.header[0].Render(source, i, loading) : source?.[columns[0].dataIndex!]}
                            </Flex>
                          </Flex>

                          <Box
                            sx={{
                              display: 'grid',
                              gridGap: 4,
                              gridTemplateColumns: 'repeat(1, minmax(128px, 1fr))',
                              py: 22,
                              width: '100%'
                            }}
                          >
                            {mobileColumns &&
                              mobileColumns.content &&
                              mobileColumns.content.map((col: ITableColumn) => (
                                <Flex
                                  key={col?.key}
                                  sx={{
                                    ...col.styles
                                  }}
                                  flex={1}
                                >
                                  {col?.Render ? (
                                    <Flex justifyContent='space-between' width='100%'>
                                      {col.title && (
                                        <Box variant={'table-header-column-mobile'} textAlign={col.align}>
                                          {col.title}
                                        </Box>
                                      )}
                                      {col?.Render(source, i, loading)}
                                    </Flex>
                                  ) : (
                                    <Text textAlign={col?.align}>{source?.[col.dataIndex!]}</Text>
                                  )}
                                </Flex>
                              ))}
                          </Box>

                          <Flex>
                            {mobileColumns &&
                              mobileColumns.footer &&
                              mobileColumns.footer.map((col: any) => (
                                <Flex key={col?.key} flex={1} sx={{ ...col.styles }} justifyContent='flex-end'>
                                  {col?.Render ? (
                                    <Flex flexDirection='column'>{col?.Render(source, i, loading)}</Flex>
                                  ) : (
                                    <Text textAlign={col?.align}>{source?.[col.dataIndex!]}</Text>
                                  )}
                                </Flex>
                              ))}
                          </Flex>
                          {subRowComponent ? (
                            <Flex width={30} alignItems='center' justifyContent='center' sx={{ cursor: 'pointer' }} onClick={() => toggleDetails(source.key)}>
                              {selected.includes(source.key) ? <ChevronUp height={16} /> : <ChevronDown height={16} />}
                            </Flex>
                          ) : null}
                        </Flex>
                        {subRowComponent && selected.includes(source.key) && subRowComponent(source)}
                      </React.Fragment>
                    )
              )}
            </Box>
          )}
        </>
      )}
    </Flex>
  )
}

export default Table
