import { Flex } from 'rebass/styled-components'
import { useActiveWeb3React } from 'wallet-module'
import { WalletStep1 } from './wallet-steps/step1'
import { WalletStep2 } from './wallet-steps/step2'
import { useWalletDisplay, WalletSteps } from 'modules/bend/hooks/useWallet'
import { WalletStep3 } from './wallet-steps/step3'

export const WalletMenu: React.FC = () => {
  const { account } = useActiveWeb3React()
  const {
    errorMsg,
    screenState,
    setScreenState,
    connector,
    tryActivation,
    pendingError,
    setPendingError,
    pendingErrorWrongNetwork,
    setPendingWrongNetwork,
    pendingWallet
  } = useWalletDisplay()

  const handleShowChangeProvider = () => {
    setScreenState(WalletSteps.SELECT_PROVIDER)
  }

  const handleSelectProviderBack = () => {
    setScreenState(WalletSteps.CONNECTED)
  }

  return (
    <Flex flexDirection='column'>
      {(() => {
        switch (screenState) {
          case WalletSteps.PENDING:
            return (
              <WalletStep3
                connector={pendingWallet}
                tryActivation={tryActivation}
                error={pendingError}
                setPendingError={setPendingError}
                pendingErrorWrongNetwork={pendingErrorWrongNetwork}
                setPendingWrongNetwork={setPendingWrongNetwork}
                handleBack={handleShowChangeProvider}
                errorMsg={errorMsg}
              />
            )
          case WalletSteps.SELECT_PROVIDER:
            return <WalletStep2 handleBack={handleSelectProviderBack} tryActivation={tryActivation} account={account} connector={connector} />
          default:
          case WalletSteps.CONNECTED:
            return <WalletStep1 account={account} connector={connector} handleShowChangeProvider={handleShowChangeProvider} />
        }
      })()}
    </Flex>
  )
}
