import dynamic from 'next/dynamic'
import { ThemeProvider } from 'styled-components'
import { Reset } from 'styled-reset'
import useDefaultTheme, { GlobalStyle } from '../theme'
import { appWithTranslation } from 'next-i18next'
// import i18n from '../i18n'
import { ResponsiveContextProvider, WizardContextProvider, BreadcrumbsContextProvider, DataContextProvider, MobileContextProvider } from '../contexts'
import { Web3ReactManager } from '../modules/wallet'
import { MulticallContextProvider } from 'multicall-module'
import { WalletContextProvider, getLibrary } from 'wallet-module'
import { BendContextProvider } from '../modules/bend'
import { ModalsProvider, DrawerProvider } from 'theme/ui'

// import { I18nextProvider } from 'react-i18next'
import Router, { useRouter } from 'next/router'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ProgressBar from '@badrap/bar-of-progress'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import UserContextProvider from 'contexts/user'
import { ApolloProvider } from '@apollo/client'
import { clientNftApi } from 'clients'
import { COOKIE_USER_DENIED_LOGIN, DEFAULT_CHAIN_ID, LS_TOKEN } from 'constants/index'
import { PlatformContextProvider } from 'contexts/platform'
// import TermsAndServiceNotification from 'components/common/terms-and-service-notification'
import { getCookie, setCookies, removeCookies } from 'cookies-next'
import { useEffect } from 'react'
import ErrorBoundary from 'components/common/error-boundary'
import TestnetUsageIcon from 'components/common/testnet-notification/icon'
import Head from 'components/common/head'
import Header from 'components/common/header'
import LandingHeader from 'components/common/header/landing-header'
import NotificationsProvider from 'contexts/notifications'
import WalletCheckProvider from 'components/common/wallet-check/context'
import BreadcrumbsProvider from 'components/common/breadcrumbs/context'
import AppNotificationsProvider from 'components/common/app-notofications/context'
import ValidateToken from 'components/common/validate-token'
import { ModalAppProvider } from 'components/common/modal/components/context'

const Web3ReactProvider: any = dynamic(() => import('@web3-react/core').then((module: any) => module['Web3ReactProvider']), { ssr: false })
const Web3ProviderNetwork: any = dynamic(() => import('components/common/web3-default-provider').then((module: any) => module), { ssr: false })

const _bend_version = '_bend_version'
const BEND_VERSION = getCookie(_bend_version)

const progress = new ProgressBar({
  size: 2,
  color: '#0057ff',
  className: 'progressbar',
  delay: 100
})

Router.events.on('routeChangeStart', progress.start)
Router.events.on('routeChangeComplete', progress.finish)
Router.events.on('routeChangeError', progress.finish)

//if (typeof window === 'undefined') return context
if (typeof window !== 'undefined' && 'ethereum' in window) {
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  ;(window.ethereum as any).autoRefreshOnNetworkChange = false
}

/**
 * React query client
 */
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  }
})

const App = ({ Component, pageProps }: any) => {
  const theme = useDefaultTheme()
  // const { locale } = useRouter()
  const { route } = useRouter()
  // useEffect(() => {
  //   i18n.changeLanguage(locale)
  // }, [locale])

  useEffect(() => {
    if (!window?.ethereum) return
    const provider: any = window.ethereum
    provider.on('accountsChanged', function () {
      window.localStorage.removeItem(LS_TOKEN)
      removeCookies(COOKIE_USER_DENIED_LOGIN)
    })

    if (process.env.NEXT_PUBLIC_APP_VERSION !== BEND_VERSION) {
      setCookies('_bend_version', process.env.NEXT_PUBLIC_APP_VERSION, {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      })
    }
  }, [])

  return (
    <Head title={pageProps?.seo?.title} description={pageProps?.seo?.description} image={pageProps?.seo?.image}>
      <ErrorBoundary>
        <ApolloProvider client={clientNftApi}>
          <QueryClientProvider client={client}>
            <Web3ReactProvider getLibrary={getLibrary}>
              <Web3ProviderNetwork getLibrary={getLibrary}>
                <WalletContextProvider>
                  <MulticallContextProvider>
                    <Web3ReactManager>
                      <ThemeProvider theme={theme}>
                        <ResponsiveContextProvider>
                          <DrawerProvider>
                            <WizardContextProvider>
                              <BreadcrumbsContextProvider>
                                <DataContextProvider>
                                  <BendContextProvider>
                                    <UserContextProvider>
                                      <ModalsProvider>
                                        <MobileContextProvider>
                                          <DrawerProvider>
                                            <NotificationsProvider>
                                              <Reset />
                                              <GlobalStyle {...theme} />
                                              {/* <TermsAndServiceNotification /> */}
                                              {DEFAULT_CHAIN_ID === '5' && <TestnetUsageIcon />}
                                              <PlatformContextProvider>
                                                <WalletCheckProvider>
                                                  <AppNotificationsProvider>
                                                    <BreadcrumbsProvider>
                                                      <ModalAppProvider>
                                                        {route === '/' ? null : route === '/[locale]' ? <LandingHeader /> : <Header />}
                                                        <ValidateToken />
                                                        <Component {...pageProps} />
                                                      </ModalAppProvider>
                                                    </BreadcrumbsProvider>
                                                  </AppNotificationsProvider>
                                                </WalletCheckProvider>
                                              </PlatformContextProvider>
                                            </NotificationsProvider>
                                          </DrawerProvider>
                                        </MobileContextProvider>
                                      </ModalsProvider>
                                    </UserContextProvider>
                                  </BendContextProvider>
                                </DataContextProvider>
                              </BreadcrumbsContextProvider>
                            </WizardContextProvider>
                          </DrawerProvider>
                        </ResponsiveContextProvider>
                      </ThemeProvider>
                    </Web3ReactManager>
                  </MulticallContextProvider>
                </WalletContextProvider>
              </Web3ProviderNetwork>
            </Web3ReactProvider>
            {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </Head>
  )
}

export default appWithTranslation(App)
