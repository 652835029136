import { ethImagePath, SHOW_DECIMAL_PLACES } from 'constants/index'
import { Flex, Text, Box, Image } from 'rebass/styled-components'
import { Avatar, Button } from 'theme/ui'
import { isWeth } from 'utils'
import { useTranslation } from 'next-i18next'
import NumberFormat from 'components/common/number-format'
import { BEND_ADDRESS, VE_BEND_ADDRESS } from 'modules/bend/constants'
import { useActiveWeb3React } from 'modules/wallet-module/lib/esm'

export const WrapStep1: React.FC<any> = ({ reserves, balances, handleWrap, handleUnWrap, isDashboard = false }) => {
  const { account } = useActiveWeb3React()
  const { t: tc } = useTranslation('common')
  return (
    <Flex flexDirection={'column'} flex={1}>
      <Flex flexDirection={'column'} flex={1} mt={isDashboard ? 10 : 20}>
        <Box key='eth_balance' mb={isDashboard ? 10 : 10}>
          <Flex justifyContent='space-between' flex={1} alignItems='center'>
            <Avatar
              icon={
                <Flex maxHeight={32} maxWidth={32}>
                  <Image src={ethImagePath} height={'100%'} width={'100%'} />
                </Flex>
              }
              iconSize={32}
              text={'ETH'}
              fontSize={!isDashboard ? 13 : 'lg'}
            />
            <Text fontSize={!isDashboard ? 13 : 'lg'} fontWeight='bold'>
              {account ? <NumberFormat number={balances.eth} format={2} /> : '--'}
            </Text>
          </Flex>
        </Box>
        {reserves.map((reserve: any) => {
          if (isWeth(reserve.assetAddress))
            return (
              <Box key={reserve.key} mb={10}>
                <Flex justifyContent='space-between' flex={1} alignItems='center'>
                  <Avatar
                    icon={
                      <Flex maxHeight={32} maxWidth={32}>
                        <Image
                          src={isWeth(reserve.assetAddress) ? `/images/elements/${reserve.assetAddress}-dark.svg` : reserve.assetIcon}
                          height={'100%'}
                          width={'100%'}
                        />
                      </Flex>
                    }
                    iconSize={32}
                    text={reserve.assetName === 'ETH' ? 'WETH' : reserve.assetName}
                    fontSize={!isDashboard ? 13 : 'lg'}
                  />
                  <Text fontSize={!isDashboard ? 13 : 'lg'} fontWeight='bold'>
                    {account ? (
                      <NumberFormat number={balances.reserve[reserve.assetAddress]?.amount?.dividedBy(`1e${reserve.decimals}`)} format={SHOW_DECIMAL_PLACES} />
                    ) : (
                      '--'
                    )}
                  </Text>
                </Flex>
              </Box>
            )
        })}
        <Box key='bend_balance' mb={10}>
          <Flex justifyContent='space-between' flex={1} alignItems='center'>
            <Avatar
              icon={
                <Flex maxHeight={32} maxWidth={32}>
                  <Image src={'/images/elements/bend.svg'} height={'100%'} width={'100%'} />
                </Flex>
              }
              iconSize={32}
              text={'BEND'}
              fontSize={!isDashboard ? 13 : 'lg'}
            />
            <Text fontSize={!isDashboard ? 13 : 'lg'} fontWeight='bold'>
              {account ? <NumberFormat number={balances.reserve[BEND_ADDRESS]?.amount?.dividedBy(`1e18`)} format={SHOW_DECIMAL_PLACES} /> : '--'}
            </Text>
          </Flex>
        </Box>
        <Box key='vebend_balance' mb={10}>
          <Flex justifyContent='space-between' flex={1} alignItems='center'>
            <Avatar
              icon={
                <Flex maxHeight={32} maxWidth={32}>
                  <Image src={'/images/elements/vebend-small.png'} height={'100%'} width={'100%'} />
                </Flex>
              }
              iconSize={32}
              text={'veBEND'}
              fontSize={!isDashboard ? 13 : 'lg'}
            />
            <Text fontSize={!isDashboard ? 13 : 'lg'} fontWeight='bold'>
              {account ? <NumberFormat number={balances.reserve[VE_BEND_ADDRESS]?.amount?.dividedBy(`1e18`)} format={SHOW_DECIMAL_PLACES} /> : '--'}
            </Text>
          </Flex>
        </Box>
      </Flex>
      {!isDashboard ? (
        <Flex justifyContent={'space-between'}>
          <Button color='primary' size='md' fontWeight={'bold'} flex={0.48} onClick={() => handleWrap()} disabled={!account}>
            {tc('button.wrap')}
          </Button>
          <Button color='primary' size='md' fontWeight={'bold'} flex={0.48} onClick={() => handleUnWrap()} disabled={!account}>
            {tc('button.unwrap')}
          </Button>
        </Flex>
      ) : (
        <Button color='primary' size='xl' fontWeight={'bold'} flex={1} onClick={() => handleWrap()} mt={5} disabled={!account}>
          {tc('button.convert.eth-weth')}
        </Button>
      )}
    </Flex>
  )
}
