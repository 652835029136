import CopyHelper from 'modules/wallet/components/account-details/copy'
import { IconWallet, Avatar, IconCopy, IconDisconnect, Button } from 'theme/ui'
import { injected, SUPPORTED_WALLETS } from 'wallet-module'
import { useTranslation } from 'next-i18next'
import { Flex, Text } from 'rebass'
import useTheme from 'hooks/common/useTheme'
import { useWeb3React } from '@web3-react/core'
import { Ens } from 'components/common/ens'
import { useCallback } from 'react'

export const WalletStep1: React.FC<any> = ({ account, connector, handleShowChangeProvider }) => {
  const { t } = useTranslation('common')
  const { t: tc } = useTranslation('common')
  const { deactivate } = useWeb3React()
  const { colors } = useTheme()

  function formatConnectorName() {
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(k => SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK')))
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return <Text>{t('wallet.label.connected-with', { name })}</Text>
  }

  const handleDeactivate = useCallback(async () => {
    try {
      await deactivate()
    } catch (error) {
      console.log('deactivate error', error)
    }
  }, [deactivate])

  return (
    <>
      {account && (
        <Text fontWeight='bold' fontSize={13}>
          {tc('label.connected-wallet')}
        </Text>
      )}
      <Flex alignItems='center' flex={1} mt={20} mb={20}>
        {account && (
          <Avatar
            width='100%'
            hasBorderRadius={8}
            icon={<IconWallet size={14} color={'white'} />}
            iconBackground={account ? 'green.1000' : 'red.1000'}
            iconSize={32}
            hasBackground
            text={
              <Flex flex={1} justifyContent='space-between' width='100%'>
                <Text fontWeight='bold' mr={connector !== injected ? 15 : 38}>
                  {account && <Ens address={account} maxLength={20} />}
                </Text>

                <Flex sx={{ position: 'fixed right' }}>
                  <CopyHelper toCopy={account!} color='transparent' fontColor='black' fontWeight='bold' fontSize='md'>
                    <IconCopy color={colors.grey[500]} />
                  </CopyHelper>

                  {
                    //connector !== injected && (
                    <Button
                      size='icon'
                      color='transparent'
                      ml={5}
                      onClick={() => {
                        handleDeactivate()
                      }}
                    >
                      <IconDisconnect color={colors.grey[500]} />
                    </Button>
                    //)
                  }
                </Flex>
              </Flex>
            }
          />
        )}
      </Flex>
      {account && (
        <Flex fontSize={12} justifyContent='space-between'>
          <Text sx={{ opacity: 0.5 }}>{formatConnectorName()}</Text>
          <Text
            color={colors.primary}
            sx={{ ':hover': { cursor: 'pointer', opacity: 0.8, transition: 'ease-in-out 0.2' } }}
            onClick={() => {
              handleDeactivate()
              handleShowChangeProvider()
            }}
          >
            {tc('label.change-provider')}
          </Text>
        </Flex>
      )}
    </>
  )
}
