import { gql } from '@apollo/client'
import BigNumber from 'bignumber.js'
import { clientBendProtocol } from 'clients'
import { useEffect, useState } from 'react'

interface UseBorrowApyProps {
  initialValue: BigNumber
  reserveId: string
}

interface UseBorrowApyResult {
  borrowApy: BigNumber
  isLoading: boolean
}

/**
 * Get deposit APY subscription
 */
export const useBorrowApy = ({ reserveId, initialValue }: UseBorrowApyProps): UseBorrowApyResult => {
  const [borrowApy, setBorrowApy] = useState<BigNumber>(initialValue)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const client = clientBendProtocol
      .subscribe({
        query: gql`
          subscription DepositApy($reserveId: String!) {
            reserve(id: $reserveId) {
              variableBorrowRate
            }
          }
        `,
        variables: {
          reserveId
        }
      })
      .subscribe(({ data: { reserve } }) => {
        const result = new BigNumber(100).multipliedBy(new BigNumber(reserve.variableBorrowRate).dividedBy(1e27)).decimalPlaces(4, 1)
        if (result.gt(0)) setIsLoading(false)
        setBorrowApy(result)
      })

    return () => {
      client.unsubscribe()
    }
  }, [reserveId])

  return {
    borrowApy,
    isLoading
  }
}
