import React, { useCallback } from 'react'
import { Label as LabelRebass } from '@rebass/forms'
import { default as RCSlider, createSliderWithTooltip } from 'modules/rc-slider'
import { Flex } from 'rebass/styled-components'
// Hooks
import useTheme from 'hooks/common/useTheme'
// Interfaces
import { SliderProps, SliderContextProps } from './interfaces'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'next-i18next'

const SliderWithTooltip = createSliderWithTooltip(RCSlider)

export const SliderContext = React.createContext<SliderContextProps>({
  sliderValue: 0
})

export const SliderContextProvider: React.FC = ({ children }) => {
  const [sliderValue, setSliderValue] = React.useState<number>(0)

  const getSliderPercent = useCallback((amount: number | BigNumber, max: number | BigNumber) => {
    return new BigNumber(amount).multipliedBy(100).dividedBy(max).dp(0, 6).toNumber()
  }, [])

  return (
    <SliderContext.Provider
      value={{
        sliderValue,
        setSliderValue,
        getSliderPercent
      }}
    >
      {children}
    </SliderContext.Provider>
  )
}

const Slider: React.FC<SliderProps> = ({ label, name, unit = '%', min = 1, max = 10, hideMaxLabel = false, inWeeks = false, ...restprops }) => {
  const { darkMode, colors, space, fontSizes } = useTheme()
  const { t: tc } = useTranslation('common')
  return (
    <Flex flexDirection='column'>
      {label && (
        <Flex justifyContent='space-between' alignItems='center'>
          <LabelRebass color={colors.grey[300]} fontSize={fontSizes.sm} mb={space.sm} htmlFor={name} justifyContent='flex-start'>
            {label}
          </LabelRebass>
          {!hideMaxLabel && (
            <LabelRebass color={colors.grey[100]} fontSize={fontSizes[100]} mb={space.sm} htmlFor={name} justifyContent='flex-end'>
              {max}
              {unit}
            </LabelRebass>
          )}
        </Flex>
      )}
      <SliderWithTooltip
        min={min}
        max={max}
        tipFormatter={value => (inWeeks ? (value === 1 ? `${value} ${tc('label.week')}` : `${value} ${tc('label.weeks')}`) : `${value}${unit}`)}
        tipProps={{ placement: 'top' }}
        railStyle={{ background: darkMode ? colors.primary : colors.grey[100] }}
        trackStyle={{ background: darkMode ? colors.primary : colors.primary }}
        handleStyle={{
          background: darkMode ? colors.primary : colors.white,
          borderColor: darkMode ? colors.primary : colors.primary,
          borderWidth: 3,
          height: 18,
          width: 18,
          marginTop: -7
        }}
        {...restprops}
      />
    </Flex>
  )
}

export default Slider
