import { useActiveWeb3React } from 'modules/wallet-module/lib/esm'
import { getSigner } from 'modules/wallet-module/lib/esm/utils/contract'
import moment from 'moment'
import { useCallback } from 'react'
import { useTranslation } from 'next-i18next'

export default function useSignature() {
  const { t: tc } = useTranslation('common')
  const { account, library } = useActiveWeb3React()

  const signMessage = useCallback(async () => {
    try {
      const message = tc('label.verify-address', { account, time: moment().format() })
      const signer = getSigner(library!, account!)
      const signature = await signer.signMessage(message)

      return {
        message,
        signature,
        address: account?.toLocaleLowerCase()
      }
    } catch (err: any) {
      return {
        error: err.message
      }
    }
  }, [account, library, tc])

  return {
    signMessage
  }
}
