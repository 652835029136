import { createContext, useContext } from 'react'
import { GetNftAssetNftAsset } from 'utils/api/get-nft-asset'
import { Screen } from '.'

export type SingleBorrowType = {
  enabled: boolean
  nftAsset?: GetNftAssetNftAsset
}

type BorrowContextType = {
  data?: any
  screenState?: Screen
  setScreenState?: any
  account?: string
  scrollToTop?: any
  setApproveStep?: any
  approvingList?: any
  setApproveList?: any
  approvedList?: any
  setApprovedList?: any
  single?: SingleBorrowType
  wizardStep?: number
  setWizardStep?: any
  handler?: (step: Screen, wizardStep: number) => void[]
  isSingle?: boolean
  stepNumber?: number
}

const BorrowContext = createContext<BorrowContextType>({})

export const useBorrowContext = () => useContext(BorrowContext)

type BorrowProvider = {
  children: React.ReactNode
  value: object
}

export default function BorrowProvider({ children, ...restprops }: BorrowProvider) {
  return <BorrowContext.Provider {...restprops}>{children}</BorrowContext.Provider>
}
