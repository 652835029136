import { LEND_POOL_ADDRESSES_PROVIDER, UI_POOL_DATA_PROVIDER, UI_POOL_DATA_PROVIDER_ABI } from '../../constants'
import { ethers } from 'ethers'
import { rpc } from 'constants/index'
import BigNumber from 'bignumber.js'
import { IGetSimpleLoansData } from './types'
import { JsonRpcProvider } from '@ethersproject/providers'

export class SimpleLoansData {
  private contractAddress = UI_POOL_DATA_PROVIDER
  private contractAbi = UI_POOL_DATA_PROVIDER_ABI
  private lendPoolAddress = LEND_POOL_ADDRESSES_PROVIDER
  private nftAddresses: string[] = []
  private nftTokenIds: string[] = []
  private data: IGetSimpleLoansData[][] | null = null
  private zero = new BigNumber(0)
  private contract

  /**
   * The constructor function is a special function that is called when an instance of a class is
   * created
   * @param {string[]} nftAddresses - An array of addresses of the NFTs.
   * @param {string[]} nftTokenIds - An array of IDs of the NFTs.
   */
  constructor(nftAddresses: string[], nftTokenIds: string[], provider?: JsonRpcProvider) {
    this.nftAddresses = nftAddresses
    this.nftTokenIds = nftTokenIds
    this.contract = new ethers.Contract(this.contractAddress, this.contractAbi, provider || rpc)
  }

  /**
   * It returns a promise that resolves to an array of arrays of simple loan data
   * @returns An array of arrays of structs.
   */
  private async getSimpleLoansData(): Promise<IGetSimpleLoansData[][]> {
    return await this.contract.functions.getSimpleLoansData(this.lendPoolAddress, this.nftAddresses, this.nftTokenIds)
  }

  /**
   * It initializes the data for the SimpleLoansData class.
   * @returns A new instance of the SimpleLoansData class.
   */
  public async init() {
    this.data = await this.getSimpleLoansData()
    // return new SimpleLoansData(this.nftAddresses, this.nftTokenIds)
  }

  /**
   * It returns the loanId of the first loan in the SimpleLoans contract
   * @returns The loanId of the loan
   */
  public async getLoanId(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['loanId']._hex) : this.zero
  }

  /**
   * It returns an array of loan IDs
   * @returns An array of loanIds
   */
  public async getLoanIds(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['loanId']._hex)) : []
  }

  /**
   * It returns the state of the SimpleLoans contract
   * @returns The state of the contract.
   */
  public async getState(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['state']._hex) : this.zero
  }

  /**
   * It returns an array of BigNumber objects, each of which represents the state of a loan
   * @returns The state of the loan.
   */
  public async getStates(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['state']._hex)) : []
  }

  /**
   * It returns the reserve asset of the first loan in the list of simple loans
   * @returns The reserve asset of the first loan in the list of loans.
   */
  public async getReserveAsset(): Promise<string> {
    return this.data ? this.data[0][0]['reserveAsset'] : ''
  }

  /**
   * It returns an array of strings, each string being the name of a reserve asset
   * @returns An array of strings.
   */
  public async getReserveAssets(): Promise<string[]> {
    return this.data ? this.data[0].map(result => result['reserveAsset']) : []
  }

  /**
   * It returns the amount of borrows available in the reserve
   * @returns The available borrows in reserve.
   */
  public async getAvailableBorrowsInReserve(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['availableBorrowsInReserve']._hex) : this.zero
  }

  /**
   * It returns an array of BigNumbers, each of which represents the amount of borrows available in a
   * reserve
   * @returns An array of BigNumbers
   */
  public async getAvailableBorrowsInReserves(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['availableBorrowsInReserve']._hex)) : []
  }

  /**
   * It returns the total debt in the reserve
   * @returns The total debt in reserve.
   */
  public async getTotalDebtInReserve(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['totalDebtInReserve']._hex) : this.zero
  }

  /**
   * It returns an array of BigNumbers, each of which represents the total debt in the reserve for a
   * given loan
   * @returns The total debt in reserve for each loan.
   */
  public async getTotalDebtsInReserve(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['totalDebtInReserve']._hex)) : []
  }

  /**
   * It returns the total amount of collateral in the reserve
   * @returns The total collateral in reserve.
   */
  public async getTotalCollateralInReserve(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['totalCollateralInReserve']._hex) : this.zero
  }

  /**
   * It returns an array of BigNumbers, each of which represents the total amount of collateral in
   * reserve for a given loan
   * @returns The total collateral in reserve for each loan.
   */
  public async getTotalCollateralsInReserve(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['totalCollateralInReserve']._hex)) : []
  }

  /**
   * It returns the health factor of the SimpleLoans contract
   * @returns The health factor of the SimpleLoans contract.
   */
  public async getHealthFactor(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['healthFactor']._hex) : this.zero
  }

  /**
   * It returns an array of health factors for all the loans in the SimpleLoans contract
   * @returns The health factors of the loans.
   */
  public async getHealthFactors(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['healthFactor']._hex)) : []
  }

  /**
   * It returns the liquidation price of the loan
   * @returns The liquidate price of the loan.
   */
  public async getLiquidatePrice(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['liquidatePrice']._hex) : this.zero
  }

  /**
   * It returns the liquidate prices of the liquidation requests.
   * @returns The liquidate price of the collateral asset.
   */
  public async getLiquidatePrices(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['liquidatePrice']._hex)) : []
  }

  /**
   * It returns the address of the bidder of the first loan in the list of loans
   * @returns The bidderAddress of the first loan in the array.
   */
  public async getBidderAddress(): Promise<string> {
    return this.data ? this.data[0][0]['bidderAddress'] : ''
  }

  /**
   * It returns an array of all the bidder addresses in the SimpleLoans contract
   * @returns An array of strings.
   */
  public async getBidderAddresses(): Promise<string[]> {
    return this.data ? this.data[0].map(result => result['bidderAddress']) : []
  }

  /**
   * It returns the bid price of the SimpleLoans contract
   * @returns The bid price of the loan.
   */
  public async getBidPrice(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['bidPrice']._hex) : this.zero
  }

  /**
   * It returns the bid prices of the auction.
   * @returns The bid prices for the auction.
   */
  public async getBidPrices(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['bidPrice']._hex)) : []
  }

  /**
   * It returns the total amount of borrows that have been made on the Compound protocol
   * @returns The amount of borrows in the auction.
   */
  public async getBidBorrowAmount(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['bidBorrowAmount']._hex) : this.zero
  }

  /**
   * It returns an array of BigNumbers, each of which represents the amount of DAI that a user has
   * borrowed from the Compound protocol
   * @returns The bidBorrowAmounts of the SimpleLoans
   */
  public async getBidBorrowAmounts(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['bidBorrowAmount']._hex)) : []
  }

  /**
   * It returns the bidFine value from the SimpleLoans contract
   * @returns The bidFine value from the SimpleLoans contract.
   */
  public async getBidFine(): Promise<BigNumber> {
    return this.data ? new BigNumber(this.data[0][0]['bidFine']._hex) : this.zero
  }

  /**
   * It returns an array of BigNumber objects, each of which represents the bid fine for a loan
   * @returns The bidFine for each loan.
   */
  public async getBidFines(): Promise<BigNumber[]> {
    return this.data ? this.data[0].map(result => new BigNumber(result['bidFine']._hex)) : []
  }
}
