import { useContext, useEffect } from 'react'

// Components
import { Flex, FlexProps } from 'rebass/styled-components'
import { ModalContext } from 'theme/ui'
import Footer from 'components/common/footer'
import { HEADER_HEIGHT } from 'components/common/header'
import WalletCheck from 'components/common/wallet-check'
import HomepageNotifications from 'components/home/homepage-notifications'
import AppNotifications from 'components/common/app-notofications'
import Breadcrumbs from 'components/common/breadcrumbs'

const App: React.FC<FlexProps> = ({ children, ...restprops }): React.ReactElement => {
  const { isOpen: isModalOpen } = useContext(ModalContext)

  useEffect(() => {
    if (!isModalOpen) {
      document.querySelector('body')!.classList.remove('modal-open')
      return
    }
    document.querySelector('body')!.classList.add('modal-open')
  }, [isModalOpen])

  return (
    <Flex flex='auto' flexDirection='column' mt={[HEADER_HEIGHT]} minHeight={`calc(100vh - ${HEADER_HEIGHT}px)`}>
      <Flex flexDirection='column' alignItems='center' width={['100%', '100%', 'auto']} height='100%' flex={1} {...restprops}>
        <HomepageNotifications />
        <WalletCheck />
        <AppNotifications />
        <Breadcrumbs />
        <Flex flexDirection='column' alignItems='center' flexGrow={1} width='100%'>
          {children}
        </Flex>
        <Flex width={'100%'} mt={40}>
          <Footer />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default App
