import { motion, useDomEvent } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { Image as ImageRebass, ImageProps } from 'rebass/styled-components'
import styled from 'styled-components'
import { MotionBox } from 'theme/ui'

const transition = {
  type: 'spring',
  damping: 25,
  stiffness: 120
}

export type PreloadImageProps = {
  zoom?: boolean
}

const PreloadImage: React.FC<ImageProps & PreloadImageProps> = ({ src, width = '100%', height = '100%', zoom, ...restprops }) => {
  const [preloadSrc, setPreloadSrc] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  useDomEvent(useRef(window), 'scroll', () => isOpen && setIsOpen(false))

  useEffect(() => {
    if (!src) return
    const image = new Image()
    image.src = src
    image.onload = () => {
      setPreloadSrc(src)
    }
  }, [src])

  if (zoom)
    return (
      <ImageContainer className={isOpen ? 'open' : ''} width={width} height={height}>
        <motion.div animate={{ opacity: isOpen ? 1 : 0 }} transition={transition} className='shade' onClick={() => setIsOpen(false)} />
        <motion.img
          whileInView='visible'
          viewport={{ once: true }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          src={preloadSrc}
          onClick={() => setIsOpen(!isOpen)}
          layout
          transition={transition}
          style={{ aspectRatio: '1', objectFit: 'cover' }}
        />
      </ImageContainer>
    )

  return (
    <MotionBox whileInView='visible' viewport={{ once: true }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <ImageRebass src={preloadSrc} width={width} height={height} sx={{ display: 'block', aspectRatio: '1', objectFit: 'cover', ...restprops.sx }} />
    </MotionBox>
  )
}

export default PreloadImage

const ImageContainer = styled.div<any>`
  position: relative;
  cursor: zoom-in;

  &.open {
    cursor: zoom-out;
  }

  .shade {
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  &.open .shade {
    pointer-events: auto;
    opacity: 1;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100%;
    z-index: 1001;
  }

  &.open img {
    position: fixed;
    width: auto;
    height: calc(100vh * 0.4);
    min-height: calc(100vh * 0.4);
    max-width: 100%;
    margin: auto;
  }
`
