import React from 'react'

interface IIconUniswap {
  color?: string
}

const IconUniswap = React.forwardRef<SVGSVGElement, IIconUniswap>(({ color = 'currentColor', ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={17.174} height={19.781} viewBox='0 0 17.174 19.781' fill={color} {...rest}>
      <g id='Group_6260' data-name='Group 6260' transform='translate(0)'>
        <g id='Group_6261' data-name='Group 6261'>
          <path
            id='Path_2541'
            data-name='Path 2541'
            d='M90.583,61.321c-.214-.03-.224-.041-.122-.051a3.369,3.369,0,0,1,.959.082,4.123,4.123,0,0,1,2.1,1.408l.184.214.265-.041a5.688,5.688,0,0,1,3.264.408,4.226,4.226,0,0,1,.734.428.986.986,0,0,1,.071.286,1.731,1.731,0,0,1-.112,1.112.3.3,0,0,0-.031.326.307.307,0,0,0,.276.173c.245,0,.5-.388.622-.928l.051-.214.092.1a4.017,4.017,0,0,1,.99,1.959l.02.153-.092-.133a1.724,1.724,0,0,0-.49-.52,3.064,3.064,0,0,0-1.683-.357,6.894,6.894,0,0,1-1.867-.286c-.837-.275-1.265-.632-2.255-1.949a9.373,9.373,0,0,0-.99-1.163A3.461,3.461,0,0,0,90.583,61.321Z'
            transform='translate(-83.849 -56.823)'
            fill={color}
          />
          <path
            id='Path_2542'
            data-name='Path 2542'
            d='M180.228,66.536a2.209,2.209,0,0,1,.184-.877.7.7,0,0,1,.092-.173c.01,0-.01.071-.041.153a1.949,1.949,0,0,0-.041.9c.071.459.1.52.592,1.02.224.235.49.53.592.653l.173.224-.173-.163a6.206,6.206,0,0,0-.816-.643c-.071-.041-.082-.041-.133.01s-.051.1-.051.4a2.186,2.186,0,0,1-.224,1.041c-.082.153-.092.122-.02-.051a1.487,1.487,0,0,0,.061-.632c0-.888-.1-1.1-.724-1.459-.153-.092-.418-.224-.571-.3a2.273,2.273,0,0,1-.275-.133,4.56,4.56,0,0,1,.857.255c.357.143.418.153.459.143.031-.03.051-.112.061-.367'
            transform='translate(-165.894 -60.741)'
            fill={color}
          />
          <path
            id='Path_2543'
            data-name='Path 2543'
            d='M90.389,70.146a3.6,3.6,0,0,1-.643-2.193l.02-.214.1.02a2.4,2.4,0,0,1,.653.245c.408.245.592.581.765,1.418.051.245.122.53.153.622a3.676,3.676,0,0,0,.408.734c.112.163.041.245-.214.224A2.09,2.09,0,0,1,90.389,70.146Z'
            transform='translate(-83.237 -62.832)'
            fill={color}
          />
          <path
            id='Path_2544'
            data-name='Path 2544'
            d='M155.68,123.438c-2.02-.816-2.734-1.52-2.734-2.714,0-.173.01-.316.01-.316a1.919,1.919,0,0,1,.173.133,4.078,4.078,0,0,0,2.142.653,8.879,8.879,0,0,1,1.561.326,2.98,2.98,0,0,1,2.183,2.377,4.127,4.127,0,0,1-.061,1.275,2.555,2.555,0,0,1-.326.734c-.01,0-.031-.051-.031-.133a1.66,1.66,0,0,0-.592-1.153A8.15,8.15,0,0,0,155.68,123.438Z'
            transform='translate(-141.866 -111.686)'
            fill={color}
          />
          <path
            id='Path_2545'
            data-name='Path 2545'
            d='M115.445,159.436a2.423,2.423,0,0,0-.1-.428l-.051-.153.092.112a1.8,1.8,0,0,1,.326.592,1.273,1.273,0,0,1,.071.571,1.3,1.3,0,0,1-.071.551,1.843,1.843,0,0,1-.428.683,2.455,2.455,0,0,1-1.53.673c-.122.01-.469.041-.775.061a5.983,5.983,0,0,0-1.734.286c-.061.02-.122.041-.133.031a2.312,2.312,0,0,1,.551-.326,5.551,5.551,0,0,1,1.53-.408,7,7,0,0,0,.908-.184A1.763,1.763,0,0,0,115.445,159.436Z'
            transform='translate(-103.059 -147.347)'
            fill={color}
          />
          <path
            id='Path_2546'
            data-name='Path 2546'
            d='M180.476,167.088a2.38,2.38,0,0,1-.184-1.653c.02-.051.041-.1.061-.1a.44.44,0,0,1,.143.071c.122.082.377.224,1.03.581a5.21,5.21,0,0,1,1.622,1.194,2.523,2.523,0,0,1,.541,1.234,4.048,4.048,0,0,1-.051,1.214,3.007,3.007,0,0,1-1.479,1.959,1.416,1.416,0,0,1-.214.1.813.813,0,0,1,.092-.224,2.025,2.025,0,0,0,.092-1.581,6.5,6.5,0,0,0-.816-1.469A9.689,9.689,0,0,1,180.476,167.088Z'
            transform='translate(-167.162 -153.356)'
            fill={color}
          />
          <path
            id='Path_2547'
            data-name='Path 2547'
            d='M76.127,216.525a5.545,5.545,0,0,1,2.54-1.224,3.782,3.782,0,0,1,1.316.051,2.272,2.272,0,0,1,1.285.826,3.047,3.047,0,0,1,.469,1.387c.041.275.092.561.1.622a1.369,1.369,0,0,0,.449.806,1.553,1.553,0,0,0,1.408.041.691.691,0,0,1,.173-.051,1.564,1.564,0,0,1-.439.3,1.445,1.445,0,0,1-.734.173,1.473,1.473,0,0,1-1.245-.765,5.745,5.745,0,0,1-.337-.673,2.454,2.454,0,0,0-.959-1.347,1.257,1.257,0,0,0-1.194-.112.768.768,0,0,0-.265,1.194,1.044,1.044,0,0,0,.581.306.622.622,0,0,0,.7-.622.488.488,0,0,0-.337-.5c-.326-.143-.683.02-.673.337a.281.281,0,0,0,.194.275c.082.041.082.041.02.031a.378.378,0,0,1-.133-.663.734.734,0,0,1,1.092.235,1.069,1.069,0,0,1,.02.694.954.954,0,0,1-1.163.551,1.663,1.663,0,0,1-.888-.6,2.711,2.711,0,0,0-2.01-1.03l-.194-.031Z'
            transform='translate(-70.414 -199.672)'
            fill={color}
          />
          <path
            id='Path_2548'
            data-name='Path 2548'
            d='M.347.439C2.724,3.326,6.386,7.815,6.57,8.059c.153.2.092.4-.163.541a1.56,1.56,0,0,1-.581.163.736.736,0,0,1-.49-.214c-.092-.092-.49-.673-1.387-2.071C3.265,5.407,2.683,4.519,2.673,4.509a16.283,16.283,0,0,0,1.2,2.2A17.852,17.852,0,0,1,4.917,8.671c0,.133-.041.2-.2.388a2.27,2.27,0,0,0-.49,1.377,4.268,4.268,0,0,1-1.163,2.346,4.191,4.191,0,0,0-.643.908,1.725,1.725,0,0,0-.2.816,2.552,2.552,0,0,0,.133,1,3.406,3.406,0,0,0,.49.959,2.377,2.377,0,0,1,.377.745c0,.082.02.082.388,0a4.035,4.035,0,0,0,2-.979.924.924,0,0,0,.306-.775.832.832,0,0,0-.071-.428,1.831,1.831,0,0,0-.714-.694,1.613,1.613,0,0,1-.847-1.092,1.92,1.92,0,0,1,.316-1.224,3.659,3.659,0,0,0,.449-1.612c.031-.428.082-.6.2-.734a.761.761,0,0,1,.561-.235,1.869,1.869,0,0,0,1.122-.459.959.959,0,0,0,.347-.745L7.284,8l-.133-.143C6.672,7.3.031,0,0,0-.01,0,.153.194.347.439ZM3.479,14.945a.421.421,0,0,0-.133-.561c-.173-.112-.439-.061-.439.092a.1.1,0,0,0,.082.1c.092.051.1.1.031.214s-.071.214.02.286A.292.292,0,0,0,3.479,14.945Z'
            transform='translate(0)'
            fill={color}
            fillRule='evenodd'
          />
          <path
            id='Path_2549'
            data-name='Path 2549'
            d='M97.745,131.919a.905.905,0,0,0-.551.6.879.879,0,0,0,.051.551c.112.143.214.184.5.184.561,0,1.041-.245,1.092-.541a.789.789,0,0,0-.459-.734A1.037,1.037,0,0,0,97.745,131.919Zm.653.51a.23.23,0,0,0-.1-.347c-.275-.173-.694-.031-.694.235,0,.133.214.275.418.275A.563.563,0,0,0,98.4,132.429Z'
            transform='translate(-90.134 -122.34)'
            fill={color}
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
})

export default IconUniswap
