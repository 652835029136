import { IPermalink, IRoutes } from './types'

export const permalink: IPermalink = {
  home: '/',
  dashboard: '/account',
  deposit: '/yielding/deposit-eth',
  withdraw: '/account/deposits?action=withdraw',
  liquidityDepositEth: '/liquidity/deposit-eth',
  borrow: '/borrow',
  downPayment: '/down-payment',
  batchBorrow: '/account/my-nfts?action=batch-borrow',
  repay: '/repay',
  nftCustody: '/nft-custody',
  nftDashboardCustody: '/account/nft-custody',
  nftCustodyDeposit: '/nft-custody/deposit',
  nftCustodyWithdraw: '/nft-custody/withdraw',
  auction: '/auctions',
  bid: '/auctions/bid',
  vebendLocking: '/yielding/lock-bend',
  fairLaunch: '/fair-launch',
  trade: '',
  forum: 'https://forum.benddao.xyz/',
  governance: 'https://snapshot.org/#/benddao.eth',
  analytics: '/analytics',
  analyticsCollection: '/analytics/collection',
  reserveNotFound: '/error/reserve-not-found',
  loanNotFound: '/error/loan-not-found',
  somethingWentWrong: '/error/something-went-wrong',
  nftFaucet: '/nft-faucet',
  myDeposits: '/account/deposits',
  myRewards: '/account/rewards',
  mySettings: '/account/settings',
  flashClaim: '/flash-claim',
  flashClaimAirdrop: '/flash-claim/airdrop',
  flashClaimTransferBack: '/flash-claim/transfer-back',
  flashClaimTransferBackDooples: '/flash-claim/transfer-back-dooples',
  liquidityFarming: '/yielding/liquidity-mining',
  myNotifications: '/account/notifications',
  dashboardOverview: '/account/dashboard',
  nfts: '/account/my-nfts',
  dashboardReserves: '/account/deposits',
  dashboardReservesHistory: '/account/deposit-history',
  dashboardBorrowsHistory: '/account/borrows-history',
  dashboardAuctions: '/account/auctions',
  dashboardSettings: '/account/settings',
  transferBack: '/flash-claim/transfer-back',
  auctionLoansInAuction: '/auctions/loans-in-auction',
  auctionAvailableToLiquidate: '/auctions/available-to-liquidate',
  auctionAvailableToAuction: '/auctions/available-to-auction',
  auctionHealthFactorAlertList: '/auctions/health-factor-alert-list',
  auctionHistory: '/auctions/auction-history',
  batchRepay: '/liquidity/batch-repay',
  downpayment: '/liquidity/buy/down-payment',
  liquidityExplore: '/liquidity/buy-nfts',
  liquidityAsset: '/asset',
  asset: '/asset',
  liquidityListAsset: '/liquidity/list-asset',
  liquidityBuyAsset: '/liquidity/buy',
  liquidity: '/liquidity',
  yielding: '/yielding',
  liquidityCollateralListing: '/liquidity/sell-nfts',
  marketplaceActivity: '/liquidity/activity',
  offer: '/asset/offer',
  adjustOffer: '/asset/adjust-offer',
  cancelOffer: '/asset/cancel-offer',
  acceptOffer: '/asset/accept-offer',
  myOffers: '/account/my-offers',
  ecosystem: '/pages/ecosystem',
  myNfts: '/account/my-nfts?type_in=original',
  myBorrows: '/account/my-nfts?type_in=in_loan',
  accountDanglingOrders: '/account/inactive-orders'
}

const routes: IRoutes[] = [
  {
    type: 'nav-item',
    name: 'Home',
    icon: 'IconHome',
    route: '/',
    auth: false,
    external: false
  },
  {
    type: 'nav-item',
    name: 'Liquidity',
    icon: 'IconMarketplace',
    route: permalink.liquidity,
    customRoute: permalink.liquidityExplore,
    auth: false,
    external: false,
    children: [
      {
        type: 'nav-item',
        parent: 'Yielding',
        name: 'Deposit ETH',
        icon: 'IconEth',
        route: permalink.liquidityDepositEth,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        parent: 'Liquidity',
        name: 'Borrow ETH',
        icon: 'IconBorrow',
        route: permalink.batchBorrow,
        auth: false,
        external: false
      },
      {
        type: 'separator',
        parent: 'Liquidity',
        name: 'liquidity-separator-1',
        icon: '',
        route: '',
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        parent: 'Liquidity',
        name: 'Buy NFTs',
        icon: 'IconMarketplace',
        route: permalink.liquidityExplore,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        parent: 'Liquidity',
        name: 'Sell NFTs',
        icon: 'IconBNFT',
        route: permalink.liquidityCollateralListing,
        auth: false,
        external: false
      },
      {
        type: 'separator',
        parent: 'Liquidity',
        name: 'liquidity-separator-2',
        icon: '',
        route: '',
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        parent: 'Liquidity',
        name: 'Down Payment Buy',
        icon: 'IconDownPayment',
        route: permalink.downpayment,
        auth: false,
        external: false
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'Yield',
    icon: 'IconYielding',
    route: permalink.yielding,
    customRoute: permalink.vebendLocking,
    auth: false,
    external: false,
    children: [
      {
        type: 'nav-item',
        parent: 'Yielding',
        name: 'Deposit ETH',
        icon: 'IconEth',
        route: permalink.deposit,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        parent: 'Yielding',
        name: 'Lock BEND',
        icon: 'IconStake',
        route: permalink.vebendLocking,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        parent: 'Yielding',
        name: 'Liquidity mining',
        icon: 'IconLiquidityFarming',
        route: permalink.liquidityFarming,
        auth: false,
        external: false
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'Auctions',
    icon: 'IconAuction',
    route: permalink.auction,
    customRoute: permalink.auctionAvailableToAuction,
    auth: false,
    external: false,
    children: [
      {
        type: 'nav-item',
        name: 'Available to Auction',
        icon: 'IconActiveAuctions2',
        route: permalink.auctionAvailableToAuction,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        name: 'Loans in Auction',
        icon: 'IconAuction',
        route: permalink.auctionLoansInAuction,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        name: 'Health factor Alert list',
        icon: 'IconAuctionAlert',
        route: permalink.auctionHealthFactorAlertList,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        name: 'Auction history',
        icon: 'IconHistory',
        route: permalink.auctionHistory,
        auth: false,
        external: false
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'Community',
    icon: 'IconCollaterals',
    route: '#',
    external: false,
    onClick: (e: any) => e.preventDefault,
    children: [
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'Discord',
        icon: 'IconDiscord',
        route: 'https://discord.gg/benddao',
        auth: false,
        external: true
      },
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'Twitter',
        icon: 'IconTwitter',
        route: 'https://twitter.com/benddao',
        auth: false,
        external: true
      },
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'GitHub',
        icon: 'IconGithub',
        route: 'https://github.com/BendDAO',
        auth: false,
        external: true
      },
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'Dework',
        icon: 'IconDework',
        route: 'https://app.dework.xyz/benddao',
        auth: false,
        external: true
      },
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'Forum',
        icon: 'IconForum',
        route: permalink.forum,
        auth: false,
        external: true
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'More',
    icon: 'IconMore',
    route: '#',
    external: false,
    onClick: (e: any) => e.preventDefault,
    children: [
      {
        type: 'nav-item',
        parent: 'More',
        name: 'Flash claim',
        icon: 'IconFlashClaimHeader',
        route: permalink.flashClaim,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        parent: 'More',
        name: 'Ecosystem',
        icon: 'IconEcosystem',
        route: permalink.ecosystem,
        auth: false,
        external: false,
        isNew: false
      },
      {
        type: 'nav-item',
        parent: 'More',
        name: 'Dune Analytics',
        icon: 'IconDuneAnalytics',
        route: 'https://dune.com/cgq0123/Bend-DAO',
        auth: false,
        external: true,
        isNew: false
      },
      {
        type: 'nav-item',
        parent: 'More',
        name: 'Analytics',
        icon: 'IconAnalytics',
        route: permalink.analytics,
        auth: false,
        external: false,
        isNew: false
      },
      {
        type: 'nav-item',
        parent: 'More',
        name: 'Fair Launch',
        icon: 'IconIdoHeader',
        route: 'https://medium.com/@BendDAO/benddao-fair-launch-fcb1e3c5e4a3',
        auth: false,
        external: true
      }
    ]
  }
]

export const auctionSidebarRoute: IRoutes = {
  type: 'nav-item',
  name: 'Auction',
  icon: 'IconAuction',
  route: permalink.auction,
  auth: false,
  external: false,
  children: [
    {
      type: 'nav-item',
      name: 'Available to Auction',
      icon: 'IconActiveAuctions2',
      route: permalink.auctionAvailableToAuction,
      auth: false,
      external: false
    },
    {
      type: 'nav-item',
      name: 'Loans in Auction',
      icon: 'IconAuction',
      route: permalink.auctionLoansInAuction,
      auth: false,
      external: false
    }
  ]
}

export const accountRoute: IRoutes = {
  type: 'nav-item',
  name: 'Account',
  icon: 'IconDashboard',
  route: permalink.dashboard,
  external: false,
  children: [
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Dashboard',
      icon: 'IconGridList',
      route: permalink.dashboardOverview,
      auth: false,
      external: false,
      onlyMobile: true
    },
    {
      type: 'separator',
      parent: 'Dashboard',
      name: 'dash-separator-1',
      icon: '',
      route: ''
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'My NFTs',
      icon: 'IconBNFT',
      route: permalink.nfts,
      auth: false,
      external: false
    },
    // {
    //   type: 'nav-item',
    //   parent: 'Dashboard',
    //   name: 'Borrows',
    //   icon: 'IconBorrow',
    //   route: permalink.myBorrows,
    //   auth: false,
    //   external: false
    // },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Auctions',
      icon: 'IconMyAuctions',
      route: permalink.dashboardAuctions,
      auth: false,
      external: false
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Deposits',
      icon: 'IconReserve',
      route: permalink.dashboardReserves,
      auth: false,
      external: false
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Rewards',
      icon: 'IconClaimRewards',
      route: permalink.myRewards,
      auth: false,
      external: false
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'My Offers',
      icon: 'IconOffer',
      route: permalink.myOffers,
      auth: false,
      external: false
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Inactive Orders',
      icon: 'IconDandlingCancel',
      route: permalink.accountDanglingOrders,
      auth: false,
      external: false
    },
    {
      type: 'separator',
      parent: 'Dashboard',
      name: 'dash-separator-2',
      icon: '',
      route: ''
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Custody',
      icon: 'IconNftVault',
      route: permalink.nftDashboardCustody,
      auth: false,
      external: false
    },
    {
      type: 'separator',
      parent: 'Dashboard',
      name: 'dash-separator-3',
      icon: '',
      route: ''
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Settings',
      icon: 'IconSettings',
      route: permalink.dashboardSettings,
      auth: false,
      external: false
    }
  ]
}

export default routes
