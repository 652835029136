import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'

export enum NotificationColor {
  green = 'green.1000',
  red = 'red.1000',
  yellow = 'yellow.1000',
  primary = 'primary'
}

type Notification = {
  key: string | number
  time: number
  color: NotificationColor
  title: string
  content: string | ReactNode
  active: boolean
  link?: string
  external?: boolean
  close?: boolean
}

type InitialState = {
  notifications: Array<Notification>
  getTextColor: (background: NotificationColor) => string
}

type NotificationsProviderProps = {
  children: ReactNode
}

const initialState = {
  notifications: [],
  getTextColor: () => ''
}

const NotificationsContext = createContext<InitialState>(initialState)

export const useNotifications = () => useContext(NotificationsContext)

export default function NotificationsProvider({ children }: NotificationsProviderProps) {
  const notifications = useMemo(
    () => [
      /*       {
        key: 'f8af3ca9-9f3f-4b1a-8e7b-2892a6b5faca',
        time: 1661860800,
        color: NotificationColor.green,
        title: 'Update Notice',
        content: (
          <>
            <Text>85% Liquidation Threshold</Text>
            <Separator />
            <Text>24 hours Auction Duration</Text>
            <Separator />
            <Text>New voting for adjusting Auction Duration to 12 hours</Text>
          </>
        ),
        active: true,
        link: 'https://snapshot.org/#/benddao.eth/proposal/0x767fc08730f53dcd272b6a95b67256d5e125b9c8b9c67d923ee88857ebece9d2',
        external: true
      }, */
      // {
      //   key: 'f8af3ca9-9f3f-4b1a-8e7b-2892a6b5faca',
      //   time: 1662465600,
      //   color: NotificationColor.green,
      //   title: 'Update Notice',
      //   content: (
      //     <>
      //       <Text>80% Liquidation Threshold </Text>
      //       <Separator />
      //       <Text>24 hours Auction Duration</Text>
      //       <Separator />
      //       <Text>New voting for adjusting Liquidation Threshold to 75%</Text>
      //     </>
      //   ),
      //   active: true,
      //   link: 'https://snapshot.org/#/benddao.eth/proposal/0xa43190c22344bc4942dec1e6fb636c4c308f4ca20170930605922dc1b955a289',
      //   external: true
      // }
      // {
      //   key: 'f8af3ca9-9f3f-4b1a-8e7b-2892a6b5faca',
      //   time: 1663226037,
      //   color: NotificationColor.green,
      //   title: 'Maintenance Notice',
      //   content: (
      //     <>
      //       <Text textAlign='center' lineHeight={['xl', 'xl', 'xl', 'lg']}>
      //         All BendDAO features will be temporarily paused approximatelly 3 hours before the #Merge at block 15,540,293.
      //       </Text>
      //     </>
      //   ),
      //   active: true,
      //   link: 'https://twitter.com/BendDAO/status/1567436580176150528',
      //   external: true,
      //   close: false
      // }
    ],
    []
  )

  const getTextColor = useCallback((background: NotificationColor) => {
    switch (background) {
      default:
      case NotificationColor.green:
        return 'white'
      case NotificationColor.red:
        return 'white'
      case NotificationColor.primary:
        return 'white'
      case NotificationColor.yellow:
        return 'black'
    }
  }, [])

  return <NotificationsContext.Provider value={{ notifications, getTextColor }}>{children}</NotificationsContext.Provider>
}

// const Separator = () => {
//   return (
//     <Box sx={{ opacity: 0.5, mx: [0, 0, 0, 15], my: [10, 10, 10, 0] }}>
//       <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'>
//         <defs></defs>
//         <circle id='Ellipse_424' data-name='Ellipse 424' fill='white' cx='4' cy='4' r='4' />
//       </svg>
//     </Box>
//   )
// }
