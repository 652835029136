import { useEffect, useMemo } from 'react'
import { useActiveWeb3React } from 'wallet-module'
import { parseCallKey, toCallKey } from '../utils'
import { useMulticall } from './useMulticall'
import { DEFAULT_CHAIN_ID } from 'constants/index'
const INVALID_RESULT = {
  valid: false,
  blockNumber: undefined,
  data: undefined
}
const showDebug = false
export function useCallsData(calls, options) {
  const { MulticallDispatch, callResults, callListeners } = useMulticall()
  if (showDebug) console.log('useCallsData init calls', JSON.stringify(calls))
  if (showDebug) console.log('useCallsData init callResults', JSON.stringify(callResults))
  if (showDebug) console.log('useCallsData init callListeners', JSON.stringify(callListeners))
  const serializedCallKeys = useMemo(() => {
    var _a, _b, _c
    return JSON.stringify(
      (_c =
        (_b = (_a = calls === null || calls === void 0 ? void 0 : calls.filter(c => Boolean(c))) === null || _a === void 0 ? void 0 : _a.map(toCallKey)) ===
          null || _b === void 0
          ? void 0
          : _b.sort()) !== null && _c !== void 0
        ? _c
        : []
    )
  }, [calls])
  useEffect(() => {
    var _a
    const callKeys = JSON.parse(serializedCallKeys)
    if (callKeys.length === 0) return undefined
    const calls = callKeys.map(key => parseCallKey(key))
    if (showDebug) console.log('useCallsData useEffect calls = serializedCallKeys', calls)
    if (showDebug) console.log('useCallsData useEffect callListeners', callListeners)
    const listeners = callListeners !== null && callListeners !== void 0 ? callListeners : {}
    listeners[DEFAULT_CHAIN_ID] = (_a = listeners[DEFAULT_CHAIN_ID]) !== null && _a !== void 0 ? _a : {}
    const blocksPerFetch = (options === null || options === void 0 ? void 0 : options.blocksPerFetch) ? options.blocksPerFetch : 1
    const callback = options === null || options === void 0 ? void 0 : options.callback
    calls.forEach(call => {
      var _a, _b
      const callKey = toCallKey(call)
      listeners[DEFAULT_CHAIN_ID][callKey] = (_a = listeners[DEFAULT_CHAIN_ID][callKey]) !== null && _a !== void 0 ? _a : {}
      listeners[DEFAULT_CHAIN_ID][callKey][blocksPerFetch] =
        ((_b = listeners[DEFAULT_CHAIN_ID][callKey][blocksPerFetch]) !== null && _b !== void 0 ? _b : 0) + 1
      listeners[DEFAULT_CHAIN_ID][callKey].callback = callback
    })
    MulticallDispatch({
      type: 'UPDATE',
      payload: {
        callListeners: listeners
      }
    })
    return () => {
      const listeners = callListeners !== null && callListeners !== void 0 ? callListeners : {}
      if (showDebug) console.log('useCallsData useEffect removing callListeners:', JSON.stringify(callListeners))
      if (showDebug) console.log('useCallsData useEffect removing calls:', JSON.stringify(callListeners))
      if (!listeners[DEFAULT_CHAIN_ID]) return
      calls.forEach(call => {
        const callKey = toCallKey(call)
        if (!listeners[DEFAULT_CHAIN_ID][callKey]) return
        if (!listeners[DEFAULT_CHAIN_ID][callKey][blocksPerFetch]) return
        if (listeners[DEFAULT_CHAIN_ID][callKey][blocksPerFetch] === 1) {
          delete listeners[DEFAULT_CHAIN_ID][callKey][blocksPerFetch]
        } else {
          listeners[DEFAULT_CHAIN_ID][callKey][blocksPerFetch]--
        }
      })
      if (showDebug) console.log('useCallsData useEffect removing new listeners:', JSON.stringify(listeners))
      MulticallDispatch({
        type: 'UPDATE',
        payload: {
          callListeners: listeners
        }
      })
    }
  }, [MulticallDispatch, callListeners, serializedCallKeys])
  return calls.map(call => {
    var _a
    if (showDebug) console.log('useCallsData return calls call', JSON.stringify(call))
    if (!call) return INVALID_RESULT
    if (showDebug) console.log('useCallsData return callResults ', JSON.stringify(callResults))
    if (showDebug) console.log('useCallsData return callResults[chainId] ', callResults[DEFAULT_CHAIN_ID])
    const result = (_a = callResults[DEFAULT_CHAIN_ID]) === null || _a === void 0 ? void 0 : _a[toCallKey(call)]
    if (showDebug) console.log('useCallsData return result ', result)
    let data
    if ((result === null || result === void 0 ? void 0 : result.data) && (result === null || result === void 0 ? void 0 : result.data) !== '0x') {
      data = result.data
    }
    return { valid: true, data, blockNumber: result === null || result === void 0 ? void 0 : result.blockNumber }
  })
}
