import BigNumber from 'bignumber.js'
import { useBorrowApy } from 'hooks/subscriptions/useBorrowApy'
import NumberFormat from '../number-format'
import { NumberFormatProps } from '../number-format/interfaces'

interface BorrowApyProps {
  initialValue: BigNumber
  reserveId: string
}

const BorrowApy: React.FC<BorrowApyProps & Omit<NumberFormatProps, 'number'>> = ({ initialValue, reserveId, ...restprops }) => {
  const { borrowApy } = useBorrowApy({ reserveId, initialValue })
  return <NumberFormat number={borrowApy} {...restprops} />
}

export default BorrowApy
