import { Flex, Text, FlexProps, Box } from 'rebass/styled-components'
import { Avatar, Button, IconSuccess, Input } from 'theme/ui'
// import { useTranslation } from 'next-i18next'
import SpacerDash from '../spacer-dash'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { UpdateUserEmailProps, useUserContext } from 'contexts/user'
import { useMutation, UseMutationResult } from 'react-query'
import useResponsive from 'hooks/common/useResponsive'
import { useModalContext } from '../modal/components/context'
import BigNumber from 'bignumber.js'

type SetUpEmailProps = {
  collateralCount?: number
  totalBorrowAmount?: BigNumber
}

export const SetUpEmail: React.FC<FlexProps & SetUpEmailProps> = ({ collateralCount, totalBorrowAmount, ...restprops }) => {
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('common')
  const { isTablet } = useResponsive()
  const { handleClose, enableKiosk, disableKiosk } = useModalContext()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const { updateUserEmail } = useUserContext()
  const { mutate, isLoading }: UseMutationResult<UpdateUserEmailProps, any, any, any> = useMutation(data => updateUserEmail(data), {
    onSuccess: () => {
      handleClose?.()
      disableKiosk?.()
    },
    onError: error => {
      console.log('Error updating user email.', error)
      disableKiosk?.()
    }
  })

  const onSubmit = useCallback(
    ({ email }) => {
      enableKiosk?.()
      mutate({ email })
    },
    [enableKiosk, mutate]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex variant='card-body-modal' maxWidth={[580]} flex={1} {...restprops}>
        <Flex variant={!isTablet ? 'card5-modal' : 'card5'} flexDirection='column' alignItems='center' flex={1}>
          <Flex flexDirection='column' alignItems='center' px={[20, 20, 25]} pt={[20, 20, 25]}>
            <Avatar
              iconSize={69}
              hasBackground
              hasBorderRadius={16}
              icon={
                <Flex justifyContent='center' alignItems='center'>
                  <IconSuccess size={33} color='white' />
                </Flex>
              }
            />
            <Text fontSize='xxl' fontWeight='bold' textAlign='center' mt={20} mb={10}>
              {tc('label.tx-success')}
            </Text>
            <Text
              fontSize={13}
              fontWeight='500'
              // color='grey.300'
              textAlign='center'
              mb={20}
              lineHeight={1.5}
              dangerouslySetInnerHTML={{
                __html: t('batch-borrow.step-success', { collateralCount, amount: totalBorrowAmount, assetTicker: 'ETH' })
              }}
            />
          </Flex>
          <SpacerDash bgColor='grey.100' height={4} width='100%' />
          <Box my={20} width='100%' textAlign='center' px={[20, 20, 25]}>
            <Text as='span' lineHeight='22px' fontSize='md' dangerouslySetInnerHTML={{ __html: tc('label.no-email-recommendation') }} />
          </Box>
          <Box mb={20} width='100%' textAlign='center' px={[20, 20, 25]}>
            <Input
              left='Email address'
              placeholder='@'
              size='lg'
              errors={errors}
              {...register('email', {
                required: 'Field email is required.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }
              })}
            />
          </Box>
          <Box px={[20, 20, 25]} width='100%'>
            <Button type='submit' size='xl' width='100%' maxWidth={[537]} color='primary' loading={isLoading} disabled={isLoading}>
              {tc('button.save-email-settings')}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </form>
  )
}
