import { useCallback, useMemo } from 'react'
import { isAddress, ERC20_INTERFACE, approve } from 'wallet-module'

import { useMultipleContractSingleData, useSingleCallResult } from 'multicall-module'
import { useERC20Contract } from '.'
import BigNumber from 'bignumber.js'
import get from 'lodash/get'

const showDebug = false

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useAllowancesWithLoadingIndicator(
  account?: string,
  address?: string,
  tokens?: (string | undefined)[]
): [{ [tokenAddress: string]: any | undefined }, boolean] {
  if (showDebug) console.log('useAllowancesWithLoadingIndicator got tokens', JSON.stringify(tokens))
  const validatedTokens: string[] = useMemo(() => tokens?.filter((t?: string): t is string => isAddress(t) !== false) ?? [], [tokens])
  if (showDebug) console.log('useAllowancesWithLoadingIndicator got validatedTokens', validatedTokens)
  const validatedTokenAddresses = useMemo(() => validatedTokens.map(vt => vt), [validatedTokens])

  if (showDebug) console.log('useAllowancesWithLoadingIndicator got validatedTokenAddresses', JSON.stringify(validatedTokenAddresses))
  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'allowance', [account, address])

  if (showDebug) console.log('useAllowancesWithLoadingIndicator got balances', balances)
  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{
              [tokenAddress: string]: any | undefined
            }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? value.toString() : undefined
              if (amount) {
                memo[token] = {
                  token: token,
                  amount: amount
                }
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading
  ]
}

export function useAllowances(account?: string, address?: string, tokens?: (string | undefined)[]): { [tokenAddress: string]: any | undefined } {
  return useAllowancesWithLoadingIndicator(account, address, tokens)[0]
}

export const useApprove = (tokenAddress: string, addressToApprove: string) => {
  const tokenContract = useERC20Contract(tokenAddress)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(tokenContract, addressToApprove)
      console.log({ tx })
      return tx
    } catch (e) {
      console.log({ e })
      if (typeof e === 'string') throw { msg: e }
      else return e
    }
  }, [tokenContract, addressToApprove])

  return { onApprove: handleApprove }
}

export const useBalanceOf = (contractAddress: string, account: string) => {
  const tokenContract = useERC20Contract(contractAddress)
  const { result } = useSingleCallResult(tokenContract, 'balanceOf', [account])

  const balance = useMemo(() => {
    if (!result) return new BigNumber(0)
    return new BigNumber(get(result, 0).toString()).dividedBy(1e18).dp(4, 1)
  }, [result])

  return balance
}
