import useTheme from 'hooks/common/useTheme'
import isEmpty from 'lodash/isEmpty'
import Link from 'components/common/link'
import { Fragment } from 'react'
import { useTranslation } from 'next-i18next'
import { Flex, Text } from 'rebass/styled-components'
import { Container, Icon } from 'theme/ui'
import { useBreadcrumbsContext } from './context'

const Breadcrumbs = () => {
  const { t: tc } = useTranslation('common')
  const { breadcrumbs, style } = useBreadcrumbsContext()
  const { colors } = useTheme()

  if (isEmpty(breadcrumbs)) return null

  return (
    <Container variant='card-body' sx={{ width: '100%', maxWidth: 1400, alignItems: 'center', mt: [20], mb: [-10], ...style }}>
      <Link href='/' passHref>
        <Flex as='a' color='black' variant='breadcrumb' ml={0}>
          {tc('label.home')}
        </Flex>
      </Link>
      <Separator />
      {breadcrumbs &&
        breadcrumbs?.map((breadcrumb, index) => (
          <Fragment key={breadcrumb.key}>
            {breadcrumb.href ? (
              <Link href={breadcrumb.href} passHref>
                <Flex as='a' variant='breadcrumb' color='black'>
                  {breadcrumb.icon && <Icon icon={breadcrumb.icon} size={16} color={colors.primary} />}
                  <Text as='span' ml={breadcrumb.icon ? 5 : undefined}>
                    {breadcrumb.label}
                  </Text>
                </Flex>
              </Link>
            ) : (
              <Flex as='span' variant='breadcrumb'>
                {breadcrumb.icon && <Icon icon={breadcrumb.icon} size={16} color={colors.primary} />}
                <Text as='span' ml={breadcrumb.icon ? 5 : undefined}>
                  {breadcrumb.label}
                </Text>
              </Flex>
            )}
            {index < breadcrumbs.length - 1 && <Separator />}
          </Fragment>
        ))}
    </Container>
  )
}

export default Breadcrumbs

const Separator = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='9.419' height='7.616' viewBox='0 0 9.419 7.616'>
    <g id='Group_9267' data-name='Group 9267' transform='translate(-313.571 -110.692)'>
      <path
        id='Rectangle_5_copy'
        data-name='Rectangle 5 copy'
        d='M6,1.258,3.25,3.629.5,1.258'
        transform='translate(313.371 117.75) rotate(-90)'
        fill='none'
        stroke='#b1b1b1'
        strokeLinecap='round'
        strokeWidth='1.5'
      />
      <path
        id='Rectangle_5_copy-2'
        data-name='Rectangle 5 copy'
        d='M6,1.258,3.25,3.629.5,1.258'
        transform='translate(318.371 117.75) rotate(-90)'
        fill='none'
        stroke='#b1b1b1'
        strokeLinecap='round'
        strokeWidth='1.5'
      />
    </g>
  </svg>
)
