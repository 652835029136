import useTheme from 'hooks/common/useTheme'
import { Flex, Box, Text } from 'rebass/styled-components'
import { useTranslation } from 'next-i18next'
import { LS_TOKEN } from 'constants/index'
import { Button, Modal, Spacer, useModal, NotificationSmall } from 'theme/ui'
import { useFormContext } from 'react-hook-form'
import { useBorrowContext } from '../context'
import { useCallback, useEffect, useMemo } from 'react'
import { Screen } from '..'
import SimpleBar from 'modules/simplebar-react'
import useBatchBorrow, { NotificationState, useBatchBorrowDelegate } from 'modules/bend/hooks/useBatchBorrow'
import { useUserContext } from 'contexts/user'
import { SetUpEmail } from 'components/common/set-up-email'
import { BnftItem, NftCollateral } from '../details/nft-collateral'

export const ApproveTokensStep: React.FC = () => {
  const { colors } = useTheme()
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('common')
  const { watch, setValue } = useFormContext()
  const { account, approvedList, setApprovedList, setApproveList, handler, isSingle } = useBorrowContext()
  const { notificationState, setNotificationState } = useBatchBorrow(account || '')
  const { buttonState: buttonStateDelegate, handleApproveDelegation } = useBatchBorrowDelegate(account || '')
  const { user } = useUserContext()
  const [toggleModal] = useModal(
    <Modal noLayoutStyle width={620} padding={10}>
      <SetUpEmail mt={-20} mb={20} mx={-10} />
    </Modal>
  )

  const bnftItems = watch('bnftItems')
  const totalBorrowAmount = watch('totalBorrowAmount')
  const type = watch('type')

  const bnftItemsParsed = useMemo((): Array<BnftItem> => {
    if (!bnftItems) return []
    return bnftItems.map((bnftItem: string) => JSON?.parse(bnftItem))
  }, [bnftItems])

  useEffect(() => {
    let allApproved = true
    if (type !== 'erc721') {
      for (let i = 0; i < bnftItemsParsed.length; i++) {
        const element = bnftItemsParsed[i]
        if (!approvedList.find((item: string) => item === element.tokenID)) allApproved = false
      }
    } else {
      for (let i = 0; i < bnftItemsParsed.length; i++) {
        const element = bnftItemsParsed[i]
        if (!approvedList.find((item: string) => item === element.address)) allApproved = false
      }
    }
    if (buttonStateDelegate !== 'approved') allApproved = false

    if (allApproved) setNotificationState(NotificationState.APPROVED)
  }, [bnftItemsParsed, approvedList, type, setNotificationState, buttonStateDelegate])

  useEffect(() => {
    return () => {
      setApprovedList([''])
      setApproveList([''])
    }
  }, [setApproveList, setApprovedList])

  useEffect(() => {
    if (window.localStorage.getItem(LS_TOKEN) && !user?.email && notificationState === NotificationState.SUCCESS) {
      setTimeout(() => {
        toggleModal()
      }, 2000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationState, user?.email])

  const handleContinue = useCallback(() => {
    if (!handler) return
    if (isSingle) {
      handler(Screen.CONFIRM_BORROW, 3)
    } else {
      handler(Screen.CONFIRM_BORROW, 4)
    }
  }, [handler, isSingle])

  const handleBack = useCallback(() => {
    if (!handler) return
    setValue('totalBorrowAmount', '0')
    if (isSingle) {
      handler(Screen.ENTER_BORROW_AMOUNTS, 1)
    } else {
      handler(Screen.ENTER_BORROW_AMOUNTS, 2)
    }
  }, [handler, isSingle, setValue])

  return (
    <>
      <Flex flexDirection='column' px={20}>
        {(notificationState === NotificationState.APPROVE || notificationState === NotificationState.APPROVED) && (
          <Text fontSize='md' fontWeight='bold' textAlign={'center'} mb={10}>
            {tc('label.approve-tokens')}
          </Text>
        )}
        <Box>
          <Flex flexDirection='column' width='100%' sx={{ border: `1px solid ${colors.grey[300]}`, borderRadius: 'sm' }}>
            <SimpleBar sx={{ minHeight: isSingle ? [125, 125, 76] : 210, maxHeight: isSingle ? [125, 125, 76] : 210 }}>
              {(notificationState === NotificationState.APPROVE || notificationState === NotificationState.APPROVED) && (
                <Box px={20} pt={20} pb={10} width='100%' sx={{ display: 'grid', gridTemplateColumns: 'repeat(1fr)', gridGap: '10px', overflow: 'hidden' }}>
                  {bnftItemsParsed.map((bnftItem, index: number) => (
                    <NftCollateral
                      key={`${bnftItem.address}+${bnftItem.tokenID}+${index}`}
                      nft={bnftItem}
                      buttonStateDelegate={buttonStateDelegate}
                      handleApproveDelegation={handleApproveDelegation}
                    />
                  ))}
                </Box>
              )}
            </SimpleBar>
          </Flex>
        </Box>
      </Flex>

      <Box width='100%' px={20} mt={20}>
        {notificationState === NotificationState.APPROVE && (
          <NotificationSmall noicon>
            <Text as='span' dangerouslySetInnerHTML={{ __html: t('batch-borrow.step4') }} />
          </NotificationSmall>
        )}
        {notificationState === NotificationState.APPROVED && (
          <NotificationSmall type='success' noicon flexDirection={'column'} lineHeight={1.5}>
            <Text maxWidth={400} as='span' dangerouslySetInnerHTML={{ __html: t('batch-borrow.step5') }} />
            <Text
              as='span'
              fontWeight={'bold'}
              dangerouslySetInnerHTML={{ __html: t('batch-borrow.step5-1', { amount: totalBorrowAmount, assetTicker: 'ETH' }) }}
            />
          </NotificationSmall>
        )}

        <Flex flexDirection={['row']} mt={20}>
          {(notificationState === NotificationState.APPROVE || notificationState === NotificationState.APPROVED) && (
            <>
              <Button flex={1} color='black' size='xl' outlined onClick={handleBack}>
                {tc('button.back')}
              </Button>
              <Spacer size='sm' />
            </>
          )}
          {(notificationState === NotificationState.APPROVE || notificationState === NotificationState.APPROVED) && (
            <>
              <Button flex={1} color='primary' size='xl' onClick={handleContinue} disabled={notificationState !== NotificationState.APPROVED}>
                {tc('button.continue')}
              </Button>
            </>
          )}
        </Flex>
      </Box>
    </>
  )
}
export default ApproveTokensStep
