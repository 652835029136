import { ModalProps } from '../types'
import Backdrop from './backdrop'
import { createPortal } from 'react-dom'
import { cloneElement, isValidElement, ReactNode, useMemo, useState } from 'react'
import { X } from 'react-feather'
import { MotionBox } from 'theme/ui'
import useResponsive from 'hooks/common/useResponsive'
import ModalProvider from './context'
import ScrollableArea from './scrollable-area'
import { Box } from 'rebass/styled-components'

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500
    }
  },
  exit: {
    y: '100vh',
    opacity: 0
  }
}

const opacity = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: 'easeIn'
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.15,
      ease: 'easeOut'
    }
  }
}

const opacityMobile = {
  hidden: {
    y: '100vh'
  },
  visible: {
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.2,
      ease: 'easeIn'
    }
  },
  exit: {
    y: '100vh',
    transition: {
      duration: 0.15,
      ease: 'easeOut'
    }
  }
}

const modal = {
  position: 'relative',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxHeight: '80vh',
  // overflowY: 'auto',
  borderRadius: 'sm'
}

const modalMobile = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxHeight: '95vh',
  // overflowY: 'auto',
  bottom: 0,
  borderTopLeftRadius: 'sm',
  borderTopRightRadius: 'sm',
  borderBottomLeftRadius: [0, 0, 'sm'],
  borderBottomRightRadius: [0, 0, 'sm']
}

const x = {
  bg: 'white',
  borderRadius: 'sm',
  cursor: 'pointer',
  color: 'black',
  position: 'absolute',
  right: [8, 8, -24],
  top: [-32, -32, -24],
  zIndex: 30001,
  size: 24,
  p: 4
}

// const pan = {
//   width: '100%',
//   justifyContent: 'center',
//   position: 'absolute',
//   top: 10,
//   zIndex: 30001
// }

const Modal: React.FC<ModalProps> = ({ handleClose, children, type = 'opacity', mode }) => {
  const { isTablet } = useResponsive()
  const [kioskMode, setKioskMode] = useState(mode === 'kiosk')
  const [customComponent, setCustomComponent] = useState<ReactNode>(null)

  const Component = useMemo(
    () => (
      <ModalProvider handleClose={handleClose} setKioskMode={setKioskMode} setCustomComponent={setCustomComponent}>
        <Backdrop onClick={!kioskMode ? handleClose : undefined}>
          <ScrollableArea>
            {type === 'dropIn' && (
              <MotionBox
                onClick={e => e.stopPropagation()} // Prevent click from closing modal
                variants={dropIn}
                initial='hidden'
                animate='visible'
                exit='exit'
                sx={isTablet ? modal : modalMobile}
              >
                {!kioskMode && <Box as={X} onClick={handleClose} sx={x} />}
                {/* <Pan handleClose={handleClose} /> */}
                {(isValidElement(customComponent) && customComponent) || (isValidElement(children) && cloneElement(children))}
              </MotionBox>
            )}

            {type === 'opacity' && (
              <MotionBox
                onClick={e => e.stopPropagation()}
                variants={isTablet ? opacity : opacityMobile}
                initial='hidden'
                animate='visible'
                exit='exit'
                sx={isTablet ? modal : modalMobile}
              >
                {!kioskMode && <Box as={X} onClick={handleClose} sx={x} />}
                {/* <Pan handleClose={handleClose} /> */}
                {(isValidElement(customComponent) && customComponent) || (isValidElement(children) && cloneElement(children))}
              </MotionBox>
            )}
          </ScrollableArea>
        </Backdrop>
      </ModalProvider>
    ),
    [children, customComponent, handleClose, isTablet, kioskMode, type]
  )

  return createPortal(Component, document.getElementById('modal') as HTMLElement)
}

export default Modal

// type PanProps = {
//   handleClose: () => void
// }

// const Pan: React.FC<PanProps> = ({ handleClose }) => {
//   const { isTablet } = useResponsive()
//   const { colors } = useTheme()
//   const { isPan } = useModalContext()
//   return !isTablet ? (
//     <MotionFlex animate={{ opacity: isPan ? 1 : 0 }} onPan={() => (isPan ? handleClose() : {})} sx={pan}>
//       <SpacerDash bgColor={colors.grey[5]} width={200} height={4} sx={{ borderRadius: 'sm' }} />
//     </MotionFlex>
//   ) : null
// }
