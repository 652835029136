import React from 'react'
import { Flex, Text } from 'rebass/styled-components'
import { ILabel } from './interfaces'

const Label: React.FC<ILabel> = ({ icon, text, children, ...restprops }) => (
  <Flex alignItems='center' {...restprops}>
    {React.isValidElement(icon) && (
      <Flex alignItems='center' mr={15}>
        {icon}
      </Flex>
    )}
    <Text as='span'>
      {text && text}
      {children && children}
    </Text>
  </Flex>
)

export default Label
