import { Contract } from '@ethersproject/contracts'
import { useActiveWeb3React, useContract, ERC20_ABI } from 'wallet-module'
import {
  BEND_COMPETITION_ABI,
  BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI,
  CRYPTO_PUNKS_MARKET_ABI,
  ERC721_ABI,
  LEND_POOL_ABI,
  LEND_POOL_LOAN_ABI,
  PUNK_GATEWAY_ABI,
  UI_POOL_DATA_PROVIDER_ABI,
  WETH_GATEWAY_ABI,
  WRAPPED_PUNKS_ABI,
  WETH_ABI,
  LEND_POOL_ADDRESS_PROVIDER_ABI,
  MERKLE_DISTRIBUTOR_ABI,
  VE_BEND_ABI,
  FEE_DISTRIBUTOR_ABI,
  UNISWAP_PAIR_ABI,
  DEBT_TOKEN_ABI,
  BNFT_ABI,
  BOUND_PUNK_GATEWAY_ABI,
  AIRDROP_DISTRIBUTION_CONTRACT_ABI,
  BEND_STAKE_ABI,
  ENS_ABI,
  ENS_PUBLIC_RESOLVER_ABI,
  ENS_REGISTRAR_ADDRESSES,
  EIP2981_ABI
} from '../constants'

export function useILendPoolContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, LEND_POOL_ABI, withSignerIfPossible)
}

export function useERC20Contract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, ERC20_ABI, withSignerIfPossible)
}

export function useERC721Contract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, ERC721_ABI, withSignerIfPossible)
}

export function useLendPoolLoanContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, LEND_POOL_LOAN_ABI, withSignerIfPossible)
}

export function useWethGatewayContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, WETH_GATEWAY_ABI, withSignerIfPossible)
}

export function usePunkGatewayContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, PUNK_GATEWAY_ABI, withSignerIfPossible)
}

export function useCryptoPunkContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, CRYPTO_PUNKS_MARKET_ABI, withSignerIfPossible)
}

export function useWPunkContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, WRAPPED_PUNKS_ABI, withSignerIfPossible)
}

export function useUiPoolDataProviderContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, UI_POOL_DATA_PROVIDER_ABI, withSignerIfPossible)
}

export function useBendCompetitionContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, BEND_COMPETITION_ABI, withSignerIfPossible)
}

export function useBendIncentivesControllerContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI, withSignerIfPossible)
}

export function useWethContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, WETH_ABI, withSignerIfPossible)
}

export function useAddressProviderContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, LEND_POOL_ADDRESS_PROVIDER_ABI, withSignerIfPossible)
}

export function useMerkleDistributorContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, MERKLE_DISTRIBUTOR_ABI, withSignerIfPossible)
}

export function useVeBendContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, VE_BEND_ABI, withSignerIfPossible)
}

export function useFeeDistributorContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, FEE_DISTRIBUTOR_ABI, withSignerIfPossible)
}

export function useUniswapPairContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, UNISWAP_PAIR_ABI, withSignerIfPossible)
}

export function useDebtTokenContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, DEBT_TOKEN_ABI, withSignerIfPossible)
}

export function useBNFTContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, BNFT_ABI, withSignerIfPossible)
}

export function useBoundPunkGatewayContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, BOUND_PUNK_GATEWAY_ABI, withSignerIfPossible)
}

export function useAirdropDistributionContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, AIRDROP_DISTRIBUTION_CONTRACT_ABI, withSignerIfPossible)
}

export function useBendStakeContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, BEND_STAKE_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract(ENS_REGISTRAR_ADDRESSES, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useEIP2981Contract(address?: string | null, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && address, EIP2981_ABI, withSignerIfPossible)
}
