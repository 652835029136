import React from 'react'
import { Heading2 } from '../../'
import Container from '../container'
import { IDescription } from './interfaces'

const Description: React.FC<IDescription> = ({ title, children, ...restprops }) => (
  <Container hasBorder hasPadding {...restprops}>
    {title && <Heading2 text={title} color='primary' mb={10} />}
    {children}
  </Container>
)

export default Description
