var _a
import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
//import { WalletConnectConnector } from './walletconnect-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
// import { WalletLinkConnector } from '@web3-react/walletlink-connector'
// import { PortisConnector } from '@web3-react/portis-connector'
//import { FortmaticConnector } from './fortmatic'
import { NetworkConnector } from './network-connector'
// const PORTIS_ID = process.env.NEXT_PUBLIC_NETWORK_URL ?? 'https://mainnet.infura.io/v3/'
const NETWORK_URL = (_a = process.env.NEXT_PUBLIC_NETWORK_URL) !== null && _a !== void 0 ? _a : 'https://mainnet.infura.io/v3/'
const CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID ? parseInt(process.env.NEXT_PUBLIC_CHAIN_ID) : 1

export const network = new NetworkConnector({
  urls: {
    [CHAIN_ID]: NETWORK_URL,
    [56]: 'https://bsc-dataseed.binance.org/',
    [97]: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
  },
  defaultChainId: CHAIN_ID
})
class myWall extends WalletConnectConnector {
  constructor() {
    super({
      rpc: {
        [CHAIN_ID]: NETWORK_URL
      },
      bridge: 'https://bridge.walletconnect.org',
      qrcode: true,
      infuraId: process.env.NEXT_PUBLIC_INFURA_ID,
      chainId: CHAIN_ID
    })
  }
  setRpc() {}
}

export const walletconnect = new myWall()
export const networkReady = true
let networkLibrary
export function getNetworkLibrary() {
  return (networkLibrary = networkLibrary !== null && networkLibrary !== void 0 ? networkLibrary : new Web3Provider(network.provider))
}
export const injected = new InjectedConnector({
  supportedChainIds: [CHAIN_ID]
})
// mainnet only
// export const portis = new PortisConnector({
//   dAppId: PORTIS_ID ?? '',
//   networks: [1],
// })
// mainnet only
// export const walletlink = new WalletLinkConnector({
//   url: NETWORK_URL,
//   appName: 'comm.finance',
//   appLogoUrl: ''
// })
