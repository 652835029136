import { useTranslation } from 'next-i18next'
import { Flex, Box, FlexProps } from 'rebass/styled-components'
import { IconError } from 'theme/ui'

type NoRecordsProps = {
  text?: string
  icon?: React.ReactElement | null
}

const NoRecords: React.FC<NoRecordsProps & FlexProps> = ({ text, icon, ...restprops }) => {
  const { t } = useTranslation('common')
  return (
    <Flex flexDirection='column' alignItems='center' justifyContent='center' minHeight={160} width='100%' {...restprops}>
      {icon ? icon : <IconError color='grey.300' size={32} />}
      <Box color='grey.300' fontSize='md' mt={20}>
        {text || t('label.no-records')}
      </Box>
    </Flex>
  )
}

export default NoRecords
