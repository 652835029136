import { injected, walletconnect } from '../connectors'
export const NetworkContextName = 'NETWORK' + Math.random()
export var ChainId
;(function (ChainId) {
  ChainId[(ChainId['MAINNET'] = 1)] = 'MAINNET'
  ChainId[(ChainId['ROPSTEN'] = 3)] = 'ROPSTEN'
  ChainId[(ChainId['RINKEBY'] = 4)] = 'RINKEBY'
  ChainId[(ChainId['G\u00D6RLI'] = 5)] = 'G\u00D6RLI'
  ChainId[(ChainId['KOVAN'] = 42)] = 'KOVAN'
  ChainId[(ChainId['BSC'] = 56)] = 'BSC'
  ChainId[(ChainId['OKEX_TESTNET'] = 65)] = 'OKEX_TESTNET'
  ChainId[(ChainId['OKEX'] = 66)] = 'OKEX'
  ChainId[(ChainId['BSC_TESTNET'] = 97)] = 'BSC_TESTNET'
  ChainId[(ChainId['HECO'] = 128)] = 'HECO'
  ChainId[(ChainId['HECO_TESTNET'] = 256)] = 'HECO_TESTNET'
})(ChainId || (ChainId = {}))
export const NETWORK_LABELS = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.BSC]: 'BSC',
  [ChainId.BSC_TESTNET]: 'BSC Testnet',
  [ChainId.OKEX]: 'OKExChain',
  [ChainId.OKEX_TESTNET]: 'OKEx Testnet',
  [ChainId.HECO]: 'HECO',
  [ChainId.HECO_TESTNET]: 'HECO Testnet'
}
export const SUPPORTED_WALLETS = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  }
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: '',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true,
  // },
}
