import { AbstractConnector } from '@web3-react/abstract-connector'
import { Button, Notification } from 'theme/ui'
import { injected, SUPPORTED_WALLETS } from 'wallet-module'
import { useTranslation } from 'next-i18next'
import { Flex, Box, Text } from 'rebass/styled-components'
import Option from './option'
import { changeNetwork } from 'utils'
import { useMemo } from 'react'

interface PendingViewProps {
  connector?: AbstractConnector
  error: boolean
  pendingErrorWrongNetwork: any
  setPendingError: (error: boolean) => void
  setPendingWrongNetwork: any
  tryActivation: (connector: AbstractConnector) => void
  handleBack: any
  errorMsg: any
}

export const WalletStep3: React.FC<PendingViewProps> = ({
  connector,
  error,
  setPendingError,
  pendingErrorWrongNetwork,
  setPendingWrongNetwork,
  tryActivation,
  handleBack,
  errorMsg
}) => {
  const isMetamask = window?.ethereum?.isMetaMask
  const { t } = useTranslation('common')

  const selectedWallet = useMemo(() => {
    const wallet = Object.keys(SUPPORTED_WALLETS).find(key => {
      const option = SUPPORTED_WALLETS[key]
      if (option.connector === connector) {
        if (option.connector === injected) {
          if (isMetamask && option.name !== 'MetaMask') {
            return null
          }
          if (!isMetamask && option.name === 'MetaMask') {
            return null
          }
        }
        return option
      }
    })
    return wallet
  }, [connector, isMetamask])

  return (
    <Flex flexDirection='column' width='100%'>
      {Object.keys(SUPPORTED_WALLETS).map(key => {
        const option = SUPPORTED_WALLETS[key]
        if (option.connector === connector) {
          if (option.connector === injected) {
            if (isMetamask && option.name !== 'MetaMask') {
              return null
            }
            if (!isMetamask && option.name === 'MetaMask') {
              return null
            }
          }
          return <Option id={`connect-${key}`} key={key} color={option.color} header={option.name} icon={'/images/' + option.iconName} />
        }
        return null
      })}

      <Flex
        my={20}
        flex={1}
        flexDirection='column'
        sx={{
          borderRadius: 'sm'
        }}
      >
        {error && pendingErrorWrongNetwork ? (
          <Box width='100%'>
            <Flex width='100%' flexDirection='column' alignItems='center' mb={20}>
              <Flex color='primary' flex={1} width='100%'>
                <Notification noicon reducePadding width='100%'>
                  {t('wallet.label.wrong-network')}
                </Notification>
              </Flex>
            </Flex>
            <Flex flex={1} justifyContent='space-between'>
              <Button flex={0.48} onClick={handleBack} buttonStyles={{ cursor: 'pointer' }} fontWeight='bold' width='100%' outlined>
                {t('wallet.button.back')}
              </Button>
              {selectedWallet !== 'WALLET_CONNECT' && (
                <Button
                  flex={0.48}
                  size='md'
                  buttonStyles={{ cursor: 'pointer' }}
                  onClick={async () => {
                    try {
                      await changeNetwork()
                      setPendingError(false)
                      setPendingWrongNetwork(false)
                    } catch (error) {
                      console.log('Error changing network')
                    }
                  }}
                  color='primary'
                  fontWeight='bold'
                  maxWidth={160}
                >
                  <Text mx={-10}>{t('wallet.label.change-network')}</Text>
                </Button>
              )}
              {selectedWallet === 'WALLET_CONNECT' && (
                <Button
                  flex={0.48}
                  size='md'
                  onClick={async () => {
                    setPendingError(false)
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    connector && tryActivation(connector)
                  }}
                  color='primary'
                  fontWeight='bold'
                >
                  {t('wallet.label.try-again')}
                </Button>
              )}
            </Flex>
          </Box>
        ) : error ? (
          <>
            <Flex width='100%' flexDirection='column' justifyContent='center' alignItems='center' mb={20}>
              <Flex flexDirection='column' color='primary'>
                <Notification noicon reducePadding>
                  <Box>{t('wallet.label.error-connecting')}</Box>
                  <Box>{errorMsg?.message}</Box>
                </Notification>
              </Flex>
            </Flex>
            <Button
              width='100%'
              onClick={() => {
                setPendingError(false)
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                connector && tryActivation(connector)
              }}
              size='md'
              color='primary'
              fontWeight='bold'
            >
              {t('wallet.label.try-again')}
            </Button>
          </>
        ) : (
          <Flex flexDirection='column' color='primary' mt={20}>
            <Notification reducePadding>{t('wallet.label.initializing')}</Notification>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
