import { Contract } from '@ethersproject/contracts'
import { calculateGasMargin } from 'wallet-module'
import { DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT } from '../constants'

/**
 * @dev Deposits an `amount` of underlying asset into the reserve, receiving in return overlying bTokens.
 * - E.g. User deposits 100 USDC and gets in return 100 aUSDC
 * @param asset The address of the underlying asset to deposit
 * @param amount The amount to be deposited
 * @param onBehalfOf The address that will receive the bTokens, same as msg.sender if the user
 *   wants to receive them on his own wallet, or a different address if the beneficiary of bTokens
 *   is a different wallet
 * @param referralCode Code used to register the integrator originating the operation, for potential rewards.
 *   0 if the action is executed directly by the user, without any middle-man
 **/
export const deposit = async (contract: any, asset_address: string, amount: string, onBehalfOf_address: string, referralCode: string) => {
  console.debug('util deposit data', asset_address, amount, onBehalfOf_address, referralCode)
  const estimatedGas = await contract.estimateGas.deposit(asset_address, amount, onBehalfOf_address, referralCode).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.deposit(asset_address, amount, onBehalfOf_address, referralCode)
  })

  return contract
    .deposit(asset_address, amount, onBehalfOf_address, referralCode, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.LendPool.deposit)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to deposit', error)
      throw error
    })
}

/**
 * @dev Withdraws an `amount` of underlying asset from the reserve, burning the equivalent bTokens owned
 * E.g. User has 100 aUSDC, calls withdraw() and receives 100 USDC, burning the 100 aUSDC
 * @param asset The address of the underlying asset to withdraw
 * @param amount The underlying amount to be withdrawn
 *   - Send the value type(uint256).max in order to withdraw the whole bToken balance
 * @param to Address that will receive the underlying, same as msg.sender if the user
 *   wants to receive it on his own wallet, or a different address if the beneficiary is a
 *   different wallet
 * @return The final amount withdrawn
 **/
export const withdraw = async (contract: any, asset_address: string, amount: string, to_address: string) => {
  console.debug('util withdraw data', asset_address, amount, to_address)
  const estimatedGas = await contract.estimateGas.withdraw(asset_address, amount, to_address).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.withdraw(asset_address, amount, to_address)
  })

  return contract
    .withdraw(asset_address, amount, to_address, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.LendPool.withdraw)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to withdraw', error)
      throw error
    })
}

/**
 * @dev Allows users to borrow a specific `amount` of the reserve underlying asset
 * - E.g. User borrows 100 USDC, receiving the 100 USDC in his wallet
 *   and lock collateral asset in contract
 * @param asset The address of the underlying asset to borrow
 * @param amount The amount to be borrowed
 * @param nftAsset The address of the underlying nft used as collateral
 * @param nftTokenId The token ID of the underlying nft used as collateral
 * @param onBehalfOf Address of the user who will receive the loan. Should be the address of the borrower itself
 * calling the function if he wants to borrow against his own collateral
 * @param referralCode Code used to register the integrator originating the operation, for potential rewards.
 *   0 if the action is executed directly by the user, without any middle-man
 **/
export const borrow = async (
  contract: any,
  asset_address: string,
  amount: string,
  nftAsset_address: string,
  nftTokenId: string,
  onBehalfOf_address: string,
  referralCode: string
) => {
  console.debug('util borrow data', asset_address, amount, onBehalfOf_address, referralCode, nftAsset_address, nftTokenId)
  const estimatedGas = await contract.estimateGas
    .borrow(asset_address, amount, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode)
    .catch((e: any) => {
      console.error('e', e)
      return contract.estimateGas.borrow(asset_address, amount, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode)
    })

  return contract
    .borrow(asset_address, amount, nftAsset_address, nftTokenId, onBehalfOf_address, referralCode, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.LendPool.borrow)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to borrow', error)
      throw error
    })
}

/**
 * @notice Repays a borrowed `amount` on a specific reserve, burning the equivalent loan owned
 * - E.g. User repays 100 USDC, burning loan and receives collateral asset
 * @param contract The contract
 * @param nftAsset The address of the underlying NFT used as collateral
 * @param nftTokenId The token ID of the underlying NFT used as collateral
 * @param amount The amount to repay
 **/
export const repay = async (contract: any, nftAsset_address: string, nftTokenId: string, amount: string) => {
  console.debug('util repay data', nftAsset_address, nftTokenId, amount)
  const estimatedGas = await contract.estimateGas.repay(nftAsset_address, nftTokenId, amount).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.repay(nftAsset_address, nftTokenId, amount)
  })

  return contract
    .repay(nftAsset_address, nftTokenId, amount, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.LendPool.repay)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to repay', error)
      throw error
    })
}

/**
 * It's a function that takes in a contract, an address, a token id, an amount, and a bid fine, and
 * then it returns a promise that resolves to a response
 * @param {Contract} contract - Contract
 * @param {string} nftAsset_address - The address of the NFT contract
 * @param {string} nftTokenId - The token ID of the NFT you want to redeem.
 * @param {string} amount - the amount to redeem
 * @param {string} bidFine - bid fine amount,
 * @returns The response from the contract.
 */
export const redeem = async (contract: Contract, nftAsset_address: string, nftTokenId: string, amount: string, bidFine: string) => {
  console.debug('util redeem data', nftAsset_address, nftTokenId)
  const estimatedGas = await contract.estimateGas.redeem(nftAsset_address, nftTokenId, amount, bidFine).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.redeem(nftAsset_address, nftTokenId, amount, bidFine)
  })

  return contract
    .redeem(nftAsset_address, nftTokenId, amount, bidFine, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.LendPool.redeem)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to redeem', error)
      throw error
    })
}

/**
 * @dev Function to liquidate a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting liquidated, and receives
 *   the collateral asset
 * @param nftAsset The address of the underlying NFT used as collateral
 * @param nftTokenId The token ID of the underlying NFT used as collateral
 * @param amount Amount to pay when the total borrowed debt value is greater than the bid price
 * @return The liquidate amount, payback amount
 **/
export const liquidate = async (contract: any, nftAsset_address: string, nftTokenId: string, amount: string) => {
  console.debug('util liquidate data', nftAsset_address, nftTokenId, amount)
  const estimatedGas = await contract.estimateGas.liquidate(nftAsset_address, nftTokenId, amount).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.liquidate(nftAsset_address, nftTokenId, amount)
  })

  return contract
    .liquidate(nftAsset_address, nftTokenId, amount, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.LendPool.liquidate)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to liquidate', error)
      throw error
    })
}

/**
 * @dev Function to auction a non-healthy position collateral-wise
 * - The caller (liquidator) buy collateral asset of the user getting liquidated, and receives
 *   the collateral asset
 * @param nftAsset The address of the underlying NFT used as collateral
 * @param nftTokenId The token ID of the underlying NFT used as collateral
 * @param onBehalfOf Address of the user who will get the underlying NFT, same as msg.sender if the user
 *   wants to receive them on his own wallet, or a different address if the beneficiary of NFT
 *   is a different wallet
 * @return The liquidate amount, payback amount
 **/
export const auction = async (contract: any, nftAsset_address: string, nftTokenId: string, amount: string, onBehalfOf_address: string) => {
  console.debug('util auction data', nftAsset_address, nftTokenId, amount, onBehalfOf_address)
  const estimatedGas = await contract.estimateGas.auction(nftAsset_address, nftTokenId, amount, onBehalfOf_address).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.auction(nftAsset_address, nftTokenId, amount, onBehalfOf_address)
  })

  return contract
    .auction(nftAsset_address, nftTokenId, amount, onBehalfOf_address, {
      gasLimit: calculateGasMargin(estimatedGas, DEFAULT_ADDED_GAS_CALC_PERCENT, RECOMMENDED_MIN_GAS_LIMIT.LendPool.auction)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to auction', error)
      throw error
    })
}
