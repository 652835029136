import { Fragment, useEffect, useMemo, useState } from 'react'
import { Box, Flex, Image, Text, Link as RebassLink } from 'rebass/styled-components'
import Link from 'components/common/link'
import styled from 'styled-components'
import { AnimatePresence, Button, Icon, MotionBox } from 'theme/ui'
import { X, Icon as IconProps, ChevronRight, ChevronLeft } from 'react-feather'
import { createPortal } from 'react-dom'
import useTheme from 'hooks/common/useTheme'
import SpacerDash from '../spacer-dash'
import isEmpty from 'lodash/isEmpty'
import { IRoutes } from 'constants/types'
import { accountRoute, permalink } from 'constants/routes'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

type MobileMenuProps = {
  showMobileMenu: boolean
  toggleMobileMenu: any
}

const menuWidth = 300
const MobileAccountSidebar: React.FC<MobileMenuProps> = ({ showMobileMenu, toggleMobileMenu }) => {
  const { colors } = useTheme()
  const [showSubmenu, toggleSubmenu] = useState(false)
  const [submenuRoutes, setSubmenuRoutes] = useState<Array<IRoutes>>([])
  const [submenuTitle, setSubmenuTitle] = useState('')
  const { pathname } = useRouter()
  const { t: tc } = useTranslation('common')

  const activePathname = useMemo(() => {
    if (!pathname) return null
    if (pathname === permalink.home) return '/'
    const path = pathname.split('/')
    return `/${path[2]}/${path[3]}`
  }, [pathname])

  const activePathnameSub = useMemo(() => {
    if (!pathname) return null
    if (pathname === permalink.home) return '/'
    const path = pathname.split('/')
    if (
      `/${path[2]}/${path[3]}` === permalink.liquidity ||
      `/${path[2]}/${path[3]}` === permalink.liquidity ||
      `/${path[2]}/${path[3]}` === permalink.auction
    ) {
      return `/${path[2]}/${path[3]}/${path[4]}`
    }
    if (`/${path[2]}/${path[3]}` === permalink.dashboard) {
      return `/${path[2]}/${path[3]}/${path[4]}`
    }
    return `/${path[2]}/${path[3]}`
  }, [pathname])

  useEffect(() => {
    if (!showMobileMenu) {
      document.querySelector('body')!.classList.remove('sidebar-open')
      return
    }
    document.querySelector('body')!.classList.add('sidebar-open')
  }, [showMobileMenu])

  return createPortal(
    <AnimatePresence>
      {showMobileMenu && (
        <>
          <MotionBox
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0,
              transition: { delay: 0.5 }
            }}
            onClick={() => {
              toggleMobileMenu((state: boolean) => !state)
              toggleSubmenu(false)
            }}
            bg='backdrop'
            sx={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1999
            }}
          />
          <MotionBox
            width={menuWidth}
            bg='white'
            initial={{
              x: -menuWidth
            }}
            animate={{
              x: 0,
              transition: {
                delay: 0.3,
                type: 'tween',
                ease: 'easeIn'
              }
            }}
            exit={{
              x: -menuWidth,
              transition: {
                delay: 0,
                type: 'tween',
                ease: 'easeOut'
              }
            }}
            sx={{
              position: 'fixed',
              left: 0,
              top: 0,
              height: '100vh',
              maxHeight: '100vh',
              zIndex: 2000
            }}
          >
            <Flex alignItems='center' justifyContent='space-between' width='100%' p={30}>
              <Flex alignItems='center'>
                <Box sx={{ mr: 12, height: 28, img: { filter: 'grayscale(100%) brightness(0%)}' } }}>
                  <Image src='/images/bend-logo-black-3x.png' width={180} />
                </Box>
              </Flex>
              <CloseIcon
                cursor='pointer'
                color={colors.black}
                onClick={() => {
                  toggleMobileMenu((state: boolean) => !state)
                  toggleSubmenu(false)
                }}
              />
            </Flex>
            <SpacerDash bgColor='grey.100' width='100%' />
            <Flex
              sx={{
                width: menuWidth,
                overflowX: 'hidden'
              }}
            >
              <Flex
                sx={{
                  position: 'relative',
                  width: menuWidth * 2
                }}
              >
                <MotionBox
                  initial={{
                    x: 0
                  }}
                  animate={{
                    x: showSubmenu ? -menuWidth : 0,
                    transition: {
                      delay: 0,
                      type: 'tween',
                      ease: 'linear'
                    }
                  }}
                  sx={{
                    width: menuWidth,
                    height: '100vh'
                  }}
                >
                  <Text fontSize='md' lineHeight='19px' fontWeight='500' px={30} py={15} mt={15} opacity={0.6}>
                    {tc('label.account')}
                  </Text>
                  <SpacerDash bgColor='grey.100' height={1} width='100%' />
                  {accountRoute.children?.map((route: IRoutes) => (
                    <Fragment key={route.name}>
                      {isEmpty(route.children) ? (
                        route.type === 'separator' ? null : route.external ? (
                          <Flex
                            as={RebassLink}
                            href={route.route}
                            target='_blank'
                            rel='noopener noreferrer'
                            color='black'
                            alignItems='center'
                            px={30}
                            py={15}
                            onClick={() => {
                              toggleMobileMenu((state: boolean) => !state)
                              toggleSubmenu(false)
                            }}
                          >
                            <Icon color={route.route === activePathname ? colors.primary : colors.grey[300]} size={16} icon={route.icon} />
                            <Text as='span' ml={12} lineHeight='16px' fontSize={'md'} color='black' fontWeight={route.route === activePathname ? '550' : '500'}>
                              {route.name}
                            </Text>
                          </Flex>
                        ) : (
                          <Link href={route.route} passHref>
                            <Flex as='a' color='black' alignItems='center' px={30} py={15} onClick={() => toggleMobileMenu((state: boolean) => !state)}>
                              <Icon color={route.route === activePathname ? colors.primary : colors.grey[300]} size={16} icon={route.icon} />
                              <Text
                                as='span'
                                ml={12}
                                lineHeight='16px'
                                fontSize={'md'}
                                color='black'
                                fontWeight={route.route === activePathname ? '550' : '500'}
                              >
                                {route.name}
                              </Text>
                            </Flex>
                          </Link>
                        )
                      ) : (
                        <Flex
                          as='a'
                          href='#'
                          color='black'
                          alignItems='center'
                          px={30}
                          py={15}
                          justifyContent='space-between'
                          onClick={e => {
                            e.preventDefault()
                            toggleSubmenu(state => !state)
                            setSubmenuTitle(route.name)
                            if (route.children) setSubmenuRoutes(route.children)
                          }}
                        >
                          <Flex>
                            <Icon color={route.route === activePathname ? colors.primary : colors.grey[300]} size={16} icon={route.icon} />
                            <Text as='span' ml={12} lineHeight='16px' fontSize={'md'} fontWeight={route.route === activePathname ? '550' : '500'}>
                              {route.name}
                            </Text>
                          </Flex>
                          <ChevronRight size={10} strokeWidth={4} />
                        </Flex>
                      )}

                      <SpacerDash bgColor='grey.100' height={1} width='100%' />
                    </Fragment>
                  ))}
                </MotionBox>

                <MotionBox
                  initial={{
                    x: menuWidth
                  }}
                  animate={{
                    x: showSubmenu ? 0 : menuWidth,
                    transition: {
                      delay: 0,
                      type: 'tween',
                      ease: 'linear'
                    }
                  }}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: menuWidth,
                    height: '100vh'
                  }}
                >
                  <Flex px={30} py={15} mt={15}>
                    <Button
                      size='icon'
                      backgroundColor='transparent'
                      fontColor='black'
                      onClick={() => toggleSubmenu(state => !state)}
                      style={{ borderColor: 'transparent' }}
                    >
                      <Flex alignItems='center' mt={1}>
                        <Box>
                          <ChevronLeft size={10} strokeWidth={4} />
                        </Box>
                        <Text fontSize='md' fontWeight='500' lineHeight='lg' ml={12} opacity={0.6}>
                          {submenuTitle}
                        </Text>
                      </Flex>
                    </Button>
                  </Flex>
                  <SpacerDash bgColor='grey.100' height={1} width='100%' />
                  {submenuRoutes.map(route => (
                    <Fragment key={route.name}>
                      {route.type === 'separator' ? null : route.external ? (
                        <Flex
                          as={RebassLink}
                          href={route.route}
                          target='_blank'
                          rel='noopener noreferrer'
                          color='black'
                          alignItems='center'
                          px={30}
                          py={15}
                          onClick={() => {
                            toggleMobileMenu((state: boolean) => !state)
                          }}
                        >
                          <Icon color={route.route === activePathnameSub ? colors.primary : colors.grey[300]} size={16} icon={route.icon} />
                          <Text as='span' ml={12} lineHeight='16px' fontSize={'md'} fontWeight={route.route === activePathnameSub ? '550' : '500'}>
                            {route.name}
                          </Text>
                        </Flex>
                      ) : (
                        <Link href={route.route} passHref>
                          <Flex
                            as='a'
                            color='black'
                            alignItems='center'
                            px={30}
                            py={15}
                            onClick={() => {
                              toggleMobileMenu((state: boolean) => !state)
                            }}
                          >
                            <Icon color={route.route === activePathnameSub ? colors.primary : colors.grey[300]} size={16} icon={route.icon} />
                            <Text as='span' ml={12} lineHeight='16px' fontSize={'md'} fontWeight={route.route === activePathnameSub ? '550' : '500'}>
                              {route.name}
                            </Text>
                          </Flex>
                        </Link>
                      )}

                      <SpacerDash bgColor='grey.100' height={1} width='100%' />
                    </Fragment>
                  ))}
                </MotionBox>
              </Flex>
            </Flex>
          </MotionBox>
        </>
      )}
    </AnimatePresence>,
    document.getElementById('mobile-menu') as HTMLElement
  )
}

const CloseIcon = styled<IconProps>(X)`
  width: 19px;
  height: 19px;
`

export default MobileAccountSidebar
