import React from 'react'
import { Flex } from 'rebass/styled-components'

const ScrollableArea: React.FC = ({ children }) => {
  return (
    <Flex
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        alignItems: ['flex-end', 'flex-end', 'center'],
        justifyContent: ['center'],
        overflowY: 'auto',
        zIndex: 30000
      }}
    >
      {children}
    </Flex>
  )
}

export default ScrollableArea
