import BigNumber from 'bignumber.js'
import usePlatform from 'contexts/platform'
import get from 'lodash/get'
import { useSingleCallResult } from 'modules/multicall-module'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BDebtWETH, CRYPTO_PUNKS_MARKET_ABI, DEBT_TOKEN_ABI, ERC721_ABI, LEND_POOL, WETH_ADDRESS } from '../constants'
import { useCryptoPunkContract, useDebtTokenContract, useERC721Contract } from './useContract'
import { isPunk, isWeth } from 'utils'
import { getContract } from 'modules/wallet-module/lib/esm/utils/contract'
import { useActiveWeb3React } from 'modules/wallet-module/lib/esm'

export function useCheckNftApprovedForBorrow({
  account,
  collectionAddress,
  assetAddress = WETH_ADDRESS,
  tokenID
}: {
  account?: string | null
  collectionAddress?: string
  assetAddress?: string
  tokenID?: string
}) {
  const [nftApproved, setNftApproved] = useState<undefined | boolean>(undefined)
  const [delegationApproved, setDelegationApproved] = useState<undefined | boolean>(undefined)

  const {
    config: { WETH_GATEWAY, PUNK_GATEWAY, isLoadingConfig }
  } = usePlatform()

  const contract = useERC721Contract(collectionAddress, false)

  const gateway = useMemo(
    () => (isPunk(collectionAddress) ? PUNK_GATEWAY : isWeth(assetAddress) ? WETH_GATEWAY : LEND_POOL),
    [PUNK_GATEWAY, WETH_GATEWAY, assetAddress, collectionAddress]
  )

  const debtTokenContract = useDebtTokenContract(BDebtWETH)
  const { result: borrowAllowanceResult } = useSingleCallResult(debtTokenContract, 'borrowAllowance', [account, WETH_GATEWAY])
  const borrowAllowance = useMemo(() => new BigNumber(get(borrowAllowanceResult, 0)?.toString()), [borrowAllowanceResult])

  const { result: approvedAddress } = useSingleCallResult(
    !isPunk(collectionAddress) ? contract : undefined,
    'isApprovedForAll',
    [account, gateway],
    {},
    isLoadingConfig
  )

  const CryptoPunkContract = useCryptoPunkContract(collectionAddress, false)
  const { result: cryptoPunk } = useSingleCallResult(isPunk(collectionAddress) ? CryptoPunkContract : undefined, 'punksOfferedForSale', [tokenID])

  useEffect(() => {
    if (isPunk(collectionAddress)) {
      if (!cryptoPunk) return
      if (get(cryptoPunk, 0) && get(cryptoPunk, 4) === PUNK_GATEWAY) {
        setNftApproved(true)
      } else setNftApproved(false)
    } else {
      if (!approvedAddress) return
      if (get(approvedAddress, 0)) {
        setNftApproved(true)
      } else setNftApproved(false)
    }
  }, [PUNK_GATEWAY, approvedAddress, collectionAddress, cryptoPunk, tokenID])

  useEffect(() => {
    if (borrowAllowance.isNaN()) return
    if (borrowAllowance.gt(0)) setDelegationApproved(true)
    else setDelegationApproved(false)
  }, [borrowAllowance])

  return {
    nftApproved,
    delegationApproved
  }
}

export function useCheckNftApprovedForBorrowList({
  account,
  assetAddress = WETH_ADDRESS,
  list
}: {
  account?: string | null
  assetAddress?: string
  list: {
    collectionName: string
    name?: string | undefined
    tokenID: string
    address: string
    amount?: string | undefined
  }[]
}) {
  const [nftApproved, setNftApproved] = useState<undefined | boolean>(undefined)
  const { library } = useActiveWeb3React()

  const {
    config: { WETH_GATEWAY, PUNK_GATEWAY }
  } = usePlatform()

  const checkApproved = useCallback(async () => {
    if (!library || !account) return undefined
    let allApproved = true
    for (let i = 0; i < list.length; i++) {
      const element = list[i]
      const gateway = isPunk(element.address) ? PUNK_GATEWAY : isWeth(assetAddress) ? WETH_GATEWAY : LEND_POOL
      const contract: any = isPunk(element.address)
        ? getContract(element.address || '', CRYPTO_PUNKS_MARKET_ABI, library, account)
        : getContract(element.address || '', ERC721_ABI, library, account)
      const check = isPunk(element.address)
        ? await contract.functions?.punksOfferedForSale(element.tokenID)
        : await contract.functions?.isApprovedForAll(account, gateway)

      if (isPunk(element.address)) {
        if (get(check, 0) && get(check, 4) !== PUNK_GATEWAY) allApproved = false
      } else {
        if (get(check, 0) === false) allApproved = false
      }
    }

    const debtTokenContract = getContract(BDebtWETH, DEBT_TOKEN_ABI, library, account)
    const checkBorrowAllowance = await debtTokenContract.functions?.borrowAllowance(account, WETH_GATEWAY)

    if (!new BigNumber(get(checkBorrowAllowance, 0).toString()).isGreaterThan(0)) allApproved = false

    setNftApproved(allApproved)
    return allApproved
  }, [PUNK_GATEWAY, WETH_GATEWAY, account, assetAddress, library, list])

  useEffect(() => {
    checkApproved()
  }, [checkApproved])

  return {
    nftApproved
  }
}
