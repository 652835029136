import SpacerDash from 'components/common/spacer-dash'
import useTheme from 'hooks/common/useTheme'
import { useTranslation } from 'next-i18next'
import { Flex, Text, Box } from 'rebass/styled-components'
import { IconCheck } from 'theme/ui'
import { useBorrowContext } from '../context'

const WizardTimelineFourStep: React.FC = () => {
  const { colors } = useTheme()
  const { t: tc } = useTranslation()
  const { wizardStep: step } = useBorrowContext()

  return (
    <Box width='100%' mb={20} mt={[20, 20, 0]}>
      <Flex width={'100%'} justifyContent='center' alignItems='center' fontWeight='bold' flex={1} px={20}>
        <Flex flex={0.3} justifyContent='center' alignItems='center' flexDirection={'column'}>
          <Text fontSize='sm' lineHeight={['lg', 'lg', 'xl']} mt={1.5} textAlign='center' color={step === 1 ? 'black' : 'grey.500'}>
            {tc('label.select-nft-collaterals')}
          </Text>
        </Flex>

        <Flex flex={0.3} justifyContent='center'>
          <Text fontSize='sm' lineHeight={['lg', 'lg', 'xl']} mt={1.5} textAlign='center' color={step === 2 ? 'black' : 'grey.500'}>
            {tc('label.enter-borrow-amounts')}
          </Text>
        </Flex>

        <Flex flex={0.2} justifyContent='center'>
          <Text fontSize='sm' lineHeight={['lg', 'lg', 'xl']} mt={1.5} textAlign='center' color={step === 3 ? 'black' : 'grey.500'}>
            {tc('label.approve-tokens')}
          </Text>
        </Flex>

        <Flex flex={0.2} justifyContent='center'>
          <Text fontSize='sm' lineHeight={['lg', 'lg', 'xl']} mt={1.5} textAlign='center' color={step === 3 ? 'black' : 'grey.500'}>
            {tc('label.borrow-eth')}
          </Text>
        </Flex>
      </Flex>

      <Flex width='100%' mt={20} flexDirection='column'>
        <SpacerDash bgColor={colors.grey[5]} height={4} width={'100%'} />
        <Flex width={'100%'} justifyContent='center' alignItems='center' mt={-15} fontWeight='bold' flex={1} px={20}>
          <Flex flex={0.3} justifyContent='center' alignItems='center' flexDirection={'column'}>
            <Box
              width={27}
              height={27}
              minWidth={27}
              backgroundColor={step === 1 ? colors.primary : 'green.1000'}
              sx={{ borderRadius: 'icon' }}
              alignItems='center'
            >
              {step === 1 ? (
                <Text fontSize='sm' mt={[2.5, 2, 3, 1.5]} textAlign='center' color={'white'}>
                  1
                </Text>
              ) : (
                <Box ml={7} mt={[3]}>
                  <IconCheck color='white' />{' '}
                </Box>
              )}
            </Box>
          </Flex>

          <Flex flex={0.3} justifyContent='center'>
            <Box
              width={27}
              height={27}
              minWidth={27}
              backgroundColor={step === 2 ? colors.primary : step === 1 ? 'grey.5' : 'green.1000'}
              sx={{ borderRadius: 'icon' }}
              alignItems='center'
              mx={30}
            >
              {step && step >= 3 ? (
                <Box ml={7} mt={[3]}>
                  <IconCheck color='white' />
                </Box>
              ) : (
                <Text fontSize='sm' mt={[2.5, 2.5, 3, 1.5]} textAlign='center' color={step === 2 ? 'white' : 'grey.500'}>
                  2
                </Text>
              )}
            </Box>
          </Flex>
          <Flex flex={0.2} justifyContent='center'>
            <Box
              width={27}
              height={27}
              minWidth={27}
              backgroundColor={step === 3 ? colors.primary : step === 1 || step === 2 ? 'grey.5' : 'green.1000'}
              sx={{ borderRadius: 'icon' }}
              alignItems='center'
            >
              {step && step >= 4 ? (
                <Box ml={7} mt={[3]}>
                  <IconCheck color='white' />
                </Box>
              ) : (
                <Text fontSize='sm' mt={[2.5, 2.5, 3, 1.5]} textAlign='center' color={step === 2 || step === 1 ? colors.grey[500] : 'white'}>
                  3
                </Text>
              )}
            </Box>
          </Flex>
          <Flex flex={0.2} justifyContent='center'>
            <Box
              width={27}
              height={27}
              minWidth={27}
              backgroundColor={step === 4 ? colors.primary : step === 1 || step === 2 || step === 3 ? 'grey.5' : 'green.1000'}
              sx={{ borderRadius: 'icon' }}
              alignItems='center'
            >
              {step && step >= 5 ? (
                <Box ml={7} mt={[3]}>
                  <IconCheck color='white' />
                </Box>
              ) : (
                <Text fontSize='sm' mt={[2.5, 2.5, 3, 1.5]} textAlign='center' color={step === 2 || step === 1 ? colors.grey[500] : 'white'}>
                  4
                </Text>
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default WizardTimelineFourStep
