import { createContext, useCallback, useContext, useReducer } from 'react'

export enum AppNotificationColor {
  green = 'green.1000',
  red = 'red.1000',
  yellow = 'yellow.1000',
  primary = 'primary'
}

export type AppNotification = {
  key: string
  message: string | React.ReactNode
  button?: string
  onClick?: () => void
  close?: boolean
  type?: 'default' | 'success' | 'fail' | 'verify'
}

type InitialState = {
  notifications: Array<AppNotification>
}

type AppNotificationContextProps = {
  addAppNotification: (notification: AppNotification) => void
  removeAppNotification: (id: string) => void
  clearAppNotifications: () => void
}

type AppNotificationsProviderProps = {
  children: React.ReactNode
}

const initialState: InitialState = {
  notifications: []
}

type ReducerAction = {
  action: 'addAppNotification' | 'removeAppNotification' | 'clearAppNotifications'
  payload?: any
}

const AppNotificationContext = createContext<AppNotificationContextProps & InitialState>({
  ...initialState,
  addAppNotification: () => {},
  removeAppNotification: () => {},
  clearAppNotifications: () => {}
})

export const useAppNotificationContext = () => useContext(AppNotificationContext)

const reducer = (state: InitialState, { action, payload }: ReducerAction) => {
  switch (action) {
    default:
    case 'addAppNotification':
      const filteredNotifications = state.notifications.filter(notification => notification.key.toLowerCase() !== payload?.key.toLowerCase())
      return { ...state, notifications: [payload, ...filteredNotifications] }
    case 'removeAppNotification':
      const filteredNotifications2 = state.notifications.filter(notification => notification.key.toLowerCase() !== payload?.toLowerCase())
      return { ...state, notifications: filteredNotifications2 }
    case 'clearAppNotifications':
      return { ...state, notifications: [] }
  }
}

export default function AppNotificationsProvider({ children }: AppNotificationsProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const addAppNotification = useCallback(
    payload =>
      dispatch({
        action: 'addAppNotification',
        payload
      }),
    []
  )

  const removeAppNotification = useCallback(
    id =>
      dispatch({
        action: 'removeAppNotification',
        payload: id
      }),
    []
  )

  const clearAppNotifications = useCallback(
    () =>
      dispatch({
        action: 'clearAppNotifications'
      }),
    []
  )

  return (
    <AppNotificationContext.Provider value={{ ...state, addAppNotification, removeAppNotification, clearAppNotifications }}>
      {children}
    </AppNotificationContext.Provider>
  )
}
