import { Flex, Image } from 'rebass/styled-components'
import { IconWrap } from 'theme/ui'

interface IWrapIconElement {
  assetIconPath: string
  wrappingAssetIconPath: string
}

const WrapIconElement: React.FC<IWrapIconElement> = ({ assetIconPath, wrappingAssetIconPath, ...restprops }) => {
  return (
    <Flex {...restprops} alignItems='center' sx={{ ':hover': { cursor: 'pointer', opacity: 0.8 } }}>
      <Flex sx={{ borderRadius: 'sm', width: 17, height: 17, overflow: 'hidden' }}>
        <Image src={assetIconPath} width={'100%'} height={'100%'} />
      </Flex>
      <Flex mx={10}>
        <IconWrap />
      </Flex>
      <Flex sx={{ borderRadius: 'sm', width: 17, height: 17, overflow: 'hidden' }}>
        <Image src={wrappingAssetIconPath} width={'100%'} height={'100%'} />
      </Flex>
    </Flex>
  )
}

export default WrapIconElement
