import { BASE_URL } from 'constants/index'
import common from 'locales/en/common.json'
import { default as NextHead } from 'next/head'

type MetaTag = React.ReactNode
type MetaTagsProps = {
  title?: string
  description?: string
  image?: string
}

const metaTags = ({ title, description, image }: MetaTagsProps): Array<MetaTag> => [
  <title key='title'>{title}</title>,
  <meta key='charSet' charSet='utf-8' />,
  <link key='icon' rel='icon' href='/favicon.ico' />,
  <link key='apple-touch-icon' rel='apple-touch-icon' href='/logo192.png' />,
  <meta key='viewport' name='viewport' content='initial-scale=1.0, width=device-width' />,

  <meta key='metatitle' name='title' content={title} />,
  <meta key='metadescription' name='description' content={description} />,

  <meta key='og:type' property='og:type' content='website' />,
  <meta key='og:url' property='og:url' content={BASE_URL} />,
  <meta key='og:title' property='og:title' content={title} />,
  <meta key='og:description' property='og:description' content={description} />,
  <meta key='og:image' property='og:image' content={image ? image : `${BASE_URL}/images/bend-hero-nfts-2.png`} />,
  // <meta key='og:image:width' property='og:image:width' content='2560px' />,
  // <meta key='og:image:height' property='og:image:height' content='1440px' />,

  <meta key='twitter:card' property='twitter:card' content='summary_large_image' />,
  <meta key='twitter:url' property='twitter:url' content={BASE_URL} />,
  <meta key='twitter:title' property='twitter:title' content={title} />,
  <meta key='twitter:description' property='twitter:description' content={description} />,
  <meta key='twitter:image' property='twitter:image' content={image ? image : `${BASE_URL}/images/bend-hero-nfts-2.png`} />
]

interface HeadProps {
  title?: string
  description?: string
  image?: string
}

const Head: React.FC<HeadProps> = ({ title, description, image, children }) => {
  return (
    <>
      <NextHead>
        {metaTags({ title: title ? `BendDAO - ${title}` : common.title, description: description ? description : common.description, image }).map(tag => tag)}
      </NextHead>
      {children}
    </>
  )
}

export default Head
