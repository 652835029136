import { calculateGasMargin } from 'wallet-module'

// Write
/**
 * @notice approve contract
 * @param ERC721contract ERC721 Contract
 * @param operator_address operator contract address
 */
export const setApprovalForAll = async (ERC721contract: any, operator_address: string) => {
  const estimatedGas = await ERC721contract.estimateGas.setApprovalForAll(operator_address, true).catch((e: any) => {
    console.error('e', e)
    return ERC721contract.estimateGas.setApprovalForAll(operator_address, true)
  })
  return ERC721contract.setApprovalForAll(operator_address, true, {
    gasLimit: calculateGasMargin(estimatedGas)
  })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to setApprovalForAll', error)
      throw error
    })
}

/**
 * @notice mint
 * @param ERC721contract ERC721 Contract
 * @param contractAddress contract address
 */
export const mint = async (ERC721contract: any, tokenId: string) => {
  const estimatedGas = await ERC721contract.estimateGas.mint(tokenId).catch((e: any) => {
    console.error('e', e)
    return ERC721contract.estimateGas.mint(tokenId)
  })
  return ERC721contract.mint(tokenId, {
    gasLimit: calculateGasMargin(estimatedGas)
  })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to mint', error)
      throw error
    })
}

/**
 * @dev Function to safeTransferFrom token
 * @param contract The contract
 * @param from_address The account address
 * @param to_address The receiver address
 * @param token_id Token id
 * @return
 **/
export const safeTransferFrom = async (contract: any, from_address: string, to_address: string, token_id: string) => {
  console.debug('util safeTransferFrom data', contract, from_address, to_address, token_id)
  const estimatedGas = await contract.estimateGas.safeTransferFrom(from_address, to_address, token_id).catch((e: any) => {
    console.error('e', e)
    return contract.estimateGas.safeTransferFrom(from_address, to_address, token_id)
  })

  return contract
    .safeTransferFrom(from_address, to_address, token_id, {
      gasLimit: calculateGasMargin(estimatedGas)
    })
    .then((response: any) => {
      return response
    })
    .catch((error: Error) => {
      console.debug('Failed to safeTransferFrom', error)
      throw error
    })
}
