import { Flex, Text, FlexProps, Box } from 'rebass/styled-components'
import { Avatar, Button, IconSignature } from 'theme/ui'
import { useTranslation } from 'next-i18next'
import { useUserContext } from 'contexts/user'
import SpacerDash from '../spacer-dash'

type VerifyAddressProps = {
  expired?: boolean
  onClick?: () => void
  onDismiss?: () => void
}

export const VerifyAddress: React.FC<VerifyAddressProps & FlexProps> = ({ onClick, onDismiss, expired, ...restprops }) => {
  const { t: tc } = useTranslation('common')
  const { handleLogin } = useUserContext()

  return (
    <Flex flex={1} {...restprops}>
      <Flex flexDirection='column' alignItems='center' flex={1}>
        <Avatar
          iconSize={69}
          hasBackground
          hasBorderRadius={16}
          icon={
            <Flex justifyContent='center' alignItems='center'>
              <IconSignature size={38} color='white' />
            </Flex>
          }
        />
        <Flex flexDirection='column' alignItems='center' mt={20}>
          <Text fontSize='xxl' fontWeight='bold' textAlign='center' mb={10}>
            {tc('title.verify-address')}
          </Text>
          <Text fontSize={13} fontWeight='500' color='grey.300' textAlign='center' mb={20} px={30} lineHeight={1.5}>
            {expired ? tc('subtitle.verify-address-expired') : tc('subtitle.verify-address')}
          </Text>
        </Flex>
        <SpacerDash bgColor='grey.100' height={4} width='100%' />
        <Box my={20} width='100%' maxWidth={[266]} textAlign='center'>
          <Text as='span' fontSize='md' dangerouslySetInnerHTML={{ __html: tc('paragraph-1.verify-address') }} textAlign='center' />
          <Text fontSize='md' lineHeight='22px' textAlign='center' mt={20}>
            {tc('paragraph-2.verify-address')}
          </Text>
        </Box>
        <Button
          mb={10}
          size='xl'
          width='100%'
          maxWidth={[200, 266]}
          color='primary'
          onClick={async () => {
            if (onClick) {
              onClick()
            } else {
              const result = await handleLogin()
              if (result?.status) {
                console.log('Login error', result.message)
              }
              if (onDismiss) onDismiss()
            }
          }}
        >
          {tc('button.verify-address')}
        </Button>
      </Flex>
    </Flex>
  )
}
