import { useUserContext } from 'contexts/user'
import useENS from 'modules/bend/hooks/useENS'
import { shortenAddress } from 'modules/wallet-module/lib/esm'
import Popover from 'theme/ui/common/popover'

interface IEns {
  address: string | undefined | null
  chars?: number
  maxLength?: number
  showUsername?: boolean
}

export const Ens: React.FC<IEns> = ({
  address,
  chars = 8,
  maxLength,
  showUsername = true
}: {
  address: string | undefined | null
  chars?: number
  maxLength?: number
  showUsername?: boolean
}) => {
  const ensResolve = useENS(address)
  const { user } = useUserContext()

  if (!address) return <></>
  if (ensResolve.name) {
    if (!maxLength) return <>{ensResolve.name}</>
    if (ensResolve.name.length > maxLength)
      return (
        <Popover overlay={ensResolve.name} placement='top' sx={{ cursor: 'default' }}>
          {ensResolve.name.slice(0, maxLength)}..
        </Popover>
      )
    else return <>{ensResolve.name}</>
  }
  if (address?.toLowerCase() === user?.address?.toLowerCase() && user?.username && showUsername) return <>{user?.username}</>
  return <>{shortenAddress(address, chars)}</>
}
