import { useMemo, forwardRef, useState } from 'react'
import Link from 'components/common/link'
import { useRouter } from 'next/router'
import { Flex, Text, Link as LinkRebass, FlexProps, Box, Image } from 'rebass/styled-components'
import { IRoutes } from '../../../../constants/types'
import useTheme from 'hooks/common/useTheme'
import useResponsive from 'hooks/common/useResponsive'
import Icon from '../../icons/icon'
import { permalink } from 'constants/routes'
import { useTranslation } from 'next-i18next'
import isEmpty from 'lodash/isEmpty'
import DropDown from '../../../../components/common/dropw-down'
import useWindowPosition from 'hooks/common/useWindowPosition'
import { HEADER_HEIGHT, WINDOW_POSITION_TRIGGER } from 'components/common/header'
import SpacerDash from 'components/common/spacer-dash'
import Button from 'theme/ui/forms/button'
import { IconDashboard } from 'theme/ui/icons'
import MobileAccountSidebar from 'components/common/mobile-navigation-sidebar/account'
import useENSAvatar from 'modules/bend/hooks/useENSAvatar'
import { useActiveWeb3React } from 'modules/wallet-module'

interface INavButton extends IRoutes {}

const NavButtonAccount: React.FC<INavButton> = props => {
  const { onClick, type } = props
  const { isTouch } = useResponsive()
  return type === 'separator' ? null : (
    <Flex alignItems='center'>{isTouch ? <LinkComponent onClick={onClick} as='a' {...props} /> : <LinkComponent onClick={onClick} {...props} />}</Flex>
  )
}

export default NavButtonAccount

const LinkComponent = forwardRef<HTMLElement, INavButton & FlexProps>(({ route, icon, name, children, ...restprops }, ref) => {
  const { colors } = useTheme()
  const { pathname } = useRouter()
  const { isDesktop } = useResponsive()
  const [showMobileMenu, toggleMobileMenu] = useState(false)
  const { account } = useActiveWeb3React()
  const { data: avatar } = useENSAvatar(account)

  const activePathname = useMemo(() => {
    if (!pathname) return null
    const path = pathname.split('/')
    return `/${path[2]}}`
  }, [pathname])

  const windowPosition = useWindowPosition()
  return (
    <Flex
      sx={{
        position: 'relative',
        justifyContent: 'center'
      }}
      ref={ref}
      {...restprops}
    >
      {children && !isEmpty(children) ? (
        !isDesktop ? (
          <>
            <MobileAccountSidebar showMobileMenu={showMobileMenu} toggleMobileMenu={toggleMobileMenu} />
            <Button size='icon' color={avatar ? 'transparent' : 'blue.100'} p={avatar ? 0 : 4.5} onClick={() => toggleMobileMenu(state => !state)}>
              {avatar ? <Image src={avatar} size={31} sx={{ borderRadius: 'sm' }} /> : <IconDashboard size={22} color={colors.primary} />}
            </Button>
          </>
        ) : (
          <DropDown
            trigger='hover'
            title='Account'
            buttonComponent={
              <Flex
                sx={{
                  alignItems: 'center',
                  height: windowPosition > WINDOW_POSITION_TRIGGER ? HEADER_HEIGHT - 20 : HEADER_HEIGHT,
                  pl: [0, 0, 0, 40]
                }}
              >
                <Button type='button' size='icon' color={avatar ? 'transparent' : 'blue.100'} p={avatar ? 0 : 4.5}>
                  {avatar ? <Image src={avatar} size={31} sx={{ borderRadius: 'sm' }} /> : <IconDashboard size={22} color={colors.primary} />}
                </Button>
              </Flex>
            }
            menuComponent={
              <Flex flexDirection='column'>
                {children.map(child => {
                  return child.type === 'separator' ? (
                    <Box key={child.name} my={10}>
                      <SpacerDash width='100%' height={1} bgColor='grey.100' />
                    </Box>
                  ) : child.external ? (
                    <LinkRebass
                      key={child.name}
                      href={child.route}
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={child.onClick}
                      sx={{
                        '&:hover': {
                          opacity: 1
                        }
                      }}
                    >
                      <SubmenuButton child={child} />
                    </LinkRebass>
                  ) : (
                    <Link key={child.name} href={child.route} passHref>
                      <SubmenuButton as='a' child={child} />
                    </Link>
                  )
                })}
              </Flex>
            }
            menuStyle={{
              minWidth: 220,
              maxWidth: 250,
              borderRadius: 0,
              zIndex: 1001,
              top: windowPosition > WINDOW_POSITION_TRIGGER ? HEADER_HEIGHT - 20 : HEADER_HEIGHT,
              right: [-45, -45, -90]
            }}
            menuWrapperStyle={{
              bg: 'white',
              boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.16)',
              px: 20,
              borderRadius: 0
            }}
            disableModal
          />
        )
      ) : (
        <Link key={name} href={route} passHref>
          <Flex
            as='a'
            sx={{
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              justifyContent: 'center',
              alignItems: 'center',
              px: [0, 0, 0, 14, 15],
              height: windowPosition > WINDOW_POSITION_TRIGGER ? HEADER_HEIGHT - 20 : HEADER_HEIGHT,
              '&:after': {
                content: "''",
                position: 'absolute',
                left: activePathname === route ? 0 : '50%',
                bottom: 0,
                width: activePathname === route ? '100%' : 0,
                height: 3,
                bg: activePathname === route ? ['white', 'white', 'white', 'white', 'primary'] : 'transparent',
                transition: 'all 0.3s'
              },
              '& svg': {
                transition: 'all 0.3s',
                color: activePathname === route ? 'primary' : 'grey.300'
              },
              '&:hover': {
                cursor: 'pointer',
                '&:after': {
                  left: 0,
                  width: '100%',
                  bg: ['white', 'white', 'white', 'white', 'primary']
                },
                '& svg': {
                  color: 'primary'
                }
              }
            }}
          >
            {isDesktop && icon ? <Icon icon={icon} size={20} /> : <Icon icon={icon} size={20} color={colors.white} />}
            <Text
              as='span'
              fontSize={['sm', 'sm', 'md']}
              lineHeight='md'
              fontWeight={['700']}
              mt={['10px', '10px', '10px', '10px', 0]}
              ml={[0, 0, 0, 0, 10]}
              color={['white', 'white', 'white', 'white', 'black']}
              textAlign={'center'}
            >
              {name}
            </Text>
          </Flex>
        </Link>
      )}
    </Flex>
  )
})

type SubmenuProps = { child: INavButton }

const SubmenuButton = forwardRef<HTMLElement, SubmenuProps & FlexProps>(({ child, ...restprops }, ref) => {
  const { t: tc } = useTranslation('common')
  const { pathname } = useRouter()

  const activePathname = useMemo(() => {
    if (!pathname) return null
    if (pathname === permalink.home) return '/'
    const path = pathname.split('/')
    return `/${path[2]}/${path[3]}`
  }, [pathname])

  return (
    <Flex
      fontSize={['lg', null, null, null, 'md', 'md']}
      lineHeight='md'
      fontWeight={['700']}
      my={[10]}
      alignItems='center'
      color='grey.300'
      px={[10, 30, 30, 30, 0]}
      sx={{
        '& svg': {
          transition: 'all 0.3s',
          color: activePathname === child.route ? 'primary' : 'grey.300'
        },
        '&:hover': {
          cursor: 'pointer',
          '& svg': {
            transition: 'all 0.3s',
            color: 'primary'
          }
        }
      }}
      ref={ref}
      {...restprops}
    >
      <Icon icon={child.icon} size={20} />
      <Text ml={10} color={['black']}>
        {child.name}
      </Text>
      {child.soon && (
        <Flex
          sx={{
            bg: 'primary',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'sm',
            // height: 14,
            lineHeight: '14px',
            width: 35,
            ml: 10
          }}
        >
          <Text fontSize={8}>{tc('label.soon').toUpperCase()}</Text>
        </Flex>
      )}
      {child.isNew && (
        <Flex
          sx={{
            bg: 'primary',
            borderColor: 'primary',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'sm',
            // height: 14,
            lineHeight: '14px',
            width: 35,
            ml: 8
          }}
        >
          <Text fontSize={8}>{tc('label.new').toUpperCase()}</Text>
        </Flex>
      )}
    </Flex>
  )
})
