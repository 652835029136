import useTheme from 'hooks/common/useTheme'
import { ApproveButtonState } from 'modules/bend/hooks/useBatchBorrow'
import { useTranslation } from 'next-i18next'
import { Flex, Text } from 'rebass/styled-components'
import { Button, Label, Loader } from 'theme/ui'

interface IApproveButton {
  state: ApproveButtonState
  handleClick: any
}
const ApproveButton: React.FC<IApproveButton> = ({ state, handleClick }) => {
  const { t: tc } = useTranslation('common')
  const { colors } = useTheme()

  //const state = useMemo(() => (type === 'debtToken' ? buttonStateDelegate : buttonState), [buttonState, buttonStateDelegate, type])

  return (
    <Flex width={105}>
      {state === ApproveButtonState.APPROVE && (
        <Button size='sm' color='primary' width='100%' onClick={handleClick}>
          <Text fontWeight={'bold'}>{tc('button.approve')}</Text>
        </Button>
      )}
      {state === ApproveButtonState.APPROVED && (
        <Label size='sm' color='green.1000' width='100%' py={6} outlined>
          <Text fontWeight={'bold'}>{tc('button.approved')}</Text>
        </Label>
      )}
      {state === ApproveButtonState.LOADING ? (
        <Button size='sm' color='primary' width='100%' outlined>
          <Loader size={11} color={colors.primary} />
          <Text ml={5} fontWeight={'bold'}>
            {tc('button.loading')}
          </Text>
        </Button>
      ) : (
        state === ApproveButtonState.PROCESSING && (
          <Button size='sm' color='primary' width='100%' outlined>
            <Loader size={11} color={colors.primary} />
            <Text ml={5} fontWeight={'bold'}>
              {tc('button.approving-loading')}
            </Text>
          </Button>
        )
      )}
    </Flex>
  )
}

export default ApproveButton
