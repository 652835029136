import { useActiveWeb3React } from 'modules/wallet-module'
import { useQuery } from 'react-query'

const useENSAvatar = (account: string | null | undefined = '') => {
  const { library } = useActiveWeb3React()

  return useQuery(['get avatar', account, !!library], async () => {
    if (!account || !library) return null
    return await library.getAvatar(account)
  })
}

export default useENSAvatar
