import { ApolloQueryResult, gql } from '@apollo/client'
import { clientBendProtocol, clientNftApi } from 'clients'
import { ETH, PUNKS_NAME } from 'constants/index'
import { LoanState } from 'constants/types'
import { isEmpty, get, orderBy as lodashOrderBy, filter as lodashFilter } from 'lodash'
import { isWeth, isWPunk } from 'utils'
import { MAX_BORROW_MULTIPLIER } from 'modules/bend/constants'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import { OrderDirection } from '../../types'
import { SimpleLoansData } from 'modules/bend/classes/SimpleLoansData'
import { JsonRpcProvider } from '@ethersproject/providers'

type BigInt = string

enum BorrowsOrderBy {
  name = 'name',
  tokenID = 'tokenID',
  currentAmount = 'currentAmount',
  variableBorrowRate = 'variableBorrowRate',
  availableToBorrow = 'availableToBorrow',
  healthFactor = 'healthFactor'
}

export type LoansArgs = {
  account: string | null | undefined
  orderDirection?: OrderDirection
  orderBy?: BorrowsOrderBy
  filter?: Array<string>
  state?: LoanState | [LoanState]
  provider?: JsonRpcProvider
}

export type LoanArgs = LoansArgs & {
  id: string
}

type NftPrice = {
  priceInEth: BigInt
  oracle?: {
    usdPriceEth: BigInt
  }
}

type LoanReserveAsset = {
  underlyingAsset: string
  variableBorrowRate: BigInt
  decimals: number
  name: string
  symbol: string
  price: NftPrice
}

type NftAsset = {
  symbol: string
  name: string
  nftAsset?: string
  underlyingAsset: string
  baseLTVasCollateral: BigInt
  liquidationThreshold: BigInt
  price: NftPrice
  redeemFine: string
  minBidFine: string
  redeemDuration: string
  auctionDuration: string
}

type Loan = {
  id: string
  reserveAsset: LoanReserveAsset
  nftTokenId: string
  currentAmount: BigInt
  nftAsset: NftAsset
  image: string
  // imageType: nftImageUrlType
  imageType: string
  openseaImageURL: string
  loanId: string
  healthFactor: number
  state: LoanState
  availableToBorrow: BigInt
  order: Borrows_NftOrder | null | undefined
  orderBy: any
  bidStartTimestamp: number
  bidPrice: string
}

const QUERY_LOANS_BY_USER = gql`
  query Loans($borrower: String, $state: [LoanState!]) {
    loans(where: { borrower: $borrower, state_in: $state }) {
      id
      state
      loanId
      nftTokenId
      reserveAsset {
        underlyingAsset
        variableBorrowRate
        decimals
        name
        symbol
        price {
          priceInEth
          oracle {
            usdPriceEth
          }
        }
      }
      currentAmount
      nftAsset {
        underlyingAsset
        symbol
        name
        bnftToken
        baseLTVasCollateral
        liquidationThreshold
        price {
          priceInEth
        }
        redeemFine
        minBidFine
        redeemDuration
        auctionDuration
      }
      loanBalanceHistory(orderBy: timestamp, orderDirection: desc) {
        currentAmount
      }
      bidStartTimestamp
      bidPrice
    }
  }
`

const QUERY_LOAN_BY_ID = gql`
  query Loans($id: ID) {
    loans(where: { id: $id }) {
      id
      state
      loanId
      nftTokenId
      reserveAsset {
        underlyingAsset
        variableBorrowRate
        decimals
        name
        symbol
        price {
          priceInEth
          oracle {
            usdPriceEth
          }
        }
      }
      currentAmount
      nftAsset {
        underlyingAsset
        symbol
        name
        bnftToken
        baseLTVasCollateral
        liquidationThreshold
        price {
          priceInEth
        }
        redeemFine
        minBidFine
        redeemDuration
        auctionDuration
      }
      loanBalanceHistory(orderBy: timestamp, orderDirection: desc) {
        currentAmount
      }
      bidStartTimestamp
      bidPrice
    }
  }
`

export type Borrows_NftOrder = {
  id: string
  status: string
  nftItem: {
    collectionAddress: string
    tokenID: string
  }
}

type Borrows_NftOrder_Data = {
  nftOrders: Array<Borrows_NftOrder>
}

export const getLoans = async (args: LoansArgs) => {
  const { account, state } = args
  if (!account) return []
  let { orderDirection, orderBy } = args
  const { filter } = args
  if (typeof orderDirection === 'undefined') {
    orderDirection = OrderDirection.asc
  }

  if (typeof orderBy === 'undefined') {
    orderBy = BorrowsOrderBy.name
  }

  const {
    data: { loans }
  }: ApolloQueryResult<{ loans: Loan[] }> = await clientBendProtocol.query({
    query: QUERY_LOANS_BY_USER,
    variables: {
      borrower: account.toLowerCase(),
      state: !state ? [LoanState.Active, LoanState.Auction] : state
    }
  })

  if (isEmpty(loans)) return []

  const {
    data: { nftOrders }
  }: ApolloQueryResult<Borrows_NftOrder_Data> = await clientNftApi.query({
    query: gql`
      query nftOrders($makerAddress: String, $endTime_gt: Int) {
        nftOrders(makerAddress: $makerAddress, endTime_gt: $endTime_gt) {
          id
          status
          nftItem {
            collectionAddress
            tokenID
          }
        }
      }
    `,
    variables: {
      makerAddress: account.toLowerCase(),
      endTime_gt: moment().unix()
    }
  })

  const payload: Array<Loan> = []

  const nftAddresses: string[] = []
  const nftTokenIds: string[] = []

  for (let i = 0; i < loans.length; i += 1) {
    nftAddresses.push(loans[i].nftAsset.underlyingAsset)
    nftTokenIds.push(loans[i].nftTokenId)

    const nftOrder = nftOrders.find(
      order =>
        loans[i].nftAsset.underlyingAsset.toLowerCase() === order.nftItem.collectionAddress.toLowerCase() &&
        loans[i].nftTokenId === order.nftItem.tokenID &&
        order.status === 'created'
    )

    const LoansData = new SimpleLoansData([loans[i].nftAsset.underlyingAsset], [loans[i].nftTokenId], args?.provider)
    await LoansData.init()

    const floorPrice = new BigNumber(loans[i].nftAsset.price.priceInEth)
      .dividedBy(1e18)
      .dividedBy(new BigNumber(loans[i].reserveAsset.price.priceInEth).dividedBy(1e18))
    const liquidationThreshold = new BigNumber(loans[i].nftAsset.liquidationThreshold).dividedBy(1e4)
    const currentAmount = new BigNumber(await LoansData.getTotalDebtInReserve()).dividedBy(`1e${loans[i].reserveAsset.decimals}`)
    const healthFactor = floorPrice.multipliedBy(liquidationThreshold).dividedBy(currentAmount).dp(2).toNumber()

    const availableToBorrow = new BigNumber(await LoansData.getAvailableBorrowsInReserve())
      .dividedBy(`1e${loans[i].reserveAsset.decimals}`)
      .multipliedBy(MAX_BORROW_MULTIPLIER)

    payload.push({
      id: loans[i].id,
      state: loans[i].state,
      loanId: loans[i].loanId,
      reserveAsset: {
        underlyingAsset: loans[i].reserveAsset.underlyingAsset,
        variableBorrowRate: new BigNumber(100).multipliedBy(new BigNumber(loans[i].reserveAsset.variableBorrowRate).dividedBy(1e27)).dp(2).toFixed(),
        decimals: loans[i].reserveAsset.decimals,
        name: isWeth(loans[i].reserveAsset.underlyingAsset) ? ETH.name : loans[i].reserveAsset.name,
        symbol: isWeth(loans[i].reserveAsset.underlyingAsset) ? ETH.symbol : loans[i].reserveAsset.symbol,
        price: {
          priceInEth: loans[i].reserveAsset.price.priceInEth
        }
      },
      nftTokenId: loans[i].nftTokenId,
      currentAmount: currentAmount.toFixed(),
      nftAsset: {
        symbol: loans[i].nftAsset.symbol,
        name: loans[i].nftAsset.name,
        underlyingAsset: loans[i].nftAsset.underlyingAsset,
        baseLTVasCollateral: new BigNumber(loans[i].nftAsset.baseLTVasCollateral).dividedBy(1e2).toFixed(),
        liquidationThreshold: liquidationThreshold.toFixed(),
        price: {
          priceInEth: loans[i].nftAsset.price.priceInEth
        },
        redeemFine: loans[i].nftAsset.redeemFine,
        minBidFine: loans[i].nftAsset.minBidFine,
        redeemDuration: loans[i].nftAsset.redeemDuration,
        auctionDuration: loans[i].nftAsset.auctionDuration
      },
      image: '',
      imageType: '',
      openseaImageURL: '',
      healthFactor,
      availableToBorrow: availableToBorrow.dp(4, 1).toFixed(loans[i].reserveAsset.decimals),
      order: isEmpty(nftOrder) ? null : nftOrder,
      orderBy: {
        name: isWPunk(loans[i].nftAsset.underlyingAsset) ? PUNKS_NAME : loans[i].nftAsset.name,
        tokenID: isNaN(Number(loans[i].nftTokenId)) ? loans[i].nftTokenId : Number(loans[i].nftTokenId),
        currentAmount: Number(new BigNumber(await LoansData.getTotalDebtInReserve()).dividedBy(`1e${loans[i].reserveAsset.decimals}`).dp(4).toFixed()),
        variableBorrowRate: Number(new BigNumber(100).multipliedBy(new BigNumber(loans[i].reserveAsset.variableBorrowRate).dividedBy(1e27)).dp(2).toFixed()),
        availableToBorrow: availableToBorrow.dp(4, 1).toNumber(),
        healthFactor
      },
      bidStartTimestamp: loans[i].bidStartTimestamp,
      bidPrice: loans[i].bidPrice
    })
  }

  if (isEmpty(filter)) {
    return lodashOrderBy(payload, [`orderBy.${orderBy}`], [orderDirection])
  }

  return lodashOrderBy(
    lodashFilter(payload, item => filter?.includes(item.nftAsset.underlyingAsset)),
    [`orderBy.${orderBy}`],
    [orderDirection]
  )
}

export const getLoan = async (args: LoanArgs) => {
  const { id } = args
  if (!id) return null
  let { orderDirection, orderBy } = args
  if (typeof orderDirection === 'undefined') {
    orderDirection = OrderDirection.asc
  }

  if (typeof orderBy === 'undefined') {
    orderBy = BorrowsOrderBy.name
  }

  const {
    data: { loans }
  }: ApolloQueryResult<{ loans: Loan[] }> = await clientBendProtocol.query({
    query: QUERY_LOAN_BY_ID,
    variables: {
      id
    }
  })

  if (isEmpty(loans)) return []

  const payload: Array<Loan> = []

  const nftAddresses: string[] = []
  const nftTokenIds: string[] = []

  for (let i = 0; i < loans.length; i += 1) {
    nftAddresses.push(loans[i].nftAsset.underlyingAsset)
    nftTokenIds.push(loans[i].nftTokenId)

    const {
      data: { nftOrders }
    }: ApolloQueryResult<Borrows_NftOrder_Data> = await clientNftApi.query({
      query: gql`
        query nftOrders($collectionAddress: String, $tokenID: String, $endTime_gt: Int) {
          nftOrders(collectionAddress: $collectionAddress, tokenID: $tokenID, endTime_gt: $endTime_gt) {
            status
            nftItem {
              collectionAddress
              tokenID
            }
          }
        }
      `,
      variables: {
        collectionAddress: loans[i].nftAsset.underlyingAsset,
        tokenID: loans[i].nftTokenId,
        endTime_gt: moment().unix()
      }
    })

    const nftOrder = nftOrders.find(
      order =>
        loans[i].nftAsset.underlyingAsset.toLowerCase() === order.nftItem.collectionAddress.toLowerCase() &&
        loans[i].nftTokenId === order.nftItem.tokenID &&
        order.status === 'created'
    )

    const LoansData = new SimpleLoansData([loans[i].nftAsset.underlyingAsset], [loans[i].nftTokenId])
    await LoansData.init()

    const floorPrice = new BigNumber(loans[i].nftAsset.price.priceInEth)
      .dividedBy(1e18)
      .dividedBy(new BigNumber(loans[i].reserveAsset.price.priceInEth).dividedBy(1e18))
    const liquidationThreshold = new BigNumber(loans[i].nftAsset.liquidationThreshold).dividedBy(1e4)
    const currentAmount = new BigNumber(await LoansData.getTotalDebtInReserve()).dividedBy(`1e${loans[i].reserveAsset.decimals}`)
    const healthFactor = floorPrice.multipliedBy(liquidationThreshold).dividedBy(currentAmount).dp(2).toNumber()

    const availableToBorrow = new BigNumber(await LoansData.getAvailableBorrowsInReserve())
      .dividedBy(`1e${loans[i].reserveAsset.decimals}`)
      .multipliedBy(MAX_BORROW_MULTIPLIER)

    payload.push({
      id: loans[i].id,
      state: loans[i].state,
      loanId: loans[i].loanId,
      reserveAsset: {
        underlyingAsset: loans[i].reserveAsset.underlyingAsset,
        variableBorrowRate: new BigNumber(100).multipliedBy(new BigNumber(loans[i].reserveAsset.variableBorrowRate).dividedBy(1e27)).dp(2).toFixed(),
        decimals: loans[i].reserveAsset.decimals,
        name: isWeth(loans[i].reserveAsset.underlyingAsset) ? ETH.name : loans[i].reserveAsset.name,
        symbol: isWeth(loans[i].reserveAsset.underlyingAsset) ? ETH.symbol : loans[i].reserveAsset.symbol,
        price: {
          priceInEth: loans[i].reserveAsset.price.priceInEth
        }
      },
      nftTokenId: loans[i].nftTokenId,
      currentAmount: currentAmount.dp(4).toFixed(),
      nftAsset: {
        symbol: loans[i].nftAsset.symbol,
        name: loans[i].nftAsset.name,
        underlyingAsset: loans[i].nftAsset.underlyingAsset,
        baseLTVasCollateral: new BigNumber(loans[i].nftAsset.baseLTVasCollateral).dividedBy(1e2).toFixed(),
        liquidationThreshold: liquidationThreshold.toFixed(),
        price: {
          priceInEth: loans[i].nftAsset.price.priceInEth
        },
        redeemFine: loans[i].nftAsset.redeemFine,
        minBidFine: loans[i].nftAsset.minBidFine,
        redeemDuration: loans[i].nftAsset.redeemDuration,
        auctionDuration: loans[i].nftAsset.auctionDuration
      },
      image: '',
      imageType: '',
      openseaImageURL: '',
      healthFactor,
      availableToBorrow: availableToBorrow.dp(4, 1).toFixed(),
      order: isEmpty(nftOrder) ? null : nftOrder,
      orderBy: {
        name: isWPunk(loans[i].nftAsset.underlyingAsset) ? PUNKS_NAME : loans[i].nftAsset.name,
        tokenID: isNaN(Number(loans[i].nftTokenId)) ? loans[i].nftTokenId : Number(loans[i].nftTokenId),
        currentAmount: Number(new BigNumber(await LoansData.getTotalDebtInReserve()).dividedBy(`1e${loans[i].reserveAsset.decimals}`).dp(4).toFixed()),
        variableBorrowRate: Number(new BigNumber(100).multipliedBy(new BigNumber(loans[i].reserveAsset.variableBorrowRate).dividedBy(1e27)).dp(2).toFixed()),
        availableToBorrow: availableToBorrow.dp(4, 1).toNumber(),
        healthFactor
      },
      bidStartTimestamp: loans[i].bidStartTimestamp,
      bidPrice: loans[i].bidPrice
    })
  }

  return get(payload, 0)
}
