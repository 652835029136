import { useQuery, UseQueryResult } from 'react-query'
import { clientNftApi } from 'clients'
import { ApolloQueryResult, gql } from '@apollo/client'
import { nftImageUrlType } from 'constants/index'
import { Box, Image as ImageRebass, ImageProps } from 'rebass/styled-components'
import PreloadImage, { PreloadImageProps } from '../preload-image'
import { useEffect, useState } from 'react'
import { isPunk, isWPunk } from 'utils'

interface NftImage {
  collectionAddress: string
  tokenId: string
}

const GET_COLLECTION_IMAGE_BY_ADDRESS = gql`
  query NftItemImageByCollectionAddressAndTokenID($collectionAddress: String!, $tokenID: String!) {
    nftItem(collectionAddress: $collectionAddress, tokenID: $tokenID) {
      storageImageUrl
    }
  }
`

const NftImage: React.FC<NftImage & Omit<ImageProps, 'src'> & PreloadImageProps> = ({
  collectionAddress,
  tokenId,
  zoom,
  width = '100%',
  height = '100%',
  ...restprops
}) => {
  const { data, isLoading, isIdle }: UseQueryResult<string, any> = useQuery(['nft token image', collectionAddress, tokenId], async () => {
    const {
      data: { nftItem }
    }: ApolloQueryResult<{ nftItem: { image: string; imageType: nftImageUrlType; storageImageUrl: string } }> = await clientNftApi.query({
      query: GET_COLLECTION_IMAGE_BY_ADDRESS,
      variables: {
        collectionAddress: collectionAddress,
        tokenID: tokenId
      }
    })

    if (nftItem?.storageImageUrl) return nftItem.storageImageUrl

    return undefined
  })

  const [preloadSrc, setPreloadSrc] = useState('')
  useEffect(() => {
    if (!data) return
    const image = new Image()
    image.src = data
    image.onload = () => {
      setPreloadSrc(data)
    }
  }, [data])

  if (isIdle || isLoading || !preloadSrc)
    return (
      <Box sx={{ minWidth: '100%', minHeight: '100%', mb: 1 }}>
        <ImageRebass src='/images/no-image.png' width={width} height={height} sx={{ display: 'block', ...restprops.sx }} />
      </Box>
    )

  return (
    <PreloadImage
      src={preloadSrc}
      sx={{
        objectFit: 'cover',
        bg: isWPunk(collectionAddress) || isPunk(collectionAddress) ? 'cryptopunk' : undefined
      }}
      width={width}
      height={height}
      zoom={zoom}
      {...restprops}
    />
  )
}

export default NftImage
