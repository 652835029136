import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import moment from 'moment'
import { JsonRpcProvider, WebSocketProvider } from '@ethersproject/providers'
// Hooks
import { useActiveWeb3React } from '../hooks'
import { useWeb3React } from '@web3-react/core'
// Constants
import { DEFAULT_CHAIN_ID } from 'constants/index'
// Components

const initialState = {
  walletModalOpen: false,
  chainEnabled: false,
  transactions: [],
  lastBlockNumber: 0,
  waitTxConfirm: false,
  httpProvider: undefined,
  wsProvider: undefined
}
const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'UPDATE':
      return Object.assign(Object.assign({}, state), payload)
    default:
      return state
  }
}
export const WalletContext = React.createContext(Object.assign({}, initialState))
export const shouldCheck = (lastBlockNumber, tx) => {
  if (tx.receipt) return false
  if (tx.confirmed) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (moment().unix() - tx.addedTime) / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}
const WalletContextProvider = ({ children }) => {
  const [WalletState, WalletDispatch] = React.useReducer(reducer, initialState)
  const { account } = useWeb3React()
  const { lastBlockNumber, transactions } = WalletState
  const { library: injectedLibrary } = useActiveWeb3React()

  const providerHttpRef = useRef()
  const providerWsRef = useRef()

  useEffect(() => {
    providerHttpRef.current = new JsonRpcProvider(String(process.env.NEXT_PUBLIC_NETWORK_URL))
    providerWsRef.current = new WebSocketProvider(String(process.env.NEXT_PUBLIC_NETWORK_URL_WSS))
  }, [])

  useEffect(() => {
    WalletDispatch({
      type: 'UPDATE',
      payload: {
        wsProvider: providerWsRef.current
      }
    })
  }, [providerWsRef.current])

  useEffect(() => {
    WalletDispatch({
      type: 'UPDATE',
      payload: {
        httpProvider: providerHttpRef.current
      }
    })
  }, [providerHttpRef.current])

  const library = useMemo(() => {
    if (injectedLibrary) return injectedLibrary
    else return providerWsRef.current
  }, [injectedLibrary, providerWsRef.current])

  const [lastBlockNumberChecked, setLastBlockNumberChecked] = useState(0)
  const blockNumberCallback = useCallback(blockNumber => {
    WalletDispatch({
      type: 'UPDATE',
      payload: {
        lastBlockNumber: blockNumber
      }
    })
  }, [])
  const addTransaction = useCallback(
    (tx, callback = () => {}) => {
      const hash = tx.hash
      const txs = transactions !== null && transactions !== void 0 ? transactions : {}
      txs[hash] = {
        hash,
        from: account,
        addedTime: moment().unix(),
        confirmed: false,
        callback
      }
      WalletDispatch({
        type: 'UPDATE',
        payload: {
          transactions: txs,
          waitTxConfirm: true
        }
      })
    },
    [transactions, account]
  )
  const [txHashChecking, setTxHashChecking] = useState('')
  useEffect(() => {
    if (!library || !lastBlockNumber || lastBlockNumberChecked >= lastBlockNumber) return
    setLastBlockNumberChecked(lastBlockNumber)
    Object.keys(transactions)
      .filter(hash => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach(hash => {
        if (hash === txHashChecking) return
        setTxHashChecking(hash)
        library
          .getTransactionReceipt(hash)
          .then(receipt => {
            if (receipt) {
              const txs = transactions
              txs[hash].confirmed = true
              txs[hash].receipt = {
                blockHash: receipt.blockHash,
                blockNumber: receipt.blockNumber,
                contractAddress: receipt.contractAddress,
                from: receipt.from,
                status: receipt.status,
                to: receipt.to,
                transactionHash: receipt.transactionHash,
                transactionIndex: receipt.transactionIndex
              }
              WalletDispatch({
                type: 'UPDATE',
                payload: {
                  transactions: txs,
                  waitTxConfirm: false
                }
              })
              if (receipt.status === 1) {
                txs[hash].callback({ tx: receipt.transactionHash, status: 'success' })
              } else {
                txs[hash].callback({ tx: receipt.transactionHash, status: 'fail' })
              }
              setTxHashChecking('')
            } else {
              const txs = transactions
              txs[hash].lastCheckedBlockNumber = lastBlockNumber
              WalletDispatch({
                type: 'UPDATE',
                payload: {
                  transactions: txs
                }
              })
              setTxHashChecking('')
            }
          })
          .catch(error => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
      })
  }, [txHashChecking, setTxHashChecking, lastBlockNumberChecked, setLastBlockNumberChecked, library, transactions, lastBlockNumber, blockNumberCallback])
  // attach/detach listeners
  useEffect(() => {
    if (!library) return undefined
    try {
      library
        .getBlockNumber()
        .then(blockNumberCallback)
        .catch(error => console.error(`Failed to get block number for chainId: ${DEFAULT_CHAIN_ID}`, error))
      library.on('block', blockNumberCallback)
    } catch (e) {
      console.log('e', e)
    }
    return () => {
      library.removeListener('block', blockNumberCallback)
    }
  }, [library, blockNumberCallback])
  return React.createElement(
    WalletContext.Provider,
    { value: Object.assign(Object.assign({}, WalletState), { WalletDispatch, addTransaction: addTransaction }) },
    children
  )
}
export default WalletContextProvider
